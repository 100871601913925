import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import { compressImage, headlineValidationLogic, imageFromFile, photoValidationLogic } from '../../../utils/FieldValidations';
import SketchExample from './SketchExample'
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import "cropperjs/dist/cropper.css";

export default function CreateAdHeadingSection(props) {
  const {
    headline,
    headlineChange,
    backgroundColor,
    handleChangeBackgroundColor,
    headlineColor,
    handleChangeHeadlineColor,
    fileupload,
    imageplacement,
    handlePhoto,
    buttontext,
    buttontextChange,
    buttonTextColor,
    handleChangeButtonTextColor,
    buttonColor,
    handleChangeButtonColor,
    buttonBodyColor,
    handleChangeButtonBodyColor,
    upload,
    interval,
    hide,
    info,
    popup,
    handleCreative,
    prof,
    dr2,
    dr,
    handleProf2,
  } = props

  const [showPhotoCropperModal, setShowPhotoCropperModal] = useState(false);
  const [photoToCrop, setPhotoToCrop] = useState("");
  const photoInputRef = useRef();

  const handleClosePhotoCropperModal = () => {
    setPhotoToCrop("");
    photoInputRef.current.value = "";
    setShowPhotoCropperModal(false)};
  const handleShowPhotoCropperModal = () => setShowPhotoCropperModal(true);

  const cropperRef = useRef(null);


  const applyCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    const croppedURL = cropper.getCroppedCanvas().toDataURL();
    compressImage(croppedURL).then(data => {
      handlePhoto(data);
      handleClosePhotoCropperModal();
    }).catch(err => {
      console.log(err);
      handleClosePhotoCropperModal();
    });
  }

  const PhotoCropModal = () => {
    return <Modal show={showPhotoCropperModal} onHide={handleClosePhotoCropperModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop your image</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '1000px'}}>
        <Cropper
        style={{maxHeight: "600px"}}
          src={photoToCrop}
          // style={{ height: 400, width: "100%" }}
          // Cropper.js options
          // initialAspectRatio={29 / 30}
          minCropBoxWidth={100}
          viewMode={1}
          aspectRatio={29 / 30}
          guides={false}
          ref={cropperRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosePhotoCropperModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={applyCrop}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const startCropper = async (file) => {
    const image = await imageFromFile(file);
    setPhotoToCrop(image);
    handleShowPhotoCropperModal();
  }



  const verifyAndHandlePhoto = (e) => {

    const eventInstance = { ...e };
    const file = e.target.files[0]
    if(!file) return;

    photoValidationLogic(file, () => { startCropper(file); }, (error) => { window.alert(error) });
  };

  const [validationMessages, _setValidationMessages] = React.useState({});

  const setValidationMessage = (field, message) => {
    _setValidationMessages((prevState) => ({ ...prevState, [field]: message }));
  }

  const validateHeadline = useCallback(() => {
    setValidationMessage("headline", headlineValidationLogic(headline));
  }, [headline]);

  useEffect(() => {
    validateHeadline();
  }, [headline, validateHeadline]);

  const MandatoryAsteriskIndicator = ({ message }) => {
    return <span className="mandatory-asterisk-indicator" title={message}>*</span>
  }

  

  return (
    <div className="col-lg-4 col-md-12" id="Section-1">
      <div className="row">
        <label className="label-text3">Headline <MandatoryAsteriskIndicator message={"Headline is required"} /></label>
      </div>
      <div className="row">
        <span style={{ width: '100%' }}>
          <input
            className={classNames("form-control", { "is-invalid": !!validationMessages.headline })}
            type="text"
            name="text"
            placeholder={"Headline Goes Here"}
            onChange={headlineChange}
            defaultValue={headline}
            aria-describedby="headlineHelp"
            maxLength={20}
          />{' '}
          <div class="invalid-feedback">
            {validationMessages.headline}
          </div>
          {
            !validationMessages.headline && <small id="headlineHelp" class="form-text text-muted">
              {headline.length}/20
            </small>
          }
        </span>
      </div>
      <div style={{ marginTop: '1%' }} className="row">
        <div className="col-6 settings color" style={{ marginTop: '2%' }}>
          <SketchExample
            color={backgroundColor}
            onChangeComplete={handleChangeBackgroundColor}
          />
          <label className="label-text2">Background Color</label>
        </div>
        <div className="col-6 settings color" style={{ marginTop: '2%' }}>
          <SketchExample
            color={headlineColor}
            onChangeComplete={handleChangeHeadlineColor}
          />
          <label className="label-text2">Text Color</label>
        </div>
      </div>
      <div style={{ marginTop: '5%' }} className="row">
        <label className="label-text3">Your Image <MandatoryAsteriskIndicator message={"Your Image is required"} /></label>
      </div>
      <div className="row">
        <div style={{ padding: '0px' }} className="col-6">
          <div className="settings">
            <label
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                display: 'block',
              }}
              for="photo-upload"
              className="label-text2"
            >
              <img
                style={{ float: 'left', width: '120px',cursor:'pointer' }}
                alt=""
                src={fileupload}
              /></label>
              <div >
              <img
                src={imageplacement}
                alt=""
                style={{ float: 'left', width: '55px' }}
              />
              <div
                className="label-text2"
                style={{ float: 'left', fontSize: '10px', lineHeight: '1.5' }}
              >
                (.jpg/.png/.gif)
                <br />
                320px wide
                <br />
                Up to 4MB
              </div>
              </div>
            
            <div className="form-group">
              <input
              ref={photoInputRef}
                style={{ display: 'none' }}
                id="photo-upload"
                type="file"
                onChange={verifyAndHandlePhoto}
                accept="image/gif, image/jpeg, image/png"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '5%' }} className="row">
        <label className="label-text3">Button</label>
      </div>
      <div className="row">
        <input
          className="form-control"
          type="text"
          name="text"
          placeholder={buttontext}
          onChange={buttontextChange}
        />
      </div>
      <div style={{ marginTop: '1%' }} className="row">
        <div className="col-6 settings color" style={{ marginTop: '2%' }}>
          <SketchExample
            color={buttonColor}
            onChangeComplete={handleChangeButtonColor}
          />
          <label className="label-text2">Background Color</label>
        </div>
        <div className="col-6 settings color" style={{ marginTop: '2%' }}>
          <SketchExample
            color={buttonBodyColor}
            onChangeComplete={handleChangeButtonBodyColor}
          />
          <label className="label-text2">Button Color</label>
        </div>
        <div className="col-6 settings color" style={{ marginTop: '2%' }}>
          <SketchExample
            color={buttonTextColor}
            onChangeComplete={handleChangeButtonTextColor}
          />
          <label className="label-text2">Button Text Color</label>
        </div>
      </div>
      <div style={{ marginTop: '5%' }} className="row">
        <div className="form-group">
          <div style={{ marginLeft: '0%' }} className="row">
            <label style={{}} className="label-text3">
              Have your own ad you would like to use?
            </label>
          </div>
          <div style={{ marginLeft: '0%' }} className="row">
            <button className='btn btn-link' style={{ padding: '10px 0px' }}
              // onClick={() => {
              //   props.history.push(`/custom-ad-design?campaign=${campaignID}`)
              // }}
              onClick={handleCreative}
            >Upload your own ad</button>
            {/*input style={{marginLeft:'2%'}} type="checkbox" onClick={handleAdditional} checked={additional} />*/}
            {/*
            <label
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                display: 'block',
              }}
              for="creative-upload"
              className="label-text3"
            >
              <img
                style={{ marginTop: '-1%', marginLeft: '1%' }}
                src={upload}
                alt=""
                height="50px"
              />
            </label>
            <div style={{ marginBottom: '44px' }}>
              <img
                src={imageplacement}
                alt=""
                style={{ float: 'left', width: '55px' }}
              />
              <div
                className="label-text2"
                style={{ float: 'left', fontSize: '10px', lineHeight: '1.5' }}
              >
                (.jpg/.png/.gif)
                <br />
                320px wide
                <br />
                Up to 4MB
              </div>
            </div>
            <div className="form-group">
              <input
                style={{ display: 'none' }}
                id="creative-upload"
                type="file"
                onChange={handleCreative}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group">
          <label style={{}} className="label-text3">
            Interested in professional design services?
          </label>
          {/*<input style={{marginLeft:'3.5%'}} type="checkbox" onClick={handleCsv} checked={csv} /> */}
          <img
            style={{ marginLeft: '1%' }}
            alt=""
            src={prof ? dr2 : dr}
            height="60px"
            onClick={handleProf2}
          />
          {/*<input style={{}} type="checkbox" onClick={handleProf} checked={prof} />*/}
        </div>
      </div>
      <PhotoCropModal />
    </div>
  )
}
