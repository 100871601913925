import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Cropper } from "react-cropper";
import { compressImage, imageFromFile, logoValidationLogic, messagingValidationLogic, stripTags, subheadingValidationLogic, urlValidationLogic } from "../../../utils/FieldValidations";
import RichTextArea from "../../common/RichTextArea";
import SketchExample from "./SketchExample";

function CreateAdSubHeadingSection(props) {
  const {
    subhead,
    subheadChange,
    messaging,
    messagingChange,
    backgroundColor2,
    handleChangeBackgroundColor2,
    subheadColor,
    handleChangeSubheadColor,
    phone,
    phoneChange,
    imageplacement,
    fileupload,
    backgroundColor3,
    handleLogo,
    url,
    urlChange,
    handleChangeBackgroundColor3,
  } = props;

  /** Logo */
  const [showLogoCropperModal, setShowLogoCropperModal] = useState(false);
  const [logoToCrop, setLogoToCrop] = useState("");

  const handleCloseLogoCropperModal = () => {
    setLogoToCrop("");
    logoInputRef.current.value = "";
    setShowLogoCropperModal(false);}
  const handleShowLogoCropperModal = () => setShowLogoCropperModal(true);

  const logoCropperRef = useRef(null);
  const logoInputRef = useRef();


  const applyLogoCrop = () => {
    const imageElement = logoCropperRef.current;
    const cropper = imageElement.cropper;
    const croppedURL = cropper.getCroppedCanvas().toDataURL();
    compressImage(croppedURL, 100).then(data => {
      handleLogo(croppedURL);
      handleCloseLogoCropperModal();
    }).catch(err => {
      console.log(err);
      handleCloseLogoCropperModal();
    });
  }

  const LogoCropModal = () => {
    return <Modal show={showLogoCropperModal} onHide={handleCloseLogoCropperModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop your logo</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '500px'}}>
        <Cropper
        style={{maxHeight: "490px"}}
          src={logoToCrop}
          viewMode={1}
          // style={{ height: 400, width: "100%" }}
          // Cropper.js options
          // initialAspectRatio={29 / 30}
          minCropBoxWidth={100}
          aspectRatio={215 / 75}
          guides={false}
          ref={logoCropperRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseLogoCropperModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={applyLogoCrop}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const startLogoCropper = async (file) => {
    const image = await imageFromFile(file);
    setLogoToCrop(image);
    handleShowLogoCropperModal();
    console.log("Entered start Logo")
  }



  const verifyAndHandleLogo = (e) => {
    const eventInstance = { ...e };
    const file = e.target.files[0]
    if (!file) return;
    logoValidationLogic(file, () => { startLogoCropper(file); }, (error) => { window.alert(error) });
  };

  const [validationMessages, _setValidationMessages] = React.useState({});

  const setValidationMessage = (field, message) => {
    _setValidationMessages((prevState) => ({ ...prevState, [field]: message }));
  }

  const validateSubheading = useCallback(() => {
    setValidationMessage("subhead", subheadingValidationLogic(subhead));
  }, [subhead]);

  useEffect(() => {
    validateSubheading();
  }, [subhead, validateSubheading]);

  const validateMessaging = useCallback(() => {
    setValidationMessage("messaging", messagingValidationLogic(messaging));
    console.log(validationMessages)
  }, [messaging]);

  useEffect(() => {
    validateMessaging();
  }, [messaging, validateMessaging]);

  const validateURL = useCallback(() => {
    setValidationMessage("url", urlValidationLogic(url));
  }, [url]);

  useEffect(() => {
    validateURL();
  }, [url, validateURL]);

  const MandatoryAsteriskIndicator = ({ message }) => {
    return <span className="mandatory-asterisk-indicator" title={message}>*</span>
  }


  return (
      <div className="col-lg-4 col-md-12 Section-2">
        <div className="row">
        <div className="col-12 text-right no-right-padding">
          <label className="label-text3" for="subheading">Subhead <MandatoryAsteriskIndicator message={"Subhead is required"} /></label>
          </div>
        </div>
        <div className="row">
          <input
          className={classNames("form-control", { "is-invalid": !!validationMessages.subhead })}
            type="text"
          id="subheading"
          placeholder={"Subhead Goes Here"}
          defaultValue={subhead}
            onChange={subheadChange}
          aria-describedby="subheadHelp"
          maxLength={30}
          />
        <div class="invalid-feedback">
          {validationMessages.subhead}
        </div>
        {
          !validationMessages.subhead && <small id="subheadHelp" class="form-text text-muted">
           {subhead.length}/30
          </small>
        }
        </div>
        <div style={{ marginTop: "5%" }} className="row">
        <div className="col-12 text-right no-right-padding">
          <label className="label-text3" for="messaging">Messaging <MandatoryAsteriskIndicator message={"Messaging is required"} /></label>
          </div>
        </div>
        <div className="row">
          <span style={{ width: "100%" }}>
          <RichTextArea
            type="text"
            placeholder={"Body text/Messaging goes here"}
            className={classNames({ "invalid-richtext": !!validationMessages.messaging })}
            id="messaging" aria-describedby="messagingHelp" value={messaging} onChange={messagingChange} />
          {/* <input
              className="form-control"
              type="text"
            id="messaging"
              placeholder={messaging}
              onChange={messagingChange}
            />{" "} */}
          </span>

        <div class="invalid-feedback" style={{ display: validationMessages.messaging ? 'block' : 'none' }}>
          {validationMessages.messaging}
        </div>
        {
          !validationMessages.messaging && <small id="messagingHelp" class="form-text text-muted">
            {stripTags(messaging).length}/70
          </small>
        }
        </div>
        <div style={{ marginTop: "1%" }} className="row">
          <div className="col-6 settings color" style={{ marginTop: "2%" }}>
            <SketchExample
              color={backgroundColor2}
              onChangeComplete={handleChangeBackgroundColor2}
            />
            <label className="label-text2">Background Color</label>
          </div>
          <div className="col-6 settings color" style={{ marginTop: "2%" }}>
            <SketchExample
              color={subheadColor}
              onChangeComplete={handleChangeSubheadColor}
            />
            <label className="label-text2">Text Color</label>
          </div>
        </div>
        <div style={{ marginTop: "5%" }} className="row">
        <div className="col-12 text-right no-right-padding">
          <label className="label-text3">Phone # </label>
          </div>
        </div>
        <div className="row">
          <span style={{ width: "100%" }}>
            <input
              className="form-control"
              type="tel"
              name="text"
            placeholder={'000-000-0000'}
            value={phone}
            onChange={phoneChange}
           
            />{" "}
          </span>
        </div>
        <div style={{ marginTop: "5%" }} className="row">
        <div className="col-12 text-right no-right-padding">
          <label className="label-text3">Ad URL <MandatoryAsteriskIndicator message={"Ad URL is required"} /></label>
          </div>
        </div>
        <div className="row">
          <span style={{ width: "100%" }}>
            <input
              className="form-control"
              type="text"
              name="text"
              defaultValue={url}
            placeholder={"http://example.com"}
              onChange={urlChange}
            />{" "}
          <div class="invalid-feedback" style={{ display: validationMessages.url ? 'block' : 'none' }}>
            {validationMessages.url}
          </div>
          </span>
        </div>
        <div style={{ marginTop: "5%" }} className="row">
        <div className="col-12 text-right no-right-padding">
          <label className="label-text3">Your Logo <MandatoryAsteriskIndicator message={"Your Logo is required"} /></label>
          </div>
        </div>
        <div className="row">
          <div style={{ padding: "0px", marginLeft: "38%" }} className="col-6">
            <div className="settings">
              <label
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  display: "block",
                }}
                for="logo-upload"
                className="label-text2"
              >
                <div
                  style={{
                    float: "left",
                    fontSize: "10px",
                    lineHeight: "1.5",
                  }}
                >
                (.jpg/.png/.gif)
                <br />
                200px wide
                <br />
                Up to 4MB
                </div>
                <img
                  src={imageplacement}
                  style={{ float: "left", width: "55px" }}
                  alt=""
                />
                <img
                  style={{ float: "left", width: "120px",cursor:'pointer' }}
                  alt=""
                  src={fileupload}
                />
              </label>
              <div className="form-group">
                <input
                ref={logoInputRef}
                  style={{ display: "none" }}
                  id="logo-upload"
                  type="file"
                accept="image/gif, image/jpeg, image/png"
                onChange={(e) => {
                  verifyAndHandleLogo(e);
                }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "1%", float: "right" }}
          className="row text-right"
        >
          <div className="col-6 settings color " style={{ marginTop: "2%" }}>
            <label className="label-text2">Logo Background Color</label>
            <SketchExample
              color={backgroundColor3}
              onChangeComplete={handleChangeBackgroundColor3}
            />
          </div>
        </div>

        <div style={{ marginTop: "4%", float: "right" }} className="row text-right">
          <span className="mandatory-asterisk-indicator" style={{ fontWeight: "800" , marginTop: "2px", marginRight:"6px"}}>*</span>
          <label class="label-text2">All fields marked with an asterisk (*) are required</label>
      
       </div>
       

      <LogoCropModal />
      </div>
  );
}

export default CreateAdSubHeadingSection;
