import React, {useState} from 'react';
import {Link,Redirect,useLocation} from "react-router-dom";
import { FaRegEnvelope } from 'react-icons/fa';
import { Form, Spin, Input, Button, notification, Col, Row , Popover} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { FiLock } from 'react-icons/fi';
import queryString from 'query-string';


function PasswordResetBox() {
    let location = useLocation();
    const [form] = Form.useForm();
    const passwordValidator = require('password-validator');

    const[redirect,setRedirect] = useState(false);
    const[loading,setLoading] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);


    const onFinish = (values) => {
        //let locationObj = queryString.parse(location.search);
        let { password1, code } = values;
        let username = location.search.split('=')[1];
        Auth.forgotPasswordSubmit(username.trim(), code.trim(), password1.trim())
        .then(() => {
            setRedirect(true);
        })
        .catch(err => {
          alert(err.message)
          setLoading(false);
        });

      // show loader
      setLoading(true);
    
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const title = 'Password Policy';
      const passwordPolicyContent = (
        <React.Fragment>
          <h4>Your password should contain: </h4>
          <ul>
            <li>Minimum length of 8 characters</li>
            <li>Numerical characters (0-9)</li>
            <li>Special characters</li>
            <li>Uppercase letter</li>
            <li>Lowercase letter</li>
          </ul>
        </React.Fragment>
      );
      const schema = new passwordValidator();
      schema
      .is()
      .min(8)
      .has()
      .uppercase()
      .has()
      .lowercase()
      .has()
      .digits()
      .has()
      .symbols(); 
      const formatPasswordValidateError = (errors) => {
        for (let i = 0; i < errors.length; i++) {
          if (errors[i] === 'min') {
            return 'password length should be a at least 8 characters';
          } else if (errors[i] === 'lowercase') {
            return 'password should contain lowercase letters';
          } else if (errors[i] === 'uppercase') {
            return 'password should contain uppercase letters';
          } else if (errors[i] === 'digits') {
            return 'password should contain digits';
          } else if (errors[i] === 'symbols') {
            return 'password should contain symbols';
          }
        }
      };
      const validateToNextPassword = (rule, value, callback) => {
        const validationRulesErrors = schema.validate(value, { list: true });
    

        if (validationRulesErrors.length > 0) {
          callback(formatPasswordValidateError(validationRulesErrors));
        }
        callback();
      };
      const compareToFirstPassword = (rule, value, callback) => {
        console.log(form.getFieldValue('password1'));
        if (value && value !== form.getFieldValue('password1')) {
          callback('Two passwords that you entered are inconsistent!');
        } else {
          callback();
        }
      };
    const handleConfirmBlur = (event) => {
        const { value } = event.currentTarget;
    
        setConfirmDirty(confirmDirty || !!value );
      };
      return (
        <>
        <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        {'Reset Password'}
                    </h3>
                    <div className="text-center">
            <p>Check your email for the confirmation code</p>
          </div>
                </div>
<div className="billing-content">
  <div className="contact-form-action">
    <Form  form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
    <div className="row justify-content-end">
 
          <div className="col-lg-8">
          <div className="input-box">
            <div className="form-group">
              <span className="form-icon">
                    <FiLock />
                </span>
              <Form.Item label="Confirmation Code" name="code" rules= {[{ required: true, message: 'Please input your Confirmation Code!' }] }>
                    <Input 
                      className="form-control"
                      rules={[{required:true,message:'Please input your verification code!'}]} 
                      placeholder="Enter your verification code"
                    />
              </Form.Item>
            </div>
          </div>
          </div>
          <div className="col-lg-8">
          <div className="input-box">
            <div className="form-group">
          <Form.Item>
            <span className="form-icon">
                <FiLock />
            </span>
            <Popover placement="right" title={title} content={passwordPolicyContent} trigger="focus">
                <Form.Item  label="Password" name="password1" rules= {[{ required: true, message: 'Please input your Password!' },{validator: validateToNextPassword}] }>
                    <Input  className="form-control" placeholder='Enter password' type="Password"/>
                </Form.Item>
            </Popover>
          </Form.Item>
</div>
</div>
</div>


<div className="col-lg-8">
          <div className="input-box">
            <div className="form-group">
          <span className="form-icon">
                <FiLock />
            </span>
            <Form.Item  label="Confirm Password" name="password2" rules={[{required:true,message:'Please confirm your password!'},{validator:compareToFirstPassword}]}>
                 <Input  className="form-control" placeholder='Confirm password' type="password" onBlur={handleConfirmBlur}/>
            </Form.Item>
            </div>
            </div>
            </div>


            <div className="col-lg-8">
            <div className="btn-box margin-top-20px margin-bottom-20px">
            <Button className="theme-btn border-0" htmlType="submit" disabled={loading}>
                {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Change Password'}
            </Button>
            </div>
            </div>
             
          </div>
        </Form>
        {redirect && <Redirect to={{ pathname: '/login' }} />}
        </div>
        </div>
        </div>
        </>
        );

}

export default PasswordResetBox;