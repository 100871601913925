import React, {useState} from 'react';
import {Link,Redirect} from "react-router-dom";
import { FaRegEnvelope } from 'react-icons/fa';
import { Form, Icon, Spin, Input, Button, notification, Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';

function RecoverPassBox(props) {
    const [form] = Form.useForm();
    const[loading,setLoading] = useState(false);
    const[redirect,setRedirect] = useState(false);
    const[email,setEmail]=useState('');
    const onFinish = (values) => {
        setLoading(true);
        let { email } = values;
        setEmail(email);
        Auth.forgotPassword(email)
          .then(data => {
            setRedirect(true);
          })
          .catch(err => {
            alert(err.message);
            setLoading(false);
          });
      
    
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return (
        <>
            <section className="form-shared padding-top-0px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 mx-auto changePass-align">
                            <div className="billing-form-item mb-0">
                                <div className="billing-title-wrap">
                                    <h3 className="widget-title font-size-28">{props.message || "Recover Password!"}</h3>
                                    <p className="font-size-16 font-weight-medium">Enter the email of your account
                                        to reset password.
                                        Then you will receive a link to email to reset the password.  If you have any
                                        issue about reset password <Link to="/contact" className="color-text">contact us.</Link>
                                    </p>
                                </div>
                                <div className="billing-content">
                                    <div className="contact-form-action">
                                    <Form  form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                            <div className="input-box">
                                                <div className="form-group">
                                                    <span className="la form-icon"><FaRegEnvelope /></span>
                                                    <Form.Item  label="Email" name="email" rules={[{required:true,message:'Please input your email!'}]}>
                                                <Input className="form-control" placeholder='Email' type="text" />
                                                    </Form.Item>                                                </div>
                                            </div>
                                            <div className="btn-box margin-top-20px margin-bottom-20px">
                                            <Button className="theme-btn border-0" htmlType="submit" disabled={loading}>
                                        {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Reset Password'}
                                        </Button>
                                            </div>
                                            <p className="font-weight-medium">
                                                <Link to="/login" className="color-text">Login </Link>
                                                or
                                                <Link to="/sign-up" className="color-text"> Register</Link>
                                            </p>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {redirect && (
          <Redirect
            to={{
              pathname: '/reset-password',
              search: `?username=${email}`
            }}
          />
        )}
        </>
    );
}

export default RecoverPassBox;
