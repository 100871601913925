import {React,useEffect} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Banner9 from "../components/banner/banner8/Banner9";
import About1 from "../components/about/About1";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function Financial() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="about-page">
            {/* Header */}
            <GeneralHeader />

            {/* Banner */}
            <Banner9 title="Grow Your Financial Services Business with Mobile Location-Based Advertising" subtitle="An Easy and Targeted Way to Expand Your Clientele" />


            <section className="about-area sect-padding-top">
                <div >
                    <About1 aboutcontent={sectiondata.aboutdata.financial} />
                </div>
            </section>






            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default Financial;
