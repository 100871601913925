import React, {useEffect, useState} from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import DashboardItem from "../../components/DashboardItem";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from "react-router-dom";
import { BsListCheck, BsBookmark, BsPencil } from 'react-icons/bs'
import { FaGlobeAmericas, FaRegEdit,FaRegEnvelope,FaRegTrashAlt } from 'react-icons/fa'
import { GiPositionMarker } from 'react-icons/gi'
import { FiPhone, FiEdit } from 'react-icons/fi'
import { AiOutlineUser, AiOutlinePlusCircle, AiOutlinePoweroff, AiOutlineYoutube, AiOutlineExclamationCircle } from 'react-icons/ai'
import Button from "../../components/common/Button";
import NewsLetter from "../../components/other/cta/NewsLetter";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import sectiondata from "../../store/store";
import { Auth } from 'aws-amplify';
import { IDENTITY_USER_TOKEN_KEY } from '../../components/data/constants';
import {refresh} from '../../components/common/TokenRefresh';
import campaignsb from "../../assets/images/Campaigns-button-navy.svg";
import profile from "../../assets/images/Profile-button-navy.svg";
import metrics from "../../assets/images/Metrics-Reporting-button-navy.svg";
import signout from "../../assets/images/Sign-Out-button-navy.svg";
import create from "../../assets/images/Create-Campaign-button-green.svg";
import Loader from '../../components/loading/Loader';


function Dashboard() {
    const params = new URLSearchParams(window.location.search);
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [isOpenForm, setIsOpenForm] = useState(false)
    const[campaigns,setCampaigns]=useState([]);
    const [loading, setLoading] = useState(true);
    const signOut=()=>{
        Auth.signOut().then(() => {
            localStorage.removeItem(IDENTITY_USER_TOKEN_KEY)
           window.location.href='/';
        })
        .catch(err => {
         console.log(err);
        });

    };
    const yyyymmdd = (indate)=> {
        let outdate = new Date(indate);
        if(outdate instanceof Date){
        var mm = outdate.getMonth() + 1; // getMonth() is zero-based
        var dd = outdate.getDate();
      
        return [outdate.getFullYear(),
                '-'+(mm>9 ? '' : '0') + mm+'-',
                (dd>9 ? '' : '0') + dd
               ].join('');
            }else{
                return '0';
            }
      };

    const changeAccount = (campaignid)=>{
        let email = localStorage.getItem('email');
        const fetchData = async () => {
            refresh();
            const rawResponse = await fetch(SERVER_URL+'/account?email='+email+'&campaign='+campaignid, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
              }
            });
          };
          fetchData();
        alert("Request to change plan received.  You will receive an email followup from our account team shortly.");
    }

 

    useEffect(() => {
        const body = document.querySelector('body')

        function showDeleteAcntModal(e) {
            body.classList.add('modal-open')
            body.style.paddingRight = '17px'
            e.preventDefault()
        }
        document.addEventListener('click', function (e) {
                for (
                    let target = e.target;
                    target && target !== this;
                    target = target.parentNode
                ) {
                    if (target.matches('.delete-account-info .delete-account, .card-item .card-content-wrap .delete-btn')) {
                        showDeleteAcntModal.call(target, e)
                        break
                    }
                }
            },false
        )
        function hideDeleteAcntModal(e) {
            body.classList.remove('modal-open')
            body.style.paddingRight = '0'
            e.preventDefault()
        }
        document.addEventListener('click', function (e) {
                for (
                    let target = e.target;
                    target && target !== this;
                    target = target.parentNode
                ) {
                    if (target.matches('.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn')) {
                        hideDeleteAcntModal.call(target, e)
                        break
                    }
                }
            }, false
        )

        let email = localStorage.getItem('email');
        const url = params.has('email') ? '/campaign?email='+params.get('email') : '/campaign?email='+email;
        // const url = params.has('userId') ? '/campaign?email='+email+'&userId='+params.get('userId') : '/campaign?email='+email;
        const fetchData = async () => {
            refresh();
            const rawResponse = await fetch(SERVER_URL+url, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
              }
            });
            const content = await rawResponse.json();
            console.log(content);
            if(content&&Array.isArray(content.message)){
                setCampaigns(content.message);
                console.log(content.message);
                setLoading(false);
            }else{
                setLoading(false);
            }
          };
          fetchData();
    },[])

    return (
        <main className="dashboard-page">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle={params.has('name') ? `${params.get('name')}'s Dashbaord` : 'My Dashboard'} MenuPgTitle="pages" img={sectiondata.dashboard.breadcrumbimg} />

            {/*<TestVanillaJs />*/}

            <section className="dashboard-area padding-top-40px padding-bottom-90px">
                <div className="container">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="tab-content" id="nav-tabContent">
                                        <div className="row res-margin">
                                            <div style={{paddingLeft:'0px'}} className="col-12"><h3 style={{color:'#333F57'}} className="sec__title">Campaigns</h3></div>
                                        </div>
                                        <div className="row res-margin">
                                            <div style={{paddingLeft:'0px'}} className="col-12"><span style={{color:'#333F57'}}>Summaries of and links to edit your advertising campaigns.</span></div>
                                        </div>
                                        <div className="row res-margin svg-multiple">
                                            <div style={{paddingLeft:'0px'}} className="col-lg-8 col-sm-12"><img width='15%' src={campaignsb}></img>
                                            <Link to="/user-profile"><img width='15%' src={profile}></img></Link>
                                            <Link to="/metrics"><img width='22%' src={metrics}></img></Link>
                                            <Link to="/"><img width='15%' src={signout}></img></Link>
                                            <Link className="responsive-display" to="/list-map-view?place=-77.0364,38.8951&type="><img width='95%' src={create}></img></Link>
                                            </div>
                                            <div className="col-2 campaign-hidediv"></div>
                                            
                                        </div>
                                {
                                    loading && (
                                        <Loader style={{ marginTop: '30px' }} message="Loading your campaigns, please wait..." />
                                    )
                                }

                                        <div className="row res-margin">
                                            
                                            {campaigns.map((item, i) => {
                                                return (
                                                   
                                                    <div key={i} className="col-lg-4 column-td-6">
                                                         <DashboardItem campaign={item} changeAccount={changeAccount} email={params.has('email') ? params.get('email') : ''}/>
                                                    </div>
                                                )
                                            })}

                                        </div>

                                </div>
                            </div>
                        </div>

                </div>
            </section>

           

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />


            {/* Modal */}
            <div className="modal-form text-center">
                <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                    <div className="modal-bg"></div>
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content p-4">
                            <div className="modal-top border-0 mb-4 p-0">
                                <div className="alert-content">
                                    <span className="la warning-icon"><AiOutlineExclamationCircle /></span>
                                    <h4 className="modal-title mt-2 mb-1">Your account will be deleted permanently!</h4>
                                    <p className="modal-sub">Are you sure to proceed.</p>
                                </div>
                            </div>
                            <div className="btn-box">
                                <button type="button" className="theme-btn border-0 button-success mr-1" data-dismiss="modal">
                                    Cancel
                                </button>
                                <button type="button" className="theme-btn border-0 button-danger">
                                    delete!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Dashboard;
