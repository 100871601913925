export const SUBHEAD_MAX_LENGTH = 30;
export const HEADLINE_MAX_LENGTH = 20;
export const MESSAGING_MAX_LENGTH = 70;
export const PHONE_MAX_LENGTH = 15;
export const URL_MAX_LENGTH = 35;

export const subheadingValidationLogic = (subhead) => {
    if (subhead && subhead.length > 0) {
        if (subhead.length > SUBHEAD_MAX_LENGTH) {
            return `Subheading must be less than ${SUBHEAD_MAX_LENGTH} characters (${subhead.length}/${SUBHEAD_MAX_LENGTH})`;
        }
    }
    return null;
}

export const stripTags = (str) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
}

export const messagingValidationLogic = (messaging) => {
    ["<b>", "<u>", "<i>", "</b>", "</u>", "</i>"].forEach(tag => {
        messaging = messaging.replace(new RegExp(tag, 'g'), '');
    });
    if (messaging && messaging.length > 0) {
        if (messaging.length > MESSAGING_MAX_LENGTH) {
            return `Messaging must be less than ${MESSAGING_MAX_LENGTH} characters (${messaging.length}/${MESSAGING_MAX_LENGTH})`;
        }
    }
    return null;
}


export const headlineValidationLogic = (headline) => {
    if (headline && headline.length > 0) {
        if (headline.length > HEADLINE_MAX_LENGTH) {
            return `Headline must be less than ${HEADLINE_MAX_LENGTH} characters (${headline.length}/${HEADLINE_MAX_LENGTH})`;
        }
    }
    return null;
}

export const urlValidationLogic = (url) => {
    if (url && url.length > 0) {
        // Must be a valid web address
        if (!url.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
            return "Must be a valid web address";
        }
    }
    return null;
}

export const phoneValidationLogic = (phone) => {
    if (phone && phone.length > 0) {
        // Phone must be of format 123-456-7890
        if (!phone.match(/^\d{3}-\d{3}-\d{4}$/)) {
            return "Phone must be of format 123-456-7890";
        }
    }
    return null;
}

export const photoValidationLogic = (photoFile, onValid, onInvalid) => {

    // Checking if it is an image
    const fileType = photoFile.type
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
    if (validImageTypes.includes(fileType) === false) {
        onInvalid("The file you uploaded is not an image. Please upload a valid image file (.jpg, .png, or .gif).")
        return;
    }

    // Checking if the size is under 4 MB
    if (photoFile.size > 4000000) {
        onInvalid('File\'s size is too large. Please choose a file under 4 MB')
        return;
    }

    // Checking if the image's width is at least 320px
    const image = new Image()
    image.src = URL.createObjectURL(photoFile)
    image.onload = () => {
        if (image.width < 320) {
            onInvalid('Image must be at least 320px wide')
            return
        } else {
            onValid()
        }
    }
}

export const logoValidationLogic = (photoFile, onValid, onInvalid) => {

    // Checking if it is an image
    const fileType = photoFile.type
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
    if (validImageTypes.includes(fileType) === false) {
        onInvalid("The file you uploaded is not an image. Please upload a valid image file (.jpg, .png, or .gif).")
        return;
    }

    // Checking if the size is under 4 MB
    if (photoFile.size > 4000000) {
        onInvalid('File\'s size is too large. Please choose a file under 4 MB')
        return;
    }

    // Checking if the image's width is at least 320px
    const image = new Image()
    image.src = URL.createObjectURL(photoFile)
    image.onload = () => {
        if (image.width < 100) {
            onInvalid('Image must be at least 100px wide')
            return
        } else {
            onValid()
        }
    }
}

export const imageFromFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
    })
};

    // Maintains aspect ratio and scales down the image to fit within the given width
    export const compressImage = (imageBase64, maxSize = 320) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageBase64;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const aspectRatio = image.width / image.height;
                canvas.width = maxSize;
                canvas.height = maxSize / aspectRatio;
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                resolve(canvas.toDataURL('image/jpeg'));
            }
        })
    }
