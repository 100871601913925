import React from 'react';
import Button from "../../common/Button";


function InfoBox3({infoitems, isbtnshow}) {
    return (
        <div className="row mt-5">
            {infoitems.map((item, index) => {
                return (
                    <div className="col-lg-3 column-td-6 mb-4" key={index}>
                        <div style={{backgroundColor:'#333F57',  color:'white', height:'230px'}} className={item.active ? 'info-icon icon-box info-more-content category-fig' : 'info-icon icon-box category-fig'}>
                            <div >
                                <span style={{backgroundColor:'#333F57'}}>
                                   <img style={{marginTop:'-100px'}} height='100px' src={item.icon} />
                                </span>
                            </div>
                            <div className="main-search-input-btn info-content">
                                <h4 className="info__title" style={{color:'white'}}>
                                    {item.title}
                                </h4>
                                <p className="info__desc" style={{color:'white'}}>
                                    {item.description}
                                </p>
                                {
                                    isbtnshow === true ? <Button text="get Started" url="#" className="radius-rounded mt-4" />
                                    : ''
                                }

                            </div>
                        </div>
                    </div>
                )
            })}

        </div>

    );
}

export default InfoBox3;
