import React, { useEffect, useState } from "react";
import GeneralHeader from "../../../components/common/GeneralHeader";
import Breadcrumb from "../../../components/common/Breadcrumb";
import Footer from "../../../components/common/footer/Footer";
import ScrollTopBtn from "../../../components/common/ScrollTopBtn";
import breadcrumbimg from "../../../assets/images/bread-bg.jpg";

import axios from "axios";

const state = {
    breadcrumbimg: breadcrumbimg,
};

const CaseStudyRequestStatus = Object.freeze({
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
});

const BLOG_URL = process.env.REACT_APP_BLOG_URL;

function CaseStudyList() {
    const [posts, setPosts] = useState([]);
    const [postStatus, setPostStatus] = React.useState(
        CaseStudyRequestStatus.LOADING
    );

    const fetchCaseStudyPosts = async () => {
        const res = await axios.get(BLOG_URL + '/pages');
        return res.data;
    };

    

    useEffect(() => {
        async function fetchData() {
            setPostStatus(CaseStudyRequestStatus.LOADING);
            const posts = await fetchCaseStudyPosts();
            setPosts(posts);
            
            setPostStatus(CaseStudyRequestStatus.SUCCESS);
        }
        fetchData();
    }, []);

    const formatDate = (userdate) => {
        let blogDate = new Date(userdate);
        let date = blogDate.toLocaleDateString("en-us", { day: "numeric" });
        let month = blogDate.toLocaleDateString("en-us", { month: "long" });
        let year = blogDate.toLocaleDateString("en-us", { year: "numeric" });
        return `${month} ${date}, ${year}`;
    };

    return (
        <div>
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb
                CurrentPgTitle="Case Studies"
                MenuPgTitle="Case Studies"
                img={state.breadcrumbimg}
            />

            <section className="blog-single-area padding-top-40px padding-bottom-70px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {/* <BlogDetailContent /> */}

                            {postStatus === CaseStudyRequestStatus.LOADING && (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {postStatus === CaseStudyRequestStatus.SUCCESS &&
                                posts.map((blog) => (
                                    <a
                                        href={`/case-study/${blog.slug}`}
                                        target="_self"
                                        rel="noreferrer"
                                    >
                                        <div
                                            className="blog-card card"
                                            key={blog.id}
                                            style={{ marginBottom: "16px" }}
                                        >
                                            <div
                                                className="card-horizontal"
                                                style={{ display: "flex", flex: "1 1 auto" }}
                                            >
                                                <div className="img-square-wrapper">
                                                   
                                                </div>
                                                <div className="card-body">
                                                    <h4 class="card-title" style={{ marginTop: "0px" }}
                                                    dangerouslySetInnerHTML={{__html: blog.title.rendered}}>
                                                    </h4>
                                                    <p class="card-text">{formatDate(blog.date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                        </div>

                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </div>
    );
}

export default CaseStudyList;
