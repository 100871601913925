import React  from 'react'
import GeneralHeader from '../components/common/GeneralHeader'
import Footer from "../components/common/footer/Footer";
import Banner6 from "../components/banner/banner6/Banner6";


function Terms() {
    return (
        <main className="faq-page">
            {/* Header */}
            <GeneralHeader />
            {/* Banner */}
            <Banner6 title="Terms and Conditions" />
            <h1 style={{marginLeft:'10px'}}>Terms and Conditions</h1>

<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >Fair Housing Notice</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Geophenom takes fair housing seriously and we train our team to follow the law: It is unlawful to discriminate based on certain protected characteristics, which include, but are not limited to: race, creed, color, national origin, ancestry, sexual orientation, gender identity or expression, genetic information, military status, sex, age, disability, marital status, source of income, domestic violence victims, pregnancy, HIV/AIDs, religion or familial status. </p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Geophenom does not support or offer any advertising products used for the purposes of marketing real estate listings, to stay in accordance with fair housing regulations.  All real-estate related advertising is strictly reviewed for compliance with this policy.  Real estate agents are encouraged to market “Just Sold” properties or to market themselves as a neighborhood resource, to build awareness and their personal brand. </p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >Terms of Use</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Welcome to the Geophenom Web site (the "Web site"). The goal of this Web site is to provide you with access to comprehensive geofence advertising related products/services and related links to meet your needs. Please read our Terms of Use (the "Terms") carefully before continuing on with your use of this Web site. These Terms shall govern the use of the Web site and apply to all Internet traffic visiting the Web site. By accessing or using this Web site, you agree to the Terms. The Terms are meant to protect all of our Web site visitors and your use of this Web site signifies your agreement with these Terms. IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT USE THIS WEB SITE. Geophenom reserves the right, in its sole discretion, to modify, alter or otherwise update these Terms at any time. Such modifications shall be effective immediately upon posting. By using this Web site after we have posted notice of such modifications, alterations or updates you agree to be bound by such revised Terms.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >In accordance with our goals, this Web site will permit you to link to many other Web sites, that may or may not be affiliated with this Web site and/or Geophenom, and that may have terms of use that differ from, or contain terms in addition to, the terms specified here. Your access to such Web sites through links provided on this Web site is governed by the terms of use and policies of those sites, not this Web site. </p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >LINKS</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >This Web site may contain links to other Web sites ("Linked Sites"). The Linked Sites are provided for your convenience and information only and, as such, you access them at your own risk. The content of any Linked Sites is not under Geophenom's control. Geophenom is not responsible for, and does not endorse, such content, whether or not Geophenom is affiliated with the owners of such Linked Sites. You may not establish a hyperlink to this Web site or provide any links that state or imply any sponsorship or endorsement of your Web site by Geophenom, or its affiliates or Providers.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >The translation application made available on the Web site is the sole and exclusive property of a third party provider. Geophenom makes no representations as to the accuracy of any translations provided by the translation application contained on the Web site.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >DISCLAIMER OF WARRANTIES AND LIABILITY</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >ALL CONTENT ON THIS WEB SITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. OTHER THAN THOSE WARRANTIES WHICH, UNDER THE U.S. LAWS APPLICABLE TO THESE TERMS, ARE IMPLIED BY LAW AND ARE INCAPABLE OF EXCLUSION, RESTRICTION, OR MODIFICATION, GEOPHENOM DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER GEOPHENOM, ITS AFFILIATED OR RELATED ENTITIES, NOR THE PROVIDERS, NOR ANY PERSON INVOLVED IN THE CREATION, PRODUCTION, AND DISTRIBUTION OF THIS WEB SITE WARRANT THAT THE FUNCTIONS CONTAINED IN THIS WEB SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVER THAT MAKES THE CONTENT AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE CONTENT THAT YOU ACCESS ON THIS WEB SITE IS PROVIDED SOLELY FOR YOUR CONVENIENCE AND INFORMATION ONLY. GEOPHENOM DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS WEB SITE, OR AS TO THE RELIABILITY, ACCURACY OR CURRENCY OF ANY INFORMATION CONTENT, SERVICE AND/OR MERCHANDISE ACQUIRED PURSUANT TO YOUR USE OF THIS WEB SITE.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >YOU EXPRESSLY AGREE THAT USE OF THIS WEB SITE IS AT YOUR SOLE RISK. YOU (AND NOT GEOPHENOM) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION OF YOUR SYSTEM. YOU EXPRESSLY AGREE THAT NEITHER GEOPHENOM, NOR ITS AFFILIATED OR RELATED ENTITIES (INCLUDING ITS PROVIDERS), NOR ANY OF THEIR RESPECTIVE EMPLOYEES, OR AGENTS, NOR ANY PERSON OR ENTITY INVOLVED IN THE CREATION, PRODUCTION AND DISTRIBUTION OF THIS WEB SITE, IS RESPONSIBLE OR LIABLE TO ANY PERSON OR ENTITY WHATSOEVER FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER WHATSOEVER BASED UPON OR RESULTING FROM THE USE OR ATTEMPTED USE OF THIS WEB SITE OR ANY OTHER LINKED SITE. BY WAY OF EXAMPLE, AND WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, GEOPHENOM AND RELATED PERSONS AND ENTITIES SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY CLAIM OR DAMAGE ARISING FROM FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION, COMPUTER VIRUS, THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO OR ALTERATION OF PERSONAL RECORDS, OR THE RELIANCE UPON OR USE OF DATA, INFORMATION, OPINIONS OR OTHER MATERIALS APPEARING ON THIS WEB SITE. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT GEOPHENOM IS NOT LIABLE OR RESPONSIBLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER SUBSCRIBERS OR THIRD PARTIES. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH JURISDICTIONS, GEOPHENOM'S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >INDEMNIFICATION</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >You agree to indemnify, defend, and hold harmless Geophenom and the Providers, its and their officers, directors, employees, affiliates, agents, licensors, and suppliers from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation by you of these Terms. </p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >THIRD PARTY RIGHTS</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >These Terms are for the benefit of Geophenom and its Providers, its and their officers, directors, employees, affiliates, agents, licensors, and suppliers. Each of these individuals or entities shall have the right to assert and enforce these Terms directly against you on its or their own behalf.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >JURISDICTIONAL ISSUES</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Unless otherwise specified, the content contained in this Web site is presented solely for your convenience and/or information. This Web site is controlled and operated by Geophenom from its offices within Severna Park, Maryland. Geophenom makes no representation that content in its Web site is appropriate or available for use in other locations. Those who choose to access this Web site from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. You may not use or export the materials in this Web site in violation of U.S. export laws and regulations. These Terms shall be governed by, construed and enforced in accordance with the laws of the Commonwealth of Virginia, as they are applied to agreements entered into and to be performed entirely within such State. Any action you, any third party or Geophenom brings to enforce these Terms, or in connection with any matters related to this Web site, shall be brought only in either the state or Federal courts located in Virginia and you expressly consent to the jurisdiction of said courts. If any provision of these Terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >The information provided by this website is for the personal, non-commercial use of consumers and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. All Information Deemed Reliable but Not Guaranteed.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px',marginRight:'5px'}} >DMCA Notice</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >In accordance with Digital Millennium Copyright Act (DMCA) provisions that are applicable to Internet Service Providers (17 U.S.C. § 512), Geophenom takes the ownership of content very seriously, and has a strict policy to protect ownership of content displayed on this site, or any Geophenom hosted subdomain, sub-directory, meta tag or the like. If you believe that your work and/or intellectual property has been posted on this site in a manner that infringes upon your copyright, please provide the following written information to our designated copyright agent below:</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >customerservice@geophenomllc.com</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >523 Benfield Rd. #203</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Severna Park, MD 21146</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >An electronic or physical signature of the person authorized to act on the behalf of the owner of the copyright interest;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >A description of the copyrighted work that you claim has been infringed upon;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >A direct link to, or a full description of location of the infringing material upon this site, or a hosted subdomain;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Your address, telephone number, and email address;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Please ensure that you are the lawful copyright owner or have right to the copyright under the requirements of the DMCA prior to sending notice to Geophenom. </p>

            {/* Footer */}
            <Footer />
        </main>
    )
}
export default Terms;