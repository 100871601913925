import React,{useEffect} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/footer/Footer";
import breadcrumbimg from "../assets/images/bread-bg.jpg";
import {refresh} from '../components/common/TokenRefresh';
import { IDENTITY_USER_TOKEN_KEY } from '../components/data/constants';
import { isUserLoggedIn } from '../utils/APIRequests';

const state = {
    breadcrumbimg: breadcrumbimg,
  };
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function PersistGuestUserData(props) {


    //fences api - ListMapView - line 111
    const addCampaignToFences = (campaignId, fenceArray) => {
        var newArr = [];
        fenceArray.forEach(function (entry) {
            if(entry.location&&entry.location.lat){
                var newObj = {};
                newObj['campaign'] = campaignId;
                newObj['locationid'] = entry.place_id;
                newObj['businessname'] = entry.name;
                newObj['address'] = entry.formatted_address;
                if(typeof entry.location.lat === "function"){
                    newObj['latitude'] = entry.location.lat();
                    newObj['longitude'] = entry.location.lng();
                }else{
                    newObj['latitude'] = entry.location.lat;
                    newObj['longitude'] = entry.location.lng;
                }

                newObj['type'] = (entry.type) ? entry.type : '';
                newArr.push(newObj);
            }
        });
        return newArr;
    };
    
    //campaign api - ListMapView - line 175
    const handleSubmit = () => {
        (async () => {
            refresh();
            const isUserLogged = await isUserLoggedIn();
            console.log({ isUserLogged });
            const pendingCampaignBody = JSON.parse(localStorage.getItem('pendingCampaignBody'));
            const pendingFences = JSON.parse(localStorage.getItem('pendingFences'));
            const rawResponse = await fetch(SERVER_URL+'/campaign', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                },
                body: JSON.stringify(pendingCampaignBody)
            });
            const content = await rawResponse.json();
            localStorage.setItem('campaign',content.message);
            var formattedFences = addCampaignToFences(content.message, pendingFences)
            console.log(formattedFences);
            const rawResponseFences = await fetch(SERVER_URL+'/fence', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                },
                body: JSON.stringify(formattedFences)
            });

            sendAdRequest();
            // showLoading();
            // console.log("/campaigndesign?campaign=" + content.message + "&type="+"", "_self") //logging url
            // window.open("/campaigndesign?campaign=" + content.message + "&type="+"", "_self"); //"&type="+centroidtype - centroidtype is empty in most case
        })();
    };


    //Ad API
    const sendAdRequest = async () => {
        const method = 'POST';
        const pendingAdDesign = JSON.parse(localStorage.getItem('pendingAd'));
        let body = JSON.stringify({
            ...pendingAdDesign,
            campaign: parseInt(localStorage.getItem('campaign')),
        });

        const rawResponse = await fetch(SERVER_URL + '/ad?ad=' + 0, { //'/ad?ad=' + ad --> ad = 0 default
          method: method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
          },
          body: body,
        });
        const content = await rawResponse.json();
        console.log(content);
        localStorage.removeItem("pendingRequests");
          window.open(
            '/review?ad=' + content.message + '&campaign=' + localStorage.getItem('campaign'),
            '_self'
          );
      };
    
    //Calling Campaign API
    useEffect(() => {
        (async () => {
            handleSubmit();
        })()

    },[])

    //Ad API - Booking - Line 305

    return (
        <main className="pricing-plan-page">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle=""
        MenuPgTitle="pages"
        img={state.breadcrumbimg}
      />

        {/* Message */}
        <div className='container'>
            <div className='row justify-content-center align-items-center text-center' style={{ height: '40vh', display: 'flex' }}>
                <div className='col-6'>
                    <div className='row justify-content-center align-items-center text-center'>
                        <div class="spinner-border text-primary" role="status">
                        </div>
                    </div>
                    <div className='row justify-content-center align-items-center text-center mt-4'>
                        <span> Getting your campaign ready, please wait...</span>
                    </div>
                </div>
            </div>
        </div>

        {/* Footer */}
      <Footer />

</main>
    );
}

export default PersistGuestUserData;