import React, { useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai'
import { FaRegEnvelope } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import { RiSendPlane2Line } from 'react-icons/ri'

function AskQuestionField({title}) {

    useEffect(() => {
        const script = document.createElement('script');
        const scriptSettings = document.createElement('script');
        script.src = 'https://koi-3qnnfn4tsw.marketingautomation.services/client/form.js?ver=2.0.1';
        script.async = true;
        scriptSettings.innerHTML = `
        var ss_form = {'account': 'MzawMLEwNrUwAgA', 'formID': 'szSyTDMztkjSTUtOMdM1MTQ3100yTrPQTTExNTM2MTe3tDQ2AgA'};

        ss_form.width = '100%';
        
        ss_form.domain = 'app-3QNNFN4TSW.marketingautomation.services';
        
        // ss_form.hidden = {'field_id': 'value'}; // Modify this for sending hidden variables, or overriding values
        
        ss_form.target_id = 'faq-form'; // Optional parameter: forms will be placed inside the element with the specified id
        
        // ss_form.polling = true; // Optional parameter: set to true ONLY if your page loads dynamically and the id needs to be polled continually.`;
        document.head.appendChild(scriptSettings);
        document.head.appendChild(script);
        return () => {
            try {
                document.head.removeChild(scriptSettings);
                document.head.removeChild(script);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    return (
        <>
            <div className="faq-forum">
                <div className="billing-form-item">
                    {title ? (
                        <div className="billing-title-wrap">
                            <h3 className="widget-title pb-0">{title}</h3>
                            <div className="title-shape margin-top-10px"></div>
                        </div>
                    ) : ''}
                    <div>
                    <main>
                        <div id="faq-form"> </div>
                    </main>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AskQuestionField;