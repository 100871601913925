import React  from 'react'
import GeneralHeader from '../components/common/GeneralHeader'
import Footer from "../components/common/footer/Footer";
import Banner6 from "../components/banner/banner6/Banner6";


function Accessibility() {
    return (
        <main className="faq-page">
            {/* Header */}
            <GeneralHeader />
            {/* Banner */}
            <Banner6 title="Accessibility" />
            
            <h1 style={{marginLeft:'10px'}}>Accessibility</h1>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Geophenom is committed to providing exceptional customer service in everything we do. That includes providing an accessible, easy-to-use website. If you have difficulty accessing content on our site or viewing a file or you notice any accessibility problems, please submit a request on our Contact Us page or call us at 410-562-1983 to specify the nature of the accessibility issue and the assistive technology you use. In addition, we welcome your suggestions and comments about improving accessibility on Geophenomllc.com.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}}>Ways to improve online accessibility</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >There are several things you can do to adjust your web browser and make your online experience more accessible.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}}>For individuals who are visually impaired</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >If you have trouble seeing web pages, please consider these suggestions from the US Social Security Administration to optimize your system and browser and improve your online experience. </p>
<p style={{marginLeft:'20px',marginRight:'5px'}} ><a href={'https://www.ssa.gov/accessibility/textsize.html'}>Changing the size of the text on your screen</a></p>
<p style={{marginLeft:'20px',marginRight:'5px'}} ><a href={'https://www.ssa.gov/accessibility/browseAloud.html'}>Using your computer to read web pages out loud</a></p>
<p style={{marginLeft:'20px',marginRight:'5px'}} ><a href={'https://www.ssa.gov/accessibility/keyboard_nav.html'}>Navigating screens using the keyboard</a></p>
<p style={{marginLeft:'20px',marginRight:'5px'}} ><a href={'https://www.ssa.gov/accessibility/magnifyscreen.html'}>Magnifying your screen</a></p>
<p style={{marginLeft:'20px',marginRight:'5px'}} ><a href={'https://www.ssa.gov/accessibility/mousepointer.html'}>Making your mouse pointer more visible (Windows users)</a> </p>

            {/* Footer */}
            <Footer />
        </main>
    )
}
export default Accessibility;