import React from 'react';
import SectionsHeading from "../common/SectionsHeading";
import {Link} from "react-router-dom";
function About1({aboutcontent}) {
    return (
        <>


                    <div>
                        <div>
                    {aboutcontent.titles.map((item, i) => {
                        let bgcolor = '#FFFFFF';
                        let color = '##333F57'
                        let tc = 'gf2';
                        let dc = 'sec__desc';
                        if(i%2!=0){
                            bgcolor = '#333F57';
                            color = '#FFFFFF';
                            tc='gf';
                            dc = 'sec__desc3';
                            if(aboutcontent.items[i].boxClass){
                                return(
                                    <div  style={{color:color,backgroundColor:bgcolor}}>
                                    <div  className="container">
                                        <div className='row sect-padding-top sect-padding-bottom' >
                                        
                                        <div className='col-lg-6 col-md-12 text-md-left text-center'>
                                            <img  src={aboutcontent.items[i].img} className="img-width"/>
                                        </div>
                                        
                                        <div className='col-lg-6 col-md-12 text-md-left text-center' style={{marginTop:'auto',marginBottom:'auto'}}>
                                            <SectionsHeading
                                                title={item}
                                                titleClass={tc}
                                                desc={aboutcontent.contents[i]}
                                                descClass={dc}>
                                            </SectionsHeading>  
                                            <div className="main-search-input-btn margin-top-10 padding-left-started">
                                            <Link to='/list-map-view?place=-77.0364,38.8951&type='><button className="button theme-btn" type="submit">Let's Get Started!</button></Link>
                                                    </div>
                                        </div> 

                                    </div> 
                                    </div>
                                    </div>
                                )
                            }
                            return(
                                <div  style={{color:color,backgroundColor:bgcolor}}>
                                    <div  className="container">
                                <div className='row sect-padding-top sect-padding-bottom' >

                                    <div className='col-lg-6 col-md-12 text-md-left text-center'>
                                        <img src={aboutcontent.items[i].img} className="img-width"/>
                                    </div>
                                    <div className='col-lg-6 col-md-12 text-md-left text-center' style={{marginTop:'auto',marginBottom:'auto'}}>
                                        <SectionsHeading
                                            title={item}
                                            titleClass={tc}
                                            desc={aboutcontent.contents[i]}
                                            descClass={dc}>
                                        </SectionsHeading> 
                                    </div> 

                                </div> 
                                </div>
                                </div> 
                                )                 
                        }else{
                            if(aboutcontent.items[i].boxClass){
                                return(
                                    <div  style={{color:color,backgroundColor:bgcolor}}>
                                    <div  className="container">
                                    <div className='row sect-padding-top sect-padding-bottom' >
                                        <div className='col-lg-6 col-md-12 text-md-left text-center' style={{marginTop:'auto',marginBottom:'auto'}}>
                                            <SectionsHeading
                                                title={item}
                                                titleClass={tc}
                                                desc={aboutcontent.contents[i]}
                                                descClass={dc}>
                                            </SectionsHeading> 
                                            <div className="main-search-input-btn margin-top-10">
                                            <Link to='/list-map-view?place=-77.0364,38.8951&type='><button className="button theme-btn" type="submit">Let's Get Started!</button></Link>
                                            </div>
                                        </div> 
                                        
                                        <div className='col-lg-6 col-md-12 text-md-left text-center'>
                                            <img  src={aboutcontent.items[i].img} className="img-width"/>
                                        </div>
                                        
                                    </div>  
                                    </div></div>
                                    ) 
                            }
                            return(
                                <div  style={{color:color,backgroundColor:bgcolor}}>
                                <div  className="container">
                                <div className='row sect-padding-top sect-padding-bottom' >
                                    <div className='col-lg-6 col-md-12 text-md-left text-center' style={{marginTop:'auto',marginBottom:'auto'}}>
                                        <SectionsHeading
                                            title={item}
                                            titleClass={tc}
                                            desc={aboutcontent.contents[i]}
                                            descClass={dc}>
                                        </SectionsHeading> 

                                    </div> 
                                    <div className='col-lg-6 col-md-12 text-md-left text-center'>
                                        <img width='100%' src={aboutcontent.items[i].img} className="img-width"/>
                                    </div>
                                </div>  
                                </div></div>
                                )  
                        }
                        

                    })}
                       
                    </div>
                    </div>


        </>
    );
}

export default About1;
