import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/common/Breadcrumb";
import axios from "axios";
import GeneralHeader from "../../components/common/GeneralHeader";
import BlogSidebar from "../../components/sidebars/BlogSidebar";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from "../../assets/images/bread-bg.jpg";
import ReactHtmlParser from "react-html-parser";
import BlogShare from '../../components/blogs/BlogShare'

import { Link } from "react-router-dom";
const state = {
  breadcrumbimg: breadcrumbimg,
};

function BlogPostSingle(props) {
  const [blogPost, setBlogPost] = React.useState({});
  const BLOG_URL = process.env.REACT_APP_BLOG_URL;
  let { id } = useParams();

  const fetchBlogPost = async (id) => {
    const res = await axios.get(BLOG_URL + `/blogs?slug=${id}`);
    const author = await axios.get(BLOG_URL + `/users/${res.data[0].author}`);
    const image = await axios.get(BLOG_URL + `/media/${res.data[0].featured_media}`);

    const relatedPosts = res.data[0].acf.relatedPosts;
    let relatedPostData = [];
    if (Array(relatedPosts).length > 0) {
      try {
        relatedPostData = await Promise.all(
          relatedPosts.map(async (post) => {
            const postData = await axios.get(BLOG_URL + `/blogs/${post}`);

            const authorDetails = await axios.get(BLOG_URL + `/users/${postData.data.author}`);

            const image = await axios.get(BLOG_URL + `/media/${postData.data.featured_media}`);

            return {
              ...postData.data,

              authorDetails: authorDetails.data,

              image: image.data.source_url,
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
    setBlogPost({
      ...res.data[0],
      image: image.data.source_url,
      author: author.data,
      relatedPosts: relatedPostData,
    });
  };

  useEffect(() => {
    async function fetchData() {
      await fetchBlogPost(id);
    }
    fetchData();
  }, []);

  const formatDate = (userdate) => {
    let blogDate = new Date(userdate);
    let date = blogDate.toLocaleDateString("en-us", { day: "numeric" });
    let month = blogDate.toLocaleDateString("en-us", { month: "long" });
    let year = blogDate.toLocaleDateString("en-us", { year: "numeric" });
    return `${month} ${date}, ${year}`;
  };

  return (
    <div>
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Phenomenoms"
        MenuPgTitle="Blog"
        img={state.breadcrumbimg}
      />

      <section className="blog-single-area padding-top-40px padding-bottom-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              {Object.keys(blogPost).length <= 0 && (
                //    Loading

                <div className="loading-spinner"></div>
              )}
              {Object.keys(blogPost).length > 0 && (
                <div class="card">
                  <img
                    class="card-img-top"
                    src={blogPost.image}
                    alt="Card image cap"
                  />
                  <div class="card-body">
                    <h5 class="card-title">{blogPost.title.rendered}</h5>
                    <h6 class="card-subtitle mb-2 blog-post-author">
                      By {blogPost.author.name}, posted{" "}
                      {formatDate(blogPost.date)}
                    </h6>
                    <p class="card-text">
                      {ReactHtmlParser(blogPost.content.rendered)}
                    </p>
                  </div>
              
                    <div className="tag-items d-flex justify-content-between margin-top-30px margin-bottom-50px" style={{paddingLeft: '20px'}}>
                        <BlogShare />
                    </div>

                </div>
              )}
            </div>
            <div className="col-lg-4 col-sm-12 col-sm-12">
              {Object.keys(blogPost).length > 0 && (
                <div className="sidebar section-bg">
                  <div>
                    <div className="sidebar-widget similar-widget">
                      <h3 className="widget-title">Related Posts</h3>
                      <div className="title-shape"></div>
                      <div className="similar-list padding-top-30px">
                        {Object.keys(blogPost.relatedPosts).length > 0 ? (
                          blogPost.relatedPosts.map((post, index) => {
                            
                            return (
                              <div key={index} className="recent-item mb-3">
                                <div className="recent-img">
                                  <Link to={`/blog/${post.slug}`}>
                                    <img src={post.image} alt="blog" />
                                  </Link>
                                </div>
                                <div className="recentpost-body">
                                  <h4 className="recent__link">
                                    <Link to={`/blog/${post.slug}`}>
                                      {post.title.rendered}
                                    </Link>
                                  </h4>
                                  <p className="recent__meta">
                                    {formatDate(post.date)} by{" "}
                                    <span class="author-name-for-related-post">{post.authorDetails.name}</span>
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <span>No Related Posts</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </div>
  );
  return (
    <div>
      <h1>{blogPost.title.rendered}</h1>
      <img src={blogPost.image} alt="" />
      <p>{blogPost.content.rendered}</p>
    </div>
  );
}

export default BlogPostSingle;

