import React  from 'react'

export default function BannerOneCategories({connector, title, items}) {
    return (
        <>
            <div className="highlighted-categories">
                <h5 style={{}} className="highlighted__title">
                    {title}
                </h5>
                <div className="main-search-input-btn highlight-lists row justify-content-left mt-4">
                    {items.map((item, index) => {
                        return (
                            <div className="col-lg-2 col-md-4 col-sm-4 category-item" key={index}>
                                <a href={item.path} className="d-block">
                                    <img className="icon-element" src={item.icon} />
                                    {item.title}
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
