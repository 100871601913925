import React from 'react';
import InfoBoxOne from "../../other/infoboxes/infobox1/InfoBoxOne";
/*import HowItWorkOneVideo from "./HowItWorkOneVideo"; */
import {Link} from "react-router-dom";

function HowItWorkOne({hiw1content}) {
    return (
        <>
            <div className="row padding-top-100px">
                <InfoBoxOne infoitems={hiw1content.items} cardcol={4} />
            </div>


            <div className="row">
                <div className="col-lg-8 mx-auto padding-top-30px">
                <div className="main-search-input-btn">
                    <Link to='/list-map-view?place=-77.0364,38.8951&type='><button className="button theme-btn" type="submit">Get Started Now</button></Link>
                </div>
                </div>
            </div>
        </>
    );
}

export default HowItWorkOne;
