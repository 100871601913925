import React, {Component} from 'react';
import ModalVideo from "react-modal-video";

class Banner7 extends Component {
    constructor()
    {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal()
    {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='kTw05gC2T9Y' onClose={() => this.setState({isOpen: false})}/>
                <section style={{marginTop:'-40px'}} className="breadcrumb-area about-breadcrumb breadcrumb-mobile">
                    <div className="breadcrumb-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="breadcrumb-content">
                                        <h2 className="breadcrumb__title mb-0">Design Your Ad</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bread-svg">
                        <svg viewBox="0 0 500 130" preserveAspectRatio="none">
                            <path
                                d="M-4.22,89.30 C280.19,26.14 324.21,125.81 511.00,41.94 L500.00,150.00 L0.00,150.00 Z"/>
                        </svg>
                    </div>
                </section>
            </>
        );
    }
}

export default Banner7;
