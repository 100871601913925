const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_PROJ_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWSCOG_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWSCOG_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEBCLIENT_ID,
    "oauth": {},
    "authenticationFlowType": "USER_SRP_AUTH"
};


export default awsmobile;
