import React  from 'react'
import GeneralHeader from '../components/common/GeneralHeader'
import Footer from "../components/common/footer/Footer";
import Banner6 from "../components/banner/banner6/Banner6";


function Privacy() {
    return (
        <main className="faq-page">
            {/* Header */}
            <GeneralHeader />
            {/* Banner */}
            <Banner6 title="Privacy Policy" />
            
            <h1 style={{marginLeft:'10px'}}>GEOPHENOM PRIVACY POLICY</h1>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Because we value you as our customer, we want to address any privacy concerns you may have. To that end, we have set forth our privacy policy for handling the information you share with us online and through any documentation submitted as part of a real estate transaction with Geophenom. We want your decision to do business with us to be an informed one. We have adopted this Privacy Policy to explain what information may be collected when you utilize our applications and services, how we use this information, and under what circumstances we may disclose the information to affiliates and third parties. You should review this Privacy Policy frequently, as it may change from time to time without notice. Any changes will be effective immediately upon the posting of the revised Privacy Policy.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >1. Collecting Information</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >When you visit our consumer applications or purchase and/or sell real estate with Geophenom, we may collect information about you that you share with us. We typically collect two kinds of information about you through our applications and real estate services: (a) information that you provide that personally identifies you; and (b) information that does not personally identify you, which we automatically collect when you visit our applications or that you provide to us.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >a. Personally Identifiable Information: This identification information may include, but is not limited to, your name, physical address, email address, telephone, mobile and fax numbers, details of your real estate, lending, title and insurance needs and references, and other details of your life (“Personal Information”).</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >b. Non-Personally Identifiable Information: Our definition of non-personally identifiable information is any information that does not personally identify you (“Non-PII”). Non-PII can include certain personally identifiable information that has been de- identified; that is, information that has been rendered anonymous by a de-identification process. We obtain Non-PII about you from information that you affirmatively provide to us, either separately or together with your personally identifiable information. We also automatically collect certain Non-PII from you when you access any applications. This information can include, among other things, IP addresses, the type of browser you are using, the third-party website from which your visit originated, the operating system you are using, the domain name of your Internet service provider, the search terms you use on our applications, the specific web pages you visit, the duration and frequency of your visits, and location information. </p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >c. Cookies, Web Beacons and other Tracking Technologies: As you may be aware, it is a common practice that when you visit our consumer applications, we may collect data through the use of cookies, web beacons or other tracking technologies (i.e. small text files placed on your computer by consumer applications), web server logs, and other web tools. This technology allows us to customize your online experience as well as track your use of our system so that we can improve your experience. The tracking data we collect may include, but is not limited to, the date, time and location of your visit, the time you spend, the web pages you view, the other consumer applications you visit, your search queries, and basic information about your computer, such as your Internet Protocol (“IP”) address, your domain name, your browser type, and the name of your internet service provider. By continuing to browse our site, you are agreeing to our use of cookies. Most web browsers automatically accept cookies, but will also provide controls that allow you to block or delete them. Instructions for blocking or deleting cookies are generally available in your browser's privacy or help documentation. If you choose to delete cookies, some settings and preferences controlled by those cookies, including advertising preferences, will be deleted and may need to be recreated. You may deactivate cookies if you do not want your browsing to be tracked. </p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >When visiting our consumer-facing applications, you may be directed, through hyperlinks, to other consumer applications that are beyond our control. This Privacy Policy does not cover the collection of information by those other companies and consumer applications. Although we encourage third parties to provide their own privacy policies, we are not responsible for their activities, such as how they handle the information they collect online. </p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >2. How We Use & Share Information Collected </h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >a. Personal Information: We primarily use the identification information you voluntarily share with us to:</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Customize your online transaction experiences and to carry out your online requests;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Tell you about our products, services and other offerings;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Contact you for a variety of reasons, such as providing you promotional or marketing information for our products;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Communicate with you about your transaction;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >In certain instances, we may also share your personally identifiable information with our third-party vendors and marketing partners that, among other activities: perform functions on our behalf (or on behalf of our affiliated companies); administer our offerings; provide us marketing or promotional assistance; analyze our data; assist us with customer service; and offer other services and products that may be relevant to the purchase or sale of real estate. Our vendors agree to use this information, and we share information with them, only to carry out our requests;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >On occasion, we use your identification information to validate your identity;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >As for usage tracking data, we primarily use that information to operate and improve our consumer applications, and to further our marketing efforts.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >You may opt-out from receiving future promotional or marketing information from us, our affiliated companies, and our third-party vendors, or direct that we not share your information with any affiliated companies or third-party vendors, as set forth below in Section 7. Except as provided in this Privacy Policy, our Terms of Use, or as set forth when you submit the information, your personally identifiable information will not be sold to any third parties or used in any manner outside the scope of this Privacy Policy without your prior approval. We further reserve the right to disclose your information under limited circumstances, such as to cooperate with law enforcement or judicial authorities as required by law or legal process, to protect our own legal rights, or to protect the public good.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >b.Non-PII: We use Non-PII in a variety of ways, including to:</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Help analyze site traffic, understand customer needs and trends;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Carry out targeted promotional activities;</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Improve our services and offerings.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >We may use your Non-PII by itself or aggregate it with information we have obtained from others. We may share your Non-PII with any affiliated companies and third parties to achieve these objectives and others, but remember that aggregate information is anonymous information that does not personally identify you. Whenever you are online or on our applications, you run the risk that the information you provide will fall into the wrong hands. Although we implement and maintain commercially reasonable security procedures and practices to safeguard your privacy, we cannot guarantee the security of any information you provide to us. We accept no responsibility or liability for the activities of third parties. By using our consumer applications and applications, you agree to our Terms of Use.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >3. Updating Information</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >If you would like to review the information about you that we have collected through our consumer applications, or to change that information, you can make that request by following the procedure indicated below in Section 7. We may require that you verify your identity before we process your request.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >4. Processing Location</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >We process your personal information in the United States. For users located outside of the United States, please see Sections 10 and 11 titled International Users and European Union Residents and General Data Protection Regulation Information for more detailed information.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >5. Anti-Fraud Disclosure </h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Electronic communications such as email, text messages and social media messaging, are neither secure nor confidential. While Geophenom has adopted policies and procedures to aid in avoiding fraud, even the best security protections can still be bypassed by unauthorized parties. Geophenom will never send you any electronic communication with instructions to transfer funds or to provide nonpublic personal information, such as credit card or debit numbers or bank account and/or routing numbers.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >YOU SHOULD NEVER TRANSMIT NONPUBLIC PERSONAL INFORMATION, SUCH AS CREDIT OR DEBIT CARD NUMBERS OR BANK ACCOUNT OR ROUTING NUMBERS, BY EMAIL OR OTHER UNSECURED ELECTRONIC COMMUNICATION. EMAILS ATTEMPTING TO INDUCE FRAUDULENT WIRE TRANSFERS ARE COMMON AND MAY APPEAR TO COME FROM A TRUSTED SOURCE.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >If you receive any electronic communication directing you to transfer funds or provide nonpublic personal information, EVEN IF THAT ELECTRONIC COMMUNICATION APPEARS TO BE FROM Geophenom or its affiliates, do not respond to it and immediately contact your real estate agent or title closer. Such requests, even if they may otherwise appear to be from Geophenom, are likely part of a scheme to defraud you by stealing funds from you or using your identity to commit a crime.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >To notify Geophenom of suspected fraud related to your transaction, send an email to customerservice@geophenomllc.com.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >6. Updating Our Privacy Policy</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >We may, from time to time, change our Privacy Policy. We will notify you of any changes by posting our updated Privacy Policy on our consumer applications and inserting a new “Effective Date.”</p>
<h2 style={{marginTop:'20px',marginBottom:'20px'}} >7. Contacting & Opt-Out Information</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >You may contact us as at customerservice@geophenomllc.com if: (a) you have questions or comments about our Privacy Policy; (b) wish to make corrections to any personally identifiable information you have provided; (c) want to opt-out from receiving future commercial correspondence, including emails, from us, our affiliated companies or third parties; or (d) wish to withdraw your consent to sharing your personally identifiable information with others.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >We will respond to your request and, if applicable and appropriate, make the requested change in our active databases as soon as reasonably practicable. Please note that requests may take up to 30 days and we may not be able to fulfill certain requests while allowing you access to certain benefits and features of our services.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >8. Effective Date and Contact Information:</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >This Privacy Policy is effective as of 05/16/2021. Our company can be reached at:</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Address: 523 Benfield Rd. #203 Severna Park, MD 21146</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Telephone Number: 1-410-562-1983</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Email Address:  customerservice@geophenomllc.com</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >9. California Privacy Rights</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Under California law, California residents have the right to request in writing from businesses with whom they have an established business relationship (1) a list of categories of personal information, such as name, address, e-mail address, and the type of services provided to the customer, that a business has disclosed to third parties [including affiliates that are separate legal entities] during the immediately preceding calendar year for the third parties' direct marketing purposes and (2) the names and addresses of all such third parties.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >To request the above information please contact us by writing to customerservice@geophenomllc.com.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >10. International Users</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >Our consumer applications are hosted by servers in the United States. If you are located outside of the United States, your personal information may be transferred to the United States. By submitting your personal information, you consent to its transfer to the United States and to its storage, processing, and use there in accordance with this Privacy Policy.</p>
<h2 style={{marginLeft:'10px',marginTop:'20px',marginBottom:'20px'}} >11. European Union Residents and General Data Protection Regulation Information</h2>
<p style={{marginLeft:'20px',marginRight:'5px'}} >None of our applications and/or real estate services are targeted towards, directed to or intended as solicitations to consumers in the European Union. In the event that the General Data Protection Regulation “GDPR” (a privacy law applicable to consumers residing in the European Economic Union) is applicable to a specific transaction and/or a specific consumer, any such consumer has the below rights.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >(a) Right of access. You have the right to obtain from us confirmation as to whether or not Personal Information concerning you is processed, and to request access to the Personal Information.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >(b) Right to correct or update. You have the right to correct or update inaccurate Personal Information about you.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >(c) Right to restrict processing. Under certain circumstances, you have the right to restrict the processing of your Personal Information. In such instances, your Personal Information may only be processed by us for limited purposes.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >(d) Right to object. Under certain circumstances, you have the right to object to the use or processing of your Personal Information where we no longer have a legitimate or legal need to process it; to object to your Personal Information being processed for direct marketing purposes; and object to automated decision making (including profiling) where the decision would have a legal or similarly significant impact on you.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >(e) Right to data portability. Under certain circumstances, you have a right to receive a copy of your Personal Information, which you have provided to us, in a machine-readable format and the right to transmit that Personal Information for use in another party’s service.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >(f) Right to be forgotten. Under certain circumstances, you have the right to request that your Personal Information be erased. Some Personal Information may be in backup archives and/or must be retained for legal or compliance reasons.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >To make any of these requests, please follow the instructions outlined in Section 7. We may require that you verify your identity to the exercise of any of these rights. Under certain circumstances, and in the event of a data breach involving your Personal Information that is likely to result in a risk to your rights and freedoms, we are required to notify the applicable supervisory authority within 72 hours after becoming aware of such a breach.</p>
<p style={{marginLeft:'20px',marginRight:'5px'}} >If you have been referred to our Company by a third party corporate client or a relocation services company, our Company is acting as a “Data Processor” under the direction of such corporate client or relocation services company, which are deemed “Data Controllers” under GDPR. The Data Controller is responsible for telling our company how your Personal Information should be used or processed in those specific transactions.</p>

            {/* Footer */}
            <Footer />
        </main>
    )
}
export default Privacy;