import React from 'react';
import WidgetFilterCategory from "./widgets/WidgetFilterCategory";
import WidgetFilterHHSize from "./widgets/WidgetFilterHHSize";
import WidgetFilterTags from "./widgets/WidgetFilterTags";
import Button from "../common/Button";
import sectiondata from "../../store/store";
import { BsChevronRight } from 'react-icons/bs'

function ListingListSidebar2() {
    return (
        <>
            <div className="sidebar">
                <WidgetFilterHHSize />
                <WidgetFilterTags tagitems={sectiondata.listing.sidebar.widgetFilterTags.tags} title={"Demographic/Behavioral Placeholder #4"} />
                <WidgetFilterCategory catitems={sectiondata.listing.sidebar.widgetFilterCategory.items} title={"Demographic/Behavioral Placeholder #6"} />
                <WidgetFilterCategory catitems={sectiondata.listing.sidebar.widgetFilterCategory.items} title={"Demographic/Behavioral Placeholder #8"} />

                <div className="sidebar-widget">
                    <div className="btn-box">
                        <Button text="apply demographics" url="#" className="d-block w-100 text-center">
                            <span className="d-inline-block"><BsChevronRight /></span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListingListSidebar2;
