import React from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg'
import tbd from '../assets/images/TBD.jpg'
import sectiondata from "../store/store";

const state = {
    breadcrumbimg: breadcrumbimg,
}
function CampaignOptions() {
    return (
        <main className="pricing-plan-page">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle="Campaign Options" MenuPgTitle="pages" img={state.breadcrumbimg}/>

            {/* Ad Design */}
            <img src={tbd} alt="tbd" />


            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default CampaignOptions;
