import React  from 'react'
import GeneralHeader from '../../components/common/GeneralHeader'
import BannerOne from '../../components/banner/banner1/BannerOne'
import WhatIs from '../../components/banner/banner1/WhatIs'
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import SectionsHeading from "../../components/common/SectionsHeading";
import PopularCategories from "../../components/other/categories/PopularCategories";
import HowItWorkOne from "../../components/hiw/hiw1/HowItWorkOne";
import FunFactsOne from "../../components/other/funfacts/funfacts1/FunFactsOne";
import Button from "../../components/common/Button";
import SectionDivider from "../../components/common/SectionDivider";
import InfoBox3 from "../../components/other/infoboxes/InfoBox3";
import sectiondata from "../../store/store";
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom";
import CreativeAd from "../../components/sliders/CreativeAd"
import Testimonial from "../../components/sliders/Testimonial"


function Home() {
    return (
        <main className="home-1">
            {/* Header */}
            <GeneralHeader />
            <CookieConsent location="bottom"  buttonStyle={{background:'#FF6B6B',color:'white'}}>This website uses cookies to enhance the user experience.</CookieConsent>
            {/* Search */}
            <BannerOne />
            {/* What is Geofencing? */}
            <WhatIs />
            {/* Plan the Marketing Campaign */}
            <section className="ptm-area text-center sect-padding-top sect-padding-bottom home-desc-center">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.howitworks.hiw1.sectitle} titleClass="creative" descClass="creative" desc={sectiondata.howitworks.hiw1.seccontent} />
                    </div>
                    <HowItWorkOne hiw1content={sectiondata.howitworks.hiw1} />
                </div>
            </section>
            {/* Target Your Exact Customer */}
            <section className="hiw-area sect-padding-top sect-padding-bottom after-none text-center home-desc-center">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.howitworks.hiw2.sectitle} desc={sectiondata.howitworks.hiw2.seccontent} />
                    </div>

                    <InfoBox3  infoitems={sectiondata.howitworks.hiw2.items} />
                </div>
                <div className="row ">                                                            
                    <div className="col-12 center main-search-input-btn">
                        <Link to='/list-map-view?place=-77.0364,38.8951&type='><button className="button theme-btn" type="submit">Get Started Now</button></Link>
                    </div>
                </div>
            </section>
            {/* Creative Ad Examples */}
            <section style={{backgroundColor:'#333F57',  color:'white'}} className="testimonial-area sect-padding-top sect-padding-bottom text-center home-desc-center">

                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.testimonialdata.sectitle} titleClass="creative before-none pt-0" descClass="creative" desc={sectiondata.testimonialdata.seccontent} />
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mx-auto mt-4">
                            <CreativeAd slideitems={sectiondata.clientlogos} />
                        </div>
                    </div>
                </div>
            </section>
            {/* Numbers Say Everything */}
            <section className="funfact-area section-bg-2 sect-padding-top sect-padding-bottom text-center home-desc-center">
                <div className="container">
                    <div className="row section-title-width">
                        <SectionsHeading title={sectiondata.funfacts.funfact1.sectitle} titleClass="numbers" descClass="numbers" desc={sectiondata.funfacts.funfact1.seccontent} />
                    </div>

                    <FunFactsOne countitems={sectiondata.funfacts.funfact1.counteritems} />
                </div>
                <div className="row ">                                                            
                    <div className="col-12 center main-search-input-btn">
                        <Link to='/list-map-view?place=-77.0364,38.8951&type='><button className="button theme-btn" type="submit">Get Started Now</button></Link>
                    </div>
                </div>
            </section>
            {/* What Our Customers Have To Say */}
            <section style={{backgroundColor:'#333F57',  color:'white'}} className="testimonial-area sect-padding-top sect-padding-bottom padding-top-100px padding-bottom-80px text-center">

                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.creativedata.sectitle} titleClass="creative before-none pt-0" descClass="creative"  />
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mx-auto mt-4">
                            <Testimonial slideitems={sectiondata.creativedata.sliders} />
                        </div>
                    </div>
                </div>
            </section>

            {/* Most Popular Categories */}
            <section className="cat-area sect-padding-top sect-padding-bottom home-desc-center" style={{background:'white',color:'#333F57'}}>
                <div className="container">
                    <div className="row section-title-width text-center" style={{color:'#333F57'}}>
                        <SectionsHeading title={sectiondata.popularcategories.sectitle} desc={sectiondata.popularcategories.seccontent} titleClass={'mostpopular'} descClass={'mostpopular'} />
                    </div>
                    <div className="row mt-5">
                        <PopularCategories catitems={sectiondata.popularcategories.categories} />
                    </div>
                </div>
            </section>




            {/* GeoPhenom is the Best */}
            <section className="cta-area section-bg column-sm-center sect-padding-top sect-padding-bottom home-desc-center" style={{background:'#333F57',color:'#white'}}>

                <div className="container" style={{backgroundColor:'#333F57'}}>
                    
                        <div className="row section-title-width text-center">
                            <SectionsHeading title={sectiondata.calltoactions.cta1.title} titleClass=" cta " descClass=" cta " desc={sectiondata.calltoactions.cta1.content} />
                        </div>
                    
                    <div className="row ">                                                            
                            <div className="col-12 text-center main-search-input-btn">
                                <Link to={sectiondata.calltoactions.cta1.btnurl}><button className="button theme-btn" type="submit">Let's Get Started</button></Link>
                            </div>
                        </div>
                </div>
            </section>


            <SectionDivider />



            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </main>
    )
}

export default Home;
