import React, { useState,useEffect } from 'react';
import filter from '../../../assets/images/Filter-button.svg';

function DollarSign({ onDollarSignPressed,title,value,defaultPrice }){
    const [isPressed, setIsPressed] = useState(defaultPrice);
    useEffect(() => {
        setIsPressed(defaultPrice);
      }, [defaultPrice]);
    const onPressed = () => {
        setIsPressed(!isPressed);
        onDollarSignPressed(value);
     }
    return(
        <>
    <li>
        <div style={isPressed ? {backgroundColor:'#ff6b6b'}: {backgroundColor:''}} onClick={onPressed}>
            {title}
        </div>
    </li>
    </>
    );
};

function WidgetFilterTagsPrice({tagitems, title, pressed,uuid,filterPressed, isCompetitor, defaultPrice }) {
    const onFilterClick=()=>{
        filterPressed(uuid,isCompetitor);
    }
    const [dp,setDp]=useState(defaultPrice);
    useEffect(() => {
        setDp(defaultPrice);
      }, [defaultPrice]);
    return (
        <>
            <div className="sidebar-widget tag-widget">
                <h3 style={{marginTop:'5px',paddingBottom:'0px'}} className="widget-title-price">
                    {title}
                </h3>
                <ul style={{marginBottom:'8px'}} className="tag-list mt-1" >
                    {tagitems.map((item, index) => {
                        var spressed = false;
                        if(dp.includes){
                            if( dp.includes(parseInt(item.path))){
                                spressed = true;
                            }
                        }else{
                            if((dp)==item.path){
                                spressed=true;
                            }
                        }
                        return (
                            <DollarSign key={item.title}  defaultPrice={spressed} onDollarSignPressed={pressed} title={item.title} value={item.path} />
                        )
                    })}
                    <button style={{border:'none',background:'none',width:'64px'}} value="Filter" onClick={onFilterClick}><img style={{width:'64px'}} src={filter}/></button>
                </ul>
            </div>
        </>
    );
}

export default WidgetFilterTagsPrice;
