import React from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import PasswordResetBox from "../components/other/account/PasswordResetBox";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg'

const state = {
    breadcrumbimg: breadcrumbimg,
}
function PasswordReset() {
    return (
        <main className="recover-pass-page">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle="Password Reset" MenuPgTitle="Pages" img={state.breadcrumbimg} />

            <PasswordResetBox />


            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}
export default PasswordReset;