import { Auth } from "aws-amplify";
import { useState, React, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchUserIsAdmin, fetchUserList } from "../utils/APIRequests";
import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import Breadcrumb from "../components/common/Breadcrumb";
import breadcrumbimg from "../assets/images/bread-bg.jpg";
import { useHistory } from "react-router";
import Loader from "../components/loading/Loader";
import { AiOutlineUser } from "react-icons/ai";

const state = {
  breadcrumbimg: breadcrumbimg,
};

function UserList() {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  // Check if user is logged in, otherwise redirect to login page
  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        if ((await fetchUserIsAdmin()) === false) {
          throw new Error("User is not an admin");
        }
      } catch (e) {
        history.push("/login");
      }
    }
    checkAuth();
  }, []);

  //load user data
  useEffect(() => {
    async function fetchUserData() {
      setLoading(true);
      try {
        const userList = await fetchUserList();
        setUserList(userList.message);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    fetchUserData();
  }, []);

  const navigateToDashboard = (user) => {
    history.push({ pathname: `/dashboard?email=${user.email.String}`, state: user });
  };

  return (
    <main className="home-1">
      {/* Header */}
      <GeneralHeader />
      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="User List"
        MenuPgTitle="pages"
        img={state.breadcrumbimg}
      />
      {loading && (
        <Loader
          style={{ marginTop: "30px" }}
          message="Loading your User List, please wait..."
        />
      )}

      <div className="container custom-container mt-2 mb-5">
        <div class="mt-5 user_cards">
          {userList &&
            userList.length > 0 &&
            userList.map((user) => {
              const first_name = user?.first?.String;
              const last_name = user?.last?.String;
              const user_avtar =
                first_name.substring(0, 1) + last_name.substring(0, 1);
              return (
                <div class="user_card" key={user?.id}>
                  <Link to={'/dashboard?email=' + user.email.String + "&name=" + user.first.String}>
                    <div
                      class="card user_list_card"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateToDashboard(user)}
                    >
                      <div class="card-body">
                        <div className="user_avatar">
                          {/* {user_avtar} */}
                          <AiOutlineUser className="user-icon" />
                        </div>
                        <p className="row pt-4 align-items-start">
                          <span className="col-5 user_list_card_fields">
                            Name :
                          </span>
                          {/* <span className="col-1">:</span> */}
                          <span className="col-7 col-md-7">
                            {user.first.String || user.last.String
                              ? `${user.first.String} ${user.last.String}`
                              : "--"}
                          </span>
                        </p>

                        <p className="row pt-2 align-items-start">
                          <span className="col-5 user_list_card_fields">
                            {" "}
                            Email :
                          </span>
                          <span className="col-7 col-md-7">
                            {user.email.String ? user.email.String : "--"}
                          </span>
                        </p>

                        <p className="row pt-2 align-items-start">
                          <span className="col-5 user_list_card_fields">
                            {" "}
                            Phone :
                          </span>
                          {/* <span className="col-1">:</span> */}
                          <span className="col-7 col-md-7">
                            {user.phone.String ? user.phone.String : "--"}
                          </span>
                        </p>

                        <p className="row pt-2 align-items-start">
                          <span className="col-5 user_list_card_fields">
                            {" "}
                            Organization :
                          </span>
                          {/* <span className="col-1">:</span> */}
                          <span className="col-7 col-md-7">
                            {`${
                              user.companyname.String
                                ? `${user.companyname.String}`
                                : "--"
                            }   (${
                              user.companyid.String ? user.companyid.String : "--"
                            })`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </main>
  );
}

export default UserList;
