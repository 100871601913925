// amplify
import 'crypto-js/lib-typedarrays'; 
import { Auth } from 'aws-amplify';
import React, {useState} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg'
import queryString from 'query-string';
import { Form, Input, Button, notification, Popover, Spin, Col, Row,Checkbox } from 'antd';
import {useLocation} from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {Link, Redirect} from "react-router-dom";
const state = {
    breadcrumbimg: breadcrumbimg,
}
function Verify() {
    const [confirmationCode, setConfirmationCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [redirect2, setRedirect2] = useState(false);
    const [error, setError] = useState('');
    let location = useLocation();
    const queryString = require('query-string');
    let initialUsername = queryString.parse(location.search).email;
    const resend = () =>{
      Auth.resendSignUp(initialUsername).then(() => {
        handleOpenNotification2('success', 'Succesfully resent email!', 'Please check your email and try again!');
      })
      .catch(err => {
        handleOpenNotification2('error', 'Invalid code', err.message);
        setLoading(false);
      });
    }

    const handleOpenNotification2 = (type, title, message) => {
      switch (type) {
        case 'success':
          notification['success']({
            message: title,
            description: message,
            placement: 'topRight',
            duration: 1.5,
            onClose: () => {
              //setRedirect2(true);
            }
          });
          break;
  
        case 'error':
          notification['error']({
            message: title,
            description: message,
            placement: 'topRight',
            duration: 1.5
          });
          break;
      }
    };
    const onFinish = (event) => {
        
    
        // show progress spinner
        setLoading(true);
    
        Auth.confirmSignUp(initialUsername, confirmationCode)
          .then(() => {
            handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
          })
          .catch(err => {
            handleOpenNotification('error', 'Invalid code', err.message);
            setLoading(false);
          });
      };
      const handleOpenNotification = (type, title, message) => {
        switch (type) {
          case 'success':
            notification['success']({
              message: title,
              description: message,
              placement: 'topRight',
              duration: 1.5,
              onClose: () => {
                setRedirect2(true);
              }
            });
            break;
    
          case 'error':
            notification['error']({
              message: title,
              description: message,
              placement: 'topRight',
              duration: 1.5
            });
            break;
        }
      };
      const handleOnPaste = (event) => {
        event.preventDefault();
    
        let code = event.clipboardData.getData('Text').trim();
    
        /** Update input */
        setConfirmationCode(code);
    
        // regex to check if string is numbers only
        const reg = new RegExp('^[0-9]+$');
    
        if (reg.test(code) && code.length === 6) {
          // code is a valid number
    
          setLoading(true);
          
          Auth.confirmSignUp(initialUsername, code)
            .then(() => {
              handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
            })
            .catch(err => {
              handleOpenNotification('error', 'Invalid code', err.message);
              setLoading(false);
            });
        }
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const handleChange = (event) => {
        setConfirmationCode(event.currentTarget.value);
      };
      const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
    return (
        <main className="login-page">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle="Verify" img={state.breadcrumbimg} />
            <section className="form-shared padding-top-40px padding-bottom-100px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
            <div className="billing-form-item mb-0">
            <div className="billing-content">
            <Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            
            <div className="full-width">
              <h2>Check your email</h2>
              <p>We've sent a six­ digit confirmation code</p>
            </div>
            <div className="col-lg-12">
            <div className="input-box">
            <div className="form-group">
            
            <Form.Item validateStatus={error && 'error'} help={error} label="Confirmation Code">
              <Input
                className="form-control"
                size="large"
                type="number"
                placeholder="Enter confirmation code"
                onChange={handleChange}
                onPaste={handleOnPaste}
                value={confirmationCode}
              />
            </Form.Item>
            </div>
            </div>
            </div>

         
            <Button type="primary" disabled={loading} htmlType="submit" size="large">
            {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Confirm Email'}
            </Button>

            <Button style={{marginLeft:'5px'}} type="primary" disabled={loading} onClick={resend} size="large">
            {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Resend Confirmation Email'}
            </Button>
         
            </Form>
            </div>
            </div>
            </div>
            </div>
            </div>
            </section>
            {redirect2 && <Redirect to={{ pathname: '/login' }} />}
            {/* Footer */}
            <Footer />

            

        </main>
    );
}

export default Verify;