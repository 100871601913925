import "./AdPreview.css";
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { HEADLINE_MAX_LENGTH, PHONE_MAX_LENGTH, SUBHEAD_MAX_LENGTH } from "../../../utils/FieldValidations";

export default function AdPreview(props) {
    const { headline, subhead, headlineColor, subheadColor,
        buttontext, buttonTextColor, buttonColor, buttonBodyColor,
        backgroundColor, phone, photo, logo, messaging, backgroundColor2, backgroundColor3 } = props;

    const placeholderImage =
        "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=";
    const width = 360;
    const height = 300;

    const trimmedHeadline = headline.length > HEADLINE_MAX_LENGTH ? headline.substring(0, HEADLINE_MAX_LENGTH) : headline;
    const trimmedSubhead = subhead.length > SUBHEAD_MAX_LENGTH ? subhead.substring(0, SUBHEAD_MAX_LENGTH) : subhead;
    const trimmedPhone = phone.length > PHONE_MAX_LENGTH ? phone.substring(0, PHONE_MAX_LENGTH) : phone;

    const shouldPlaceholderHeadline = trimmedHeadline.length === 0;
    const shouldPlaceholderSubhead = trimmedSubhead.length === 0;
    const shouldPlaceholderMessaging = messaging.length === 0;
    const shouldPlaceholderPhone = trimmedPhone.length === 0;

    return (
        <div id={"fed2"}
            className="sa-wrapper"
            style={{ width: `${width}px`, height: `${height}px`, }}
        >
            <div className="sa-row sa-header" style={{ backgroundColor: backgroundColor, color: headlineColor }}>
                <h1>{!shouldPlaceholderHeadline ? trimmedHeadline : "HEADLINE GOES HERE"}</h1>
                <h2>{!shouldPlaceholderSubhead ? trimmedSubhead : "Subhead goes here"}</h2>
            </div>
            <div className="sa-row sa-content">
                <div className="sa-col">
                    <img className="your-image" src={photo} />
                </div>
                <div className="sa-col sa-message" style={{ backgroundColor: backgroundColor2, color: subheadColor }}>
                    <span>{!shouldPlaceholderMessaging ? ReactHtmlParser(messaging) : "Body text/Messaging goes here"}</span>
                    <span>{
                        !shouldPlaceholderPhone ? (<a style={{ color: subheadColor, textDecoration: 'underline' }} href={`tel:${trimmedPhone}`}>{trimmedPhone}</a>) : "000-000-0000"
                    }</span>
                </div>
            </div>
            <div className="sa-row sa-footer">
                <div className="sa-col button-wrapper" style={{ backgroundColor: buttonColor }}>
                    <button style={{ backgroundColor: buttonBodyColor, color: buttonTextColor }}>{buttontext}</button>
                </div>
                <div className="sa-col logo-wrapper" style={{ backgroundColor: backgroundColor3 }}>
                    <img className="sa-your-logo" src={logo} />
                </div>
            </div>
        </div>
    );
};


