import React from 'react'
import LearnMore from '../../../assets/images/Learn-More-button-white.svg'
import WhatIsGeo from '../../../assets/images/video-placeholder-graphic.svg'
import {Link} from "react-router-dom";
import HowItWorkOneVideo from "../../hiw/hiw1/HowItWorkOneVideo";
import slicevideo from "../../../assets/video/GeoPhenomv3final.mp4";

export default function WhatIs() {
    return (
        <>
            <section className="cat-area sect-padding-top sect-padding-bottom" style={{color:'#000'}}>
                <div className="container">
                    <div className="row section-title-width text-center">
                        <div className="col-lg-6 col-md-12  section-heading" style={{textAlign:'left'}}>
                                <h2 className='sec__title whatIs-geo'>What is Geofencing?</h2>
                                <div style={{marginTop:'20px'}}>Target from zip codes to neighborhoods; coffee shops to grocery stores; parking lots to train stations; major employers to local businesses.  It surpasses the reach of digital flyers for community outreach because your potential customers have your ads at hand!</div>
                                <div style={{marginTop:'20px'}}>Serve mobile ads to the people who live at specific addresses or frequent important locations.  Drive website visitors and new leads with action oriented banner ads.  Your ads show up in a highly vetted list of websites and mobile apps so you can be sure your personal brand is safe.</div>
                                <div  style={{marginTop:'20px'}}><Link to='/gf101'><button class="button theme-btn learn-more-btn" type="submit">Learn More</button></Link></div>
                        </div>
                        <div className="col-lg-6 col-md-12 ">
                        <div  style={{marginTop:'20px'}}>
                        <video controls preload="metadata" style={{width:'100%'}}>
                                <source src={slicevideo + '#t=0.1'} />
                            </video>

                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}