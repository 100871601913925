import {React, useState,useEffect,useMemo} from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import PersonalInfo from "../../components/other/account/PersonalInfo";
import { GiChickenOven } from 'react-icons/gi'
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from '../../assets/images/bread-bg.jpg'
import placeimg from '../../assets/images/img6.jpg'
import Banner7 from "../../components/banner/banner7/Banner7";
import wizard2 from "../../assets/images/2-Design-Ad.svg";
import saveandcontinue from "../../assets/images/Continue-Button-Arrow-Green.svg";
import { IDENTITY_USER_TOKEN_KEY } from '../../components/data/constants';
import {refresh} from '../../components/common/TokenRefresh';
import * as html2canvas from 'html2canvas';
import { ImageMap } from '@qiuz/react-image-map';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import saveedits from "../../assets/images/Save-Edits-arrow-button.svg";
import useIsMobile from '../../components/customHooks/useIsMobile';
import { placeholderPhoto } from "../../assets/images/base64s.json";
import { placeholderLogo } from "../../assets/images/base64s.json";
import { headlineValidationLogic, messagingValidationLogic, subheadingValidationLogic, urlValidationLogic } from '../../utils/FieldValidations';
import { isUserLoggedIn } from '../../utils/APIRequests';


const state = {
    breadcrumbimg: breadcrumbimg,
    img: placeimg,
    title: 'Favorite Place Food Bank',
    subtitle: 'Bishop Avenue, New York',
    cardTypeIcon: <GiChickenOven />,
    cardType: 'Restaurant',
    badge: '4.6',
    cardLink: '/listing-details',
    summaryTitle: 'Booking Summary',
    summaryDate: '12/03/2020',
    summaryHour: '6:30 PM',
    summaryAdults: '2',
    summaryChildren: '1',
    summaryTotal: '$8.00'
}
function Booking() {
    let location = useLocation();
    let campaign = "0";
    location = queryString.parse(location.search);
    if(location.campaign){
        campaign = location.campaign;
    }

    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [headline,setHeadline]=useState(() => {
        const saved = localStorage.getItem("headline");
        const initialValue = saved;
      return initialValue || "";
      });
    const [subhead,setSubhead]=useState(() => {
        const saved = localStorage.getItem("subhead");
        const initialValue = saved;
      return initialValue || "";
      });
    const [messaging,setMessaging]=useState(() => {
        const saved = localStorage.getItem("messaging");
        const initialValue = saved;
      return initialValue || "";
      });
    const [headlineColor, setHeadlineColor]=useState(() => {
        const saved = localStorage.getItem("headlineColor");
        const initialValue = saved;
        return initialValue || "#E0E0E0";
      });
    const [subheadColor, setSubheadColor]=useState(() => {
        const saved = localStorage.getItem("subheadColor");
        const initialValue = saved;
        return initialValue || "#3D393A";
      });
    const [buttontext, setButtontext]=useState(() => {
        const saved = localStorage.getItem("buttontext");
        const initialValue = saved;
        return initialValue || "LEARN MORE";
      });
    const [buttonTextColor, setButtonTextColor]=useState(() => {
        const saved = localStorage.getItem("buttonTextColor");
        const initialValue = saved;
        return initialValue || "#2F2F2F";
      });
    const [buttonColor, setButtonColor]=useState(() => {
        const saved = localStorage.getItem("buttonColor");
        const initialValue = saved;
        return initialValue || "#E0E0E0";
      });
  const [buttonBodyColor, setButtonBodyColor] = useState(() => {
    const saved = localStorage.getItem("buttonBodyColor");
    const initialValue = saved;
    return initialValue || "#E0E0E0";
  });
    const [backgroundColor, setBackgroundColor]=useState(() => {
        const saved = localStorage.getItem("backgroundColor");
        const initialValue = saved;
        return initialValue || "#000000";
      });
    const [backgroundColor2, setBackgroundColor2]=useState(() => {
        const saved = localStorage.getItem("backgroundColor2");
        const initialValue = saved;
        return initialValue || "#ECECEE";
      });
    const [backgroundColor3, setBackgroundColor3]=useState(() => {
        const saved = localStorage.getItem("backgroundColor3");
        const initialValue = saved;
        return initialValue || "#FFFFFF";
      });
    const [url,setUrl]=useState(() => {
        const saved = localStorage.getItem("url");
        const initialValue = saved;
      return initialValue || "";
      });
    const [phone,setPhone]=useState(() => {
        const saved = localStorage.getItem("phone");
        const initialValue = saved;
        return initialValue || "";
      });
    const [initialAd,setInitialAd]=useState(() => {
        //const saved = localStorage.getItem("initialAd");
        //const initialValue = JSON.parse(saved);
        return {};
      });
    const [adId, setAdId]=useState(() => {
        const saved = localStorage.getItem("adId");
        const initialValue = saved;
        return initialValue || 0;
      });
    const [lines,setLines]=useState(() => {
        const saved = localStorage.getItem("lines");
        const initialValue = JSON.parse(saved);
        return [];
      });
    const [assets,setAssets]=useState(() => {
        return [];
      });
    const [csv,setCsv]=useState(() => {
        const saved = localStorage.getItem("csv");
        const initialValue = saved;
        return initialValue || false;
      });
    const [additional,setAdditional]=useState(() => {
        const saved = localStorage.getItem("additional");
        const initialValue = saved;
        return initialValue || false;
      });
    const [industry,setIndustry]=useState(() => {
        const saved = localStorage.getItem("industry");
        const initialValue = saved;
        return initialValue || 'restaurant';
      });
    const [isEdit,setIsEdit] = useState(() => {
        return  false;
      });
    const [creative,setCreative] = useState("");

    const [prof,setProf]=useState(() => {
      const saved = localStorage.getItem("prof");
      const initialValue = saved;
      return initialValue || false;
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem("headline", headline);
      }, [headline]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("messaging", messaging);
      }, [messaging]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("subhead", subhead);
      }, [subhead]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("headlineColor", headlineColor);
      }, [headlineColor]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("subheadColor", subheadColor);
      }, [subheadColor]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("buttontext", buttontext);
      }, [buttontext]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("buttonTextColor", buttonTextColor);
      }, [buttonTextColor]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("buttonColor", buttonColor);
      }, [buttonColor]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("buttonBodyColor", buttonBodyColor);
      }, [buttonBodyColor]);
  useEffect(() => {
        // storing input name
        localStorage.setItem("backgroundColor", backgroundColor);
      }, [backgroundColor]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("backgroundColor2", backgroundColor2);
      }, [backgroundColor2]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("backgroundColor3", backgroundColor3);
      }, [backgroundColor3]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("url", url);
      }, [url]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("phone", phone);
      }, [phone]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("lines", JSON.stringify(lines));
      }, [lines]);

      useEffect(() => {
        // storing input name
        localStorage.setItem("csv", csv);
      }, [csv]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("additional", additional);
      }, [additional]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("industry", industry);
      }, [industry]);
      useEffect(() => {
        // storing input name
        localStorage.setItem("isEdit", isEdit);
      }, [isEdit]);
      
      
      const [photo, setPhoto] = useState(() => {
        const saved = localStorage.getItem("photo");
        const initialValue = saved;
        return initialValue || placeholderPhoto;
      });
      
      const [logo, setLogo] = useState(() => {
        const saved = localStorage.getItem("logo");
        const initialValue = saved;
        return initialValue || placeholderLogo;
      });
      
      useEffect(() => {
        // storing input name
        localStorage.setItem("photo", photo);
      }, [photo]);

      useEffect(() => {
        // storing input name
        localStorage.setItem("logo", logo);
      }, [logo]);

  const userHasUpdatedPhoto = photo !== placeholderPhoto;
  const userHasUpdatedLogo = logo !== placeholderLogo;


  const validateAllData = () => {
    if (!headline || headlineValidationLogic(headline)) return false; // Headline
    if (!subhead || subheadingValidationLogic(subhead)) return false;	 // Subhead
    if (!messaging || messagingValidationLogic(messaging)) return false; // Messaging
    if (!userHasUpdatedPhoto) return false; // Photo
    if (!userHasUpdatedLogo) return false; // Logo
    if (!url || urlValidationLogic(url)) return false; // URL
    // if (!phone || phoneValidationLogic(phone)) return false; // Phone
    return true;
  };

  const isDataValid = validateAllData();





    const mapArea = [{"width":"22.702233250620342%","height":"98.66666666666666%","left":"0%","top":"-4.564250212347865e-15%"}];
    const onMapClick = (area, index) => {
        if(index==0){
          window.open('/list-map-view?type=',"_self");
        }else{
          saveAndContinue(campaign);
        }
    }
    const isMobile = useIsMobile();
    console.log("use-effect",useIsMobile());
    useEffect(() => {

        if (location.edit){
            setIsEdit(true);
        }
    }, []);

    const ImageMapComponent = useMemo(() => <ImageMap  className='mx-auto progDesk' style={{height:'75px'}} src={wizard2} map={mapArea} onMapClick={onMapClick} />, [mapArea, wizard2]);
    const saveAndContinue=(campaign)=>{ 
        showLoading();
        let outImage = '';
        let fed2 = document.getElementById('fed2');
        let useWidth=fed2.style.width;
        let useHeight=fed2.style.height;
        window.scrollTo(10,0);
        html2canvas(fed2).then(function(canvas) {
            //document.body.appendChild(canvas);
            outImage = stripBase64Prefix(canvas.toDataURL("image/png", "image/octet-stream"));
            if(initialAd&&initialAd.campaign){
              console.log("initialAd ===>",initialAd)
              var assetsOut = assets.map(function(k){return stripBase64Prefix(k)}).join(",");
              console.log("assests ==>",assets);
                (async () => {
                    refresh();
                    console.log("adId ==>",adId);
                    console.log(headline,subhead,backgroundColor,backgroundColor2,backgroundColor3,headlineColor,buttontext,stripBase64Prefix(photo),phone,buttonTextColor,url,buttonColor,subheadColor,stripBase64Prefix(logo),stripBase64Prefix(creative),prof,outImage,assetsOut,csv);
                    const rawResponse = await fetch(SERVER_URL+'/ad?ad='+adId, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
        
                    },
                    body: JSON.stringify([initialAd,{campaign:parseInt(campaign),headline:headline,headline2:subhead,headercolor:backgroundColor,backgroundcolor2:backgroundColor2,backgroundcolor3:backgroundColor3,headertextcolor:headlineColor,maintext:buttontext,mainphoto:stripBase64Prefix(photo),phone:phone,buttonTextColor:buttonTextColor,url:url,bodycolor:buttonColor,bodytextcolor:subheadColor,logo:stripBase64Prefix(logo),creative:stripBase64Prefix(creative),prof:prof,out:outImage,assets:assetsOut,csv:csv}])
                    });
                    const content = await rawResponse.json();
                    console.log(content);
                    if(location.edit){
                      console.log("location ==>",location);
                      console.log("location.edit",location.edit);
                      if (campaign != 0) {

                          let sendURL = SERVER_URL + '/sendchangereport?campaign=' + campaign + '&ad=' + content.message + '&email=' + localStorage.getItem('email');

                          (async () => {
                              refresh();
                              const rawResponseFences = await fetch(sendURL, {
                                  method: 'GET',
                                  headers: {
                                      'Accept': 'application/json',
                                      'Content-Type': 'application/json',
                                      'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
              
                                  }
                              });
                              const content = await rawResponseFences.json();
                              console.log(content);
                              window.history.back();
                          })();
              
                      }
                        
                    }else{
                        window.open("/review?ad="+content.message+'&campaign='+campaign+'&edit=true',"_self");
                    }
                    
                })();
            }else{
                //new ad so no edit
                var assetsOut = assets.map(function(k){return stripBase64Prefix(k)}).join(",");
                (async () => {
                  const userLoggedIn = await isUserLoggedIn();
                  const adDesignBody = JSON.stringify({ campaign: parseInt(campaign), headline: headline, headline2: subhead, headercolor: backgroundColor, backgroundcolor2: backgroundColor2, backgroundcolor3: backgroundColor3, headertextcolor: headlineColor, maintext: buttontext, mainphoto: stripBase64Prefix(photo), phone: phone, buttonTextColor: buttonTextColor, url: url, bodycolor: buttonColor, bodytextcolor: subheadColor, logo: stripBase64Prefix(logo), creative: stripBase64Prefix(creative), prof: prof, out: outImage, assets: assetsOut, csv: csv });
                  if (!userLoggedIn) {
                    localStorage.setItem("pendingAd", adDesignBody);
                    continueNoSave();
                  }
                    const rawResponse = await fetch(SERVER_URL+'/ad?ad='+adId, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
        
                    },
                      body: adDesignBody
                    });
                    const content = await rawResponse.json();
                    console.log(content);
                    if(location.edit){
                        window.history.back();
                    }else{
                        window.open("/review?ad="+content.message+'&campaign='+campaign,"_self");
                    }
                    
                })();
            }
        });
    

    };
    const showLoading=()=>{
        document.getElementById('loading').style.display='block';
    }
    const continueNoSave=()=>{
        showLoading();
        window.open("/review","_self");
    };
    const stripBase64Prefix=(base64text)=>{
        return base64text.split(',')[1];
    };
    return (
        <main className="booking-page booking-area-mobile">
            {/* Header */}
            <GeneralHeader/>

                       {/* Banner */}
                       <Banner7 />


            {/* Personal Information */}
            <section style={{}} className="booking-area padding-bottom-80px">
                <div style={{}} className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <PersonalInfo headline={headline} setHeadline={setHeadline} subhead={subhead} setSubhead={setSubhead} messaging={messaging} setMessaging={setMessaging}
                            headlineColor={headlineColor} setHeadlineColor={setHeadlineColor} subheadColor={subheadColor} setSubheadColor={setSubheadColor}
                            buttontext={buttontext} setButtontext={setButtontext} buttonTextColor={buttonTextColor} setButtonTextColor={setButtonTextColor}
                  buttonColor={buttonColor} buttonBodyColor={buttonBodyColor} setButtonBodyColor={setButtonBodyColor} setButtonColor={setButtonColor} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} backgroundColor2={backgroundColor2} backgroundColor3={backgroundColor3} setBackgroundColor3={setBackgroundColor3} setBackgroundColor2={setBackgroundColor2}
                            phone={phone} setPhone={setPhone} url={url} setUrl={setUrl} initialAd={initialAd} setInitialAd={setInitialAd} adId={adId} setAdId={setAdId} 
                            lines={lines} setLines={setLines} assets={assets} setAssets={setAssets} csv={csv} setCsv={setCsv} additional={additional} setAdditional={setAdditional} photo={photo} setPhoto={setPhoto}
                            logo={logo} setLogo={setLogo} creative={creative} setCreative={setCreative} prof={prof} setProf={setProf} industry={industry} setIndustry={setIndustry}  isEdit={isEdit} saveAndContinueBook= {saveAndContinue} campaignValue= {campaign}/>
                        </div>
                    
                    </div>
                </div>
                <div style={{ 'marginTop': '50px','width':'100%','marginRight':'0' }} className="row wizard-mobile progDesk">    
        
        {!isEdit&&<div className='row align-items-center booking-progress' style={{ position:'fixed',bottom:'0px',overflow:'hidden',margin: '0px', width: '100%',height:'100px','marginRight':'0' }}>
                        <div className="row mx-auto" style={{  }}> 
                          <div style={{padding:'5px'}} className='col-lg-8 col-md-10'>{ImageMapComponent}</div>
                          <div style={{padding:'5px'}} className='col-lg-4 col-md-2 justify-content-center align-self-center continue-btn-mobile'>
                  <button title={photo ? "" : "All fields marked with an asterisk (*) are required"} style={{ border: '0', backgroundColor: '#333F57' }} onClick={(e) => {
                    if (!isDataValid) {
                      window.alert("All fields marked with an asterisk (*) are required");
                      return;
                    }
                    saveAndContinue(campaign)
                  }}>
                    <img style={{ height: '55px', filter: isDataValid ? "" : 'saturate(0%)' }} src={saveandcontinue} />
                            </button>  
                          </div>
                        </div>
                    </div>}
        {isEdit&&<div className='row align-items-center booking-progress' style={{ position:'fixed',bottom:'0px',overflow:'hidden',margin: '0px', width: '100%',height:'100px','marginRight':'0' }}>
            <div className="mx-auto" style={{  }}> <button style={{ border: '0', backgroundColor: '#333F57' }} onClick={(e) => saveAndContinue(campaign)}><img style={{ marginLeft: '30px', height: '60px' }} src={saveedits} /></button> </div>
        </div>}
    </div>
            </section>

            {/* Footer */}
            

            <ScrollTopBtn />

        </main>
    );
}

export default Booking;
