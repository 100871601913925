
import { Auth } from 'aws-amplify';
import { IDENTITY_USER_TOKEN_KEY } from '../data/constants';



    export function refresh(queryParams = "") {
        Auth.currentSession()
      .then((session) => {
        var idTokenExpire = session.getIdToken().getExpiration();
        var refreshToken = session.getRefreshToken();
        var currentTimeSeconds = Math.round(+new Date() / 1000);
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  Auth.signOut()
                } else {
                  localStorage.setItem(IDENTITY_USER_TOKEN_KEY, data.getIdToken().getJwtToken());
                }
              });
            });
        } else {
            localStorage.setItem(IDENTITY_USER_TOKEN_KEY, session.getIdToken().getJwtToken());
        }
      })
      .catch(() => {
        window.location.href=`/login?${queryParams}`;
      });
    };

  export function isLoggedIn(){
    let token = localStorage.getItem(IDENTITY_USER_TOKEN_KEY);
    let out = false;
    if(token&&token!=null){
      out=true;
    }
    return out;
  };
    
