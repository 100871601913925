import React from 'react';

export default function SectionDivider() {
    return (
        <>
            <div className="section-block"></div>
        </>
    );
}

