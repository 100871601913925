import { Auth } from "aws-amplify";
import { ReactFragment, useState, React, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { fetchPrices, fetchUserIsAdmin, postEditPricing } from "../utils/APIRequests";
import GeneralHeader from '../components/common/GeneralHeader';
import Footer from "../components/common/footer/Footer";
import Breadcrumb from "../components/common/Breadcrumb";
import breadcrumbimg from "../assets/images/bread-bg.jpg";
import { useHistory } from "react-router";

const state = {
  breadcrumbimg: breadcrumbimg,
};

function AdminUser() {

  let history = useHistory();




  // Check if user is logged in, otherwise redirect to login page
  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        if (await fetchUserIsAdmin() === false) {
          throw new Error("User is not an admin");
        }
      }
      catch (e) {
        history.push("/login");
      }
    }
    checkAuth();
  }, []);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [starterPrice, setStarterPrice] = useState({
    current: 0,
    new: 0,
    enableEditing: false
  });
  const [growthPrice, setGrowthPrice] = useState({
    current: 0,
    new: 0,
    enableEditing: false
  });
  const [rockstarPrice, setRockstarPrice] = useState({
    current: 0,
    new: 0,
    enableEditing: false
  });

  const enableSubmitBtn = (
    (starterPrice.new !== 0 && starterPrice.new !== starterPrice.current) ||
    (growthPrice.new !== 0 && growthPrice.new !== growthPrice.current) ||
    (rockstarPrice.new !== 0 && rockstarPrice.new !== rockstarPrice.current)
  );

  useEffect(() => {
    fetchPrices().then(res => {
      console.log(res);
      setStarterPrice(state => {
        return {
          ...state,
          current: res[0]["cost"]["Float64"],
          new: res[0]["cost"]["Float64"]
        }
      });
      setGrowthPrice(state => {
        return {
          ...state,
          current: res[1]["cost"]["Float64"],
          new: res[1]["cost"]["Float64"]
        }
      });
      setRockstarPrice(state => {
        return {
          ...state,
          current: res[2]["cost"]["Float64"],
          new: res[2]["cost"]["Float64"]
        }
      });
    });
  }, []);

  const handleStarterPriceChange = (e) => {
    const newCost = e.target.value;
    setStarterPrice(state => {
      return {
        ...state,
        new: Number(newCost)
      }
    });
  }

  const handleGrowthPriceChange = (e) => {
    const newCost = e.target.value;
    setGrowthPrice(state => {
      return {
        ...state,
        new: Number(newCost)
      }
    });
  }

  const handleRockstarPriceChange = (e) => {
    const newCost = e.target.value;
    setRockstarPrice(state => {
      return {
        ...state,
        new: Number(newCost)
      }
    });
  }

  const resetFields = () => {
    setStarterPrice(state => {
      return {
        ...state,
        new: state.current,
        enableEditing: false
      }
    });
    setGrowthPrice(state => {
      return {
        ...state,
        new: state.current,
        enableEditing: false
      }
    });
    setRockstarPrice(state => {
      return {
        ...state,
        new: state.current,
        enableEditing: false
      }
    });
  }



  // // Activate the Input Field on Edit Button Click
  // const onClickEditBtnHandler = (e) => {
  //   //Default Event Prevent
  //   e.preventDefault();
  //   // Onclick activatate Input Field
  //   setEnable(!enable);
  // };

  // const currentSession = Auth.currentSession().then(res => {
  //   console.log(res);
  // });

  const handleSubmit = async () => {
    const isUserAdmin = await fetchUserIsAdmin();
    if (!isUserAdmin) {
      history.push("/login");
      return;
    }
    setIsSubmitLoading(true);
    try {
      const starterResponse = await postEditPricing(
        "1",
        starterPrice.new
      );
      const growthResponse = await postEditPricing(
        "2",
        growthPrice.new
      );
      const rockstarResponse = await postEditPricing(
        "3",
        rockstarPrice.new
      );
      if (
        starterResponse.status === 200 &&
        growthResponse.status === 200 &&
        rockstarResponse.status === 200
      ) {
        setIsSubmitLoading(false);
        alert("Successfully Updated Pricing");
        resetFields();
        window.location.href = "/pricing";
      } else {
        setIsSubmitLoading(false);
        console.error({starterResponse, growthResponse, rockstarResponse});
        throw new Error("Error Updating Pricing");
      }

    } catch (err) {
      setIsSubmitLoading(false);
      console.error(err);
      window.alert(err);
    }
  };

  return (
    <main className="home-1">
    {/* Header */}
    <GeneralHeader />
    {/* Breadcrumb */}
    <Breadcrumb
        CurrentPgTitle="Edit Pricing"
        MenuPgTitle="pages"
        img={state.breadcrumbimg}
      />
    {/* Edit Pricing */}
      <div id="price-modifier-wrapper">
        <div className="heading">
          
        </div>

        <div>
          <div className="content-container content-spacing" style={{ gap: '20px' }}>
            <p className="package-name">Starter</p>
            <span><span className="pricing-value">$</span><input
              id="starter-price"
              disabled={!starterPrice.enableEditing}
              className="price-text-field"
              type="number"
              value={starterPrice.new}
              placeholder={starterPrice.current}
              onChange={handleStarterPriceChange}
            /></span>
            <button type="button" class="btn btn-primary btn-primary-color" onClick={() => {
              setStarterPrice(state => {
                return {
                  ...state,
                  enableEditing: true
                }
              });
              document.getElementById("starter-price").focus();
            }}>
              <FiEdit />
            </button>
          </div>

          <div className="content-container content-spacing" style={{ gap: '20px' }}>
            <p className="package-name">Growth</p>
            <span><span className="pricing-value">$</span><input
              id="growth-price"
              disabled={!growthPrice.enableEditing}
              className="price-text-field"
              type="number"
              value={growthPrice.new}
              placeholder={growthPrice.current}
              onChange={handleGrowthPriceChange}
            /></span>
            <button type="button" class="btn btn-primary btn-primary-color" onClick={() => {
              setGrowthPrice(state => {
                return {
                  ...state,
                  enableEditing: true
                }
              });
              document.getElementById("growth-price").focus();
            }}>
              <FiEdit />
            </button>
          </div>

          <div className="content-container content-spacing" style={{ gap: '20px' }}>
            <p className="package-name">Rockstar</p>
            <span><span className="pricing-value">$</span><input

              id="rockstar-price"
              disabled={!rockstarPrice.enableEditing}
              className="price-text-field"
              type="number"
              value={rockstarPrice.new}
              placeholder={rockstarPrice.current}
              onChange={handleRockstarPriceChange}
            /></span>
            <button type="button" class="btn btn-primary btn-primary-color" onClick={() => {
              setRockstarPrice(state => {
                return {
                  ...state,
                  enableEditing: true
                }
              }
              );
              document.getElementById("rockstar-price").focus();
            }}>
              <FiEdit />
            </button>
          </div>


        </div>
        <div className="admin-button-set">
          <input type="submit" value="Submit" disabled={!enableSubmitBtn || isSubmitLoading} onClick={handleSubmit}
          />
          <input type="reset" value="Reset" disabled={!enableSubmitBtn} onClick={resetFields} />
        </div>
      </div>
    {/* Footer */}
    <Footer />
    </main>
  );
}

export default AdminUser;
