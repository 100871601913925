import React, {Component,useEffect, useState} from 'react';
import GeneralHeader from "../../common/GeneralHeader";
import Breadcrumb from "../../common/Breadcrumb";
import UserSidebar from "./UserSidebar";
import PlaceGrid from "../../places/PlaceGrid";
import Button from "../../common/Button";
import { FiRefreshCw } from 'react-icons/fi'
import Footer from "../../common/footer/Footer";
import ScrollTopBtn from "../../common/ScrollTopBtn";
import sectiondata from "../../../store/store";
import { IDENTITY_USER_TOKEN_KEY } from '../../data/constants';
import {refresh} from '../../common/TokenRefresh';
import RecoverPassBox from './RecoverPassBox';
import {Link,Redirect} from "react-router-dom";
import campaignsb from "../../../assets/images/My-Dashboard-button-navy.svg";
import profile from "../../../assets/images/Profile-button-navy.svg";
import metrics from "../../../assets/images/Metrics-Reporting-button-navy.svg";
import signout from "../../../assets/images/Sign-Out-button-navy.svg";
import create from "../../../assets/images/Create-Campaign-button-green.svg"

const states = {
    BreadcrumbImg: require('../../../assets/images/bread-bg.jpg')
}
function UserProfile() {
    const [user,setUser] = useState({});
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const editPhone = (phone) =>{
        var out = {"first":'',"last":'',"phone":phone};
        let email = localStorage.getItem('email');
        const fetchData = async () => {
            refresh();
            const rawResponse = await fetch(SERVER_URL+'/user?email='+email, {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
              },
              body: JSON.stringify(out)
            });

        };
        fetchData();
    };
    useEffect(() => {
        let email = localStorage.getItem('email');
        const fetchData = async () => {
            refresh();
            const rawResponse = await fetch(SERVER_URL+'/user?email='+email, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
              }
            });
            const content = await rawResponse.json();
            console.log(content);
            if(content){
                content.message.email=email;
                content.message.editPhone=editPhone;
                setUser(content.message);
                console.log(content.message);
            }
          };
          fetchData();
    },[]);

    const editName = (fullName) =>{
        var firstName = fullName.split(' ').slice(0, -1).join(' ');
        var lastName = fullName.split(' ').slice(-1).join(' ');
        var phone = '';
        var out = {"first":firstName,"last":lastName,"phone":phone};
        let email = localStorage.getItem('email');
        const fetchData = async () => {
            refresh();
            const rawResponse = await fetch(SERVER_URL+'/user?email='+email, {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
              },
              body: JSON.stringify(out)
            });
        };
        fetchData();
    };

    return (
        <main className="user-profile">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle="User Profile" MenuPgTitle="Pages"  img={states.BreadcrumbImg} />

            <section className="user-profile-area padding-top-40px padding-bottom-100px">
                <div className="container">
                    <div className="row res-margin svg-multiple">
                        <div style={{paddingLeft:'0px'}} className="col-lg-6 col-sm-12">
                        <Link to="/dashboard"><img width='25%' src={campaignsb}></img></Link>
                            <Link to="/user-profile"><img width='20%' src={profile}></img></Link>
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-4 col-sm-12 text-right svg-create-img">
                            <Link to="/list-map-view?place=-77.0364,38.8951&type="><img width='40%' src={create}></img></Link>
                            <Link to="/"><img width='30%' src={signout}></img></Link>
                        </div>
                    </div>
                    <div style={{marginTop:'5%'}} className="row">
                        <div className="col-lg-4">
                            <div className="">
                                <UserSidebar usercontent={user} />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="">
                                <RecoverPassBox message='Change Password'/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default UserProfile;
