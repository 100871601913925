import React, { useState, useEffect } from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from "../assets/images/bread-bg.jpg";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin3Line } from "react-icons/ri";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";
import { fetchUserIsAdmin } from "../utils/APIRequests";

const API_URL = process.env.REACT_APP_SERVER_URL;

const state = {
  breadcrumbimg: breadcrumbimg,
};

// Component for displaying Discount
const Discount = () => {

  // Check if user is logged in, otherwise redirect to login page
  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        if (await fetchUserIsAdmin() === false) {
          throw new Error("User is not an admin");
        }
      }
      catch (e) {
        history.push("/login");
      }
    }
    setLoading(true);
    checkAuth();
  }, []);

  // DiscountModal is a modal for creating or updating a discount (dictated by the 'type' prop)
  const DiscountModal = ({ discountPercent, discountCode, discountID, showModal, onModalHide, type = "create" }) => {
    let history = useHistory();

    console.log({ discountPercent, discountCode, discountID, showModal, onModalHide, type });

    // For handling user inputs within the modal (the 'ui' in the naming indicates its handled by the user)
    const [uiDiscountPercent, setUIDiscountPercent] = useState(discountPercent);
    const [uiDiscountCode, setUIDiscountCode] = useState(discountCode);
    const [uiDiscountID, setUIDiscountID] = useState(discountID);

    console.log({ uiDiscountCode, uiDiscountPercent, uiDiscountID });

    const charAllow = (e) => {
      // Making user type only alphanumeric
      setUIDiscountCode(e.target.value.replace(/[^a-zA-Z0-9]/gi, ""));
  };

    // Sending new value of code and discount_percent to api
    const createDiscount = async () => {
      const isUserAdmin = await fetchUserIsAdmin();
      if (!isUserAdmin) {
        history.push("/login");
        return;
      }
      const url = `${API_URL}/discount`;
    const currentSession = await Auth.currentSession();
    const { idToken } = currentSession;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: idToken.jwtToken,
      },
      body: JSON.stringify({
        code: `${uiDiscountCode}`,
        discount_percent: `${uiDiscountPercent}`,
      }),
    });
    history.push("/discount");
      return response;
  };

    // Sending Updated value of code and discount_percent to api
    const updateDiscount = async () => {
      const isUserAdmin = await fetchUserIsAdmin();
      if (!isUserAdmin) {
        history.push("/login");
        return;
      }
      const url = `${API_URL}/discount`;
      const currentSession = await Auth.currentSession();
      const { idToken } = currentSession;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: idToken.jwtToken,
        },
        body: JSON.stringify({
          discount_id: `${uiDiscountID}`,
          code: `${uiDiscountCode}`,
          discount_percent: `${uiDiscountPercent}`,
        }),
      });
      history.push("/discount");
      const json_data = await response.json();
      // alert(json_data["message"]);
      console.info({ response: json_data["message"] });
      return response;
    };

    return (
      <Modal
        show={showModal}
        onHide={onModalHide}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Discount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-container">
            <label for="code">
              <b>Code</b>
            </label>
            <input
              className="form-control mb-20"
              type="text"
              placeholder="Enter Code"
              name="code"
              pattern="^[a-zA-Z0-9]+$"
              value={uiDiscountCode}
              onChange={charAllow}
              required
            />
            <label for="discount">
              <b>Discount</b>
            </label>
            <span className="currency">
              <input
                type="number"
                className="form-control pl-38"
                placeholder="Enter Discount"
                name="discount_percent"
                id="create-discount"
                min="1"
                max="100"
                value={uiDiscountPercent}
                onChange={(e) => setUIDiscountPercent(e.target.value)}
                required
              />
            </span>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onModalHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={type === 'create' ? createDiscount : updateDiscount}>
            {type === 'create' ? 'Create Discount' : 'Update Discount'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("create");

  // For fetching current and new list from api
  const [discountArray, setDiscountArray] = useState([]);

  // When data is taking time in load we make use of loading
  const [loading, setLoading] = useState(false);

  // For handling user inputs (the 'ui' in the naming indicates its handled by the user)
  const [uiDiscountPercent, setUIDiscountPercent] = useState("");
  const [uiDiscountCode, setUIDiscountCode] = useState("");
  const [uiDiscountID, setUIDiscountID] = useState(0);

    


  let history = useHistory();

  // Fetching data from API
  const fetchDiscount = async () => {
    const url = `${API_URL}/discount`;
    const currentSession = await Auth.currentSession();
    const { idToken } = currentSession;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: idToken.jwtToken,
      },
    });
    const json_data = await response.json();
    return json_data["message"];
  };

  useEffect(() => {
    setLoading(true);
    fetchDiscount().then((res) => {
      let incomingDiscountData = [];
      res.forEach((myDiscount) => {
        incomingDiscountData.push({
          code: myDiscount["code"]["String"],
          discount_percent: myDiscount["discount_percent"]["Int64"],
          discount_id: myDiscount["discount_id"]["Int64"],
        });
      });
      setDiscountArray(incomingDiscountData);
      setLoading(false);
    });
  }, []);

  const setData = (code, discount_percent, discount_id) => {
    setUIDiscountPercent(discount_percent);
    setUIDiscountID(discount_id);
    setUIDiscountCode(code);
  };

  // Delete operation
  const onDelete = async (discount_id) => {
    const url = `${API_URL}/discount?discount_id=${discount_id}`;
    const currentSession = await Auth.currentSession();
    const { idToken } = currentSession;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: idToken.jwtToken,
      },
    });
    const json_data = await response.json();
    // alert(json_data["message"]);
    console.info({ response: json_data["message"] });
    history.push("/discount");
  };

  return (
    <>
      {/* Header */}
      <GeneralHeader />
      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Discounts"
        MenuPgTitle="pages"
        img={state.breadcrumbimg}
      />
      <div className="container">
        {/* Add Discount button */}
        <div className="discount-button-container">
          <button className="create-discount" onClick={() => {
            setData("", "", 0);
            setModalType("create");
            setShowModal(true);
          }}>
            Create Discount
          </button>
        </div>
        {/* end : Add Discount button */}
        {loading ? (
          <div
            className="loading-container"
            style={{ textAlign: "center", padding: "24px" }}
          >
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <div className="table-responsive table-scroll table-container">
            <table className="discount-table">
              <tr className="discount-table-row ">
                <th className="discount-heading text-left pl-40 w-30">Code</th>
                <th className="discount-heading text-center">Discount</th>
                <th className="discount-heading text-center">Actions</th>
              </tr>

              {discountArray.map((discount) => {
                return (
                  <>
                    <tr
                      className="discount-table-row"
                      key={discount.discount_id}
                    >
                      <td className="discount-data pl-40">{discount.code}</td>
                      <td className="discount-data text-center">
                        {discount.discount_percent}
                      </td>
                      <td className="discount-data text-center">
                        <FiEdit
                          className="user-icon "
                          onClick={() => {
                            setData(
                              discount.code,
                              discount.discount_percent,
                              discount.discount_id
                            );
                            setModalType("update");
                            setShowModal(true);
                          }}
                        />
                        <RiDeleteBin3Line
                          className="user-icon ml-13 "
                          onClick={() => onDelete(discount.discount_id)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        )}
        {/*end table  */}
        {/* create/update discount coupon popup  */}
        <DiscountModal discountCode={uiDiscountCode}
          discountID={uiDiscountID}
          discountPercent={uiDiscountPercent}
          onModalHide={() => { setShowModal(false) }}
          showModal={showModal} type={modalType} />
        {/* end :  discount coupon popup  */}
        {/* <UpdateDiscountModal /> */}
      </div>
      {/* Footer */}
      <Footer />
      <ScrollTopBtn />
    </>
  );
};

export default Discount;
