import React from 'react';
import PropTypes from 'prop-types';

const Loader = props => {
    return (
        <div style={props.style || null}>
            <div className="loader" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="loader__spinner">
                    <div class="spinner-border text-primary" role="status">
                    </div>
                </div>
                {props.message && <div className="loader__message">{props.message}</div>}
            </div>
        </div >
    );
};

Loader.propTypes = {
    message: PropTypes.string
};

export default Loader;