import React,{Component,useState} from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import  { FiSearch } from 'react-icons/fi'
import Select from "react-select";
import GooglePlacesAutocomplete from '../../components/GooglePlacesAutocomplete.tsx';


class MapViewSearchInput extends Component{
    constructor()
    {
        super()
        this.onClick = this.onClick.bind(this);
        this.saveAndContinue = this.saveAndContinue.bind(this);
        this.state = {
            value:'',
            selectedChoice: {},
            isNotAllowed: false,
            confirm: [],
            selectedCatOp: null,
            categories: [
                {
                    value: 0,
                    label: 'Select a category'
                },
                {
                    value: 'All Categories',
                    label: 'All Categories'
                },
                {
                    value: 'shopping_mall',
                    label: 'Shops'
                },
                {
                    value: 'lodging',
                    label: 'Hotels'
                },
                {
                    value: 'convenience_store',
                    label: 'Foods & Drink'
                },
                {
                    value: 'gym',
                    label: 'Fitness'
                },
                {
                    value: 'gas_station',
                    label: 'Automotive'
                },
                {
                    value: 'real_estate_agency',
                    label: 'Real Estate'
                },
                {
                    value: 'restaurant',
                    label: 'Restaurant'
                },
                {
                    value: 'accounting',
                    label: 'Financial Services'
                },
                {
                    value: 'lawyer',
                    label: 'Services'
                }
            ]
        }

    }
    service={};
    componentDidMount() {
            if(!window.google){
                this.loadGoogleMaps(() => {
                // Work to do after the library loads.
                this.setState({ googleMapsReady: true });
                this.service = new window.google.maps.places.PlacesService(document.createElement('div'));
            });
        }else{
            this.service = new window.google.maps.places.PlacesService(document.createElement('div'));
        }
    }
    typesNotAllowed=['administrative_area_level_1','administrative_area_level_2','administrative_area_level_3','administrative_area_level_4','administrative_area_level_5','archipelago','colloquial_area','continent','country','establishment','finance','floor','food','general_contractor','geocode','intersection','landmark','locality','natural_feature','neighborhood','place_of_worship','plus_code','point_of_interest','political','post_box','postal_code','postal_code_prefix','postal_code_suffix','postal_town','premise','room','route','street_address','street_number','sublocality','sublocality_level_1','sublocality_level_2','sublocality_level_3','sublocality_level_4','sublocality_level_5','subpremise','town_square'];
    getNearby=(placeId)=>{
     var request = {
         placeId: placeId,
         fields: ['name', 'geometry']
       };
       
       this.service.getDetails(request, (place,status)=>this.placeDetailsCallback(place,status,placeId));
    };
    nearbyCallback=(name,placeid,results)=>{
        console.log(results);
        var outarray=[];
        let firstVal={};
        firstVal['value']=[];
        firstVal['value']['place_id']=placeid;
        firstVal['value']['types']=[];
        firstVal['value']['types'][0]='';
        let dropDownEle = { label: name, value: firstVal };
        outarray.push(dropDownEle);
         for (var i = 0; i < results.length; i++) {
             let val={};
             val['value']=results[i];
             let dropDownEle = { label: results[i]['name']+' ('+results[i]['types'][0]+')', value: val };
             outarray.push(dropDownEle);
         }
         this.setState({confirm: outarray});
    };
    placeDetailsCallback=(place,status,placeId)=>{
        //var lat = place.geometry.location.lat();
        //var lng = place.geometry.location.lng();
        //var pyrmont = new window.google.maps.LatLng(lat,lng);
        var request = {
            location: place.geometry.location,
            radius: '100',
            //keyword:'cafe',
            //rankBy: window.google.maps.places.RankBy.DISTANCE
          };
        this.service.nearbySearch(request, (results)=>this.nearbyCallback(place.name,placeId,results));
   };
    matchToExistingType=(typeArray,place_id)=>{
        if(typeArray.length>0){
            if(this.typesNotAllowed.includes(typeArray[0])){
                this.getNearby(place_id);
                this.setState({isNotAllowed:true});
                return true;
            }else{
             this.setState({isNotAllowed:false});
             return false;
            }
        }else{
         this.setState({isNotAllowed:false});
         return false;
        }
    };

    getLabel=(type)=>{
        let labelObjs = this.state.categories;
        for(var index in labelObjs){
            if(labelObjs[index].value==type){
                return labelObjs[index].label;
            }
        }
        return null;
    };

    setValue(value){
        var isNotAllowed = this.matchToExistingType(value.value.types,value.value.place_id);
        let outVal={place_id:value.value.place_id};
        this.setState({value:outVal})
        this.setState({choice:value.value.types[0]}) 
        this.onClick(outVal,value.value.types[0],isNotAllowed,this.props.mapSearch) 

    };
    setValue2(value){
        var outVal;
        var choice;
        if(value.value){
            outVal={place_id:value.value.value.place_id};
            this.setState({value:outVal});
            choice = value.value.value.types[0];
            this.setState({choice:value.value.value.types[0]})
        }else{
            outVal={place_id:value.place_id};
            this.setState({value:outVal});
            choice = '';
            this.setState({choice:''})
        }
        this.setState({isNotAllowed:false});
        this.onClick(outVal,choice,false,this.props.mapSearch)
    };

    onClick(id,choice,isNotAllowed,mapSearch,e) {
        let location="";
        this.props.setType(choice);
        let outChoice={value:choice}
        if(!id.place_id){
                var self=this;
                navigator.geolocation.getCurrentPosition(function(position){
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);
                    location=position.coords.longitude+","+position.coords.latitude;
                    if(!isNotAllowed){
                        mapSearch(location,outChoice,e);
                    }else{
                        //alert("Please confirm you location choice");
                    }
                },function(error) {
                    if (error.code == error.PERMISSION_DENIED)
                      console.log("you denied me :-(");
                      alert("Oops. Can't find your location. Please enter business name (or address)")
                  });
        }else{
            let placeId = id.place_id;
            if(!isNotAllowed){
                mapSearch(placeId,outChoice,e);
            }else{
                //alert("Please confirm you location choice");
            }
            
        }
    };

    saveAndContinue(){
        if(this.props.count==0){
            alert('You must select at least one geofence location to continue!');
            return;
        }
        if(this.props.plan<this.props.count){
            alert('You have exceeded your plan limit, please select a new plan or unselect geofence locations to continue!');
        }else{
            window.open("/campaigndesign","_self");
        }
    };

    continue(){
        window.open("/campaigndesign","_self");
    }

    styles={
        textInputContainer:{borderRadius: 10,borderColor:'#333F57',
        borderWidth:'thick'},
        textInput:{borderRadius: 10,borderColor:'#333F57',
        borderWidth:'thick'},
        option: (base, state) =>({
            ...base,
            backgroundColor: state.isFocused ? "#40CC6F" : null,
            color: "black"     
        })
    };

    render() {
        var handleChange = selectedChoice => {
            this.setState({ selectedChoice });
        };


        return (
            <>

                <div style={{paddingLeft:'0px',marginTop:'0px',width:'100%'}} className="main-search-input">

                    <div style={{float:'left',width:'100%'}} className="main-search-input-item">
                        <div className="contact-form-action">
                            <form action="#">
                                <div className="form-group mb-0">
                                <span className="form-icon">
                                    <FiSearch/>
                                </span>
        <GooglePlacesAutocomplete
       

        selectProps={{
            defaultInputValue:this.props.defaultValue,
            onChange: this.setValue.bind(this),
            styles: this.styles,
            placeholder:this.props.placeHolder||"Enter business name (or address) or click to search near your location."
          } } 

        />

        
                                </div>
                            </form>
                        </div>
                    </div>

                    {this.state.isNotAllowed && <div className="main-search-input-item category sub-cat-search">
                        <Select
                            placeholder="Confirm Your Selected Place"
                            options={this.state.confirm}
                            onChange={this.setValue2.bind(this)}
                        />
                    </div>
                    }

                    <div className="main-search-input-btn">
                    <button
                    style={{height:'40px',lineHeight:'0px'}}
                    type='button'
                    onClick={(e) =>this.onClick(this.state.value,this.state.choice,this.state.isNotAllowed,this.props.mapSearch, e)}
                    className="button theme-btn" type="submit"
                    >
                        Search
                    </button>
                    </div>

                </div>
            </>
        )
    }
}
export default MapViewSearchInput;
