import { Auth } from "aws-amplify";
const API_URL = process.env.REACT_APP_SERVER_URL; // Might work in a aws deployed environment
// const API_URL = "https://o0kcthy8h4.execute-api.us-east-1.amazonaws.com/dev"; // for local development

export const RequestStatus = Object.freeze({
    IDLE: "IDLE",
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
});

export const fetchPrices = async () => {
    const response = await fetch(`${API_URL}/pricing`);
    const json_data = await response.json();
    return json_data["message"];
};
export const fetchDiscount = async () => {
  const response = await fetch(`${API_URL}/discount`);
  const json_data = await response.json();
  return json_data["message"];
};


export const fetchUserIsAdmin = async () => {
    const email = localStorage.getItem("email");
    const currentSession = await Auth.currentSession();
    const { idToken } = currentSession;
    const url = `${API_URL}/user?email=${email}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: idToken.jwtToken
        }
    });
    const json_data = await response.json();
    return json_data["message"]["ispricingadmin"];
}

export const fetchUserList = async () => {
    
    const currentSession = await Auth.currentSession();
    const { idToken } = currentSession;
    const url = `${API_URL}/user/list`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: idToken.jwtToken
        }
    });
    const json_data = await response.json();
    return json_data;
}

export const postEditPricing = async (type, cost) => {
    const url = `${API_URL}/pricing`;
    const currentSession = await Auth.currentSession();
    const { idToken } = currentSession;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: idToken.jwtToken
        },
        body: JSON.stringify(
            {
                "pricingid": `${type}`,
                "cost": `${cost}`
            }
        )
    });
    // const json_data = await response.json();
    return response
};

export const fetchAffliationCodeDiscount = async (code) => {
    // https://o0kcthy8h4.execute-api.us-east-1.amazonaws.com/dev/discount/validate?code=FENCEGEO90
    const url = `${API_URL}/discount/validate?code=${code}`;
    const response = await fetch(url);
    const json_data = await response.json();
    return json_data;
}

export const isUserLoggedIn = async () => {
    const user = await Auth.currentAuthenticatedUser().catch(err => {
        console.log(err);
        return null;
    }
    );
    return user !== null;
}