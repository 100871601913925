import {React,useEffect} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Banner9 from "../components/banner/banner8/Banner9";
import About7 from "../components/about/About7";
import SectionsHeading from "../components/common/SectionsHeading";
import HowItWork4 from "../components/hiw/HowItWork4";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function Start() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="about-page">
            {/* Header */}
            <GeneralHeader />

            {/* Banner */}
            <Banner9 title="Geofencing Makes Sence for Auto Dealers" subtitle="Increase Your Business With Location-Based Mobile Advertising" />


            <section className="about-area sect-padding-top">
                <div >
                    <About7 aboutcontent={sectiondata.aboutdata.automotive} />
                </div>
            </section>





            <section className="hiw-area sect-padding-top after-none text-center section-bg">
                <div className="container">
                    <div className="row section-title-width text-center">
                        <SectionsHeading title={sectiondata.howitworks.hiw7.sectitle} desc={sectiondata.howitworks.hiw7.seccontent} />
                    </div>
                    <div className="row mt-5">
                        <HowItWork4 items={sectiondata.howitworks.hiw7.items} />
                    </div>
                </div>
            </section>





            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default Start;
