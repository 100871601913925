import React from 'react';
import { BtnBold, BtnItalic, BtnUnderline, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';


function RichTextArea(props) {
    return (
        <div className={props.className}>
                    
            <EditorProvider>
                <Editor {...props} maxLength={70}>
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        {/*Additional toolbar options can be customized in message editor*/}
                        {/* <Separator />
                        <BtnNumberedList />
                        <BtnBulletList />
                        <Separator />
                        <BtnLink />
                        <BtnClearFormatting />
                        <HtmlButton />
                        <Separator />
                        <BtnStyles /> */}
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
}

export default RichTextArea;