import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumb";
import axios from "axios";
import GeneralHeader from "../../../components/common/GeneralHeader";
import Footer from "../../../components/common/footer/Footer";
import ScrollTopBtn from "../../../components/common/ScrollTopBtn";
import breadcrumbimg from "../../../assets/images/bread-bg.jpg";

const state = {
    breadcrumbimg: breadcrumbimg,
};

const BLOG_URL = process.env.REACT_APP_BLOG_URL;

function setupWebview() {
    class EmbeddedWebview extends HTMLElement {
        connectedCallback() {
            fetch(this.getAttribute('src'))
                .then(response => { return response.text() })
                .then(html => {
                    const shadow = this.attachShadow({ mode: 'closed' });
                    shadow.innerHTML = html;
                    const header = shadow.querySelector('header');
                    header.remove();
                    const footer = shadow.querySelector('footer');
                    footer.remove();
                });
        }
    }

    if (!window.customElements.get('embedded-webview')) {
        window.customElements.define(
            'embedded-webview',
            EmbeddedWebview
        );
    }

}

function CaseStudyHeader({ label, title, subtitle, description }) {
    return (
        <div className="case-study-header">
            <span className="label">{label}</span>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <p>{description}</p>
        </div>
    )
}

function CaseStudySingle(props) {
    const [caseStudy, setCaseStudy] = React.useState({});
    let { id } = useParams();
    useEffect(() => { setupWebview() }, [])

    const fetchBlogPost = async (id) => {
        const res = await axios.get(BLOG_URL + `/pages?slug=${id}`);
        const author = await axios.get(BLOG_URL + `/users/${res.data[0].author}`);

        setCaseStudy({
            ...res.data[0],
            author: author.data,
        });
    };

    useEffect(() => {
        async function fetchData() {
            await fetchBlogPost(id);
        }
        fetchData();
    }, [id]);


    return (
        <div>
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb
                
                CurrentPgTitle={
                    caseStudy.acf ? (<CaseStudyHeader label={caseStudy.acf.competitors}
                        title={caseStudy.acf.heading}
                        subtitle={caseStudy.acf.subheading}
                        description={caseStudy.acf.headline} />) : (<p>Loading</p>)
                }
                img={state.breadcrumbimg}
            />

            {
                caseStudy.link && (<embedded-webview
                    src={caseStudy.link}></embedded-webview>)
            }
            

            {/* Footer */}
            <Footer />

            <ScrollTopBtn />
        </div>
    );
}

export default CaseStudySingle;

