import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import Amplify, { Auth } from "aws-amplify";
import awsmobile from "./aws-config";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Pages
import Home from "./pages/homes/Home";
import Home2 from "./pages/homes/Home2";
import Home3 from "./pages/homes/Home3";
import Home4 from "./pages/homes/Home4";
import Home5 from "./pages/homes/Home5";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Accessibility from "./pages/Accessiblity";
import AllCategories from "./pages/categories/AllCategories";
import AllLocations from "./pages/categories/AllLocations";
import TopPlaces from "./pages/categories/TopPlaces";
import ListingGrid from "./pages/listings/ListingGrid";
import ListingList from "./pages/listings/ListingList";
import ListMapView from "./pages/listings/ListMapView";
import ListMapView2 from "./pages/listings/ListMapView2";
import ListLeftSidebar from "./pages/listings/ListLeftSidebar";
import ListRightSidebar from "./pages/listings/ListRightSidebar";
import ListingDetails from "./pages/listings/ListingDetails";
import AddListing from "./pages/listings/AddListing";
import UserProfile from "./components/other/account/UserProfile";
import TopAuthors from "./pages/TopAuthors";
import Dashboard from "./pages/dashboard/Dashboard";
import Metrics from "./pages/dashboard/Metrics";
import Booking from "./pages/dashboard/Booking";
import BookingConfirmation from "./pages/dashboard/BookingConfirmation";
import Invoice from "./pages/dashboard/Invoice";
import PricingPlan from "./pages/PricingPlan";
import AdDesign from "./pages/AdDesign";
import CampaignOptions from "./pages/CampaignOptions";
import About from "./pages/About";
import HowTo from "./pages/HowTo";
import GF101 from "./pages/GF101";
import Why from "./pages/Why";
import Automotive from "./pages/Automotive";
import Financial from "./pages/Financial";
import RealEstate from "./pages/RealEstate";
import Restaurant from "./pages/Restaurant";
import Retail from "./pages/Retail";
import Services from "./pages/Services";

import Recruiting from "./pages/Recruiting";
import RealEstateRecruiting from "./pages/RealEstateRecruiting";

import Start from "./pages/Start";
import Faq from "./pages/FAQ";
import Contact from "./pages/Contact";
import RecoverPassword from "./pages/RecoverPassword";
import PasswordReset from "./pages/PasswordReset";
import BlogFullWidth from "./pages/blogs/BlogFullWidth";
import BlogGrid from "./pages/blogs/BlogGrid";
import BlogLeftSidebar from "./pages/blogs/BlogLeftSidebar";
import BlogRightSidebar from "./pages/blogs/BlogRightSidebar";
import BlogDetail from "./pages/blogs/BlogDetail";
import BlogDetail2 from "./pages/blogs/BlogDetail2";

import Login from "./pages/Login";
import Verify from "./pages/Verify";
import SignUp from "./pages/SignUp";
import Error from "./pages/Error";
import Review from "./pages/Review";
import BlogPostSingle from "./pages/blogs/BlogPostSingle";
import AdminUser from "./pages/AdminUser";
import UserList from "./pages/UserList";
import UpgradePlan from "./pages/UpgradePlan";
import Discount from "./pages/Discount";
import SingleCampaign from "./components/SingleCampaign";
import UploadCustomAd from "./pages/UploadCustomAd";
import PersistGuestUserData from "./pages/PersistGuestUserData";
import CaseStudyList from "./pages/blogs/caseStudies/CaseStudyList";
import CaseStudySingle from "./pages/blogs/caseStudies/CaseStudySingle";


const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
  }, [location]);

  Amplify.configure(awsmobile);

  // >>New - Configuring Auth Module
  Auth.configure(awsmobile);

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute path="/index2" component={Home2} />
            <AppRoute path="/index3" component={Home3} />
            <AppRoute path="/index4" component={Home4} />
            <AppRoute path="/index5" component={Home5} />
            <AppRoute
              path="/list-map-view?type=gas_station"
              component={ListMapView}
              type={"gas_station"}
            />
            <AppRoute path="/all-categories" component={AllCategories} />
            <AppRoute path="/start" component={Start} />
            <AppRoute path="/howto" component={HowTo} />
            <AppRoute path="/gf101" component={GF101} />
            <AppRoute path="/why" component={Why} />
            <AppRoute path="/automotive" component={Automotive} />
            <AppRoute path="/financial" component={Financial} />
            <AppRoute path="/realestate" component={RealEstate} />
            <AppRoute path="/restaurant" component={Restaurant} />
            <AppRoute path="/retail" component={Retail} />
            <AppRoute path="/services" component={Services} />

            <AppRoute path="/recruiting" component={Recruiting} />
            <AppRoute
              path="/realestaterecruiting"
              component={RealEstateRecruiting}
            />

            <AppRoute path="/list-map-view" component={ListMapView} />
            <AppRoute path="/all-locations" component={AllLocations} />
            <AppRoute path="/top-place" component={TopPlaces} />
            <AppRoute path="/listing-grid" component={ListingGrid} />
            <AppRoute path="/listing-list" component={ListingList} />
            <AppRoute path="/list-map-view" component={ListMapView} />
            <AppRoute path="/list-map-view2" component={ListMapView2} />
            <AppRoute path="/list-left-sidebar" component={ListLeftSidebar} />
            <AppRoute path="/list-right-sidebar" component={ListRightSidebar} />
            <AppRoute path="/listing-details" component={ListingDetails} />
            <AppRoute path="/add-listing/new" component={AddListing} />
            <AppRoute path="/user-profile" component={UserProfile} />
            <AppRoute path="/top-author" component={TopAuthors} />
            <AppRoute path="/dashboard" component={Dashboard} />
            <AppRoute path="/metrics" component={Metrics} />
            <AppRoute path="/booking" component={Booking} />
            <AppRoute
              path="/booking-confirmation"
              component={BookingConfirmation}
            />
            <AppRoute path="/invoice" component={Invoice} />
            <AppRoute path="/pricing" component={PricingPlan} />
            <AppRoute path="/addesign" component={AdDesign} />
            <AppRoute path="/campaigndesign" component={Booking} />
            <AppRoute path="/single-campaign/upgrade" component={UpgradePlan} />
            <AppRoute path="/single-campaign" component={SingleCampaign} />
            <AppRoute path="/campaign" component={CampaignOptions} />
            <AppRoute path="/custom-ad-design" component={UploadCustomAd} />
            <AppRoute path="/about" component={About} />
            <AppRoute path="/faq" component={Faq} />
            <AppRoute path="/contact" component={Contact} />
            <AppRoute path="/recover" component={RecoverPassword} />
            <AppRoute path="/reset" component={PasswordReset} />
            <AppRoute path="/blog-full-width" component={BlogFullWidth} />
            <AppRoute path="/blog-grid" component={BlogGrid} />
            <AppRoute path="/blog-left-sidebar" component={BlogLeftSidebar} />
            <AppRoute path="/blog-right-sidebar" component={BlogRightSidebar} />
            <AppRoute path="/blogs" component={BlogDetail} />
            <AppRoute path="/case-studies" component={CaseStudyList} />
            <AppRoute path="/case-study/:id" component={CaseStudySingle} />
            <AppRoute path="/blog-single2" component={BlogDetail2} />
            <AppRoute path="/blog/:id" component={BlogPostSingle} />
            <AppRoute path="/privacy" component={Privacy} />
            <AppRoute path="/terms" component={Terms} />
            <AppRoute path="/accessibility" component={Accessibility} />
            <AppRoute path="/edit-pricing" component={AdminUser} />
            <AppRoute path="/user-list" component={UserList} />
            <AppRoute path="/discount" component={Discount} />
            <AppRoute path="/login" component={Login} />
            <AppRoute path="/sign-up" component={SignUp} />
            <AppRoute path="/reset-password" component={PasswordReset} />
            <AppRoute path="/verify-code" component={Verify} />
            <AppRoute path="/review" component={Review} />
            <AppRoute path="/createCampaignJourney" component={PersistGuestUserData} />
            <AppRoute component={Error} />
          </Switch>
        )}
      />
    </>
  );
};

export default App;
