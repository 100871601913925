import React, {useEffect} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import AskQuestionField from "../components/contact/AskQuestionField";
import ContactSidebar from "../components/sidebars/ContactSidebar";
import GeneralMap from "../components/contact/GeneralMap";
import { FiPhone } from 'react-icons/fi'
import { FaRegEnvelope } from 'react-icons/fa'
import { GoLocation } from 'react-icons/go'
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from '../assets/images/bread-bg.jpg'
import sectiondata from "../store/store";
import PostScribe from "react-postscribe";

const state = {
    breadcrumbimg: breadcrumbimg,
}
function Contact() {
    useEffect(() => {
        const script = document.createElement('script');
        const scriptSettings = document.createElement('script');
        script.src = 'https://koi-3qnnfn4tsw.marketingautomation.services/client/form.js?ver=2.0.1';
        script.async = true;
        scriptSettings.innerHTML = `
        var ss_form = {'account': 'MzawMLEwNrUwAgA', 'formID': 'szSyTDMztkjSTUtOMdM1MTQ3100yTrPQTTExNTM2MTe3tDQ2AgA'};

        ss_form.width = '100%';
        
        ss_form.domain = 'app-3QNNFN4TSW.marketingautomation.services';
        
        // ss_form.hidden = {'field_id': 'value'}; // Modify this for sending hidden variables, or overriding values
        
        ss_form.target_id = 'contact-form'; // Optional parameter: forms will be placed inside the element with the specified id
        
        // ss_form.polling = true; // Optional parameter: set to true ONLY if your page loads dynamically and the id needs to be polled continually.`;
        document.head.appendChild(scriptSettings);
        document.head.appendChild(script);
        return () => {
            try {
                document.head.removeChild(scriptSettings);
                document.head.removeChild(script);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    return (
        <main className="contact-page">
            {/* Header */}
            <GeneralHeader />

            {/* Breadcrumb */}
            <Breadcrumb CurrentPgTitle="Contact Us" MenuPgTitle="pages" img={state.breadcrumbimg} />
            <div className='row'>
                <div className='col-lg-3 col-md-1'></div>
                <div className='col-lg-6 col-md-10 text-center'>
                    <main>
                         <div id="contact-form"> </div>
                    </main>
                </div>
                <div className='col-lg-3 col-md-1'></div>
            </div>
            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default Contact;
