import React, { useEffect, useState } from "react";
import payment1 from "../../../assets/images/payment-img.png";
import payment2 from "../../../assets/images/paypal.png";
import grey from "../../../assets/images/Design-Paint-Icon-Gray.svg";
import fileupload from "../../../assets/images/Add-Image-button-gray.svg";
import example from "../../../assets/images/1-Generic Restaurants-300x250px-labels.png";
import real from "../../../assets/images/Real-Estate-Generic-BW-300x250-V1.png";
import financial from "../../../assets/images/Financial-Generic-BW-300x250-V1.png";
import retail from "../../../assets/images/Retail-Generic-BW-300x250-V1.png";
import services from "../../../assets/images/Services-Generic-BW-300x250-V1.png";
import automotive from "../../../assets/images/Automotive-Generic-BW-300x250-V1.png";
import { IDENTITY_USER_TOKEN_KEY } from "../../../components/data/constants";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import * as html2canvas from "html2canvas";
import { refresh } from "../../common/TokenRefresh";
import upload from "../../../assets/images/Upload-Creative-button-gray.svg";
import dr from "../../../assets/images/No-slider-button-gray.svg";
import dr2 from "../../../assets/images/Yes-slider-button-green.svg";
import info from "../../../assets/images/Info-icon-navy.svg";
import popup from "../../../assets/images/Info-Speech-bubble-gray-words.svg";
import imageplacement from "../../../assets/images/Image-placement-icon-gray.svg";
import CreateAdHeadingSection from "./CreateAdHeadingSection";
import CreateAdSubHeadingSection from "./CreateAdSubHeadingSection";
import { Tab, Tabs } from "react-bootstrap";
import CreateAdPageMobile from "./CreateAdPageMobile";
import locationMobile from "../../../assets/images/location-icon.png";
import brushMobile from "../../../assets/images/brush-icon.png";
import magnifierMobile from "../../../assets/images/magnifier-icon.png";
import tickMobile from "../../../assets/images/tick-icon.png";
import continueMobile from "../../../assets/images/continue-icon.png";
import { headlineValidationLogic, messagingValidationLogic, subheadingValidationLogic, urlValidationLogic } from "../../../utils/FieldValidations";
import { placeholderPhoto, placeholderLogo } from "../../../assets/images/base64s.json";
import AdPreview from "./AdPreview";
import { isUserLoggedIn } from "../../../utils/APIRequests";
const state = {
  paymentImg: payment1,
  paypalImg: payment2,
};
const automotiveL = [
  "car_dealer",
  "car_rental",
  "car_repair",
  "car_wash",
  "gas_station",
  "parking",
  "rv_park",
  "taxi_stand",
];
const financialL = ["accounting", "atm", "bank", "insurance_agency"];
const realestateL = ["real_estate_agency"];
const servicesL = [
  "airport",
  "amusement_park",
  "aquarium",
  "art_gallery",
  "beauty_salon",
  "bowling_alley",
  "bus_station",
  "campground",
  "casino",
  "cemetery",
  "church",
  "city_hall",
  "courthouse",
  "dentist",
  "doctor",
  "electrician",
  "embassy",
  "fire_station",
  "funeral_home",
  "gym",
  "hair_care",
  "hindu_temple",
  "hospital",
  "laundry",
  "lawyer",
  "library",
  "light_rail_station",
  "local_government_office",
  "locksmith",
  "lodging",
  "meal_delivery",
  "meal_takeaway",
  "mosque",
  "movie_rental",
  "movie_theater",
  "moving_company",
  "museum",
  "night_club",
  "painter",
  "park",
  "physiotherapist",
  "plumber",
  "police",
  "post_office",
  "primary_school",
  "roofing_contractor",
  "rv_park",
  "school",
  "secondary_school",
  "spa",
  "stadium",
  "storage",
  "subway_station",
  "synagogue",
  "tourist_attraction",
  "train_station",
  "transit_station",
  "travel_agency",
  "university",
  "veterinary_care",
  "zoo",
];
const retailL = [
  "bakery",
  "bicycle_store",
  "book_store",
  "clothing_store",
  "convenience_store",
  "department_store",
  "drugstore",
  "electronics_store",
  "florist",
  "furniture_store",
  "hardware_store",
  "home_goods_store",
  "jewelry_store",
  "liquor_store",
  "pet_store",
  "pharmacy",
  "shoe_store",
  "shopping_mall",
  "store",
  "supermarket",
];

function Child(props) {
  const { lines, setLines } = props.pstate;
  const { assets, setAssets } = props;

  const addUpload = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setAssets([...assets, reader.result]);
    };
    setLines([...lines, {}]);
  };

  return (
    <div style={{}} className="row">
      <div className="col-12">
        <div className="settings">
          <label
            style={{ width: "100%", display: "block" }}
            for={"additional-upload" + lines.length}
            className="label-text3"
          >
            <span style={{ float: "left", marginTop: "8px" }}>
              Upload Additional Graphics or Designs
            </span>
            <img
              style={{ float: "left", width: "18%", marginLeft: "20px" }}
              src={fileupload}
            />
          </label>
          <input
            style={{ display: "none" }}
            id={"additional-upload" + lines.length}
            type="file"
            onChange={addUpload}
          />
        </div>
      </div>
    </div>
  );
}
function PersonalInfo(props) {
  const {
    headline,
    setHeadline,
    subhead,
    setSubhead,
    messaging,
    setMessaging,
    headlineColor,
    setHeadlineColor,
    subheadColor,
    setSubheadColor,
    buttontext,
    setButtontext,
    buttonTextColor,
    setButtonTextColor,
    buttonColor,
    setButtonColor,
    buttonBodyColor,
    setButtonBodyColor,
    phone,
    setPhone,
    backgroundColor,
    setBackgroundColor,
    backgroundColor2,
    setBackgroundColor2,
    backgroundColor3,
    setBackgroundColor3,
    url,
    setUrl,
    initialAd,
    setInitialAd,
    adId,
    setAdId,
    lines,
    setLines,
    assets,
    setAssets,
    csv,
    setCsv,
    additional,
    setAdditional,
    photo,
    setPhoto,
    logo,
    setLogo,
    creative,
    setCreative,
    prof,
    setProf,
    industry,
    setIndustry,
    isEdit,
    saveAndContinueBook,
    campaignValue,
  } = props;

  const [key, setKey] = useState('Update Content');

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // const { search } = useLocation();
  // console.log({ search })
  // const campaignID = queryString.parse(search).campaign;

  const history = useHistory();

  let location = useLocation();
  let campaign = "0";
  let centroidtype = "restaurant";
  location = queryString.parse(location.search);
  if (location.campaign) {
    campaign = location.campaign;
  }
  if (location.type) {
    centroidtype = location.type;
  }
  setIndustry(centroidtype);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (campaign != 0) {
      (async () => {
        refresh();
        const rawResponse = await fetch(
          SERVER_URL + "/ad?campaign=" + campaign,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
            },
          }
        );
        const content = await rawResponse.json();
        console.log(content);
        if (
          content.message &&
          Array.isArray(content.message) &&
          content.message.length > 0
        ) {
          setHeadline(content.message[0].headline);
          setMessaging(content.message[0].messaging);
          setSubhead(content.message[0].headline2);
          setHeadlineColor(content.message[0].headertextcolor);
          setSubheadColor(content.message[0].bodytextcolor);
          setBackgroundColor(content.message[0].headercolor);
          setBackgroundColor2(content.message[0].backgroundcolor2);
          setButtonColor(content.message[0].bodycolor);
          setButtontext(content.message[0].maintext);
          setButtonTextColor(content.message[0].buttontextcolor);
          setPhone(content.message[0].phone);
          setUrl(content.message[0].url);
          setPhoto("data:image/png;base64," + content.message[0].mainphoto);
          setLogo("data:image/png;base64," + content.message[0].logo);
          setCreative("data:image/png:base64," + content.message[0].creative);
          setProf(content.message[0].prof);
          setAdId(content.message[0].adid);
          setInitialAd({
            campaign: parseInt(campaign),
            headline: content.message[0].headline,
            headline2: content.message[0].headline2,
            headercolor: content.message[0].headercolor,
            headertextcolor: content.message[0].bodytextcolor,
            maintext: content.message[0].maintext,
            mainphoto: content.message[0].mainphoto,
            phone: content.message[0].phone,
            url: content.message[0].url,
            bodycolor: content.message[0].bodycolor,
            bodytextcolor: content.message[0].bodytextcolor,
            logo: content.message[0].logo,
            creative: content.message[0].creative,
            prof: content.message[0].prof,
            out: content.message[0].out,
            buttontextcolor: content.message[0].buttontextcolor,
          });
          if (content.message[0].assets) {
            var assetsIn = content.message[0].assets.split(",");
            var assetsInApp = assetsIn.map(function (a) {
              return "data:image/png;base64," + a;
            });
            setAssets(assetsInApp);
          }
          setCsv(content.message[0].csv);
        }
      })();
    }
  }, []);
  const getExample = (ind) => {
    if (automotiveL.includes(ind)) {
      return automotive;
    } else if (financialL.includes(ind)) {
      return financial;
    } else if (realestateL.includes(ind)) {
      return real;
    } else if (servicesL.includes(ind)) {
      return services;
    } else if (retailL.includes(ind)) {
      return retail;
    } else {
      return example;
    }
  };
  const saveAndContinue = (e, campaign) => {
    let outImage = "";
    let fed2 = document.getElementById("fed2");
    let useWidth = fed2.style.width;
    let useHeight = fed2.style.height;
    window.scrollTo(10, 0);
    html2canvas(fed2).then(function (canvas) {
      //document.body.appendChild(canvas);
      outImage = stripBase64Prefix(
        canvas.toDataURL("image/png", "image/octet-stream")
      );
      if (initialAd && initialAd.campaign) {
        (async () => {
          refresh();
          const rawResponse = await fetch(SERVER_URL + "/ad?ad=" + adId, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
            },
            body: JSON.stringify([
              initialAd,
              {
                campaign: parseInt(campaign),
                headline: headline,
                headline2: subhead,
                messaging: messaging,
                headercolor: backgroundColor,
                backgroundcolor2: backgroundColor2,
                headertextcolor: headlineColor,
                maintext: buttontext,
                mainphoto: stripBase64Prefix(photo),
                phone: phone,
                buttontextcolor: buttonTextColor,
                url: url,
                bodycolor: buttonColor,
                bodytextcolor: subheadColor,
                logo: stripBase64Prefix(logo),
                creative: stripBase64Prefix(creative),
                prof: prof,
                out: outImage,
                assets: assetsOut,
                csv: csv,
              },
            ]),
          });
          const content = await rawResponse.json();
          console.log(content);
          showLoading();
          window.open(
            "/review?ad=" +
              content.message +
              "&campaign=" +
              campaign +
              "&edit=true",
            "_self"
          );
        })();
      } else {
        //new ad so no edit
        var assetsOut = assets
          .map(function (k) {
            return stripBase64Prefix(k);
          })
          .join(",");
        (async () => {
          const adDesignBody = JSON.stringify({
            campaign: parseInt(campaign),
            headline: headline,
            headline2: subhead,
            messaging: messaging,
            headercolor: backgroundColor,
            backgroundcolor2: backgroundColor2,
            headertextcolor: headlineColor,
            maintext: buttontext,
            mainphoto: stripBase64Prefix(photo),
            phone: phone,
            buttontextcolor: buttonTextColor,
            url: url,
            bodycolor: buttonColor,
            bodytextcolor: subheadColor,
            logo: stripBase64Prefix(logo),
            creative: stripBase64Prefix(creative),
            prof: prof,
            out: outImage,
            assets: assetsOut,
            csv: csv,
          });
          const userLoggedIn = await isUserLoggedIn();
          if(!userLoggedIn) {
            localStorage.setItem("pendingAd", adDesignBody);
            refresh();
          }
          const rawResponse = await fetch(SERVER_URL + "/ad?ad=" + adId, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
            },
            body: adDesignBody,
          });
          const content = await rawResponse.json();
          console.log(content);
          showLoading();
          window.open(
            "/review?ad=" + content.message + "&campaign=" + campaign,
            "_self"
          );
        })();
      }
    });
  };
  const showLoading = () => {
    document.getElementById("loading").style.display = "block";
  };

  const interval = (e) => {
    var image = document.getElementById("hoverimage");
    image.style.display = "block";
    showImage(e);
  };

  const hide = (e) => {
    var image = document.getElementById("hoverimage");
    image.style.display = "none";
  };

  const showImage = (e) => {
    console.log(e.nativeEvent);
    var x = e.nativeEvent.layerX;
    var y = e.nativeEvent.layerY;
    var image = document.getElementById("hoverimage");
    //image.style.width='50%';
    //image.style.left = x+ "px";
    //image.style.top = y+ "px";
    //image.style.left='35%';
    //image.style.top='-650%';
  };
  const continueNoSave = () => {
    showLoading();
    window.open("/review", "_self");
  };

  const stripBase64Prefix = (base64text) => {
    return base64text.split(",")[1];
  };

  const handlePhoto = (e) => {
    if (typeof e === 'string') {
      setPhoto(e);
      return
    } 
    //setPhoto(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setPhoto(reader.result);
    };
  };
  const handleLogo = (e) => {
    //setLogo(URL.createObjectURL(e.target.files[0]));
    if (typeof e === 'string') {
      setLogo(e);
      return
    } 
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setLogo(reader.result);
    };
  };
  const handleCreative = (e) => {
    history.push('/custom-ad-design?campaign=' + campaign);
    // //setLogo(URL.createObjectURL(e.target.files[0]));
    // var reader = new FileReader();
    // reader.readAsDataURL(e.target.files[0]);
    // reader.onload = function () {
    //   setCreative(reader.result);
    // };
  };

  const handleProf = (e) => {
    setProf(e.target.checked);
  };

  const handleProf2 = (e) => {
    setProf((prof) => !prof);
  };

  const handleCsv = (e) => {
    setCsv(e.target.checked);
  };

  const handleAdditional = (e) => {
    setAdditional(e.target.checked);
  };

  const handleChangeHeadlineColor = (color) => {
    setHeadlineColor(color.hex);
    setSubheadColor(color.hex);
  };
  const handleChangeSubheadColor = (color) => {
    setSubheadColor(color.hex);
  };
  const handleChangeButtonTextColor = (color) => {
    setButtonTextColor(color.hex);
  };
  const handleChangeButtonColor = (color) => {
    setButtonColor(color.hex);
  };
  const handleChangeButtonBodyColor = (color) => {
    setButtonBodyColor(color.hex);
  }
  const handleChangeBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
  };
  const handleChangeBackgroundColor2 = (color) => {
    setBackgroundColor2(color.hex);
  };
  const handleChangeBackgroundColor3 = (color) => {
    setBackgroundColor3(color.hex);
  };

  const headlineChange = (e) => {
    setHeadline(e.target.value);
  };

  const messagingChange = (e) => {
    setMessaging(e.target.value);
  };

  const subheadChange = (e) => {
    setSubhead(e.target.value);
  };
  const buttontextChange = (e) => {
    setButtontext(e.target.value);
  };

  const urlChange = (e) => {
    setUrl(e.target.value);
  };

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const phoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };

  const handleAdditionalAssets = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setAssets([...assets, reader.result]);
    };
  };


  const handleUpdateContent = () => {
    setKey("Update Content");
    console.log("clicked inisde handleUpdatecontent");
  };

  const userHasUpdatedPhoto = photo !== placeholderPhoto;
  const userHasUpdatedLogo = logo !== placeholderLogo;

  const validateAllData = () => {
    if (!headline || headlineValidationLogic(headline)) return false; // Headline
    if (!subhead || subheadingValidationLogic(subhead)) return false;	 // Subhead
    if(!messaging || messagingValidationLogic(messaging)) return false; // Messaging
    if (!userHasUpdatedPhoto) return false; // Photo
    if (!userHasUpdatedLogo) return false; // Logo
    if(!url || urlValidationLogic(url)) return false; // URL
    // if(!phone || phoneValidationLogic(phone)) return false; // Phone
    return true;
  };

  const isDataValid = validateAllData();
  

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <img
                src={grey}
                style={{ width: "40px", marginTop: "0px", marginRight: "0px" }}
                alt = ""
              ></img>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="section-heading text-center">
              <h2 className="sec__title ad">
                Design Your Perfect Ad. <br />
                It's Fast & Simple
              </h2>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "2%" }} className="row">
          <div className="col-12">
            <div className="section-heading text-center design-subhead">
              <div className="">
                You will be emailed proofs of your ads for approval in the
                following sizes: <b>300x250, 728x90, 300x600</b> & <b>320x50</b>
                .
              </div>
            </div>
          </div>
        </div>


      


        {/* Testing content - Rendering for Desktop View */}
        <div className="d-none d-lg-block">
          <div className="row">
            <CreateAdHeadingSection
              headline={headline}
              headlineChange={headlineChange}
              backgroundColor={backgroundColor}
              handleChangeBackgroundColor={handleChangeBackgroundColor}
              headlineColor={headlineColor}
              handleChangeHeadlineColor={handleChangeHeadlineColor}
              fileupload={fileupload}
              imageplacement={imageplacement}
              handlePhoto={handlePhoto}
              buttontext={buttontext}
              buttontextChange={buttontextChange}
              buttonTextColor={buttonTextColor}
              handleChangeButtonTextColor={handleChangeButtonTextColor}
              buttonColor={buttonColor}
              handleChangeButtonColor={handleChangeButtonColor}
              buttonBodyColor={buttonBodyColor}
              handleChangeButtonBodyColor={handleChangeButtonBodyColor}
              upload={upload}
              interval={interval}
              hide={hide}
              info={info}
              popup={popup}
              handleCreative={handleCreative}
              prof={prof}
              dr2={dr2}
              dr={dr}
              handleProf2={handleProf2}
            />

            
            <div className="col-4 text-center" id="Section-2">
              <div className="row" style={{ margin: '0px auto' }}>
                <AdPreview
                  headline={headline}
                  subhead={subhead}
                  headlineColor={headlineColor}
                  subheadColor={subheadColor}
                  buttontext={buttontext}
                  buttonTextColor={buttonTextColor}
                  buttonColor={buttonColor}
                  buttonBodyColor={buttonBodyColor}
                  backgroundColor={backgroundColor}
                  phone={phone}
                  photo={photo}
                  logo={logo}
                  creative={creative}
                  messaging={messaging}
                  backgroundColor2={backgroundColor2}
                  backgroundColor3={backgroundColor3}
                />
              </div>
              
              {/*<div style={{ marginTop: "5%" }} className="row">
                <div className="row mx-auto">
                  <div className="col-12">
                    <label className="label-text3">
                      Upload Additional Graphics
                    </label>
                  </div>
                </div>
                <div className="row mx-auto">
                  <label style={{}} for="misc-upload" className="label-text2">
                    <img style={{ width: "47%" }} alt="" src={cf} />
                  </label>
                  <div className="form-group">
                    <input
                      style={{ display: "none" }}
                      id="misc-upload"
                      type="file"
                      multiple
                      onChange={handleAdditionalAssets}
                    />
                  </div>
                </div>
              </div> */}

            </div>

            <CreateAdSubHeadingSection
              subhead={subhead}
              subheadChange={subheadChange}
              messaging={messaging.slice(0, 70)}
              messagingChange={messagingChange}
              backgroundColor2={backgroundColor2}
              handleChangeBackgroundColor2={handleChangeBackgroundColor2}
              subheadColor={subheadColor}
              handleChangeSubheadColor={handleChangeSubheadColor}
              phone={phone}
              phoneChange={phoneChange}
              imageplacement={imageplacement}
              fileupload={fileupload}
              backgroundColor3={backgroundColor3}
              handleLogo={handleLogo}
              url={url}
              urlChange={urlChange}
              handleChangeBackgroundColor3={handleChangeBackgroundColor3}
            />
          </div>
        </div>

        {/* Rendering for Mobile View */}
        <div className="d-block d-lg-none">

        <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-2"
    >
      <Tab eventKey="Update Content" title="Update Content">
      <div className="row">
      <CreateAdPageMobile
      headline={headline}
      headlineChange={headlineChange}
      backgroundColor={backgroundColor}
      handleChangeBackgroundColor={handleChangeBackgroundColor}
      headlineColor={headlineColor}
      handleChangeHeadlineColor={handleChangeHeadlineColor}
      fileupload={fileupload}
      imageplacement={imageplacement}
      handlePhoto={handlePhoto}
      buttontext={buttontext}
      buttontextChange={buttontextChange}
      buttonTextColor={buttonTextColor}
      handleChangeButtonTextColor={handleChangeButtonTextColor}
      buttonColor={buttonColor}
      handleChangeButtonColor={handleChangeButtonColor}
      buttonBodyColor={buttonBodyColor}
      handleChangeButtonBodyColor={handleChangeButtonBodyColor}
      upload={upload}
      interval={interval}
      hide={hide}
      info={info}
      popup={popup}
      handleCreative={handleCreative}
      prof={prof}
      dr2={dr2}
      dr={dr}
      handleProf2={handleProf2}
      setKey = {setKey}
      subhead={subhead}
                  subheadChange={subheadChange}
                  messaging={messaging.slice(0, 70)}
                  messagingChange={messagingChange}
                  backgroundColor2={backgroundColor2}
                  handleChangeBackgroundColor2={handleChangeBackgroundColor2}
                  subheadColor={subheadColor}
                  handleChangeSubheadColor={handleChangeSubheadColor}
                  phone={phone}
                  phoneChange={phoneChange}
                  imageplacement={imageplacement}
                  fileupload={fileupload}
                  backgroundColor3={backgroundColor3}
                  handleLogo={handleLogo}
                  url={url}
                  urlChange={urlChange}
                  handleChangeBackgroundColor3={handleChangeBackgroundColor3}
      />
      
        </div>
      </Tab>
      <Tab eventKey="Preview Ad" title="Preview Ad">
      <div className="row">
      <div className="col-lg-4 col-md-12 text-center" id="Section-2">
                  <div className="row" style={{ margin: '0px auto', justifyContent: 'center' }}>
                  <AdPreview
                    headline={headline}
                    subhead={subhead}
                    headlineColor={headlineColor}
                    subheadColor={subheadColor}
                    buttontext={buttontext}
                    buttonTextColor={buttonTextColor}
                    buttonBodyColor={buttonBodyColor}
                    buttonColor={buttonColor}
                    backgroundColor={backgroundColor}
                    phone={phone}
                    photo={photo}
                    logo={logo}
                    creative={creative}
                    messaging={messaging}
                    backgroundColor2={backgroundColor2}
                    backgroundColor3={backgroundColor3}
                  />
                </div>
                
              </div>


              <div style={{ width:"100%" , marginTop: '15%'}} >
                <button className="update-prev-btn" onClick={handleUpdateContent}> Update content </button>              
              </div>

              </div>



              <div style={{ marginTop: '6%' }} className="row">
      <div className="form-group form-margin-btm col-12">
        
        <div style={{ marginLeft: '-15px' , width: 'calc(100% + 30px)'}} className="row">
          {/*input style={{marginLeft:'2%'}} type="checkbox" onClick={handleAdditional} checked={additional} />*/}

          <label
            style={{
              marginTop: '0px',
              marginBottom: '0px',
              display: 'block',
              background: '#7E8896',
              border:'1px solid #000',
              width: '50%',
            }}
            for="creative-upload"
            className="label-text3"
          >
            <img
              style={{ margin: '0 auto' , display: 'table',cursor:'pointer' }}
              src={upload}
              alt=""
              height="44px"
            />
            
            
          </label>
          <div className="form-group">
            <input
              style={{ display: 'none' }}
              id="creative-upload"
              type="file"
              onChange={handleCreative}
            />
          </div>
          <button style={{ width:'50%', backgroundColor:'#7E8896',color: '#fff', border:'1px solid #000' }}> Get Design Help </button>

        </div>
      </div>
      
    </div>
              
      </Tab>
    </Tabs>

        </div>

        <div id="loading" style={{ display: "none" }} class="loading">
          Loading&#8230;
        </div>
      </div>
      
      <div>  
          <div>
              <div >  
                 <div>  
                    <ul class="progresBar">
                      <li class="active"><a href="/list-map-view?place=-77.0364,38.8951&type="><img  src={locationMobile} style={{ height: '15px' }} /></a></li>
                      <li class="active"><a href="/campaigndesign"><img  src={brushMobile} style={{ height: '15px' }} /></a></li>
                      <li><a><img  src={magnifierMobile} style={{ height: '15px' }} /></a></li>
                      <li><a><img  src={tickMobile} style={{ height: '15px' }} /></a></li>
                <li class={!isDataValid ? "disabledContinue" : "active"}><a onClick={(e) => {
                  if (!isDataValid) {
                    window.alert("Please fill all the required fields");
                    return;
                  }
                  saveAndContinueBook(campaignValue);
                }}><img src={continueMobile} style={{ height: '15px', filter: isDataValid ? "" : 'saturate(0%)' }} /></a></li>
                    </ul>      
                </div>
               </div>
          </div>
	     </div>
    </>
  );
}


export default PersonalInfo;
