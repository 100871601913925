import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useHistory, useLocation } from "react-router";
import { fetchUserIsAdmin } from '../utils/APIRequests';
import queryString from 'query-string';

function SingleCampaignItems(props) {
  let history = useHistory();
  const [isUserPriceAdmin, setIsUserPriceAdmin] = useState(false);
  let location = useLocation();
  location = queryString.parse(location.search);
  useEffect(() => {
    fetchUserIsAdmin().then(res => {
        if (res["Bool"] === true) {
            setIsUserPriceAdmin(true);
        }
    }).catch(err => {
        console.error(err);
    });
  }, []);
  console.log(props, "campaign Page");
  const editURL = props.campaign.uploadCreative === true ? 'custom-ad-design' : 'campaigndesign';
    const plantype = ["", "Starter", "Growth", "Rockstar", "Customized Elite"];
    return (
        <div className="row" style={{  fontSize: "14px", marginTop: "8%", marginRight: "1%", color: "#333F57", borderStyle: "solid", borderColor: "#E5E7E9", paddingBottom:"60px" }}>
            <div className="col-12">
                <div className="row">
                    <div style={{ fontSize: "28px", fontWeight: "600", padding: "2%", color: "black", textTransform: "capitalize", minHeight: "39px" }} className="col-12 text-center">
                        {props.campaign.name.String}
                    </div>
                </div>

                    <div className="container">
                        
                        <div className="row align-items-center">
                            <div className="col-lg-6 pr">
                                <div className="row" style={{ marginTop: "2%" }}>
                                    <div style={{ minHeight: "160px" }} className="col-12">
                                        <div style={{ marginTop: "4%" }} className="row">
                                            <div className="col-12">
                                                <h6 className="sec__title">Conversion Zone:</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 campaign-detail-text" >{props.campaign.name.String}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 campaign-detail-text" >
                                                <div>{props.campaign.address.String}</div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: "4%" }} className="row">
                                            <div className="col-12">
                                                <h6 className="sec__title">Campaign Start Date:</h6>
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: "4%" }} className="row">
                                            <div className="col-12 campaign-detail-text">
                                                {new Date(
                                                    props.campaign.creationdate.Time
                                                ).toLocaleDateString()}
                                            </div>
                                        </div>

                                        <div style={{ marginTop: "4%" }} className="row">
                                            <div className="col-12">
                                                {props.campaign.plantype.Int32 > 0 &&
                                                    <div
                                                        className="row dashboard-adReview-changeBtn">
                                                        <div style={{ marginRight: "0px" }} className="col-9">
                                                            <h6 className="sec__title">Geofence Plan:</h6>{" "}
                                                            <div className=" campaign-detail-text" >{plantype[props.campaign.plantype.Int32]}</div>
                                                        </div>
                                                        <div className="col-3">
                                                            {isUserPriceAdmin ?
                                                                <button
                                                                    type="button" title="Edit" class="btn btn-primary btn-primary-color"
                                                                    onClick={() => {history.push(`/single-campaign/upgrade?campaignid=${props.campaign.id}&email=${location.email ? location.email : localStorage.getItem("email")}`)}}
                                                                >
                                                                    <FiEdit />
                                                                </button>
                                                            :
                                                                <button
                                                                    type="button" title="Edit" class="btn btn-primary btn-primary-color"
                                                                    onClick={() => props.changeAccount(props.campaign.id)}
                                                                >
                                                                    <FiEdit />
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                <div style={{ marginTop: "4%" }} className="row dashboard-adReview-editBtn">
                                                    <div className="col-9">
                                                        <h6 className="sec__title">Geofence Selections:</h6>{" "}
                                                        <div className=" campaign-detail-text" >{props.campaign.fencecount.Int32}</div>
                                                    </div>
                                                    <div className="col-3">
                                                        <Link
                                                            to={
                                                                "/list-map-view?campaign=" +
                                                                props.campaign.id +
                                                                "&priceLevel=" +
                                                                props.campaign.pricelevel.Int32 +
                                                                "&type=" +
                                                                props.campaign.type.String +
                                                                "&place=" +
                                                                props.campaign.longitude.Float64 +
                                                                "," +
                                                                props.campaign.latitude.Float64
                                                            }
                                                        >
                                                            {/* <img src={edit} alt="" /> */}
                                                            <button
                                                                type="button" title="Edit" class="btn btn-primary btn-primary-color"
                                                            >
                                                                <FiEdit />
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div
                                                    style={{ marginTop: "4%", marginBottom: "5%" }}
                                                    className="row dashboard-adReview-editBtn"
                                                >
                                                    <div className="col-9">
                                                        <h6 className="sec__title">Destination URL:</h6>
                                                        <div className=" campaign-detail-text" >{props.campaign.adurl.String}</div>
                                                    </div>
                                                    <div className="col-3">
                                                        <Link
                                                            to={
                                                                "/campaigndesign?campaign=" +
                                                            props.campaign.id + '&ad=' + props.campaign.adId +
                                                                "&edit=true&type=" +
                                                                props.campaign.type.String
                                                            }
                                                        >
                                                            {/* <img src={edit} alt="" /> */}
                                                            <button
                                                                type="button" title="Edit" class="btn btn-primary btn-primary-color"
                                                            >
                                                                <FiEdit />
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 pl">
                                <div className="row" style={{ marginTop: "2%" }}>
                                    <div className="col-12">
                                        <div style={{ marginTop: "8%" }} className="row align-items-center dashboard-adReview-editBtn">
                                            <div className="col-9">
                                                <h6 className="sec__title">Ad Design:</h6>
                                            </div>
                                            <div className="col-3">
                                                
                                            </div>
                                        </div>

                                        <div style={{ padding: "0px", marginTop: "4%", border: "2px solid #E5E7E9", marginRight: "0px",position:"relative" }} className="col-11 campaign-image-dashboard">
                                            <img width="92%" style={{ width:"100%", height:"auto" }} height="auto" src={"data:image/png;base64," + props.campaign.adimage.String} className="card__img" alt="Card"/>      
                                            <Link style={{ position:"absolute", top:"20px", right:"10px"}}
                                                    to={
                                                        '/' + editURL + '?campaign=' + props.campaign.id + '&ad=' + props.campaign.adId + '&edit=true&type=' + props.campaign.type.String

                                                    }
                                                >
                                                    {/* <img src={edit} /> */}
                                                    <button type="button" title="Edit" class="image-editIcon" style={{ display:"block", border:"none", width:"30px",height:"30px",}}>
                                                       <FiEdit />
                                                    </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>
        </div>
    );
}

export default SingleCampaignItems;
