import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from 'react'
import RichTextArea from "../../common/RichTextArea";
import SketchExample from "./SketchExample";
import { Button, Modal } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import { headlineValidationLogic, subheadingValidationLogic, imageFromFile, photoValidationLogic, logoValidationLogic, compressImage, urlValidationLogic, stripTags } from '../../../utils/FieldValidations';
import "cropperjs/dist/cropper.css";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";


function CreateAdPageMobile(props) {
  const {
    headline,
    headlineChange,
    backgroundColor,
    handleChangeBackgroundColor,
    headlineColor,
    handleChangeHeadlineColor,
    fileupload,
    imageplacement,
    handlePhoto,
    buttontext,
    buttontextChange,
    buttonTextColor,
    handleChangeButtonTextColor,
    buttonColor,
    handleChangeButtonColor,
    buttonBodyColor, 
    handleChangeButtonBodyColor,
    upload,
    interval,
    hide,
    info,
    popup,
    handleCreative,
    prof,
    dr2,
    dr,
    handleProf2,  
    subhead,
    subheadChange,
    messaging,
    messagingChange,
    backgroundColor2,
    handleChangeBackgroundColor2,
    subheadColor,
    handleChangeSubheadColor,
    phone,
    phoneChange,
    backgroundColor3,
    handleLogo,
    url,
    urlChange,
    handleChangeBackgroundColor3,
    setKey,
  } = props;

  const history = useHistory();

  const handlePreviewAd = () => {
    setKey("Preview Ad");
  };

  /** Logo */
  const [showLogoCropperModal, setShowLogoCropperModal] = useState(false);
  const [logoToCrop, setLogoToCrop] = useState("");

  const handleCloseLogoCropperModal = () => {
    setLogoToCrop("");
    logoInputRef.current.value = "";
    setShowLogoCropperModal(false);}
  const handleShowLogoCropperModal = () => setShowLogoCropperModal(true);

  const logoCropperRef = useRef(null);
  const photoInputRef = useRef();
  const logoInputRef = useRef();


  const applyLogoCrop = () => {
    const imageElement = logoCropperRef.current;
    const cropper = imageElement.cropper;
    const croppedURL = cropper.getCroppedCanvas().toDataURL();
    compressImage(croppedURL, 100).then(data => {
      handleLogo(croppedURL);
      handleCloseLogoCropperModal();
    }).catch(err => {
      handleCloseLogoCropperModal();
    });
  }

  const LogoCropModal = () => {
    return <Modal show={showLogoCropperModal} onHide={handleCloseLogoCropperModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop your logo</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '500px'}}>
        <Cropper
          src={logoToCrop}
          viewMode={1}
          style={{maxHeight: '495px'}}
          // style={{ height: 400, width: "100%" }}
          // Cropper.js options
          // initialAspectRatio={29 / 30}
          minCropBoxWidth={100}
          aspectRatio={215 / 75}
          guides={false}
          ref={logoCropperRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseLogoCropperModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={applyLogoCrop}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const startLogoCropper = async (file) => {
    const image = await imageFromFile(file);
    setLogoToCrop(image);
    handleShowLogoCropperModal();
    console.log("Entered start Logo")
  }



  const verifyAndHandleLogo = (e) => {
    const eventInstance = { ...e };
    const file = e.target.files[0]
    if (!file) return;
    logoValidationLogic(file, () => { startLogoCropper(file); }, (error) => { window.alert(error) });
  };


  /** Photo */

  const [showPhotoCropperModal, setShowPhotoCropperModal] = useState(false);
  const [photoToCrop, setPhotoToCrop] = useState("");

  const handleClosePhotoCropperModal = () => {
    setPhotoToCrop("");
    photoInputRef.current.value = "";
    setShowPhotoCropperModal(false);}
  const handleShowPhotoCropperModal = () => setShowPhotoCropperModal(true);

  const photoCropperRef = useRef(null);


  const applyCrop = () => {
    const imageElement = photoCropperRef.current;
    const cropper = imageElement.cropper;
    const croppedURL = cropper.getCroppedCanvas().toDataURL();
    handlePhoto(croppedURL);
    handleClosePhotoCropperModal();
  }

  const PhotoCropModal = () => {
    return <Modal show={showPhotoCropperModal} onHide={handleClosePhotoCropperModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop your image</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '500px'}}>
        <Cropper
          src={photoToCrop}
          style={{maxHeight: '495px'}}
          viewMode={1}
          // style={{ height: 400, width: "100%" }}
          // Cropper.js options
          // initialAspectRatio={29 / 30}
          minCropBoxWidth={100}
          aspectRatio={29 / 30}
          guides={false}
          ref={photoCropperRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosePhotoCropperModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={applyCrop}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  }

  const startCropper = async (file) => {
    const image = await imageFromFile(file);
    setPhotoToCrop(image);
    handleShowPhotoCropperModal();
  }



  const verifyAndHandlePhoto = (e) => {

    const eventInstance = { ...e };
    const file = e.target.files[0]
    if (!file) return;

    photoValidationLogic(file, () => { startCropper(file); }, (error) => { window.alert(error) });
  };

  const [validationMessages, _setValidationMessages] = React.useState({});

  const setValidationMessage = (field, message) => {
    _setValidationMessages((prevState) => ({ ...prevState, [field]: message }));
  }

  const validateSubheading = useCallback(() => {
    setValidationMessage("subhead", subheadingValidationLogic(subhead));
  }, [subhead]);

  useEffect(() => {
    validateSubheading();
  }, [subhead, validateSubheading]);

  const validateHeadline = useCallback(() => {
    setValidationMessage("headline", headlineValidationLogic(headline));
  }, [headline]);

  useEffect(() => {
    validateHeadline();
  }, [headline, validateHeadline]);

  const validateURL = useCallback(() => {
    setValidationMessage("url", urlValidationLogic(url));
  }, [url]);

  useEffect(() => {
    validateURL();
  }, [url, validateURL]);

  const MandatoryAsteriskIndicator = ({ message }) => {
    return <span className="mandatory-asterisk-indicator" title={message}>*</span>
  }

  let location = useLocation();
  let campaign = "0";
  location = queryString.parse(location.search);
  if (location.campaign) {
    campaign = location.campaign;
  }

  return (
    <>
    
    <div className="col-lg-4 col-md-12" id="Section-1">
    <div className="row">
          <label className="label-text3">Headline <MandatoryAsteriskIndicator message={"Headline is required"} /></label>
    </div>
    <div className="row">
      <span style={{ width: '100%' }}>
            <input
              className={classNames("form-control", { "is-invalid": !!validationMessages.headline })}
              type="text"
              name="text"
              placeholder={"Headline Goes Here"}
              onChange={headlineChange}
              defaultValue={headline}
              aria-describedby="headlineHelp"
              maxLength={20}
            />{' '}
            <div class="invalid-feedback">
              {validationMessages.headline}
            </div>
            {
              !validationMessages.headline && <small id="headlineHelp" class="form-text text-muted">
                {headline.length}/20
              </small>
            }
      </span>
    </div>
    <div style={{ marginTop: '1%' }} className="row">
      <div className="col-6 settings color" style={{ marginTop: '2%' }}>
        <SketchExample
          color={backgroundColor}
          onChangeComplete={handleChangeBackgroundColor}
        />
        <label className="label-text2">Background Color</label>
      </div>
      <div className="col-6 settings color" style={{ marginTop: '2%' }}>
        <SketchExample
          color={headlineColor}
          onChangeComplete={handleChangeHeadlineColor}
        />
        <label className="label-text2">Text Color</label>
      </div>
    </div>




    <div style={{ marginTop: '2%' }} className="row">
          <label className="label-text3">Subhead <MandatoryAsteriskIndicator message={"Subheading is required"} /></label>
        </div>
        <div className="row">
        <span style={{ width: '100%' }}>
            <input
              className={classNames("form-control", { "is-invalid": !!validationMessages.subhead })}
            type="text"
              id="subheading"
              placeholder={"Subhead Goes Here"}
              defaultValue={subhead}
            onChange={subheadChange}
              aria-describedby="subheadHelp"
              maxLength={30}
            />
            <div class="invalid-feedback">
              {validationMessages.subhead}
            </div>
            {
              !validationMessages.subhead && <small id="subheadHelp" class="form-text text-muted">
                {subhead.length}/30
              </small>
            }
        </span>
        </div>




    <div style={{ marginTop: '2%' }} className="row">
          <label className="label-text3">Your Image <MandatoryAsteriskIndicator message={"Your Image is required"} /></label>
    </div>
    <div className="row">
      <div style={{ padding: '0px' }} className="col-6">
        <div className="settings">
          <label
            style={{
              marginTop: '0px',
              marginBottom: '0px',
              display: 'block',
            }}
            for="photo-upload"
            className="label-text2"
          >
            <img
              style={{ float: 'left', width: '110px' ,cursor:'pointer'}}
              alt=""
              src={fileupload}
            />
            <img
              src={imageplacement}
              alt=""
              style={{ float: 'left', width: '50px' }}
            />
            <div
              className="label-text2"
              style={{ float: 'left', fontSize: '10px', lineHeight: '1.5' }}
            >
              (.jpg/.png/.gif)
                <br />
                320px wide
                <br />
                Up to 4MB
            </div>
          </label>
          <div className="form-group">
                <input
                ref={photoInputRef}
                  style={{ display: 'none' }}
                  id="photo-upload"
                  type="file"
                  onChange={verifyAndHandlePhoto}
                  accept="image/gif, image/jpeg, image/png"
                />
          </div>
        </div>
      </div>
    </div>



    <div style={{ marginTop: "2%" }} className="row">
          <label className="label-text3">Your Logo <MandatoryAsteriskIndicator message={"Your Logo is required"} /></label>
        </div>
        <div className="row">
          <div style={{ padding: "0px"}} className="col-6">
            <div className="settings">
              <label
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  display: "block",
                }}
                for="logo-upload"
                className="label-text2"
              >
                  <img
                  style={{ float: "left", width: "110px",cursor:'pointer' }}
                  alt=""
                  src={fileupload}
                />
                   <img
                  src={imageplacement}
                  style={{ float: "left", width: "50px" }}
                  alt=""
                />
                
                <div className="label-text2"
                  style={{
                    float: "left",
                    fontSize: "10px",
                    lineHeight: "1.5",
                  }}
                >
               (.jpg/.png/.gif)
                <br />
                200px wide
                <br />
                Up to 4MB
                </div>
               
              </label>
              <div className="form-group">
                <input
                ref={logoInputRef}
                  style={{ display: "none" }}
                  id="logo-upload"
                  type="file"
                  onChange={(e) => {
                    verifyAndHandleLogo(e);
                  }
                  }
                  accept="image/gif, image/jpeg, image/png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "1%" }}
          className="row "
        >
          <div className="col-6 settings color " style={{ marginTop: "2%" }}>
            <SketchExample
              color={backgroundColor3}
              onChangeComplete={handleChangeBackgroundColor3}
            />
            <label className="label-text2">Logo Background Color</label>
          </div>
        </div>




    <div style={{ marginTop: '2%' }} className="row">
      <label className="label-text3">Button</label>
    </div>
    <div className="row">
      <input
        className="form-control"
        type="text"
        name="text"
        placeholder={buttontext}
        onChange={buttontextChange}
      />
    </div>
    <div style={{ marginTop: '1%' }} className="row">
      <div className="col-6 settings color" style={{ marginTop: '2%' }}>
        <SketchExample
          color={buttonColor}
          onChangeComplete={handleChangeButtonColor}
        />
            <label className="label-text2">Background Color</label>
      </div>

          <div className="col-6 settings color" style={{ marginTop: '2%' }}>
            <SketchExample
              color={buttonBodyColor}
              onChangeComplete={handleChangeButtonBodyColor}
            />
            <label className="label-text2">Button Color</label>
          </div>

      <div className="col-6 settings color" style={{ marginTop: '2%' }}>
        <SketchExample
          color={buttonTextColor}
          onChangeComplete={handleChangeButtonTextColor}
        />
        <label className="label-text2">Button Text Color</label>
      </div>
    </div>


    <div style={{ marginTop: "2%" }} className="row">
          <label className="label-text3">Phone #</label>
        </div>
        <div className="row">
          <span style={{ width: "100%" }}>
            <input
              className="form-control"
              type="text"
              name="text"
              placeholder={'000-000-0000'}
              value={phone}
              onChange={phoneChange}
            />{" "}
          </span>
        </div>


        <div style={{ marginTop: "2%" }} className="row">
          <label className="label-text3">Ad URL <MandatoryAsteriskIndicator message={"Ad URL is required"} /></label>
        </div>
        <div className="row">
          <span style={{ width: "100%" }}>
            <input
              className="form-control"
              type="text"
              name="text"
              defaultValue={url}
              placeholder={"http://example.com"}
              onChange={urlChange}
            />{" "}
            <div class="invalid-feedback" style={{ display: validationMessages.url ? 'block' : 'none' }}>
              {validationMessages.url}
            </div>

          </span>
        </div>


    <div style={{ marginTop: "2%" }} className="row">
          <label className="label-text3">Messaging <MandatoryAsteriskIndicator message={"Messaging is required"} /></label>
        </div>
        <div className="row">
          <span style={{ width: "100%" }}>
            <RichTextArea id="messaging" placeholder={"Body text/Messaging goes here"} value={messaging} onChange={messagingChange} />
            {/* <input
              className="form-control"
              type="text"
              name="text"
              placeholder={messaging}
              onChange={messagingChange}
            />{" "} */}
          </span>
          {stripTags(messaging).length}/70
        </div>
        <div style={{ marginTop: "1%" }} className="row">
          <div className="col-6 settings color" style={{ marginTop: "2%" }}>
            <SketchExample
              color={backgroundColor2}
              onChangeComplete={handleChangeBackgroundColor2}
            />
            <label className="label-text2">Background Color</label>
          </div>
          <div className="col-6 settings color" style={{ marginTop: "2%" }}>
            <SketchExample
              color={subheadColor}
              onChangeComplete={handleChangeSubheadColor}
            />
            <label className="label-text2">Text Color</label>
          </div>
        </div>

        <div style={{ marginTop: "2%" }} className="row">
          <span className="mandatory-asterisk-indicator" style={{ fontWeight: "800" , marginTop: "2px", marginRight:"6px"}}>*</span>
          <label class="label-text2">All fields marked with an asterisk (*) are required</label>
      
       </div>

        <div style={{ width:"100%", marginTop: '4%' }} >
                   <button className="update-prev-btn" onClick={handlePreviewAd}> Preview Ad </button>
                </div>

      <div style={{ marginTop: '6%' }} className="row">
      <div className="form-group form-margin-btm col-12">
      
        <div style={{ marginLeft: '-15px' , width: 'calc(100% + 30px)'}} className="row">
          {/*input style={{marginLeft:'2%'}} type="checkbox" onClick={handleAdditional} checked={additional} />*/}
              <button
                onClick={() => {
                  history.push('/custom-ad-design?campaign=' + campaign);
                }}
                style={{ width: '50%', backgroundColor: '#7E8896', color: '#fff', border: '1px solid #000', textAlign: 'center', padding: '10px 0px', fontSize: '14px !important' }}>Upload Creative</button>
              {/* <img
                style={{ margin: '0 auto', display: 'table', cursor: 'pointer' }}
                src={upload}
                alt=""
                height="44px"
                onClick={() => {
                  history.push('/custom-ad-design?campaign=' + campaign);
                }}
              /> */}
              {/* <label
            style={{
              marginTop: '0px',
              marginBottom: '0px',
              display: 'block',
              background: '#7E8896',
              border:'1px solid #000',
              width: '50%',
            }}
            for="creative-upload"
            className="label-text3"
          >
            <img
              style={{ margin: '0 auto' , display: 'table',cursor:'pointer' }}
              src={upload}
              alt=""
              height="44px"
            />


          </label>

          <div className="form-group">
            <input
              style={{ display: 'none' }}
              id="creative-upload"
              type="file"
              onChange={handleCreative}
            />
          </div> */}
          <a href='Contact' style={{ width:'50%', backgroundColor:'#7E8896',color: '#fff', border:'1px solid #000',textAlign:'center',paddingTop:'10px',fontSize:'14px !important' }}> Get Design Help </a>

        </div>
      </div>
    

      
    </div>

    
                   
                 
        <LogoCropModal />
        <PhotoCropModal />
  </div>
     
      </>
  );
}

export default CreateAdPageMobile;
