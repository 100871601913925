import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {AiOutlineUser} from 'react-icons/ai'
import {FiLock} from 'react-icons/fi'
import {Link,RouteComponentProps, useLocation} from "react-router-dom";
import 'crypto-js/lib-typedarrays';
import { Auth } from 'aws-amplify';
import { AUTH_USER_TOKEN_KEY, IDENTITY_USER_TOKEN_KEY } from '../../../components/data/constants';
import { Form, Icon, Spin, Input, Button, notification, Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import queryString from "query-string";


function LoginBox({title, subtitle}) {
    let history = useHistory();
    let location = useLocation();
    const [form] = Form.useForm();
    const[loading,setLoading] = useState(false);
    const redirectTo = queryString.parse(location.search).redirectTo;
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const onFinish = (values) => {
        let { email, password } = values;

        setLoading(true);

        Auth.signIn(email, password)
          .then(user => {
            const { from } = location.state || {
              from: {
                pathname: '/dashboard'
              }
            };

            localStorage.setItem(AUTH_USER_TOKEN_KEY, user.signInUserSession.accessToken.jwtToken);
            localStorage.setItem(IDENTITY_USER_TOKEN_KEY, user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('email',email);
            //alert(localStorage.getItem('email'));
            let userUrl = SERVER_URL+'/user';
            let first='';
            let last='';
            if(user&&user.attributes&&user.attributes.name){
                let nameparts = user.attributes.name.split(' ');
                if(nameparts.length>1){
                    first = nameparts[0];
                    last = nameparts[1];
                }
            }
            var userBody = new Object();
            userBody.email = email;
            userBody.firstname = first;
            userBody.lastname = last;
            userBody.phone = '';

            (async () => {
                const rawResponseUser = await fetch(userUrl, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': user.signInUserSession.idToken.jwtToken
    
                        },
                        body: JSON.stringify(userBody)
                    });
                const content = await rawResponseUser.json();
                console.log(content);
              })();


            console.log(localStorage.getItem(IDENTITY_USER_TOKEN_KEY));

            let campaign = localStorage.getItem('campaign');
            if(campaign){
                const rawResponse = async () =>  await fetch(SERVER_URL+'/campaignemail?email='+email+'&campaign='+campaign, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': user.signInUserSession.idToken.jwtToken
    
                    }
                });
            }

            const fetchData = async () => {
                const rawResponse = await fetch(SERVER_URL+'/campaign?email='+email, {
                  method: 'GET',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': user.signInUserSession.idToken.jwtToken
                  }
                });
                const content = await rawResponse.json();
                console.log(content);
                // redirectTo
                if(JSON.parse(localStorage.getItem("pendingRequests"))) {
                    window.location = '/createCampaignJourney';
                    return;
                }
                if(content&&Array.isArray(content.message)&&content.message.length>0){
                    if(redirectTo) {
                        window.location = redirectTo;
                    } else {
                        history.push(from);
                    }
                        
                }else{
                     window.open('/list-map-view?place=-77.0364,38.8951&type=','_self');
                    

                }
              };
              fetchData();
            
          })
          .catch(err => {
            alert(err.message);

            console.log(err);
            if(err.code=='UserNotConfirmedException'){
                window.open('/verify-code?email='+email,"_self");
            }
            setLoading(false);
          });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return (
        <>
            <div className="billing-form-item mb-0">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        {title}
                    </h3>
                    <p className="font-size-16 font-weight-medium">
                        {subtitle}
                    </p>
                </div>
                <div className="billing-content">
                    <div className="contact-form-action">
                    <Form  form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                                <span className="form-icon">
                                                    <AiOutlineUser />
                                                </span>
                                                <Form.Item  label="Email" name="email" rules={[{required:true,message:'Please input your email!'}]}>
                                                <Input className="form-control" placeholder='Email' type="text" />
                                                    </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                                <span className="form-icon">
                                                    <FiLock />
                                                </span>
                                                <Form.Item  label="Password" name="password" rules= {[{ required: true, message: 'Please input your Password!' }] }>
                                            <Input  className="form-control" placeholder='Enter password' type="Password"/>
                                        </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">

                                            <div>
                                                <Link to="/recover" className="color-text font-weight-medium">
                                                    Forgot password?
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="btn-box margin-top-20px margin-bottom-20px">
                                    <Button className="theme-btn border-0" htmlType="submit" disabled={loading}>
                                        {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Login Now'}
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <p className="font-weight-medium">Not a member? <Link to="/sign-up" className="color-text"> Register</Link></p>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginBox;