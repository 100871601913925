import React,{useState} from 'react';
import { GiPositionMarker } from 'react-icons/gi'
import { FiPhone, FiCheck } from 'react-icons/fi'
import { FaRegEnvelope } from 'react-icons/fa'
import { IoMdGlobe } from 'react-icons/io'
import { MdStar, MdStarHalf } from 'react-icons/md'
import SocialProfile from "./SocialProfile";
import edit from "../../../assets/images/Edit-button-navy.svg";

function UserSidebar({usercontent}) {
    const [input,setInput] = useState((usercontent.phone&&usercontent.phone.Valid)?usercontent.phone.String:'Please Enter a Phone Number');
    const editPhone = () =>{
        usercontent.editPhone(input);
    };
    let firstName = usercontent.first&&usercontent.first.Valid?usercontent.first.String:'Please Enter Your First Name';
    let lastName = usercontent.last&&usercontent.last.Valid?usercontent.last.String:'Please Enter Your Last Name';
    return (
        <>
            <div className="author-bio margin-bottom-5px">
                <div className="d-flex align-items-center">
                    <div className="author-inner-bio">
                        <h4 className="author__title font-weight-bold pb-0 mb-1">{firstName + ' ' + lastName}</h4>
                    </div>
                </div>
            </div>
            <div className="user-contact padding-top-10px">
                <ul className="info-list padding-bottom-10px">
                    <div className='row'>
                       <div className='col-12'><span className="la"><FaRegEnvelope /></span><a href={'mailto:'+usercontent.email}> {usercontent.email}</a></div> 
                    </div>
                    <div style={{marginTop:'3%'}} className='row'>
                        <div className='col-10'>
                            <span className="la"><FiPhone /></span> <input placeholder={(usercontent.phone&&usercontent.phone.Valid)?usercontent.phone.String:'Please Enter a Phone Number'} onInput={e => setInput(e.target.value)}/>
                        </div>
                        <div className='col-2'>
                            <button className='edit-campaign' style={{ border: '0', backgroundColor: '#333F57' }} onClick={(e) => editPhone()}>
                                <img style={{ marginTop:'-2%',height: '30px' }} src={edit} />
                            </button>
                        </div>
                    </div>
                </ul>
            
            </div>

        </>
    );
}

export default UserSidebar;
