import React, { useState, useRef, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import markericon from '../../assets/images/Small-Star-Map-Marker-Navy-Stroke.svg'
import markericon3 from '../../assets/images/Small-Map-Marker-Gray-Stroke.svg'
import markericon2 from '../../assets/images/Small-Map-Marker-Green-Stroke.svg'
import PlaceScrollBox from '../../components/contact/PlaceScrollBox'
import MapViewSearchInput from './MapViewSearchInput'

import one from '../../assets/images/1-Bullet-eo.svg'
import two from '../../assets/images/2-Bullet-eo.svg'
import three from '../../assets/images/3-Bullet-eo.svg'
import grey from '../../assets/images/Gray-Location-Pointer-lrg.svg'
import arrow from '../../assets/images/Thin-Right-Arrow-blue.svg'
import _ from 'lodash'
import competitorsb from '../../assets/images/Show-Competitors-button-selected-blue.svg'
import competitorsg from '../../assets/images/Show-Competitors-button-gray.svg'
import shoppingb from '../../assets/images/Show-Shopping-button-selected-blue.svg'
import shoppingg from '../../assets/images/Show-Shopping-button-gray.svg'
import parksb from '../../assets/images/Show-Parks-button-selected-blue.svg'
import parksg from '../../assets/images/Show-Parks-button-gray.svg'
import otherb from '../../assets/images/Show-Other-button-selected-blue.svg'
import otherg from '../../assets/images/Show-Other-button-gray.svg'
import addb from '../../assets/images/Add-Custom-Targets-button-selected-blue.svg'
import addg from '../../assets/images/Add-Custom-Targets-button-gray.svg'
import clearall from '../../assets/images/Clear-All-button-gray.svg'
import exportf from '../../assets/images/Export-button-gray.svg'
import Papa from 'papaparse'
import legend from '../../assets/images/Map-key-eo.svg'
import listViewMobile from '../../assets/images/List-View-Button-mobile.svg'
import useIsMobile from '../customHooks/useIsMobile'
import locationMobile from "../../assets/images/location-icon.png";
import brushMobile from "../../assets/images/brush-icon.png";
import magnifierMobile from "../../assets/images/magnifier-icon.png";
import tickMobile from "../../assets/images/tick-icon.png";
import continueMobile from "../../assets/images/continue-icon.png";
import deleteSelect from "../../assets/images/delete-select.png";
import exportSelect from "../../assets/images/export-select.png";


const greatPlaceStyle = (radius) => {
  return {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    behavior: 'url(PIE.htc)',
    border: '3px solid #000',
    width: radius * 2 + 'px',
    height: radius * 2 + 'px',
  }
}
const AnyReactComponent = ({ text, sizeInPx }) => {
  var mergedStyle = greatPlaceStyle(sizeInPx)
  return <div style={mergedStyle}>{text}</div>
}

function PlacesResults(
  competitors,
  park,
  school,
  shopping_mall,
  gas_station,
  accounting,
  gym,
  convenience_store,
  real_estate_agency,
  restaurant,
  lawyer,
  hair_care,
  manual,
  other,
) {
  this.competitors = competitors
  this.park = park
  this.school = school
  this.shopping_mall = shopping_mall
  this.gas_station = gas_station
  this.other = other
  this.accounting = accounting
  this.gym = gym
  this.convenience_store = convenience_store
  this.real_estate_agency = real_estate_agency
  this.restaurant = restaurant
  this.lawyer = lawyer
  this.hair_care = hair_care
  this.manual = manual
}

const handleClick = 'document.getElementById("'
const handleClick2 = '")'
const handleClick3 = '.click()'

export default function MapViewCluster(props) {
  const getInfoWindowString = (place) => `
    <div>
      <div style="font-size: 16px;">
        ${place.name}
      </div>
      <div style="font-size: 14px; color: grey;">
        ${place.types ? place.types[0] : ''}
      </div>
      <div style="font-size: 14px; color: grey;">
        ${'$'.repeat(place.price_level)}
      </div>`

  const getInfoWindowString2 = (place) => ` 
  <div style="font-size: 14px; color: green;">
    <input id='${place.place_id}_info' type='checkbox' ${
    selected2.includes(place.place_id) ? 'checked' : ''
  } onclick='if(${handleClick}${
    place.place_id
  }_saved${handleClick2}){${handleClick}${
    place.place_id
  }_saved${handleClick2}${handleClick3}}else{if(${handleClick}${
    place.place_id
  }${handleClick2}){${handleClick}${
    place.place_id
  }${handleClick2}${handleClick3}}};' /><label for="${
    place.place_id
  }_info"> Save Geofence</label>
    </div>`

  const getInfoWindowString3 = (place) => ` 
    <div style="font-size: 14px; color: green;">
      <input id='${place.place_id}_info' type='checkbox' checked } onclick='if(${handleClick}${place.place_id}_saved${handleClick2}){${handleClick}${place.place_id}_saved${handleClick2}${handleClick3}}else{if(${handleClick}${place.place_id}${handleClick2}){${handleClick}${place.place_id}${handleClick2}${handleClick3}}};' /><label for="${place.place_id}_info"> Save Geofence</label>
      </div>`

  // 1) map setup
  const mapRef = useRef()
  const mapsRef = useRef()
  const [zoom, setZoom] = useState(10)
  const [bounds, setBounds] = useState(null)
  const [sizeInPx, setSizeInPx] = useState(10)
  const [placesResults, setPlacesResults] = useState(
    new PlacesResults([], [], [], [], [], [], [], [], [], [], [], [], [], []),
  )
  const [otherResults, setOtherResults] = useState([])
  const [type, setType] = useState(props.type)
  const [place, setPlace] = useState(props.place)
  const [count, setCount] = useState(
    JSON.parse(localStorage.getItem('storedData') || '[]').length,
  )
  const [selected2, setSelected2] = useState([])
  const [markers, setMarkers] = useState([])
  const [manualCount, setManualCount] = useState(0)
  const [filterType, setFilterType] = useState(0)
  const [lastExpanded, setLastExpanded] = useState('')
  const [plan, setPlan] = useState('intermediate')
  const [planLimit, setPlanLimit] = useState(25)
  const [infoWindows, setInfoWindows] = useState([])
  const [savedGeofences, setSavedGeofences] = useState(
    JSON.parse(localStorage.getItem('storedData')) || [],
  )
  const [prepopulated, setPrepopulated] = useState([])
  const [pagination, setPagination] = useState(null)
  const [boxLabel, setBoxLabel] = useState('competitors')
  const [competitorsButton, setCompetitorsButton] = useState(competitorsb)
  const [shoppingButton, setShoppingButton] = useState(shoppingg)
  const [parksButton, setParksButton] = useState(parksg)
  const [otherButton, setOtherButton] = useState(otherg)
  const [addButton, setAddButton] = useState(addg)
  const [placeHolder, setPlaceHolder] = useState(
    'Enter business name (or address) or click to search near your location.',
  )
  const [defaultValue, setDefaultValue] = useState('')
  useEffect(() => {
    var lim = 25
    if (plan == 'small') {
      lim = 10
    } else if (plan == 'large') {
      lim = 50
    }
    setPlanTwice(lim)
  }, [plan])
  useEffect(() => {
    if (mapsRef.current) {
      locationToFence(mapRef.current, mapsRef.current)
    }
  }, [place])

  useEffect(() => {}, [type])

  useEffect(() => {
    if (localStorage.getItem('storedData')) {
      let data = JSON.parse(localStorage.getItem('storedData'))
      setCount(data.length)
    }
  }, [])

  const selectedMarker = new window.google.maps.MarkerImage(
    markericon2,
    null,
    null,
    null,
    new window.google.maps.Size(40, 40),
  )
  const competitorMarker = new window.google.maps.MarkerImage(
    markericon3,
    null,
    null,
    null,
    new window.google.maps.Size(40, 40),
  )
  const initialCenter = {
    geometry: {
      location: {
        lat: function () {
          return 80
        },
        lng: function () {
          return -120
        },
      },
    },
  }
  const [fencePlace, setFencePlace] = useState(initialCenter)

  const setPlanTwice = (pl) => {
    setPlanLimit(pl)
    props.setPlan(pl)
  }

  const setCountTwice = (c) => {
    setCount(c)
    props.setCount(c)
  }
  const goToMapSearch = (location, choice, e) => {
    localStorage.removeItem('storedData')
    localStorage.removeItem('place')
    localStorage.removeItem('type')
    var clearedMarkers = Object.assign({}, markers)
    for (var placeId in markers) {
      if (!selected2.includes(placeId)) {
        clearedMarkers[placeId].setMap(null)
        delete clearedMarkers[placeId]
      } else {
        console.log(placeId + ' found in selected')
      }
    }
    setMarkers(clearedMarkers)
    if ('geolocation' in navigator) {
      if (choice.value && choice.value != 0) {
        // window.location.href='/list-map-view?place='+location+'&type='+choice.value;
        setType(choice.value)
        props.setCentroidType(choice.value)
        setPlace(location)
        localStorage.setItem('place', location)
      } else {
        setType(localStorage.getItem('type') || '')
        props.setCentroidType(localStorage.getItem('type') || '')
        setPlace(location)
        localStorage.setItem('place', location)
      }
      props.setPriceLevel(-1)
      locationToFence2(location, mapRef.current, mapsRef.current)
    } else {
      alert(
        'Unable to determine your location or the location you wish to search for.',
      )
    }
  }

  const distanceInPx = (map, miles) => {
    //var pixelSize = Math.pow(2, -map.getZoom());
    var metersPerPixel =
      (156543.03392 * Math.cos((map.getCenter().lat() * Math.PI) / 180)) /
      Math.pow(2, map.getZoom())
    var metersPerMile = 1609.34
    var distanceInMeters = miles * metersPerMile
    var d = distanceInMeters / metersPerPixel

    return d
  }
  const _onZoom = () => {
    var pixCount = distanceInPx(mapRef.current, props.distanceInMiles)
    setSizeInPx(pixCount)
  }

  const closeAllInfoWindows = () => {
    var j
    console.log(infoWindows.length)
    for (var placeId in infoWindows) {
      infoWindows[placeId].close()
    }
  }

  const includesPlace = (place_id, placesArray) => {
    let out = false
    for (var place of placesArray) {
      if (place.place_id && place.place_id == place_id) {
        return true
      }
    }

    return out
  }
  // 2) load and format data
  const placesCallback = (results, status, pagination) => {
    const newMarkers = []
    var map = mapRef.current

    if (status == 'OK') {
      var newPR = new PlacesResults(
        results,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      )
      newPR[type] = results
      props.setCentroidType(type)
      setPlacesResults(newPR)
      results.forEach((result) => {
        result.show = false // eslint-disable-line no-param-reassign
      })
      results = results.filter(function (result) {
        return result.place_id !== props.place
      })
      let resultsString = localStorage.getItem('storedData') || '[]'
      let localStorageResults = JSON.parse(resultsString)
      results.forEach((place) => {
        let iconType = competitorMarker
        if (selected2.includes(place.place_id)) {
          iconType = selectedMarker
        }
        if (includesPlace(place.place_id, localStorageResults)) {
          iconType = selectedMarker
        }
        newMarkers[place.place_id] = new mapsRef.current.Marker({
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          map,
          zIndex: 999,
          icon: iconType,
          optimized: false,
        })

        infoWindows[place.place_id] = new mapsRef.current.InfoWindow({
          content:
            getInfoWindowString(place) + getInfoWindowString2(place) + `</div>`,
        })

        newMarkers[place.place_id].addListener('click', () => {
          closeAllInfoWindows()
          infoWindows[place.place_id].open(map, newMarkers[place.place_id])
        })
      })

      setMarkers(newMarkers)
      var pixCount = distanceInPx(mapRef.current, props.distanceInMiles)
      setSizeInPx(pixCount)
      if (pagination && pagination.hasNextPage) {
        console.log(pagination)
        setPagination(pagination)
      } else {
        setPagination(null)
      }
    } else {
      console.log(status)
    }
  }

  function populateSavedIconsNonCompetitors() {
    //const newMarkers = [];
    //var map = mapRef.current;
    let types = []
    savedGeofences.forEach((place) => {
      // let iconType = selectedMarker;
      // newMarkers[place.place_id]=new mapsRef.current.Marker({
      //   position: {
      //     lat: place.location.lat(),
      //     lng: place.location.lng(),
      //   },
      //   map,
      //   zIndex: 999,
      //   icon: iconType,
      //   optimized: false
      // });
      // infoWindows[place.place_id]=new mapsRef.current.InfoWindow({
      //   content: getInfoWindowString(place),
      // });

      // newMarkers[place.place_id].addListener('click', () => {
      //   closeAllInfoWindows();
      //   infoWindows[place.place_id].open(map, newMarkers[place.place_id]);
      // });
      if (place && place.types && !types.includes(place.types[0])) {
        types.push(place.types[0])
      }
    })
    setPrepopulated(types)
    types.forEach((type) => {
      compSearch2(type)
    })
    //setMarkers(newMarkers);
  }
  function compSearch2(intype) {
    let typeDerived='';
    if(intype.length<1){
      typeDerived = localStorage.getItem("type")||'';
    }else{
      typeDerived = intype;
    }
    var metersPerMile = 1609.34
    var pyrmont = mapRef.current.getCenter()
    if (fencePlace && fencePlace.geometry && fencePlace.geometry.location) {
      pyrmont = {
        lat: parseFloat(fencePlace.geometry.location.lat()),
        lng: parseFloat(fencePlace.geometry.location.lng()),
      }
    }
    var request = {
      location: pyrmont,
      radius: metersPerMile * props.distanceInMiles,
      type: [typeDerived],
    }
    var service = new mapsRef.current.places.PlacesService(mapRef.current)

    service.textSearch(request, function (results, status, pagination) {
      results = results.filter(function (result) {
        return result.place_id !== props.place
      })
      placesCallback(results, status, pagination)
    })
  }
  function compSearch(prices) {
    let typeDerived='';
    if(type.length<1){
      typeDerived = localStorage.getItem("type")||'';
    }else{
      typeDerived = type;
    }
    if (mapRef && mapsRef && typeDerived.length > 0) {
      var metersPerMile = 1609.34
      var pyrmont = mapRef.current.getCenter()
      if (fencePlace && fencePlace.geometry && fencePlace.geometry.location) {
        pyrmont = {
          lat: parseFloat(fencePlace.geometry.location.lat()),
          lng: parseFloat(fencePlace.geometry.location.lng()),
        }
      }
      var request = {
        location: pyrmont,
        radius: metersPerMile * props.distanceInMiles,
        type: [typeDerived],
      }
      var service = new mapsRef.current.places.PlacesService(mapRef.current)
      if (props.priceLevel != -1) {
        addPricesToRequest([props.priceLevel], request)
      }
      if (prices) {
        props.setPriceLevel(prices)
        addPricesToRequest([prices], request)
      }
      service.textSearch(request, function (results, status, pagination) {
        results = results.filter(function (result) {
          return result.place_id !== props.place
        })
        placesCallback(results, status, pagination)
      })
    }
  }
  function compSearch3(fenceplace2, prices) {
    let typeDerived = ''
    if (type.length < 1) {
      typeDerived = localStorage.getItem('type') || ''
      setType(typeDerived)
    } else {
      if (fenceplace2 && fenceplace2.types && fenceplace2.types.length > 0) {
        typeDerived = fenceplace2.types[0]
      }
    }
    if (mapRef && mapsRef && typeDerived.length > 0) {
      var metersPerMile = 1609.34
      var pyrmont = mapRef.current.getCenter()
      if (
        fenceplace2 &&
        fenceplace2.geometry &&
        fenceplace2.geometry.location
      ) {
        pyrmont = {
          lat: parseFloat(fenceplace2.geometry.location.lat()),
          lng: parseFloat(fenceplace2.geometry.location.lng()),
        }
      }
      var request = {
        location: pyrmont,
        radius: metersPerMile * props.distanceInMiles,
        type: [typeDerived],
      }
      var service = new mapsRef.current.places.PlacesService(mapRef.current)
      if (props.priceLevel != -1) {
        addPricesToRequest([props.priceLevel], request)
      }
      if (prices) {
        props.setPriceLevel(prices)
        addPricesToRequest([prices], request)
      }
      service.textSearch(request, function (results, status, pagination) { 
        if(results&&results.filter){
          results=results.filter(function(result){
            return result.place_id!==props.place;
          });
          results = results.filter(function (obj) {
            return !savedFencesContains(obj.place_id);
          });
          placesCallback(results, status, pagination);
        }
      })
    }
  }
  const addPricesToRequest = (prices, request) => {
    if (prices.length == 1) {
      request.minPriceLevel = prices[0]
      request.maxPriceLevel = prices[0]
    } else {
      request.minPriceLevel = Math.min(...prices)
      request.maxPriceLevel = Math.max(...prices)
    }
    //request.rankby='distance'
    //delete request.radius;
  }

  const savedFencesContains = (placeId) => {
    let out = false;
    savedGeofences.forEach((place) => {
      if(place.place_id==placeId){
        out=true;
      }
    });
    return out;
  }

  const accordSearch = (type, price, isCompetitor) => {
    if (mapRef && mapsRef && mapRef.current) {
      console.log('type:' + type)
      const newMarkers = []
      var map = mapRef.current

      if (
        type &&
        type != undefined &&
        type.length > 0 &&
        ((placesResults[type[0]] && placesResults[type[0]].length == 0) ||
          (price && price.length > 0) ||
          filterType != price.length)
      ) {
        let newResults = { ...placesResults }
        var metersPerMile = 1609.34
        var pyrmont = mapRef.current.getCenter()
        if (fencePlace && fencePlace.geometry && fencePlace.geometry.location) {
          pyrmont = {
            lat: parseFloat(fencePlace.geometry.location.lat()),
            lng: parseFloat(fencePlace.geometry.location.lng()),
          }
        }
        var request = {
          location: pyrmont,
          radius: metersPerMile * props.distanceInMiles,
          type: [type],
        }

        if (price && price.length > 0 && isCompetitor) {
          addPricesToRequest(price, request)
          setFilterType(type.length)
        } else if (price && price.length == 0) {
          setFilterType(0)
        }

        var service = new mapsRef.current.places.PlacesService(mapRef.current)
        service.textSearch(request, function (results, status, pagination) {
          console.log(pagination.hasNextPage);
          if (status == "OK") {
            results=results.filter(function(result){
              return result.place_id!==props.place;
            });
            results = results.filter(function (obj) {
              return !savedFencesContains(obj.place_id);
            });
            newResults[type] = results;
            if (isCompetitor) {
              newResults['competitors'] = results
            }
            setPlacesResults(newResults)
            resultsToMarkers(results, map, infoWindows, false)
            if (pagination && pagination.hasNextPage) {
              console.log(pagination)
              setPagination(pagination)
            } else {
              setPagination(null)
            }
          }
        })
      } else if (
        lastExpanded != type[0] &&
        type &&
        type != undefined &&
        type.length > 0 &&
        placesResults[type[0]].length > 0
      ) {
        resultsToMarkers(placesResults[type[0]], map, infoWindows, false)
      }
      setLastExpanded(type[0])
    }
  }
  const distance = (lon1, lat1, lon2, lat2) => {
    const R = 6371e3 // metres
    const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180
    const Δφ = ((lat2 - lat1) * Math.PI) / 180
    const Δλ = ((lon2 - lon1) * Math.PI) / 180

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
  }
  const byDistance = (a, b) => {
    if (a.distance < b.distance) {
      return -1
    }
    if (a.distance > b.distance) {
      return 1
    }
    return 0
  }
 let storeOther = [];
  const otherCall = (service,types,map,count=0) =>{
    document.getElementById('loading').style.display='block';
    if(types.length>count){
      var metersPerMile = 1609.34;
      var pyrmont = mapRef.current.getCenter();
      if(fencePlace&&fencePlace.geometry&&fencePlace.geometry.location){
        pyrmont = {lat:parseFloat(fencePlace.geometry.location.lat()),lng:parseFloat(fencePlace.geometry.location.lng())};
      }
      var request = {
        location: pyrmont,
        radius: metersPerMile * props.distanceInMiles,
        type: [types[count]],
      }

      service.textSearch(request, function (results, status, pagination) {
        console.log(pagination.hasNextPage);
        if (status == "OK") {
          results=results.filter(function(result){
            let dist = distance(result.geometry.location.lng(),result.geometry.location.lat(),pyrmont.lng,pyrmont.lat);
            result.distance=dist;
            return result.place_id!==props.place;
          });
          storeOther = [...storeOther,...results];
          otherCall(service,types,map,++count);
        };
      });
   }else{
    //let newResults = { ...placesResults };
    storeOther = storeOther.filter(function (obj) {
      return !savedFencesContains(obj.place_id);
    });
     storeOther.sort(byDistance);
    // newResults["other"] = storeOther;
    // setPlacesResults(newResults);
    resultsToMarkers(storeOther, map, infoWindows, false);
    setOtherResults(storeOther);
    document.getElementById('loading').style.display='none';
   }
  }
  const otherSearch = () => {
    if (mapRef && mapsRef && mapRef.current && otherResults.length == 0) {
      console.log('type:' + type)
      const newMarkers = []
      var map = mapRef.current
      setPagination(null)
      var types = [
        'movie_theater',
        'museum',
        'library',
        'post_office',
        'subway_station',
      ]
      var service = new mapsRef.current.places.PlacesService(mapRef.current)
      otherCall(service, types, map)
    }
  }
  function resultsToMarkers(results, map, infowindows, preSelected) {
    var clearedMarkers = Object.assign({}, markers)
    for (var placeId in markers) {
      if (!selected2.includes(placeId) && !preSelected) {
        clearedMarkers[placeId].setMap(null)
        delete clearedMarkers[placeId]
      } else {
        //console.log(placeId + " found in selected");
      }
    }

    results.forEach((place) => {
      if (!selected2.includes(place.place_id)) {
        var iconMarker = competitorMarker
        if (preSelected) {
          //setSelected2([...selected2,place.place_id]);
          iconMarker = selectedMarker
        }
        clearedMarkers[place.place_id] = new mapsRef.current.Marker({
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          map,
          zIndex: 999,
          icon: iconMarker,
          optimized: false,
        })

        if (preSelected) {
          infowindows[place.place_id] = new mapsRef.current.InfoWindow({
            content: getInfoWindowString(place) + `</div>`,
          })
        } else {
          infowindows[place.place_id] = new mapsRef.current.InfoWindow({
            content:
              getInfoWindowString(place) +
              getInfoWindowString2(place) +
              `</div>`,
          })
        }

        clearedMarkers[place.place_id].addListener('click', () => {
          closeAllInfoWindows()
          infowindows[place.place_id].open(map, clearedMarkers[place.place_id])
        })
      }
    })

    setMarkers(clearedMarkers)
  }
  const setManualResults = (manualResults) => {
    var maps = mapsRef.current
    var map = mapRef.current
    //var joined = selected.concat(manualResults);
    //setSelected2([...selected2,manualResults]);
    //setCountTwice(count+1);
    //setManualCount(manualCount+1);
    var request = {
      placeId: manualResults,
    }
    var service = new maps.places.PlacesService(map)
    service.getDetails(request, manualCallback)
  }

  const removeManual = (place_id) => {
    let newSavedArray = savedGeofences.filter(function (obj) {
      return obj.place_id !== place_id
    })
    setSavedGeofences(newSavedArray)
    props.setFences(newSavedArray)
    localStorage.setItem('storedData', JSON.stringify(newSavedArray))
    console.log(place_id)
    console.log(newSavedArray)
    var clearedMarkers = Object.assign({}, markers)
    for (var placeId in markers) {
      if (placeId == place_id) {
        clearedMarkers[placeId].setMap(null)
        delete clearedMarkers[placeId]
      }
    }
    setMarkers(clearedMarkers)
  }
  const [marker, setMarker] = useState()
  function centerMap(latLng, type) {
    const centerMarker = new window.google.maps.MarkerImage(
      markericon,
      null,
      null,
      null,
      new window.google.maps.Size(60, 60),
    )
    centerMarker.optimized = false
    centerMarker.zIndex = 99999999999
    if (marker && marker.map) {
      marker.setMap(null)
    }
    setMarker(
      new mapsRef.current.Marker({
        url:
          'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
        map: mapRef.current,
        position: latLng,
        icon: centerMarker,
        optimized: false,
      }),
    )

    mapRef.current.setCenter(latLng)
    var legend = document.getElementById('legend')
    mapRef.current.controls[mapsRef.current.ControlPosition.BOTTOM_LEFT].push(
      legend,
    )
  }
  function callback(place2, status) {
    setFencePlace(place2)
    if (place2 && place2.name) {
      setPlaceHolder(place2.name)
      setDefaultValue(place2.name)
    }
    if (place2 && place2.formatted_address) {
      props.setAddress(place2.formatted_address)
    }

    if (
      status == mapsRef.current.places.PlacesServiceStatus.OK &&
      place2 &&
      place2.geometry
    ) {
      props.setLatLngPair([
        place2.geometry.location.lng(),
        place2.geometry.location.lat(),
      ])
      centerMap(place2.geometry.location, type)
      if (place2.price_level) {
        props.setPriceLevel(place2.price_level.toString())
        compSearch3(place2, place2.price_level)
      } else {
        compSearch3(place2)
      }
    } else {
      console.log(status)
    }
  }
  const idInObjectArray = (id, ObjectArray) => {
    let out = false
    for (var cand in ObjectArray) {
      if (ObjectArray[cand].place_id == id) {
        return true
      }
    }
    return out
  }

  React.useEffect(() => {
    console.log(selected2)
  }, [selected2])

  function manualCallback(place3, status) {
    const infowindows = []
    var map = mapRef.current
    var updated = [...selected2, place3.place_id]
    setSelected2(updated)
    if (!idInObjectArray(place3.place_id, savedGeofences)) {
      let newSaved = {
        place_id: place3.place_id,
        name: place3.name,
        formatted_address: place3.formatted_address,
        location: place3.geometry.location,
        type:
          place3.types && place3.types.length > 0 ? place3.types[0] : 'manual',
      }
      let newSavedArray = _.cloneDeep(savedGeofences)
      //console.log(savedGeofences);
      //console.log(newSavedArray);
      newSavedArray.push(newSaved)
      setSavedGeofences(newSavedArray)
      props.setFences(newSavedArray)
      localStorage.setItem('storedData', JSON.stringify(newSavedArray))
      // console.log(newSavedArray);
    }
    resultsToMarkers([place3], map, infowindows, true)
    var elements = document.querySelectorAll(
      '*[id^=' +
        CSS.escape(place3.place_id) +
        ']:not([id$="saved"]):not([id$="savedrow"])',
    )
    let esplit = elements[0].id.split('_')
    var linenumber = esplit[esplit.length - 1]
    let row = document.getElementById(linenumber + '_row')
    if (row) {
      row.style.display = 'none'
    }
    let newArray = [...checkedBoxes, place3.place_id]
    setCheckedBoxes(newArray)
    infoWindows[place3.place_id] = new mapsRef.current.InfoWindow({
      content:
        getInfoWindowString(place3) + getInfoWindowString2(place3) + `</div>`,
    })
    countCallback(newArray)
  }
  const objFilter = (obj1, obj2Array) => {
    for (const obj2 of obj2Array) {
      if (obj1.place_id == obj2.place_id) {
        return false
      }
    }
    return true
  }
  function countCallback(count) {
    // if(count.length+manualCount>planLimit){
    //   alert("Too many geofences selected for your current plan!  You will not be able to save your geofences until you select a bigger plan or unselect some of your choices.");
    // }
    var filteredSaved = savedGeofences.filter((item) => {
      if (!count.includes(item.place_id)) {
        return item
      }
    })

    var filterSaved2 = filteredSaved.filter((item) => {
      if (objFilter(item, count)) {
        return item
      }
    })

    setCountTwice(count.length + manualCount + filterSaved2.length)
    setSelected2((selected2) => [...selected2, ...count])

    for (var placeId in markers) {
      if (count.includes(placeId) || isInCount(count, placeId)) {
        markers[placeId].setIcon(selectedMarker)
        //console.log(infoWindows[placeId].content);
        var cont = infoWindows[placeId].getContent()
        var contR = cont.replace("type='checkbox'", "type='checkbox' checked")
        infoWindows[placeId].setContent(contR)
      } else {
        markers[placeId].setIcon(competitorMarker)
        if (infoWindows[placeId]) {
          var cont = infoWindows[placeId].getContent()
          var contR = cont.replaceAll('checked', '')
          infoWindows[placeId].setContent(contR)
        }
      }
    }
  }
  const isInCount = (count, placeId) => {
    let out = false
    if (count && Array.isArray(count)) {
      for (const c in count) {
        if (count[c].place_id) {
          if (count[c].place_id == placeId) {
            return true
          }
        }
      }
    }

    return out
  }
  const show = (category) => {
    var boxLabel = category
    //if(category=='competitors'&&centroidtype){
    //   var type = centroidtype.replace("_", " ");
    //   boxLabel = 'Competitor-'+type;
    // }
    if (boxLabel == 'competitors') {
      setCompetitorsButton(competitorsb)
      setShoppingButton(shoppingg)
      setParksButton(parksg)
      setOtherButton(otherg)
      setAddButton(addg)
    }
    if (boxLabel == 'shopping') {
      setShoppingButton(shoppingb)
      setCompetitorsButton(competitorsg)
      setParksButton(parksg)
      setOtherButton(otherg)
      setAddButton(addg)
    }
    if (boxLabel == 'parks') {
      setParksButton(parksb)
      setCompetitorsButton(competitorsg)
      setShoppingButton(shoppingg)
      setOtherButton(otherg)
      setAddButton(addg)
    }
    if (boxLabel == 'other') {
      setOtherButton(otherb)
      setCompetitorsButton(competitorsg)
      setShoppingButton(shoppingg)
      setParksButton(parksg)
      setAddButton(addg)
    }
    if (boxLabel == 'manually added target zones') {
      setAddButton(addb)
      setCompetitorsButton(competitorsg)
      setShoppingButton(shoppingg)
      setParksButton(parksg)
      setOtherButton(otherg)
    }
    setBoxLabel(boxLabel)
  }

  React.useEffect(() => {
    if (mapRef.current) {
      _onZoom()
    }
  }, [props.distanceInMiles])

  function locationToFence(map, maps) {
    if (!place.includes(',')) {
      var request = {
        placeId: place,
      }
      var service = new maps.places.PlacesService(map)
      service.getDetails(request, callback)
    } else {
      var latlngarray = place.split(',')
      props.setLatLngPair(latlngarray)
      var latLng = new maps.LatLng(latlngarray[1], latlngarray[0])
      centerMap(latLng, type)
      var place3 = {
        geometry: {
          location: {
            lat: function () {
              return latlngarray[1]
            },
            lng: function () {
              return latlngarray[0]
            },
          },
        },
      }
      setFencePlace(place3)
      if (type && type.length > 0) {
        compSearch()
      } else {
        populateSavedIconsNonCompetitors()
      }
    }

    let resultsString = localStorage.getItem('storedData') || '[]'
    let results = JSON.parse(resultsString)
    const newMarkers = []
    var map = mapRef.current
    for (const place2 of results) {
      let iconType = selectedMarker
      let maps = mapsRef.current
      newMarkers[place2.place_id] = new maps.Marker({
        position: {
          lat: place2.location.lat,
          lng: place2.location.lng,
        },
        map,
        zIndex: 999,
        icon: iconType,
        optimized: false,
      })
      infoWindows[place2.place_id] = new mapsRef.current.InfoWindow({
        content:
          getInfoWindowString(place2) + getInfoWindowString3(place2) + `</div>`,
      })

      newMarkers[place2.place_id].addListener('click', () => {
        closeAllInfoWindows()
        infoWindows[place2.place_id].open(map, newMarkers[place2.place_id])
      })
    }

    window.google.maps.event.addListener(map, "click", function(event) {
      closeAllInfoWindows()
  });

    setMarkers(newMarkers)
  }
  function locationToFence2(place2, map, maps) {
    if (!place2.includes(',')) {
      var request = {
        placeId: place2,
      }
      var service = new maps.places.PlacesService(map)
      service.getDetails(request, callback)
    } else {
      var latlngarray = place2.split(',')
      props.setLatLngPair(latlngarray)
      var latLng = new maps.LatLng(latlngarray[1], latlngarray[0])
      centerMap(latLng, type)
      var place3 = {
        geometry: {
          location: {
            lat: function () {
              return latlngarray[1]
            },
            lng: function () {
              return latlngarray[0]
            },
          },
        },
      }
      setFencePlace(place3)
      if (type && type.length > 0) {
        compSearch3(place3)
      } else {
        populateSavedIconsNonCompetitors()
      }
    }
  }
  const [checkedBoxes, setCheckedBoxes] = useState([])
  const deleteObjectArray = (id, ObjectArray) => {
    let out = false
    for (var cand in ObjectArray) {
      if (ObjectArray[cand].place_id == id) {
        ObjectArray.splice(cand, 1)
      }
    }
    props.setFences(ObjectArray);
    localStorage.setItem("storedData", JSON.stringify(ObjectArray));
    return out;
}
const clearAll = () => {
  localStorage.removeItem("storedData");
  setSavedGeofences([]);
  setCheckedBoxes([]);
  countCallback([]);
  setSelected2([]);

  document.getElementById("otherbutton").click();
}
const downloadCSV = (args) => {  

  let filename = args.filename || 'export.csv';
  let columns = args.columns || null;

  let csv = Papa.unparse({ data: args.data, fields: columns})
  if (csv == null) return;

  var blob = new Blob([csv]);
  if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, args.filename);
  else
  {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
      a.download = filename;
      document.body.appendChild(a);
      a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
  }
}
  const exportFences = () =>{
    console.log(savedGeofences);
    let fenceCsvs = savedGeofences.map((fence)=>{
      return {"Name":fence.name,"Address":fence.formatted_address};
    });
    downloadCSV({ 
      filename: "Geophenom Active Campaign Geofences.csv",
      data: fenceCsvs,
      columns: ["Name","Address"]
    });
  }
  const HandleSavedCheckbox = (e, name, address) => {
    console.log('handled saved')
    var withoutSaved = e.target.id.split('_saved')[0]
    var elements = document.querySelectorAll(
      '*[id^=' +
        CSS.escape(withoutSaved) +
        ']:not([id$="saved"]):not([id$="savedrow"])',
    )
    if (elements.length > 0) {
      if (elements[0].id.split('_').length > 0) {
        let pieces = elements[0].id.split('_')
        let rowid = pieces[pieces.length - 1]
        let mrow = document.getElementById(rowid + '_row')
        if (mrow) {
          mrow.style.display = 'flex'
        }
      }
    }
    let row = document.getElementById(withoutSaved + '_row')
    if (row) {
      row.style.display = 'flex'
    }
    let rowSaved = document.getElementById(withoutSaved + '_savedrow')
    if (rowSaved) {
      rowSaved.style.display = 'none'
    }
    let newArray = savedGeofences.filter(
      (q) => q.place_id != e.target.id.split('_saved')[0],
    )
    let newCheckboxes = checkedBoxes.filter(
      (r) => r != e.target.id.split('_saved')[0],
    )
    setCheckedBoxes(newCheckboxes)
    setSavedGeofences(newArray)
    countCallback(newArray)
    var element = document.getElementById(withoutSaved)
    if (element && element.checked == true) {
      // element.click();
      element.checked = false
    } else {
      deleteObjectArray(withoutSaved, savedGeofences) //should only work if saved fence is no where else in the accordion
    }
  }

  const elementMapView = document.getElementById('map-view')
  const elementCheckedList = document.getElementById('checked-list')
  const elementUncheckList = document.getElementById('uncheck-list')

  const elementSelectedGeo = document.getElementById('selectedGeo')

  const elementAvailBtn = document.getElementById('avail-geo')
  const elementSelectedBtn = document.getElementById('selected-geo')

  const elementSelectHide = document.getElementById('mapListId')
  const elementmapToggle = document.getElementById('mapToggle')
  const elementListToggle = document.getElementById('listToggle')

  const elementSelectListToggle = document.getElementById('listSelectedToggle')
 
  

  const handleAvailMethod = () => {
    elementMapView.classList.add('NewStyleAdded1')
    elementCheckedList.classList.remove('NewStyleAdded2')
    elementUncheckList.classList.remove('NewStyleAdded3')

    elementAvailBtn.classList.add('selBtn-Highlight')
    elementSelectedBtn.classList.remove('selBtn-Highlight')

    elementSelectedGeo.classList.add('hide-div')
    elementSelectedGeo.classList.remove('show-div')
    elementSelectHide.classList.remove('progDesk')
    elementmapToggle.classList.add('active')
    elementListToggle.classList.remove('active')

    elementSelectListToggle.classList.add('progDesk')
    elementListToggle.classList.remove('progDesk')
  }

  const handleSelected = () => {
    elementMapView.classList.add('NewStyleAdded1')
    elementCheckedList.classList.remove('NewStyleAdded2')
    elementUncheckList.classList.remove('NewStyleAdded3')
    elementMapView.classList.add('NewStyleAdded')


    elementSelectedBtn.classList.add('selBtn-Highlight')
    elementAvailBtn.classList.remove('selBtn-Highlight')

    elementSelectedGeo.classList.add('hide-div')
    elementSelectedGeo.classList.remove('show-div')

    elementmapToggle.classList.add('active')
    elementListToggle.classList.remove('active')

    elementListToggle.classList.add('progDesk')
    elementSelectListToggle.classList.remove('progDesk')
    elementSelectListToggle.classList.remove('active')
    

  
    
  }

  const handleListView = () => {
    elementMapView.classList.remove('NewStyleAdded1')
    elementCheckedList.classList.remove('NewStyleAdded2')
    elementUncheckList.classList.add('NewStyleAdded3')
    elementMapView.classList.add('NewStyleAdded')

    elementSelectedGeo.classList.add('hide-div')
    elementSelectedGeo.classList.remove('show-div')
    
  }

  const handleMapView = () => {
    elementMapView.classList.add('NewStyleAdded1')
    elementCheckedList.classList.remove('NewStyleAdded2')
    elementUncheckList.classList.remove('NewStyleAdded3')

    elementSelectedGeo.classList.add('hide-div')
    elementSelectedGeo.classList.remove('show-div')
   
  }

  // 4) render map
  return (
    <>
      <div style={{ marginTop: '0px', height: '100%' }} className="col-md-4">
        <div
          className="row mobile-display"
          style={{ marginTop: '7.5%', marginBottom: '5%' }}
        >
          <div
            className="col-md-8"
            style={{ fontSize: '30px', fontWeight: 'bold', color: '#333f57' }}
          >
            Get Started Now.
          </div>
          <div className="col-md-4">
            <img style={{ width: '100%' }} src={arrow}></img>
          </div>
        </div>
        <div
          className="row mobile-display"
          style={{ marginBottom: '2%', fontSize: 'medium', color: '#333f57' }}
        >
          <div
            style={{ paddingRight: '0px' }}
            className="col-md-1 text-md-left text-center"
          >
            <img style={{}} width="65%" src={one} />
          </div>
          <div className="col-md-11 text-md-left text-left">
            <span
              style={{
                lineHeight: '18px',
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#333f57',
              }}
            >
              Search Targeted Zones
            </span>
          </div>
          <div className='row'>	
              <div className='col-md-1'></div>	
              <div style={{'lineHeight':'18px','marginLeft':'17%','color':'grey','fontSize':'12px','fontWeight':'500'}} className='col-md-12'>	
                (By address, competitor's name, local landmarks and more.)	
              </div>	
            </div>
        </div>
        <div
          className="row mobile-display"
          style={{ marginBottom: '2%', fontSize: 'medium', color: '#333f57' }}
        >
          <div
            style={{ paddingRight: '0px' }}
            className="col-md-1 text-md-left text-left"
          >
            <img style={{}} width="65%" src={two} />
          </div>
          <div className="col-md-11 text-md-left text-left">
            <span
              style={{
                lineHeight: '18px',
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#333f57',
              }}
            >
              Select Your Targets
            </span>{' '}
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div
              style={{
                lineHeight: '18px',
                marginLeft: '18%',
                color: 'grey',
                fontSize: '12px',
                fontWeight: '500',
              }}
              className="col-md-10"
            >
              (Click the icons on the map to add or delete locations.)
            </div>
          </div>
        </div>

        <div
          className="row mobile-display"
          style={{ marginBottom: '5%', fontSize: 'medium', color: '#333f57' }}
        >
          <div
            style={{ paddingRight: '0px' }}
            className="col-md-1 text-md-left text-center"
          >
            <img style={{}} width="65%" src={three} />
          </div>
          <div className="col-md-11 text-md-left text-left">
            <span
              style={{
                lineHeight: '18px',
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#333f57',
              }}
            >
              Click Save & Continue
            </span>
          </div>
          <div className="row">
            <div
              style={{
                lineHeight: '18px',
                marginLeft: '24%',
                color: 'grey',
                fontSize: '12px',
                fontWeight: '500',
                padding: '0px',
              }}
              className="col-md-10"
            >
              (To complete your campaign and start generating business.)
            </div>
          </div>
        </div>

        <div className="row subCategory-spacing">
        
          <div className="col-12">
            <div className="section-heading text-center responsive-display">
              <h2 className="sec__title ad ad-des-title">
                Categories & Geofences <img style={{height:'22px'}} src={markericon3} />
              </h2>
            </div>
            </div>
          <div className="col-12">
          <div className="MobBtn" style={{ position: 'relative' }}>
            <button
              id="avail-geo"
              onClick={handleAvailMethod}
              className="res-block adBtn-Highlight selBtn-Highlight"
              style={{ position: 'relative', zIndex: '998', height: '40px' }}
            >
              Available geofences
            </button>
            <button
              id="selected-geo"
              onClick={handleSelected}
              className="res-block adBtn-Highlight"
              style={{ position: 'relative', zIndex: '998', height: '40px' }}
            >
              Selected
            </button>
          </div>
            <PlaceScrollBox
              campaign={props.campaign}
              pagination={pagination}
              priceLevel={props.priceLevel}
              setPriceLevel={props.setPriceLevel}
              setSaved={props.setFences}
              countCallback={countCallback}
              accordionItems={placesResults}
              otherResults={otherResults}
              setMR={setManualResults}
              searchType={type}
              defaultPrice={props.priceLevel}
              compSearch={accordSearch}
              otherSearch={otherSearch}
              planLimit={planLimit}
              setSavedGeofences={setSavedGeofences}
              savedGeofences={savedGeofences}
              prepopulated={prepopulated}
              boxLabel={boxLabel}
              setBoxLabel={setBoxLabel}
              removeManual={removeManual}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              handleMapView={handleMapView}
              setSelected2={setSelected2}
            />
          </div>
        </div>
        <div style={{ width: '35%', background: '#fff' }} id="legend">
          <img style={{ width: '5%' }} src={markericon} /> Your Location (if
          applicable)&nbsp;
          <img style={{ width: '3%' }} src={markericon2} /> Selected
          Targets&nbsp;
          <img style={{ width: '3%' }} src={markericon3} /> Available
          Targets&nbsp;
        </div>
      </div>

      <div style={{ marginTop: '0px' }} className="col-md-8">
        <MapViewSearchInput
          mapSearch={goToMapSearch}
          count={count}
          plan={planLimit}
          setType={setType}
          defaultValue={defaultValue}
          placeHolder={placeHolder}
        />

        <div
          id="map-view"
          className="map-height"
          style={{ position: 'relative' }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAPS_KEY,
              libraries: ['places', 'geometry'],
            }}
            defaultCenter={{ lat: 52.6376, lng: -1.135171 }}
            options={{ gestureHandling: 'greedy' }}
            mapId="cd65f724b7192fcc"
            defaultOptions={{
              disableDefaultUI: false, // disable default map UI
              draggable: true, // make map draggable
              keyboardShortcuts: false, // disable keyboard shortcuts
              scaleControl: true, // allow scale control
              scrollwheel: true, // allow scroll wheel
              mapId: 'cd65f724b7192fcc',
              styles: [
                {
                  featureType: 'road',
                  stylers: [{ color: '#ffffff' }],
                },
                {
                  featureType: 'water',
                  stylers: [{ color: '#e9e9e9' }],
                },
                {
                  featureType: 'landscape',
                  stylers: [{ color: '#f5f5f5' }],
                },
                {
                  elementType: 'labels.text.fill',
                  stylers: [{ color: 'transparent' }],
                },
                {
                  featureType: 'poi',
                  stylers: [{ color: '#fefefe' }],
                },
                {
                  elementType: 'labels.text',
                  stylers: [
                    { saturation: 1 },
                    { weight: 0.1 },
                    { color: '#737980' },
                  ],
                },
              ],
              //icon: markericon
            }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              //map.setClickableIcons(false);
              mapRef.current = map
              mapsRef.current = maps
              locationToFence(mapRef.current, mapsRef.current)
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom)
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ])
            }}
            onZoomAnimationEnd={() => _onZoom()}
          ></GoogleMapReact>
          
            
        </div>
        <div
          id="selectedGeo"
          className={
            useIsMobile ? 'hide-div selectedGeoFence' : 'selectedGeoFence'
          }
          style={{
            textAlign: 'center',
            height: '4%',
            fontSize: '22px',
            backgroundColor: '#333F57',
            color: 'white',
            textTransform: 'capitalize',
            padding: '0px 0px 0px 0px',
          }}
        >
          <div
            style={{
              padding: '4px 0px 0px 0px',
              alignItems: 'center',
            }}
          >
             Geofences - {savedGeofences.length} Saved{' '}
            <img height="22px" src={markericon2} />{' '}
            <span className="selected-style">
            {savedGeofences && savedGeofences.length > 0 && (
              
              <button
                style={{
                  backgroundColor: '#333F57',
                  border: '0px',
                  padding: '0px 5px',
                  marginRight: '5px',
                }}
                onClick={(e) => clearAll()}
                type="button"
              >
                Clear All
                <img
                  style={{ marginLeft: '5px' }}
                  width="14px"
                  src={deleteSelect}
                />
              </button>
            )}
            {savedGeofences && savedGeofences.length > 0 && (
              <button
                style={{
                  backgroundColor: '#333F57',
                  border: '0px',
                  padding: '0px 5px',
                }}
                onClick={(e) => exportFences()}
                type="button"
              >
                Export
                <img
                  style={{ marginLeft: '5px' }}
                  width="13px"
                  src={exportSelect}
                />
              </button>
              
            )}{' '}
            </span>
          </div>
        </div>
        <div
          id="checked-list"
          style={{ fontSize: '12px', lineHeight: '16px', height: '14%', paddingTop:'5px',overflowX: 'auto' }}
          className="scrollbox container results NewStyleAdded"
        >
          <div className="meh interior" style={{ overflowY: 'auto' }}>
            {savedGeofences.map((item, i) => {
              return (
                <div
                  style={{
                    marginBottom: '5px',
                    breakInside: 'avoid',
                    pageBreakInside: 'avoid',
                  }}
                  className="row"
                  id={item.place_id + '_savedrow'}
                  key={item.place_id}
                >
                  <div
                    className="col-1"
                    style={{ paddingRight: 0, marginBottom: '-4px' }}
                  >
                    <input
                      style={{}}
                      type="checkbox"
                      id={item.place_id + '_saved'}
                      defaultChecked={true}
                      onChange={(e) =>
                        HandleSavedCheckbox(
                          e,
                          item.name,
                          item.formatted_address,
                        )
                      }
                    />
                  </div>
                  <div
                    className="col-11"
                    style={{ paddingLeft: 0, marginBottom: '-4px' }}
                  >
                    <label htmlFor={item.place_id}>
                      {' '}
                      {item.name + ' - ' + item.formatted_address}
                    </label>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div >
          <div>
            {!props.campaignId &&(
              <div>
                <div>
                <ul class="progresBar">
                  <li class="active"><a><img  src={locationMobile} style={{ height: '15px' }} /></a></li>
                  <li ><a><img  src={brushMobile} style={{ height: '15px' }} /></a></li>
                  <li><a><img  src={magnifierMobile} style={{ height: '15px' }} /></a></li>
                  <li><a><img  src={tickMobile} style={{ height: '15px' }} /></a></li>
                  <li class="active"><a onClick={(e) => props.saveAndContinue()}><img  src={continueMobile} style={{ height: '15px' }} /></a></li>
                </ul>
                </div>
              </div>
            )}
          </div>

          <div>
            {props.campaignId &&(
              <div>
                <div>
                <ul class="progresBar">
                  <li class="active"><a><img  src={locationMobile} style={{ height: '15px' }} /></a></li>
                  <li ><a><img  src={brushMobile} style={{ height: '15px' }} /></a></li>
                  <li><a><img  src={magnifierMobile} style={{ height: '15px' }} /></a></li>
                  <li><a><img  src={tickMobile} style={{ height: '15px' }} /></a></li>
                  <li class="active"><a onClick={(e) => props.handleSubmitEdit(true)}><img  src={continueMobile} style={{ height: '15px' }} /></a></li>
                </ul>
                </div>
              </div>
            )}
          </div>

          
        </div>
      </div>
    </>
  )
}