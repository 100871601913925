import {React,useEffect} from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Banner9 from "../components/banner/banner8/Banner9";
import ImageBox from "../components/about/ImageBox";
import About1 from "../components/about/About1";
import FunFactsThree from "../components/other/funfacts/FunFactsThree";
import {Link} from "react-router-dom";
import { FiPlus } from 'react-icons/fi'
import SectionsHeading from "../components/common/SectionsHeading";
import Testimonial from "../components/sliders/Testimonial";
import HowItWork4 from "../components/hiw/HowItWork4";
import TeamOne from "../components/team/TeamOne";
import Button from "../components/common/Button";
import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function RealEstateRecruiting() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="about-page">
            {/* Header */}
            <GeneralHeader />

            {/* Banner */}
            <Banner9 title="Attract New Agents to Your Brokerage in Minutes" subtitle="Reach New and Experienced Sales Associates with Mobile Location-Based Advertising" />


            <section className="about-area sect-padding-top">
                <div >
                    <About1 aboutcontent={sectiondata.aboutdata.realestaterecruiting} />
                </div>
            </section>










            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default RealEstateRecruiting;
