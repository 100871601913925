import React from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Banner8 from "../components/banner/banner8/Banner8";
import ImageBox from "../components/about/ImageBox";
import About5 from "../components/about/About5";
import About7 from "../components/about/About7";
import FunFactsThree from "../components/other/funfacts/FunFactsThree";
import {Link} from "react-router-dom";
import { FiPlus } from 'react-icons/fi'
import SectionsHeading from "../components/common/SectionsHeading";
import Testimonial from "../components/sliders/Testimonial";
import HowItWork4 from "../components/hiw/HowItWork4";
import TeamOne from "../components/team/TeamOne";
import Button from "../components/common/Button";
import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function Why() {
    return (
        <main className="about-page">
            {/* Header */}
            <GeneralHeader />

            {/* Banner */}
            <Banner8 title="Why Use Geofencing" />


            <section className="about-area sect-padding-top">

                <About7 aboutcontent={sectiondata.aboutdata.aboutst5} />

            </section>



            {/* Footer */}
            <Footer />

            <ScrollTopBtn />

        </main>
    );
}

export default Why;
