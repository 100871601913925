import React from 'react';
import GeneralHeader from "../components/common/GeneralHeader";
import Banner5 from "../components/banner/banner5/Banner5";
import About7 from "../components/about/About7";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";

function About() {
    return (
        <main className="about-page">
        {/* Header */}
        <GeneralHeader />

        {/* Banner */}
        <Banner5 />


        <section className="about-area sect-padding-top">
            <div style={{marginTop:'-10%'}} >
                <About7 aboutcontent={sectiondata.aboutdata.aboutus} />
            </div>
        </section>




        {/* Footer */}
        <Footer />

        <ScrollTopBtn />

    </main>
    );
}

export default About;
