import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
    FaBehance, FaCogs,
    FaDribbble,
    FaFacebookF, FaInstagram,
    FaLinkedinIn, FaMinus, FaPlus, FaQuoteRight, FaRegComment, FaRegEdit,
    FaRegEnvelope, FaRegHeart, FaRegHospital, FaRegMap,
    FaRegMoneyBillAlt, FaRegTrashAlt, FaSearchDollar, FaSearchPlus, FaSort, FaStethoscope,
    FaTwitter, FaYoutube
} from "react-icons/fa";
import _ from "lodash";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import GooglePlacesAutocomplete from '../GooglePlacesAutocomplete';
import _uniqueId from 'lodash/uniqueId';
import WidgetFilterTagsPrice from '../sidebars/widgets/WidgetFilterTagsPrice'
import sectiondata from "../../store/store";
import { IDENTITY_USER_TOKEN_KEY } from '../data/constants';
import { refresh } from '../common/TokenRefresh';
import other from "../../assets/images/Show-Other-button.svg";
import parks from "../../assets/images/Show-Parks-button.svg";
import shopping from "../../assets/images/Show-Shopping-button.svg";
import competitors from "../../assets/images/Show-Competitors-button.svg";
import markericon3 from '../../assets/images/Available-Targets-Icon-gray.svg'
import competitorsb from "../../assets/images/Competitors-button-selected-blue.svg";
import competitorsg from "../../assets/images/Competitors-button-gray.svg";
import shoppingb from "../../assets/images/Shopping-button-selected-blue.svg";
import shoppingg from "../../assets/images/Shopping-button-gray.svg";
import parksb from "../../assets/images/Parks-button-selected-blue.svg";
import parksg from "../../assets/images/Parks-button-gray.svg";
import otherb from "../../assets/images/Other-button-selected-blue.svg";
import otherg from "../../assets/images/Other-button-gray.svg";
import addb from "../../assets/images/Add-Custom-button-selected-blue.svg";
import addg from "../../assets/images/Add-Custom-button-gray.svg";
import mapViewMobile from '../../assets/images/Map-View-Button-mobile.svg';

import {
    FaTrashAlt
} from "react-icons/fa";


function Child(props) {
    const [manualDisabled, setManualDisabled] = useState(true);
    const { caption, ma, hc, chcboxValue,place_id,staticAddress,lineId,removeManual } = props;
    const { lines, setLines } = props.pstate;
    const styles = {
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#40CC6F" : null,
            color: "black"
        })
    };

    const ddd=(address,caption,lineId)=>{
        setManualDisabled(false);
        ma(address,caption,lineId);
    };
    const removeManualChild=(lineId)=>{
        var result = lines.find(obj => {
            return obj.lineId === lineId
          })
        removeManual(result.place_id);
        let newLines = lines.filter(function(line){
            return line.place_id!==result.place_id;
        });
        setLines(newLines);

    };



    //const handleManualCheckbox = (e, targetId,name, address, location, uuid)

    return <div style={{border:'none'}} className={'card ' + 'mb-3'} key={lineId + 'manual'}>
        <div className="row" id={lineId+'_row'} key={lineId+'_row'} onChange={e => hc(e)}>
        <div className='col-1' style={{paddingRight:0}}> <input type="checkbox" id={lineId+'_chk'} defaultChecked={false} disabled={manualDisabled} /></div>
        <div className='col-11' style={{paddingLeft:0,marginTop:'-2px'}}><label style={{ marginBottom: '4px' }} htmlFor={lineId+'_chk'}> {'Manually Entered Address ' + (parseInt(caption) + 1)}</label>
            {!staticAddress&&<GooglePlacesAutocomplete
                selectProps={{
                    onChange: (address) => ddd(address, caption,lineId),
                    styles: styles,
                    placeholder:'Start typing to see location options'
                }}

            />}
            {staticAddress&&place_id&&<div id={place_id+"_"+lineId}> {staticAddress}<FaTrashAlt onClick={() => removeManualChild(lineId)} /></div>}
        </div>
        </div>
    </div>

    {/*                        <div style={{marginBottom:'5px'}} className="row" key={item.place_id} onChange={(e) => handleCheckbox(e, item.name, item.formatted_address, item.geometry.location, "park")} >
                             <div className='col-1' style={{paddingRight:0}}><input style={{marginTop:'-2px'}} type="checkbox" defaultChecked={checkedBoxes.includes(item.place_id)} id={item.place_id} /></div>
                             <div className='col-11' style={{paddingLeft:0,marginTop:'-2px'}}><label style={{}} htmlFor={item.place_id}>
                             <span>{item.name+' - ' + item.formatted_address}</span>
                            </label>
                            </div>
                        </div>
            */}

}

function PlacesResults(competitors, park, school, shopping_mall, gas_station, accounting, gym, convenience_store, real_estate_agency, restaurant, lawyer, hair_care, manual, other) {
    this.competitors = competitors;
    this.park = park;
    this.school = school;
    this.shopping_mall = shopping_mall;
    this.gas_station = gas_station;
    this.other = other;
    this.accounting = accounting;
    this.gym = gym;
    this.convenience_store = convenience_store;
    this.real_estate_agency = real_estate_agency;
    this.restaurant = restaurant;
    this.lawyer = lawyer;
    this.hair_care = hair_care;
    this.manual = manual;
};

function PlaceScrollBox({ boxLabel,setBoxLabel, campaign, pagination, priceLevel, setPriceLevel, countCallback, accordionItems,otherResults, setMR, searchType, compSearch,otherSearch, planLimit, defaultPrice, setSaved, setSavedGeofences, savedGeofences, prepopulated,removeManual,checkedBoxes,setCheckedBoxes,setSelected2, handleMapView }) {
    const [manualAddress, setManualAddress] = useState(null);
    const [chcboxValue, setChcboxValue] = useState(false);
    const [lines, setLines] = useState([{placeId:null,staticAddress:false,lineId:0}]);
    const [prices, setPrices] = useState([defaultPrice]);
    const [defaultPrices, setDefaultPrices] = useState(priceLevel);
    const [lastPage, setLastPage] = useState(false);
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    const [competitorsButton,setCompetitorsButton]=useState(competitorsb);
    const [shoppingButton,setShoppingButton]=useState(shoppingg);
    const [parksButton,setParksButton]=useState(parksg);
    const [otherButton,setOtherButton]=useState(otherg);
    const [addButton,setAddButton]=useState(addg);

    useEffect(() => {
        if (defaultPrice > -1) {
            setDefaultPrices(defaultPrice);
            setPrices([defaultPrice]);
        }
    }, [defaultPrice]);

    useLayoutEffect(() => {
        if (campaign && campaign.length > 0) {
            const fetchData = async () => {
                refresh();
                const rawResponseFences = await fetch(SERVER_URL+'/fence?campaign=' + campaign, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                    }
                });
                const content = await rawResponseFences.json();
                if (content.message) {
                    var outArray = [];
                    var cOut = [];
                    var idNoSaved = [];
                    for (var index in content.message) {
                        var fence = content.message[index];
                        var outObj = {};
                        var location = {};
                        location.lat = () => { return fence.latitude.Float64 };
                        location.lng = () => { return fence.longitude.Float64 };
                        outObj.place_id = fence.id.String;
                        outObj.formatted_address = fence.address.String;
                        outObj.name = fence.name.String;
                        outObj.location = location;
                        outObj.types = [fence.type.String];
                        outArray.push(outObj);
                        //cOut.push(fence.id.String);
                        idNoSaved.push(fence.id.String);
                        cOut.push(fence.id.String + "_saved");

                    }
                    setCheckedBoxes(idNoSaved);
                    countCallback(idNoSaved);
                    setSaved(outArray);
                    setSavedGeofences(outArray);
                    localStorage.setItem("storedData", JSON.stringify(outArray));
                }
            }
            fetchData();
        };

    }, []);

    const nextPage = () => {
        if (pagination && pagination != null) {
            setLastPage(true);
            pagination.nextPage();
        }

    };
    const backToFirstPage = (uuid, isCompetitor) => {
        setLastPage(false);
        compSearch([uuid], prices, isCompetitor);
    };

    const idInObjectArray = (id, ObjectArray) => {
        let out = false;
        for (var cand in ObjectArray) {
            if (ObjectArray[cand].place_id == id) {
                return true;
            }
        }
        return out;
    }
    const deleteObjectArray = (id, ObjectArray) => {
        let out = false;
        for (var cand in ObjectArray) {
            if (ObjectArray[cand].place_id == id) {

                ObjectArray.splice(cand, 1);
            }
        }
        setSaved(ObjectArray);
        localStorage.setItem("storedData", JSON.stringify(ObjectArray));
        return out;
    }

    const HandleSavedCheckbox = (e, name, address) => {
        console.log("handled saved");
        let newArray = checkedBoxes.filter(q => q != e.target.id.split("_saved")[0]);
        setCheckedBoxes(newArray);
        countCallback(newArray);
        var withoutSaved = e.target.id.split("_saved")[0];
        //deleteObjectArray(withoutSaved,savedGeofences);
        var element = document.getElementById(withoutSaved);
        if (element && element.checked == true) {
            element.click();
        } else {
            deleteObjectArray(withoutSaved, savedGeofences); //should only work if saved fence is no where else in the accordion
        }
    };

    const handleManualCheckbox = (e) => {
        console.log("manual handled");
        let splits = e.target.id.split("_");
        let rownum = splits[0];
        let row = document.getElementById(rownum+"_row");
        if(row){
            row.style.display='none';
            e.target.checked=false;
            let idsplit = row.children[1].children[1].id.split("_");
            idsplit.pop();
            let placeid = idsplit.join('_');
            if(!checkedBoxes.includes(placeid)){
                let newArray = [...checkedBoxes, placeid];
                setCheckedBoxes(newArray);
                countCallback(newArray);
            }
            
            let name = row.children[1].textContent;
            if (name&&!(idInObjectArray(placeid, savedGeofences))) {
                let newSaved = {
                    place_id: placeid,
                    name: name.substring(0,27),
                    formatted_address: name.substring(28),
                    location: null,
                    type: 'manually added target zones'
                };
                let newSavedArray = _.cloneDeep(savedGeofences);
                console.log(savedGeofences);
                console.log(newSavedArray);
                newSavedArray.push(newSaved);
                setSavedGeofences(newSavedArray);
                setSaved(newSavedArray);
                localStorage.setItem("storedData", JSON.stringify(newSavedArray));
                console.log(newSavedArray);
            } else {
                deleteObjectArray(e.target.id, savedGeofences);
            }
        }
        
    };
    const handleCheckbox = (e, name, address, location, uuid) => {
        console.log("handled");
        let row = document.getElementById(e.target.id+"_row");
        if(row){
            row.style.display='none';
        }
        let newArray = [...checkedBoxes, e.target.id];
        if (checkedBoxes.includes(e.target.id) || checkedBoxes.includes(e.target.id + "_saved")) {
            newArray = newArray.filter(q => q != e.target.id.split("_saved")[0]);
            newArray = newArray.filter(q => q != e.target.id + "_saved");

        }

        setCheckedBoxes(newArray);
        countCallback(newArray);
        //console.log(newArray);
        if (name&&!(idInObjectArray(e.target.id, savedGeofences))) {
            let newSaved = {
                place_id: e.target.id,
                name: name,
                formatted_address: address,
                location: location,
                type: uuid
            };
            let newSavedArray = _.cloneDeep(savedGeofences);
            console.log(savedGeofences);
            console.log(newSavedArray);
            newSavedArray.push(newSaved);
            setSavedGeofences(newSavedArray);
            setSaved(newSavedArray);
            localStorage.setItem("storedData", JSON.stringify(newSavedArray));
            console.log(newSavedArray);
        } else {
            deleteObjectArray(e.target.id, savedGeofences);
        }
    };



    const accordionChanged = (uuid) => {
        if (uuid && uuid.length > 0 && !uuid.startsWith('manual') && !uuid.startsWith('saved')) {
            console.log(uuid);
            if (uuid == 'competitors') {
                uuid = searchType;
            }
            if (uuid == 'shopping') {
                uuid = 'shopping_mall';
            }
            if (uuid == 'parks') {
                uuid = 'park';
            }

            if(uuid!='other'){
                compSearch(uuid, prices);
            }else{
                otherSearch(uuid,prices);
            }
        }
    };
    const setMA = (addressID, checkBoxId,lineId) => {
        console.log(addressID);
        setManualAddress(addressID.value.place_id);
        let newResults = { ...accordionItems };
        newResults['manual'].push(addressID.value.place_id);
        setMR(addressID.value.place_id);
        console.log('placesResults manual:' + newResults.manual);
        var result = lines.find(obj => {
            return obj.lineId === lineId
          })
        result.staticAddress=addressID.label;
        result.place_id=addressID.value.place_id;
        setLines([...lines, {place_id:null,staticAddress:false,lineId:lines.length}]);
    }

    const dollarSignPressed = (amount) => {
        var pricesCopy = [...prices];
        var index = pricesCopy.indexOf(amount);
        if (index !== -1) {
            pricesCopy.splice(index, 1);
        } else {
            pricesCopy.push(amount);
        }
        setPrices(pricesCopy);
        setPriceLevel(pricesCopy);


    };

    const filterPressed = (uuid, isCompetitor) => {
        compSearch([uuid], prices, isCompetitor);
    }

    const clearAll = () => {
        setSavedGeofences([]);
        localStorage.setItem("storedData", JSON.stringify([]));
        setCheckedBoxes([]);
        countCallback([]);
        setSelected2([]);
    }
    useEffect(() => {
        accordionChanged(boxLabel);

    }, [boxLabel]);

    const titleConvert=(label)=>{
        if(label=='saved'){
            return 'my saved targets';
        }else{
            return label;
        }
    }
    const show = (category) => {
        var boxLabel = category;
        //if(category=='competitors'&&centroidtype){
        //   var type = centroidtype.replace("_", " ");
        //   boxLabel = 'Competitor-'+type;
        // }

        const elementCompButton = document.getElementById('compbutton')
        
        const elementShopButton = document.getElementById('shopbutton')
        const elementParksButton = document.getElementById('parksbutton')
        const elemenOtherButton = document.getElementById('otherbutton')
        const elementCustomButton = document.getElementById('custombutton')

        if(boxLabel=='competitors'){
            elementCompButton.classList.add('blue-btn')
            elementShopButton.classList.remove('blue-btn')
            elementParksButton.classList.remove('blue-btn')
            elemenOtherButton.classList.remove('blue-btn')
            elementCustomButton.classList.remove('blue-btn')

          /*setCompetitorsButton(competitorsb);
          setShoppingButton(shoppingg);
          setParksButton(parksg);
          setOtherButton(otherg);
          setAddButton(addg);*/
        }
        if(boxLabel=='shopping'){
            elementCompButton.classList.remove('blue-btn')
            elementCompButton.classList.remove('intialBtnColor')
            elementShopButton.classList.add('blue-btn')
            elementParksButton.classList.remove('blue-btn')
            elemenOtherButton.classList.remove('blue-btn')
            elementCustomButton.classList.remove('blue-btn')
          /*setShoppingButton(shoppingb);
          setCompetitorsButton(competitorsg);
          setParksButton(parksg);
          setOtherButton(otherg);
          setAddButton(addg);*/
        }
        if(boxLabel=='parks'){
            elementCompButton.classList.remove('blue-btn')
            elementCompButton.classList.remove('intialBtnColor')
            elementShopButton.classList.remove('blue-btn')
            elementParksButton.classList.add('blue-btn')
            elemenOtherButton.classList.remove('blue-btn')
            elementCustomButton.classList.remove('blue-btn')
          /*setParksButton(parksb);
          setCompetitorsButton(competitorsg);
          setShoppingButton(shoppingg);
          setOtherButton(otherg);
          setAddButton(addg);*/
        }
        if(boxLabel=='other'){
            elementCompButton.classList.remove('blue-btn')
            elementCompButton.classList.remove('intialBtnColor')
            elementShopButton.classList.remove('blue-btn')
            elementParksButton.classList.remove('blue-btn')
            elemenOtherButton.classList.add('blue-btn')
            elementCustomButton.classList.remove('blue-btn')
          /*setOtherButton(otherb);
          setCompetitorsButton(competitorsg);
          setShoppingButton(shoppingg);
          setParksButton(parksg);
          setAddButton(addg);*/
        }
        if(boxLabel=='manually added target zones'){
            elementCompButton.classList.remove('blue-btn')
            elementCompButton.classList.remove('intialBtnColor')
            elementShopButton.classList.remove('blue-btn')
            elementParksButton.classList.remove('blue-btn')
            elemenOtherButton.classList.remove('blue-btn')
            elementCustomButton.classList.add('blue-btn')
          /*setAddButton(addb);
          setCompetitorsButton(competitorsg);
          setShoppingButton(shoppingg);
          setParksButton(parksg);
          setOtherButton(otherg);*/
        }

        setBoxLabel(boxLabel);
    
    }
    const elementMapView = document.getElementById('map-view')
    const elementCheckedList = document.getElementById('checked-list')
    const elementUncheckList = document.getElementById('uncheck-list')
    const elementmapToggle = document.getElementById('mapToggle')
    const elementListToggle = document.getElementById('listToggle')
    const elementSelectedGeo = document.getElementById('selectedGeo')

    const elementAvailBtn = document.getElementById('avail-geo')
    const elementSelectedBtn = document.getElementById('selected-geo')
    const elementSelectListToggle = document.getElementById('listSelectedToggle')
    
        


    const openListView = () => {
        elementMapView.classList.remove('NewStyleAdded1')
        elementCheckedList.classList.remove('NewStyleAdded2')
        elementUncheckList.classList.add('NewStyleAdded3')
        elementMapView.classList.add('NewStyleAdded')
        elementmapToggle.classList.remove('active')
        elementListToggle.classList.add('active')
    
        elementSelectedGeo.classList.add('hide-div')
        elementSelectedGeo.classList.remove('show-div')


        
      }
      const openMapView = () => {
        elementMapView.classList.add('NewStyleAdded1')
        elementCheckedList.classList.remove('NewStyleAdded2')
        elementUncheckList.classList.remove('NewStyleAdded3')
        elementmapToggle.classList.add('active')
        elementListToggle.classList.remove('active')
    
        elementSelectedGeo.classList.add('hide-div')
        elementSelectedGeo.classList.remove('show-div')
        elementSelectListToggle.classList.remove('active')
       
      }
      const openSelectedListView = () => {
        elementMapView.classList.remove('NewStyleAdded1')
        elementCheckedList.classList.add('NewStyleAdded2')
        elementUncheckList.classList.remove('NewStyleAdded3')
    
        elementSelectedBtn.classList.add('selBtn-Highlight')
        elementAvailBtn.classList.remove('selBtn-Highlight')
    
        elementSelectedGeo.classList.add('show-div')
        elementListToggle.classList.add('progDesk')

        elementmapToggle.classList.remove('active')
        elementSelectListToggle.classList.add('active')


      }

    return (
        <>
        
            <div className='subCat-heading progDesk' style={{  fontSize: '22px', backgroundColor: '#333F57', color: 'white', textTransform: 'capitalize', padding: '2px 5px 5px 5px' }}>
                <div style={{marginLeft:'20px',marginRight:'20px'}}>
                    <div  style={{padding: '0px 0px 0px 0px','textAlign':'center','margin':'0%'}}>Categories & Geofences  <img style={{height:'22px'}} src={markericon3} /></div>
                </div>
            </div>
            <div className='subCat-padding' style={{ fontSize: '20px', color: 'white', textTransform: 'capitalize', padding: '0px 4px 2px 0px',backgroundColor:'#049EC4' }}>
                <div style={{marginLeft:'20px'}}>
        <div style={{marginBottom:'1%',fontSize:'0'}}className="row map-categories-style">
            <div style={{paddingLeft:'0',paddingRight:'0',width:'19.4%'}} >
              <button id='compbutton' className='intialBtnColor'  onClick={(e) => show('competitors')} type='image'>
                  Competitors    
              </button>
            </div>
            <div style={{paddingLeft:'0',paddingRight:'0',width:'19.4%'}} >
              <button id='shopbutton'  onClick={(e) => show('shopping')} type='image'>
                     Shopping 
              </button>
            </div>
            <div style={{paddingLeft:'0',paddingRight:'0',width:'19.4%'}}>
              <button id='parksbutton'  onClick={(e) => show('parks')} type='image'>
                     Parks
              </button>
            </div>
            <div style={{paddingLeft:'0',paddingRight:'0',width:'19.4%'}} >
              <button id='otherbutton'  onClick={(e) => show('other')} type='image'>
                    Other 
              </button>
            </div>
            <div style={{paddingLeft:'0',paddingRight:'0',width:'19.4%'}} >
              <button id='custombutton' onClick={(e) => show('manually added target zones')} type='image'>
                      Add Custom
              </button>
            </div>

          </div>
                </div>
            </div>
            <div style={{  fontSize: '18px', color: 'white', textTransform: 'capitalize', padding: '0px 0',backgroundColor:'#049EC4' }}>
                <div style={{marginLeft:'10px'}}>
                    <div className="showingCatFont" style={{'textAlign':'left'}}>Showing {titleConvert(boxLabel)} {boxLabel.startsWith('competitors') && !boxLabel.includes('-') && searchType.length > 0 ? '-' : ''} {boxLabel.startsWith('competitors') && !boxLabel.includes('-') && searchType.replaceAll("_", " ")}
                    
                   <div id="mapListId" className="mapListBtns">
                       <button id="mapToggle" onClick={openMapView} className="active">Map</button>
                       <button id="listToggle" onClick={openListView} >List</button>
                       <button id="listSelectedToggle" onClick={openSelectedListView} className="progDesk">List</button>
                   </div>
                </div>
                   
                    
          
                </div>
            </div>
            <div id="uncheck-list" style={{marginTop:'15px',fontSize:'12px',lineHeight:'16px' ,position:'relative',paddingTop:'5px',paddingBottom:'40px'}} className="scrollbox container NewStyleAdded">

<div className='row'><div className='col-12'></div></div>
                {(boxLabel == 'competitors') && <WidgetFilterTagsPrice uuid={searchType} isCompetitor={true} filterPressed={filterPressed} defaultPrice={defaultPrices} pressed={dollarSignPressed} tagitems={sectiondata.listing.sidebar.widgetFilterPriceTags.tags} title={"Limit Search By Price"} />}
                {(boxLabel == 'competitors') && searchType && searchType.length > 0 && accordionItems.competitors.map((item, i) => {
                    return (
                        <div style={{marginTop:'2px',marginBottom:'4px'}} className="row" id={item.place_id+"_row"} key={item.place_id} onChange={(e) => handleCheckbox(e, item.name, item.formatted_address, item.geometry.location, "park")} >
                             <div className='col-1' style={{paddingRight:0}}><input className='checkbox-margin' style={{marginTop:'-4px'}} type="checkbox" defaultChecked={checkedBoxes.includes(item.place_id)} id={item.place_id} /></div>
                             <div className='col-11' style={{paddingLeft:0}}><label style={{marginTop:'-4px'}} htmlFor={item.place_id}>
                             <span>{item.name+' - ' + item.formatted_address}</span>
                            </label>
                            </div>
                        </div>
                    )
                })}

                {(boxLabel == 'parks') && accordionItems.park.map((item, i) => {
                    return (

                        <div style={{marginTop:'2px',marginBottom:'4px'}} className="row" id={item.place_id+"_row"} key={item.place_id} onChange={(e) => handleCheckbox(e, item.name, item.formatted_address, item.geometry.location, "park")} >
                             <div className='col-1' style={{paddingRight:0}}><input className='checkbox-margin' style={{marginTop:'-4px'}} type="checkbox" defaultChecked={checkedBoxes.includes(item.place_id)} id={item.place_id} /></div>
                             <div className='col-11' style={{paddingLeft:0,marginTop:'-4px'}}><label style={{}} htmlFor={item.place_id}>
                             <span>{item.name+' - ' + item.formatted_address}</span>
                            </label>
                            </div>
                        </div>
                    )
                })}
                {(boxLabel == 'shopping') && accordionItems.shopping_mall.map((item, i) => {
                    return (

                        <div style={{marginTop:'2px',marginBottom:'4px'}} className="row" id={item.place_id+"_row"} key={item.place_id} onChange={(e) => handleCheckbox(e, item.name, item.formatted_address, item.geometry.location, "park")} >
                             <div className='col-1' style={{paddingRight:0}}><input className='checkbox-margin'  style={{marginTop:'-4px'}} type="checkbox" defaultChecked={checkedBoxes.includes(item.place_id)} id={item.place_id} /></div>
                             <div className='col-11' style={{paddingLeft:0,marginTop:'-4px'}}><label style={{}} htmlFor={item.place_id}>
                             <span>{item.name+' - ' + item.formatted_address}</span>
                            </label>
                            </div>
                        </div>
                    )
                })}
                {(boxLabel == 'other') && otherResults.map((item, i) => {
                    return (

                        <div style={{marginTop:'2px',marginBottom:'4px'}} className="row" id={item.place_id+"_row"} key={item.place_id} onChange={(e) => handleCheckbox(e, item.name, item.formatted_address, item.geometry.location, "park")} >
                             <div className='col-1' style={{paddingRight:0}}><input className='checkbox-margin' style={{marginTop:'-4px'}} type="checkbox" defaultChecked={checkedBoxes.includes(item.place_id)} id={item.place_id} /></div>
                             <div className='col-11' style={{paddingLeft:0,marginTop:'-4px'}}><label style={{}} htmlFor={item.place_id}>
                             <span>{item.name+' - ' + item.formatted_address}</span>
                            </label>
                            </div>
                        </div>
                    )
                })}

                {(boxLabel == 'manually added target zones') &&
                    lines.map(m => <Child key={m.lineId} ma={setMA} caption={m.lineId} hc={handleManualCheckbox} chcboxValue={chcboxValue} pstate={{ lines, setLines }} place_id={m.place_id} staticAddress={m.staticAddress} lineId={m.lineId} removeManual={removeManual} />)
                }

                {pagination && !(boxLabel == 'manual'||boxLabel =='saved'||boxLabel=='manually added target zones') && <button
                    style={{ border: '0' }}
                    onClick={nextPage}
                    type='button'
                    className="button theme-btn" type="submit">
                    Show More
                    </button>}
                {lastPage && !pagination && <button
                    style={{ border: '0' }}
                    onClick={() => backToFirstPage(searchType, true)}
                    type='button'
                    className="button theme-btn" type="submit">
                    Go Back To First Page of Results
                    </button>}

                   
            </div>
            
         

        </>
    );
}

export default PlaceScrollBox;