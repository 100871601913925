import React from 'react'
import {RiBuilding4Line,RiHotelBedLine, RiPlaneLine, RiReplyLine} from 'react-icons/ri';
import {GiChickenOven, GiPineTree, GiPositionMarker, GiWineGlass} from 'react-icons/gi';
import {
    BsBookmark,
    BsBriefcase,
    BsCheckCircle, BsEye,
    BsGear,
    BsHouseDoor,
    BsListCheck,
    BsMap,
    BsMusicNoteBeamed,
    BsPencil
} from 'react-icons/bs';
import RealEstate from '../assets/images/Real-Estate-Icon-coral.svg';
import RealEstateActive from '../assets/images/Real-Estate-Icon-active-blue.svg';
import Restaurant from '../assets/images/Restaurant-Icon-coral.svg';
import RestaurantActive from '../assets/images/Restaurant-Icon-active-blue.svg';
import Automotive from '../assets/images/Automotive-Icon-coral.svg';
import AutomotiveActive from '../assets/images/Automotive-Icon-active-blue.svg';
import Financial from '../assets/images/Financial-Icon-coral.svg';
import FinancialActive from '../assets/images/Financial-Icon-active-blue.svg';
import Retail from '../assets/images/Retail-Icon-coral.svg';
import RetailActive from '../assets/images/Retail-Icon-active-blue.svg';
import Services from '../assets/images/Services-Icon-coral.svg';
import ServicesActive from '../assets/images/Services-Icon-active-blue.svg';
import FoodAndDrink from '../assets/images/Popular-Category-Food-Drink-Icon.svg';
import Fitness from '../assets/images/Popular-Category-Fitness-Icon.svg';
import Id1 from '../assets/images/Plan-1-Icon.svg';
import Id2 from '../assets/images/Plan-2-Icon.svg';
import Id3 from '../assets/images/Plan-3-Icon.svg';


import {MdClose, MdFitnessCenter, MdStar, MdStarBorder, MdStarHalf} from 'react-icons/md';
import bannerbg1 from '../assets/images/Baltimore-Road-Map.png';
import img1 from "../assets/images/img1.jpg"; // 263*175
import img2 from "../assets/images/img7.jpg"; // 362*242
import restaurants from "../assets/images/restaurants.jpg";
import realestate from "../assets/images/realestate.jpg";
import services from "../assets/images/services.jpg";
import shops from "../assets/images/shops.jpg";
import fitness from "../assets/images/fitness.jpg";
import automotive from "../assets/images/automotive.jpg";
import financial from "../assets/images/financial.jpg";
import food from "../assets/images/food.jpg";
import authorimg from "../assets/images/small-team1.jpg"; // 67*60
import symble1 from "../assets/images/symble1.png";
import symble2 from "../assets/images/symble2.png";
import symble3 from "../assets/images/symble3.png";
import symble4 from "../assets/images/symble4.png";
import {TiArrowUnsorted, TiBrush, TiPlane} from "react-icons/ti";
import {
    IoIosCheckmarkCircle,
    IoIosFitness, IoIosRocket, IoMdCut,
    IoMdFitness,
    IoMdMusicalNotes, IoMdPaperPlane,
    IoMdStar,
    IoMdStarHalf
} from "react-icons/io";
import {
    FiBookOpen,
    FiCheck,
    FiCheckCircle, FiExternalLink,
    FiFilter,
    FiHeadphones, FiHeart,
    FiMail,
    FiMap,
    FiMusic,
    FiPlus,
    FiShoppingCart, FiThumbsUp, FiUsers
} from "react-icons/fi";
import {AiOutlineCar, AiOutlineEllipsis, AiOutlineHome, AiOutlineQuestionCircle, AiOutlineUser} from "react-icons/ai";
import team1 from "../assets/images/team2.jpg"; // 111*100
import team2 from "../assets/images/testi-img1.jpg"; // 106*95
import team3 from "../assets/images/testi-img2.jpg"; // 95*85
import team4 from "../assets/images/testi-img3.jpg"; // 83*75
import team6 from "../assets/images/testi-img4.jpg"; // 78*70
import {GoFile} from "react-icons/go";
import cta2mobileimg from "../assets/images/mobile.png";
import logo1 from "../assets/images/client-logo.png";
import darklogo from "../assets/images/logo2.png";
import {
    FaBehance, FaCogs,
    FaDribbble,
    FaFacebookF, FaInstagram,
    FaLinkedinIn, FaMinus, FaPlus, FaQuoteRight, FaRegComment, FaRegEdit,
    FaRegEnvelope, FaRegHeart, FaRegHospital, FaRegMap,
    FaRegMoneyBillAlt, FaRegTrashAlt, FaSearchDollar, FaSearchPlus, FaSort, FaStethoscope,
    FaTwitter, FaYoutube
} from "react-icons/fa";
import destinationimg1 from "../assets/images/img5.jpg";
import flag1 from "../assets/images/flag14.png";
import flag2 from "../assets/images/flag1.jpg";
import flag3 from "../assets/images/flag2.jpg";
import flag4 from "../assets/images/flag13.jpg";
import flag5 from "../assets/images/flag18.jpg";
import destinationimg6 from "../assets/images/img7.jpg";
import flag6 from "../assets/images/flag3.jpg";
import flag7 from "../assets/images/flag7.jpg";
import flag8 from "../assets/images/flag5.png";
import flag9 from "../assets/images/flag19.png";
import hiw3videoimg from "../assets/images/video-img2.jpg";
import adDesign from "../assets/images/adDesign.png";
import {FcLineChart} from "react-icons/fc";
import dreamimg from "../assets/images/img6.jpg"; // 263*165
import hiw5videoimg from "../assets/images/video-img3.jpg";
import slicevideo from "../assets/video/slice-video.m4v";
import flag10 from "../assets/images/flag10.png";
import flag11 from "../assets/images/flag11.png";
import flag12 from "../assets/images/flag12.jpg";
import flag13 from "../assets/images/flag13.jpg";
import flag14 from "../assets/images/flag14.png";
import flag15 from "../assets/images/flag15.jpg";
import flag16 from "../assets/images/flag16.jpg";
import flag17 from "../assets/images/flag17.jpg";
import flag18 from "../assets/images/flag18.jpg";
import flag19 from "../assets/images/flag19.png";
import flag20 from "../assets/images/flag20.png";
import listingdetailsvideoimg from "../assets/images/img4.jpg";
import similarimg from "../assets/images/img8.jpg"; // 90*90
import breadcrumbimg from "../assets/images/bread-bg.jpg"; //1920*838
import userimage from "../assets/images/team1.jpg"; // 368*331
import allenimage from "../assets/images/Allen Warren Original.png"
import allensmall from "../assets/images/Allen-headshot-small-67x60.png"
import allenmedium from "../assets/images/Allen-headshot-med-106x95.png"
import lizimage from "../assets/images/Elizabeth Nourse Original.png"
import joeimage from "../assets/images/joseph_miller_368_331.jpg"
import headshot from "../assets/images/headshot.jpeg"
import imgboximg from "../assets/images/img10.jpg"; // 570*345
import about2img from "../assets/images/about2.jpg"; //389*259
import about1img from "../assets/images/about1.jpg";
import about3img from "../assets/images/about3.jpg";
import about4img from "../assets/images/about4.jpg";
import mainimage from "../assets/images/Growth-blogpost-pic.png"; // 750*500
import mainimage2 from "../assets/images/Global-Positioning-blog-post-pic.png";
import contactus from "../assets/images/contact_us.jpg";
import great1 from "../assets/images/great_campaign_389_259.jpg";
import great2 from "../assets/images/great_campaign_2_389_259.jpg";
import great3 from "../assets/images/great_campaign_3_389_259.jpg";
import great4 from "../assets/images/great_campaign_4_389_259.jpg";
import gf1 from "../assets/images/geofencing_101_3_389_259.jpg";
import gf2 from "../assets/images/geofencing_101_3_389_259b.jpg";
import gf3 from "../assets/images/geofencing_101_3_389_259c.jpg";
import gf4 from "../assets/images/geofencing_101_3_389_259d.jpg";
import why1 from "../assets/images/why1.jpg";
import why2 from "../assets/images/why2.jpg";
import why3 from "../assets/images/why3.jpg";
import why4 from "../assets/images/why4.jpg";
import targets from "../assets/images/Target-Target-Icon.svg";
import personalized from "../assets/images/Target-Personalized-Icon.svg";
import nearby from "../assets/images/Target-Attractions-Icon.svg";
import andmore from "../assets/images/Target-More-Icon.svg";
import creative1 from "../assets/images/St. Regis 300x250.png"
import creative2 from "../assets/images/McFadden 300x250.png"
import creative3 from "../assets/images/Mark G Headshot 300x250 .jpeg"
import creative4 from "../assets/images/Mark G Concierge 300x250.jpeg"
import creative5 from "../assets/images/LANE 21-07 300x250 Basis-DSP-static-V1.jpg"
import creative6 from "../assets/images/Hala-300x250-Ad.jpg"
import creative7 from "../assets/images/DR-Mobile-HP-2.jpg"
import creative8 from "../assets/images/Direct Buy 300x250.jpeg"
import creative9 from "../assets/images/Compass-Example-Jeanne-300x250.png"
import creative10 from "../assets/images/Compass-Example-Goodhardt-300x250.png"
import creative11 from "../assets/images/CC-20-91-South-Glebe-300x250-static.jpg"
import creative12 from "../assets/images/CC 20-90 Seabreeze Village 300x250 V2-static.jpg"
import creative13 from "../assets/images/Adam Arian 300x250.png"
import creative14 from "../assets/images/STEFANELLI-20-21-300x250-V1.png"
import creative15 from "../assets/images/Monument-Shaw-300x250.png"
import creative16 from "../assets/images/Miller-Bros-300x250.png"
import creative17 from "../assets/images/Meridia-On-Westfield-300x250.png"
import creative18 from "../assets/images/Hala-Compass-300x250.png"
import creative19 from "../assets/images/Communities-of-Burwell-300x250.png"
import creative20 from "../assets/images/Richardson Group Ads-300x250px.jpg"
import creative21 from "../assets/images/Bob-Davidson-Lincoln-Certified-300x250.jpg"
import creative22 from "../assets/images/Bob-Davidson-Ford-Maverick-300x250.jpg"
import creative23 from "../assets/images/Bob-Davidson-Ford-Lightning-300x250.jpg"
import creative24 from "../assets/images/Bob-Davidson-Ford-Bronco-300x250.jpg"
import creative25 from "../assets/images/Bob-Davidson-Ford-Blue-Advantage-300x250.jpg"
import creative26 from "../assets/images/Freddy's Employee Fredericksburg-300x250px.jpg"
import creative27 from "../assets/images/Freddy's Hot Dog Snellville-300x250px.jpg"
import creative28 from "../assets/images/Red Star Banners-300x250px.png"
import creative29 from "../assets/images/OPAL-WA-20-02 ELEVATOR TH Banner 300x250 final-ON.png"
import creative30 from "../assets/images/MURRAY-Contingent-300x250.png"
import creative31 from "../assets/images/MURRAY-Concierge-300x250.png"
import creative32 from "../assets/images/MURRAY-Valuation-300x250.png"
import creative33 from "../assets/images/WA-20-03-Leaderboard-300x250-BLADE.png"
import creative34 from "../assets/images/Opal-Life-300x250.png"
import creative35 from "../assets/images/BP-2021-Burnham-Home-Sharing-300x250-V1.jpg"
import creative36 from "../assets/images/WA-Big-Box-300x250-Urban-Turf.jpg"
import creative37 from "../assets/images/DEA_Display_300x250.png"
import creative38 from "../assets/images/BP-The-Burnham-300x250-V3.jpg"
import creative39 from "../assets/images/Holy-Redeemer_lead_300x250A.jpg"

import reach from "../assets/images/GEO-101-Reach-People-Graphic-1.png"
import deliver from "../assets/images/GEO-101-Deliver-Ads-Graphic-2.png"
import reachtarget from "../assets/images/GEO-101-Ads-Reach-Target-Graphic-3.png"
import beyondmobile from "../assets/images/GEO-101-Beyond-Mobile-Graphic-4.png"
import measuresuccess from "../assets/images/GEO-101-Measure-Success-Graphic-5.png"
import optimizecampaigns from "../assets/images/GEO-101-Optimize-Campaigns-Graphic-6.png"
import idconversion from "../assets/images/GEO-101-Identify-Target-Graphic-7.png"
import buildyour from "../assets/images/GEO-101-Geofences-Size-Graphic-8.png"
import gotafew from "../assets/images/GEO-101-Grow-Business-Graphic-9.png"
import letsgetstarted from "../assets/images/Lets-Get-Started-button.svg"
import attract from "../assets/images/Attract-Customers-graphic-1.svg"
import reach2 from "../assets/images/Reach-Target-Audience-graphic-2.svg"
import connect from "../assets/images/Connect-Customers-graphic-3.svg"
import retarget from "../assets/images/Retarget-Current-Clients-graphic-4.svg"
import build2 from "../assets/images/Build-Campaign-graphic-5.svg"
import maximum from "../assets/images/Maximum-ROI-graphic-6.svg"
import measure from "../assets/images/Measure-Results-graphic-7.svg"
import time from "../assets/images/Time-Started-graphic-8.svg"

import mindset from "../assets/images/Customer-Mindset-Graphic-1.svg"
import leverage from "../assets/images/Leverage-Locations-Graphic-2.svg"
import gowhere from "../assets/images/Go-Where-Customers-Graphic-3.svg"
import nearbyattract from "../assets/images/Nearby-Attractions-4.svg"
import leveragecurrent from "../assets/images/Leverage-Current-Customers-Graphic-5.svg"

import attractc from "../assets/images/Attract-Customers-graphic-1.svg"
import reacht from "../assets/images/Reach-Target-Audience-graphic-2.svg"
import notraining from "../assets/images/No-Training-Get-Started-graphic-3.svg"
import professionala from "../assets/images/Professional-Ad-Design-graphic-4.svg"
import whylocationb from "../assets/images/Leverage-Locations-Graphic-2.svg"
import easilyreach from "../assets/images/Reach-Prospects-graphic-6.svg"
import howtobuildag from "../assets/images/Build-For-Financial-graphic-7.svg"
import connectwith from "../assets/images/Connect-Customers-graphic-8.svg"
import staytop from "../assets/images/Top-Of-Mind-graphic-9.svg"
import drivet from "../assets/images/Drive-Traffic-Financial-Website-graphic-10.svg"
import comprehensiver from "../assets/images/Comprehensive-Reports-graphic-11.svg"
import levero from "../assets/images/Maximize-ROI-graphic-12.svg"
import gotafewm from "../assets/images/Get-Started-Clock-graphic-13.svg"

import whychooseus from "../assets/images/Why-Choose-Us-Graphic-2.svg"
import whowearegraphic from "../assets/images/Who-We-Are-Graphic-1.svg"

import driveb from "../assets/images/Drive-Business-graphic-1.svg"
import attractn from "../assets/images/Attract-New-graphic-2.svg"
import sow from "../assets/images/Resize-Target-graphic-3.svg"
import designc from "../assets/images/Resize-Design-Custom-Ads-graphic-5.svg"
import whyl from "../assets/images/Resize-How-to-Build-graphic-12.svg"
import connectw from "../assets/images/DIY-graphic-7.svg"
import retargety from "../assets/images/Retarget-graphic-8.svg"
import promotes from "../assets/images/Promote-Special-Offers-graphic-9.svg"
import measurey from "../assets/images/Measure-Success-graphic-10.svg"
import ind from "../assets/images/ROI-Reporting-graphic-11.svg"
import howtobuild from "../assets/images/Resize-How-to-Build-graphic-12.svg"

import attractnew from "../assets/images/Attract-New-graphic-2.svg"
import sowhat from "../assets/images/Resize-Target-graphic-3.svg"
import professionalads from "../assets/images/Resize-Design-Custom-Ads-graphic-5.svg"
import whylocation from "../assets/images/Resize-Why-it-Works-graphic-6.svg"
import easilyreachq from "../assets/images/Resize-Easily-Reach-graphic-4.svg"
import howtobuildy from "../assets/images/Resize-How-to-Build-graphic-7.svg"
import connectwithy from "../assets/images/Resize-Connect-With-Competitors-graphic-8.svg"
import staytopof from "../assets/images/Resize-Top-of-Mind-graphic-9.svg"
import dontforgetto from "../assets/images/Target-Own-Store-graphic-10.svg"
import promotenew from "../assets/images/Promote-New-Products-graphic-11.svg"
import detailedreports from "../assets/images/Detailed-Reports-graphic-12.svg"
import putourreporting from "../assets/images/Resize-Detailed-Reports-graphic-13.svg"
import gotafewminutes from "../assets/images/Get-Started-Clock-graphic-9.svg"


import drivebusiness from "../assets/images/Restaurant-Location-graphic-1.svg"
import sowhatexactly from "../assets/images/Resize-Target-graphic-2.svg"
import designcustom from "../assets/images/Resize-Design-Custom-Ads-graphic-3.svg"
import whylocationbasedm from "../assets/images/Resize-Why-it-Works-graphic-4.svg"
import indepthreporting from "../assets/images/Resize-Reports-to-Work-graphic-5.svg"
import geofencingbasics from "../assets/images/Geofencing-Basics-graphic-6.svg"
import howtobuildyour from "../assets/images/How-to-Build-Restaurant-graphic-7.svg"

import notrainingneeded from "../assets/images/Resize-Started-Rocket-graphic-3.svg"
import professionaladsdesign from "../assets/images/Resize-Pro-Ad-Design-graphic-4.svg"
import easilyreachqualified from "../assets/images/Resize-Easily-Reach-graphic-6.svg"
import howtobuildageofencing from "../assets/images/How-to-Build-graphic-7.svg"
import connectwithyour from "../assets/images/Resize-Connect-With-Competitors-graphic-8.svg"
import leverageuntapped from "../assets/images/Untapped-Opportunities-graphic-11.svg"
import promotenewlistings from "../assets/images/Promote-New-Listings-graphic-12.svg"
import drivetraffic from "../assets/images/Drive-Traffic-Website-graphic-12.svg"
import detailedreportsprove from "../assets/images/Resize-Detailed-Reports-graphic-13.svg"
import putourreports from "../assets/images/Resize-Reports-to-Work-graphic-13.svg"

import promotenewincentives from "../assets/images/Promote-New-Incentives-graphic-6.svg"
import leverageuntappedopp from "../assets/images/Leverage-Nearby-graphic-5.svg"
import drivetraffic2 from "../assets/images/Drive-Traffic-Car-Website-graphic-7.svg"
import measureyour from "../assets/images/Real-Time-Results-graphic-8.svg"
import jobgraphic from "../assets/images/job-graphic.svg"
import smileygraphic from "../assets/images/smiley-graphic.svg"


const sectiondata = {
    headermenu: [
        {
            title: 'Home',
            path: '/',

        },
        {
            title: 'Get Started / Learn More',
            path: '#',
            dropdown: [
                {
                    title: 'Start a Campaign',
                    path: '/list-map-view?place=-77.0364,38.8951&type='
                },
                {
                    title: 'ad design',
                    path: '/campaigndesign'
                },
                {
                    title: 'Geofencing 101',
                    path: '/gf101'
                },
                {
                    title: 'Why Use Geofencing?',
                    path: '/why'
                },
                {
                    title: 'Build a Great Campaign',
                    path: '/howto'
                },

                {
                    title: 'Automotive Campaign Ideas',
                    path: '/automotive'
                },
                {
                    title: 'Financial Services Campaign Ideas ',
                    path: '/financial'
                },
                {
                    title: 'Real Estate Campaign Ideas',
                    path: '/realestate'
                },
                {
                    title: 'Restaurant Campaign Ideas',
                    path: '/restaurant'
                },
                {
                    title: 'Retail Campaign Ideas',
                    path: '/retail'
                },
                {
                    title: 'Services Campaign Ideas',
                    path: '/services'
                },
                {
                    title: 'Recruiting Employees',
                    path: '/recruiting'
                },
                {
                    title: 'Real Estate Agent Recruiting',
                    path: '/realestaterecruiting'
                }
            ]
        },
        {
            title: 'My Account',
            path: 'user-profile',
            dropdown: [
                {
                    title: 'User Profile',
                    path: '/user-profile'
                },
                {
                    title: 'my dashboard',
                    path: '/dashboard'
                }
            ]
        },
        {
            title: 'About Us / Pricing',
            path: '#',
            dropdown: [

                {
                    title: 'pricing',
                    path: '/pricing'
                },
                {
                    title: 'about us',
                    path: '/about'
                },
                {
                    title: 'faq',
                    path: '/faq'
                },
                {
                    title: 'contact',
                    path: '/contact'
                },
                {
                    title: 'blogs',
                    path: '/blogs'
                }
            ]
        },
        {
            title: 'Case Studies',
            path: '/case-studies',
            
        },
    ],
    herobanners: {
        banner1: {
            title: 'Reach future customers in ',
            titleHighlight: [
                {
                    active: true,
                    text: 'Auto dealerships'
                },
                {
                    active: false,
                    text: 'Restaurants'
                },
                {
                    active: false,
                    text: 'Retail'
                },
                {
                    active: false,
                    text: 'Schools'
                },
                {
                    active: false,
                    text: 'Real Estate'
                }
            ],
            content: 'Precisely reach your ideal customer.', 
            content2: 'We make it easy:',
            bgimage: bannerbg1,
        },
        banner2: {
            title: 'Find The Best Place in your city',
            content: 'Dirto helps you find out whats happening in your city, Let\'s explore.',
            categories: [
                {
                    path: '#',
                    text: 'Real Estate',
                    icon: <RiBuilding4Line />
                },
                {
                    path: '#',
                    text: 'Restaurants',
                    icon: <GiChickenOven />
                },
                {
                    path: '#',
                    text: 'Automotive',
                    icon: <RiPlaneLine />
                },
                {
                    path: '#',
                    text: 'Financial Services',
                    icon: <BsMusicNoteBeamed />
                },
                {
                    path: '#',
                    text: 'Retail',
                    icon: <IoMdFitness />
                },
                {
                    path: '#',
                    text: 'Services',
                    icon: <RiHotelBedLine />
                }
            ],
            bgimage: bannerbg1
        },
        banner3: {
            title: 'What\'s Your Plan Today?',
            content: 'All the top locations – from restaurants and clubs, to galleries, famous places and more...',
            bgimage: bannerbg1
        },
        banner4: {
            sectitle: 'Find The Best Places To Be',
            seccontent: 'Precisely reach your ideal customer. We make it easy.',
            videobgsrc: slicevideo,
        },
    },
    categories: {
        featuredcategories: {
            //connector: 'or',
            title: 'Or Browse Featured Categories:',
            items: [
                {
                    path: "/realestate",
                    title: "Real Estate",
                    icon: RealEstate,
                    iconHover: RealEstateActive
                },
                {
                    path: "/restaurant",
                    title: "Restaurants",
                    icon: Restaurant,
                    iconHover: RestaurantActive 
                },
                {
                    path: "/automotive",
                    title: "Automotive",
                    icon: Automotive,
                    iconHover: AutomotiveActive 
                },
                {
                    path: "/financial",
                    title: "Financial Services",
                    icon: Financial,
                    iconHover: FinancialActive 
                },
                {
                    path: "/retail",
                    title: "Retail",
                    icon: Retail,
                    iconHover: RetailActive
                },
                {
                    path: "/services",
                    title: "Services",
                    icon: Services,
                    iconHover: ServicesActive 
                }
            ]
        },
        browsecategories: {
            sectitle: 'What do you need to find?',
            seccontent: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
            items: [
                {
                    id: 1,
                    icon: <GiChickenOven />,
                    title: 'restaurants',
                    listingsNum: 42,
                    cardLink: '#'
                },
                {
                    id: 2,
                    icon: <RiHotelBedLine />,
                    title: 'hotels',
                    listingsNum: 22,
                    cardLink: '#'
                },
                {
                    id: 3,
                    icon: <FiShoppingCart />,
                    title: 'shopping',
                    listingsNum: 7,
                    cardLink: '#'
                },
                {
                    id: 4,
                    icon: <IoMdCut />,
                    title: 'beauty & Spa',
                    listingsNum: 31,
                    cardLink: '#'
                },
                {
                    id: 5,
                    icon: <FaRegHospital />,
                    title: 'Hospital',
                    listingsNum: 33,
                    cardLink: '#'
                },
                {
                    id: 6,
                    icon: <BsGear />,
                    title: 'Services',
                    listingsNum: 33,
                    cardLink: '#'
                },
                {
                    id: 7,
                    icon: <TiBrush />,
                    title: 'Arts & Design',
                    listingsNum: 31,
                    cardLink: '#'
                },
                {
                    id: 8,
                    icon: <BsHouseDoor />,
                    title: 'Real State',
                    listingsNum: 36,
                    cardLink: '#'
                },
                {
                    id: 9,
                    icon: <FaStethoscope />,
                    title: 'Health',
                    listingsNum: 22,
                    cardLink: '#'
                },
                {
                    id: 10,
                    icon: <FaSearchDollar />,
                    title: 'Jobs',
                    listingsNum: 40,
                    cardLink: '#'
                },
                {
                    id: 11,
                    icon: <TiPlane />,
                    title: 'Travels',
                    listingsNum: 13,
                    cardLink: '#'
                },
                {
                    id: 12,
                    icon: <FiMusic />,
                    title: 'Events',
                    listingsNum: 10,
                    cardLink: '#'
                }
            ]
        },
        browsecategories2: {
            sectitle: 'Browse by Categories',
            seccontent: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
            categories: [
                {
                    img: img1,
                    icon: <GiChickenOven />,
                    title: 'Restaurants',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <TiPlane />,
                    title: 'Travels',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <RiHotelBedLine />,
                    title: 'Hotels',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <FiMusic />,
                    title: 'Events',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <FiShoppingCart />,
                    title: 'Shops',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <IoIosFitness />,
                    title: 'Fitness',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <GiWineGlass />,
                    title: 'Food & Drink',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <TiBrush />,
                    title: 'Art & Design',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <TiBrush />,
                    title: 'Salons',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <FaRegMoneyBillAlt />,
                    title: 'Jobs',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <BsBriefcase />,
                    title: 'Businesses',
                    cardLink: '#'
                },
                {
                    img: img1,
                    icon: <FaRegHospital />,
                    title: 'Hospital',
                    cardLink: '#'
                }
            ]
        },
        popularcategories: {
            title: 'Popular:',
            lists: [
                {
                    url: '#',
                    title: 'Travel'
                },
                {
                    url: '#',
                    title: 'Food'
                },
                {
                    url: '#',
                    title: 'Business'
                },
                {
                    url: '#',
                    title: 'Salon'
                },
                {
                    url: '#',
                    title: 'Cleaning'
                }
            ]
        }
    },
    popularcategories: {
        sectitle: 'Most Popular Categories',
        seccontent: 'Precisely reach potential customers and clients by reaching them when they visit your competition. Hyper-target your ideal client based on exactly who you want to reach.',
        categories: [
            {
                icon: Automotive,
                title: 'Automotive',
                url: '/automotive',
                img: automotive
            },
            {
                icon: Financial,
                title: 'Financial',
                url: '/financial',
                img: financial
            },
            {
                icon: FoodAndDrink,
                title: 'Food & Drink',
                url: '/restaurant',
                img: fitness
            },
            {
                icon: Services,
                title: 'Services',
                url: '/services',
                img: food
            },
            {
                icon: RealEstate,
                title: 'Real Estate',
                url: '/realestate',
                img: realestate
            },
            {
                icon: Restaurant,
                title: 'Restaurants',
                url: '/restaurant',
                img: restaurants
            },

            {
                icon: Fitness,
                title: 'Fitness',
                url: '/services',
                img: services
            },

            {
                icon: Retail,
                title: 'Retail',
                url: '/retail',
                img: shops
            }



        ],
        morecats: [
            {
                icon: <TiBrush />,
                title: 'Automotive',
                stitle: '20 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <TiBrush />,
                title: 'nightlife',
                stitle: '20 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <FaRegHospital />,
                title: 'hospitals',
                stitle: '20 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <GiPineTree />,
                title: 'adventure',
                stitle: '23 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <FiBookOpen />,
                title: 'educations',
                stitle: '40 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <AiOutlineCar />,
                title: 'cars',
                stitle: '33 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <BsBriefcase />,
                title: 'jobs',
                stitle: '20 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <FaRegMoneyBillAlt />,
                title: 'business',
                stitle: '20 Listings',
                url: '#',
                img: img1
            }
        ]
    },
    populardestination: {
        sectitle: 'Discover Popular Destination',
        seccontent: 'Explore most popular destination and places, Morbi convallis bibendum urna ut viverra. Maecenas,',
        destinations: [
            {
                img: destinationimg1,
                cityFlag: flag1,
                cityName: 'Canada',
                listingTxt: '12 listings',
                boxCol: '4',
                cardUrl: '#'
            },
            {
                img: img1,
                cityFlag: flag2,
                cityName: 'United States',
                listingTxt: '22 listings',
                boxCol: '4',
                cardUrl: '#'
            },
            {
                img: img1,
                cityFlag: flag3,
                cityName: 'United Kingdom',
                listingTxt: '32 listings',
                boxCol: '4',
                cardUrl: '#'
            },
            {
                img: img1,
                cityFlag: flag4,
                cityName: 'Mexico',
                listingTxt: '44 listings',
                boxCol: '3',
                cardUrl: '#'
            },
            {
                img: img1,
                cityFlag: flag5,
                cityName: 'Costa Rica',
                listingTxt: '48 listings',
                boxCol: '3',
                cardUrl: '#'
            },
            {
                img: destinationimg6,
                cityFlag: flag6,
                cityName: 'Australia',
                listingTxt: '50 listings',
                boxCol: '3',
                cardUrl: '#'
            },
            {
                img: destinationimg6,
                cityFlag: flag7,
                cityName: 'New Zealand ',
                listingTxt: '14 listings',
                boxCol: '3',
                cardUrl: '#'
            },
            {
                img: destinationimg6,
                cityFlag: flag8,
                cityName: 'France',
                listingTxt: '20 listings',
                boxCol: '6',
                cardUrl: '#'
            },
            {
                img: destinationimg6,
                cityFlag: flag9,
                cityName: 'Italy',
                listingTxt: '14 listings',
                boxCol: '6',
                cardUrl: '#'
            }
        ],
        viewmorebtn: 'View All Destination',
        viewmorebtnurl: '/all-locations'
    },
    locations: [
        {
            img: flag1,
            title: 'United states (2)',
            titleUrl: '#'
        },
        {
            img: flag2,
            title: 'London (4)',
            titleUrl: '#'
        },
        {
            img: flag3,
            title: 'Australia (7)',
            titleUrl: '#'
        },
        {
            img: flag4,
            title: 'Japan (9)',
            titleUrl: '#'
        },
        {
            img: flag5,
            title: 'France (12)',
            titleUrl: '#'
        },
        {
            img: flag6,
            title: 'Russia (14)',
            titleUrl: '#'
        },
        {
            img: flag7,
            title: 'New Zealand (17)',
            titleUrl: '#'
        },
        {
            img: flag8,
            title: 'India (19)',
            titleUrl: '#'
        },
        {
            img: flag9,
            title: 'Netherlands (15)',
            titleUrl: '#'
        },
        {
            img: flag10,
            title: 'Sweden (17)',
            titleUrl: '#'
        },
        {
            img: flag11,
            title: 'Saudi Arabia (29)',
            titleUrl: '#'
        },
        {
            img: flag12,
            title: 'Scotland (10)',
            titleUrl: '#'
        },
        {
            img: flag13,
            title: 'Canada (43)',
            titleUrl: '#'
        },
        {
            img: flag14,
            title: 'Mexico (45)',
            titleUrl: '#'
        },
        {
            img: flag15,
            title: 'Bangladesh (50)',
            titleUrl: '#'
        },
        {
            img: flag16,
            title: 'South Africa (60)',
            titleUrl: '#'
        },
        {
            img: flag17,
            title: 'Pakistan (48)',
            titleUrl: '#'
        },
        {
            img: flag18,
            title: 'Costa Rica (33)',
            titleUrl: '#'
        },
        {
            img: flag19,
            title: 'Italy (44)',
            titleUrl: '#'
        },
        {
            img: flag20,
            title: 'Thailand (55)',
            titleUrl: '#'
        }
    ],
    addesign:{
         videoImg: adDesign
    },
    howitworks: {
        hiw1: {
            sectitle: 'Plan The Marketing Campaign of Your Dreams',
            seccontent: 'Use technology to precisely target exactly who you want to reach.',
            items: [
                {
                    id: 1,
                    icon: Id1,
                    title: 'Identify Your Target',
                    description: 'Identify your location and business type and we will do the rest.'
                },
                {
                    id: 2,
                    icon: Id2,
                    title: 'Build Your Campaign',
                    description: 'Choose the plan that works best for you and your budget.'
                },
                {
                    id: 3,
                    icon: Id3,
                    title: 'Set it and Forget It',
                    description: 'Accelerate your growth with consistent hyper-targeted advertising.'
                }
            ],
        },
        hiw2: {
            sectitle: 'Target Your Exact Customer',
            seccontent: 'When your ideal customer enters into a target location they will see your advertisement.',
            items: [
                {
                    active: false,
                    icon: targets,
                    title: 'Targets',
                    description: 'Select the best places to precisely target your advertising.'
                },
                {
                    active: false,
                    icon: personalized,
                    title: 'Personalized',
                    description: 'Select additional criteria like income, children, gender, age and education.'
                },
                {
                    active: false,
                    icon: nearby,
                    title: 'Nearby Attractions',
                    description: 'Add in nearby locations such as parks, malls, schools, and neighborhoods.'
                },
                {
                    active: true,
                    icon: andmore,
                    title: 'And More...',
                    description: 'Get started and in 5 minutes you can find your next customer.'
                }
            ]
        },
        hiw3: {
            sectitle: 'How Can I Earn Money with Dirto?',
            seccontent: 'Dirto provides the various ways that help you can earn money from your site. Morbi convallis bibendum urna ut viverra.',
            videobtn: 'watch how it works',
            videoid: 'R2kiP9Qu7Pc',
            videoImg: hiw3videoimg,
            items: [
                {
                    id: 1,
                    icon: <FaRegMoneyBillAlt />,
                    title: 'Paid Listings',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.'
                },
                {
                    id: 2,
                    icon: <FcLineChart />,
                    title: 'Promoted Listings',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.'
                },
                {
                    id: 3,
                    icon: <FaRegHeart />,
                    title: 'Paid Claim Listings',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam luctus, finibus tellus ut, convallis eros.'
                }
            ]
        },
        hiw4: {
            sectitle: 'Why Choose Us',
            seccontent: 'Connect to hyper-local audiences with pinpoint precision.  Through the use of geofencing and demographic data, your next customer is just one step away. ',
            items: [
                {
                    icon: <FiCheck />,
                    title: 'We Are Professional',
                    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,'
                },
                {
                    icon: <FiCheck />,
                    title: 'Best Service Guarantee',
                    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,'
                },
                {
                    icon: <FiCheck />,
                    title: 'We Are Trusted and Secured',
                    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,'
                },
                {
                    icon: <FiCheck />,
                    title: 'Online Support 24/7 ',
                    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,'
                }
            ]
        },
        hiw5: {
            sectitle: 'Get Started With Dirto!',
            seccontent: 'Hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero.',
            items: [
                {
                    id: 1,
                    icon: <BsPencil />,
                    title: 'Pick a Keyword',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam',
                },
                {
                    id: 2,
                    icon: <GiPositionMarker />,
                    title: 'Select Location',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam',
                },
                {
                    id: 3,
                    icon: <TiArrowUnsorted />,
                    title: 'Select Category',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam',
                },
                {
                    id: 4,
                    icon: <FiCheck />,
                    title: 'View Results',
                    desc: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam',
                }
            ],
            videoBg: hiw5videoimg,
            videoBtn: 'watch how it works',
            videoid: 'R2kiP9Qu7Pc'
        },
        hiw6: {
            sectitle: 'How Dirto Works',
            seccontent: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
            items: [
                {
                    id: 1,
                    icon: <FaSearchPlus />,
                    title: 'Find a Place',
                    description: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam'
                },
                {
                    id: 2,
                    icon: <FaRegMap />,
                    title: 'Select a location',
                    description: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam'
                },
                {
                    id: 3,
                    icon: <FaSort />,
                    title: 'Select a Category',
                    description: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam'
                },
                {
                    id: 4,
                    icon: <FiCheckCircle />,
                    title: 'See Result',
                    description: 'Proin dapibus nisl ornare diam varius ecos tempus. Aenean a quam'
                }
            ]
        },
        hiw7: {
            sectitle: 'Why Choose Us',
            seccontent: 'Connect to hyper-local audiences with pinpoint precision.  Through the use of geofencing and demographic data, your next customer is just one step away. ',
            items: [
                {
                    icon: <FiHeadphones />,
                    title: '24/7 Made Easy',
                    desc: 'Our systems enable you to accurately and precisely advertise to your ideal client 24/7/365.'
                },
                {
                    icon: <FaCogs />,
                    title: 'We’ve Got Your Back',
                    desc: 'Regular performance reporting and personal consultations with our team will help you boost campaign performance over time.'
                },
                {
                    icon: <FiThumbsUp />,
                    title: 'Drive Growth',
                    desc: 'When your target customer visits competitors or local points of interest, you\'ll be right there with them.'
                }
            ]
        }
    },
    placesgrid: [
        {
            bedge: 'New Open',
            title: 'FavMaorite Place Food Bank',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Restaurant',
            cardTypeIcon: <GiChickenOven />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '204',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.5'
        },
        {
            bedge: 'Closed',
            title: 'beach blue boardwalk',
            titleIcon: '',
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Travel',
            cardTypeIcon: <RiPlaneLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'Hotel Govendor',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Hotel',
            cardTypeIcon: <RiHotelBedLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'Monkey Place Food Bank',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Restaurant',
            cardTypeIcon: <GiChickenOven />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '204',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.5'
        },
        {
            bedge: 'Closed',
            title: 'beach blue boardwalk',
            titleIcon: '',
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Travel',
            cardTypeIcon: <RiPlaneLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'Hotel Govendor',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Hotel',
            cardTypeIcon: <RiHotelBedLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
    ],
    placesgridmoreitems: [
        {
            bedge: 'New Open',
            title: 'Mone  Place Food Bank',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image:img1,
            cardType: 'Restaurant',
            cardTypeIcon: <GiChickenOven />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '204',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.5'
        },
        {
            bedge: 'Closed',
            title: 'beach blue boardwalk',
            titleIcon: '',
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Travel',
            cardTypeIcon: <RiPlaneLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'Hotel Govendor',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Hotel',
            cardTypeIcon: <RiHotelBedLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'dd Place Food Bank',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Restaurant',
            cardTypeIcon: <GiChickenOven />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '204',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.5'
        },
        {
            bedge: 'Closed',
            title: 'beach blue boardwalk',
            titleIcon: '',
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Travel',
            cardTypeIcon: <RiPlaneLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'Hotel Govendor',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Hotel',
            cardTypeIcon: <RiHotelBedLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'Closed',
            title: 'beach blue boardwalk',
            titleIcon: '',
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Travel',
            cardTypeIcon: <RiPlaneLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
        {
            bedge: 'New Open',
            title: 'Hotel Govendor',
            titleIcon: <IoIosCheckmarkCircle />,
            titleUrl: '/listing-details',
            stitle: 'Bishop Avenue, New York',
            image: img2,
            cardType: 'Hotel',
            cardTypeIcon: <RiHotelBedLine />,
            author: authorimg,
            authorUrl: '#',
            number: '(492) 492-4828',
            website: 'www.mysitelink.com',
            date: 'Posted 1 month ago',
            view: '248',
            ratings: [
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStar />,
                <IoMdStarHalf />,
                <IoMdStar className="last-star" />,
            ],
            ratingNum: '4.6'
        },
    ],
    listing: {
        sidebar: {
            widgetFilterCategory: {
                title: 'Filter by Category',
                items: [
                    {
                        id: 1,
                        cat: 'item',
                        catNum: 11
                    },
                    {
                        id: 2,
                        cat: 'item',
                        catNum: 22
                    },
                    {
                        id: 3,
                        cat: 'item',
                        catNum: 9
                    },
                    {
                        id: 4,
                        cat: 'item',
                        catNum: 11
                    },
                    {
                        id: 5,
                        cat: 'item',
                        catNum: 12
                    },
                    {
                        id: 6,
                        cat: 'item',
                        catNum: 48
                    },
                    {
                        id: 7,
                        cat: 'item',
                        catNum: 23
                    },
                    {
                        id: 8,
                        cat: 'item',
                        catNum: 28
                    },
                    {
                        id: 9,
                        cat: 'item',
                        catNum: 24
                    },
                    {
                        id: 10,
                        cat: 'item',
                        catNum: 40
                    },
                    {
                        id: 11,
                        cat: 'item',
                        catNum: 23
                    },
                    {
                        id: 12,
                        cat: 'item',
                        catNum: 18
                    },
                    {
                        id: 13,
                        cat: 'item',
                        catNum: 65
                    },
                    {
                        id: 14,
                        cat: 'item',
                        catNum: 34
                    },
                    {
                        id: 15,
                        cat: 'item',
                        catNum: 20
                    }
                ]
            },
            widgetFilterTags: {
                title: 'Filter by Tags',
                tags: [
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    },
                    {
                        path: '#',
                        title: 'item'
                    }
                ]
            },
            widgetFilterPriceTags: {
                title: 'Limit Search By Price',
                tags: [

                    {
                        path: '1',
                        title: '$'
                    },
                    {
                        path: '2',
                        title: '$$'
                    },
                    {
                        path: '3',
                        title: '$$$'
                    },
                    {
                        path: '4',
                        title: '$$$$'
                    }
                ]
            },
            widgetFilterFeatures: {
                title: 'Filter by Features',
                features: [
                    {
                        id: 1,
                        text: 'item'
                    },
                    {
                        id: 2,
                        text: 'item'
                    },
                    {
                        id: 3,
                        text: 'item'
                    },
                    {
                        id: 4,
                        text: 'item'
                    },
                    {
                        id: 5,
                        text: 'item'
                    },
                    {
                        id: 6,
                        text: 'item'
                    },
                    {
                        id: 7,
                        text: 'item'
                    },
                    {
                        id: 8,
                        text: 'item'
                    },
                    {
                        id: 9,
                        text: 'item'
                    },
                    {
                        id: 10,
                        text: 'item'
                    },
                    {
                        id: 11,
                        text: 'item'
                    },
                    {
                        id: 12,
                        text: 'item'
                    },
                    {
                        id: 13,
                        text: 'item'
                    },
                ]
            },
            widgetSortby: {
                title: 'Sort by',
                items: [
                    {
                        id: 1,
                        title: 'Best sellers'
                    },
                    {
                        id: 2,
                        title: 'Newest'
                    },
                    {
                        id: 3,
                        title: 'Best Rated'
                    },
                    {
                        id: 4,
                        title: 'Oldest'
                    }
                ]
            },
            widgetPostedby: {
                title: 'Posted by',
                items: [
                    {
                        title: 'Dealer'
                    },
                    {
                        title: 'individual'
                    },
                    {
                        title: 'Reseller'
                    }
                ]
            },
        },
        listinglists: [
            {
                bedge: 'New Open',
                title: 'dd Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                bedge: 'Closed',
                title: 'beach blue boardwalk',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Travel',
                cardTypeIcon: <RiPlaneLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'Favorite Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                bedge: 'Closed',
                title: 'beach blue boardwalk',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Travel',
                cardTypeIcon: <RiPlaneLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
        ]
    },
    listingDetails: {
        title: 'Tasty Hand-Pulled Noodles',
        description: 'Nemo ucxqui officia voluptatem accu santium doloremque laudantium, totam rem ape dicta sunt dose explicabo. Nemo enim ipsam voluptatem quia voluptas. Excepteur sint occaecat cupidatat non proident, sunt in culpa kequi officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan tium dolorem que laudantium, totam rem aperiam the eaque ipsa quae abillo was inventore veritatis keret quasi aperiam architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
        descriptiontitle: 'Description',
        featurestitle: 'Features',
        featureslists: [
            {
                icon: <BsCheckCircle />,
                title: 'Instant Noodles'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Smoking Allowed'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Bike Parking'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Wireless Internet'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Street Parking'
            },
            {
                icon: <BsCheckCircle />,
                title: 'Accepts Credit Cards'
            }
        ],
        videotitle: 'Video',
        videoImg: listingdetailsvideoimg,
        videoid: 'R2kiP9Qu7Pc',
        videobtn: 'Watch Video',
        contactinfos: {
            title: 'Contact Information',
            address: '101 East Parkview Road, New York',
            email: 'example@gmail.com',
            number: '+7(111)123456789',
            website: 'www.techydevs.com',
            websiteUrl: 'https://techydevs.com',
            socials: [
                {
                    icon: <FaFacebookF />,
                    title: 'facebook',
                    url: 'https://facebook.com'
                },
                {
                    icon: <FaTwitter />,
                    title: 'twitter',
                    url: 'https://twitter.com'
                },
                {
                    icon: <FaInstagram />,
                    title: 'instagram',
                    url: 'https://instagram.com'
                },
                {
                    icon: <FaLinkedinIn />,
                    title: 'linkedinIn',
                    url: 'https://linkedin.com'
                },
                {
                    icon: <FaYoutube />,
                    title: 'youtube',
                    url: 'https://youtube.com'
                }
            ]
        },
        comments: [
            {
                img: team1,
                name: 'Adam Smith',
                date: 'Reviewed 2 Days ago',
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                stars: [
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStarHalf />,
                ],
                replyBtn: 'Reply',
                replyBtnIcon: <RiReplyLine />,
                replyComments: [
                    {
                        img: team1,
                        name: 'Julian Smith',
                        date: 'Reviewed 3 Days ago',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                        stars: [
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStarHalf />,
                        ],
                        replyBtn: 'Reply',
                        replyBtnIcon: <RiReplyLine />,
                    }
                ]
            },
            {
                img: team1,
                name: 'Matt Derry',
                date: 'Reviewed 4 Days ago',
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                stars: [
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                ],
                replyBtn: 'Reply',
                replyBtnIcon: <RiReplyLine />,
                replyComments: []
            }
        ],
        sidebar: {
            widgetAuthor: {
                authorImg: team2,
                authorName: 'Mark Williamson',
                date: 'Posted 3 Days ago',
                address: '101 Parkview, New York',
                email: 'example@gmail.com',
                number: '+7(111)123456789',
                website: 'www.techydevs.com',
                websiteUrl: 'https://techydevs.com',
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <FaTwitter />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://instagram.com'
                    }
                ]
            },
            widgetStaticsInfo: {
                title: 'Statics info',
                lists: [
                    {
                        icon: <BsListCheck />,
                        text: '12 Listings'
                    },
                    {
                        icon: <MdStarBorder />,
                        text: '4.4 Ratings'
                    },
                    {
                        icon: <BsBookmark />,
                        text: '24 Bookmark'
                    },
                    {
                        icon: <BsEye />,
                        text: '745 Views'
                    },
                    {
                        icon: <FiExternalLink />,
                        text: '120 Share'
                    },
                    {
                        icon: <FaRegComment />,
                        text: '20 Comments'
                    },
                    {
                        icon: <FiHeart />,
                        text: '120 Likes'
                    }
                ]
            },
            widgetOpenHours: {
                title: 'Opening Hours',
                stitle: 'now open',
                items: [
                    {
                        day: 'Monday',
                        time: '9am - 5pm',
                        close: false,
                    },
                    {
                        day: 'Tuesday',
                        time: '9am - 5pm',
                        close: false,
                    },
                    {
                        day: 'Wednesday',
                        time: '9am - 5pm',
                        close: false,
                    },
                    {
                        day: 'Thursday',
                        time: '9am - 5pm',
                        close: false,
                    },
                    {
                        day: 'Friday',
                        time: '9am - 5pm',
                        close: false,
                    },
                    {
                        day: 'Sat-Sun',
                        time: 'Closed',
                        close: true,
                    }
                ]
            },
            widgetCategories: {
                title: 'Categories',
                lists: [
                    {
                        cat: 'Restaurant',
                        catNum: 11,
                        url: '#'
                    },
                    {
                        cat: 'Shop',
                        catNum: 32,
                        url: '#'
                    },
                    {
                        cat: 'Fitness',
                        catNum: 21,
                        url: '#'
                    },
                    {
                        cat: 'Event',
                        catNum: 24,
                        url: '#'
                    },
                    {
                        cat: 'Bar',
                        catNum: 14,
                        url: '#'
                    },
                    {
                        cat: 'Salon',
                        catNum: 11,
                        url: '#'
                    },
                    {
                        cat: 'Beauty',
                        catNum: 10,
                        url: '#'
                    },
                ]
            },
            widgetTags: {
                title: 'Tags Cloud',
                lists: [
                    {
                        text: 'Travel',
                        url: '#'
                    },
                    {
                        text: 'Restaurant',
                        url: '#'
                    },
                    {
                        text: 'Gym',
                        url: '#'
                    },
                    {
                        text: 'Food',
                        url: '#'
                    },
                    {
                        text: 'Office',
                        url: '#'
                    },
                    {
                        text: 'Bar',
                        url: '#'
                    },
                    {
                        text: 'Health',
                        url: '#'
                    },
                    {
                        text: 'Car Parking',
                        url: '#'
                    },
                    {
                        text: 'Nightlife',
                        url: '#'
                    },
                    {
                        text: 'Doctor',
                        url: '#'
                    },
                    {
                        text: 'Bike Parking',
                        url: '#'
                    },
                ]
            },
            widgetSimilarListing: {
                title: 'Similar Listings',
                items: [
                    {
                        img: similarimg,
                        title: 'The best sale marketer of the next year',
                        titleUrl: '/blog-single',
                        price: '$19.00',
                        cat: 'Gym & Fitness',
                        catUrl: '#',
                        stars: [
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStarHalf />,
                        ]
                    },
                    {
                        img: similarimg,
                        title: 'How to make your ideas became true',
                        titleUrl: '/blog-single',
                        price: '$20.00',
                        cat: 'Restaurant',
                        catUrl: '#',
                        stars: [
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStarHalf />,
                        ]
                    },
                    {
                        img: similarimg,
                        title: 'What gets in the way of strategy',
                        titleUrl: '/blog-single',
                        price: '$19.00',
                        cat: 'Art & Design',
                        catUrl: '#',
                        stars: [
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStarHalf />,
                        ]
                    },
                    {
                        img: similarimg,
                        title: 'What gets in the way of strategy',
                        titleUrl: '/blog-single',
                        price: '$19.00',
                        cat: 'Outdoors',
                        catUrl: '#',
                        stars: [
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStarHalf />,
                        ]
                    },
                ]
            },
            widgetFollowConnect: {
                title: 'Follow & Connect',
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <FaTwitter />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://instagram.com'
                    }
                ]
            }
        }
    },
    userprofile: {
        sidebar: {
            img: team2,
            name: 'Mark Williamson',
            date: 'Joined 4 years ago',
            address: '101 Parkview, New York',
            number: '+7(111)123456789',
            email: 'example@gmail.com',
            website: 'www.techydevs.com',
            websiteUrl: 'https://techydevs.com',
            socials: [
                {
                    icon: <FaFacebookF />,
                    url: 'https://facebook.com'
                },
                {
                    icon: <FaTwitter />,
                    url: 'https://twitter.com'
                },
                {
                    icon: <FaInstagram />,
                    url: 'https://instagram.com'
                }
            ]
        }
    },
    dashboard: {
        breadcrumbimg: breadcrumbimg,
        cards: [
            {
                img: img2,
                title: 'Favorite Place Food Bank',
                subtitle: 'Bishop Avenue, New York',
                editTxt: 'Edit',
                editIcon: <FaRegEdit />,
                deleteTxt: 'Delete',
                deleteIcon: <FaRegTrashAlt />,
                cardLink: '/listing-details'
            },
            {
                img: img2,
                title: 'Beach Blue Boardwalk',
                subtitle: 'Bishop Avenue, New York',
                editTxt: 'Edit',
                editIcon: <FaRegEdit />,
                deleteTxt: 'Delete',
                deleteIcon: <FaRegTrashAlt />,
                cardLink: '/listing-details'
            },
            {
                img: img2,
                title: 'Hotel Govendor',
                subtitle: 'Bishop Avenue, New York',
                editTxt: 'Edit',
                editIcon: <FaRegEdit />,
                deleteTxt: 'Delete',
                deleteIcon: <FaRegTrashAlt />,
                cardLink: '/listing-details'
            },
            {
                img: img2,
                title: 'Favorite Place Food Bank',
                subtitle: 'Bishop Avenue, New York',
                editTxt: 'Edit',
                editIcon: <FaRegEdit />,
                deleteTxt: 'Delete',
                deleteIcon: <FaRegTrashAlt />,
                cardLink: '/listing-details'
            },
            {
                img: img2,
                title: 'Beach Blue Boardwalk',
                subtitle: 'Bishop Avenue, New York',
                editTxt: 'Edit',
                editIcon: <FaRegEdit />,
                deleteTxt: 'Delete',
                deleteIcon: <FaRegTrashAlt />,
                cardLink: '/listing-details'
            },
            {
                img: img2,
                title: 'Hotel Govendor',
                subtitle: 'Bishop Avenue, New York',
                editTxt: 'Edit',
                editIcon: <FaRegEdit />,
                deleteTxt: 'Delete',
                deleteIcon: <FaRegTrashAlt />,
                cardLink: '/listing-details'
            }
        ],
        userImg: userimage,
        userName: 'Mark Williamson',
        userbio: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium',
        address: '101 Parkview, New York',
        phoneNum: '+7(111)123456789',
        website: 'www.techydevs.com',
    },
    teamdata: {
        sectitle: 'Our Expert Team Members',
        seccontent: 'Extensive experience in entrepreneurship, technology, advertising, and marketing combine to create campaigns that work. ',
        teams: [
            {
                img: allenimage,
                title: 'Allen Warren',
                titleUrl: '#',
                stitle: 'Founder & Managing Member',
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <FaTwitter />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://instagram.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    }
                ]
            },
            {
                img: lizimage,
                title: ' Elizabeth Nourse',
                titleUrl: '#',
                stitle: 'Operations',
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <FaTwitter />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://instagram.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    }
                ]
            },
            {
                img: joeimage,
                title: 'Joseph Miller',
                titleUrl: '#',
                stitle: 'Technology & Innovation',
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <FaTwitter />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://instagram.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    }
                ]
            },
            {
                img: headshot,
                title: 'TBD',
                titleUrl: '#',
                stitle: 'Coming Soon',
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <FaTwitter />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://instagram.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    }
                ]
            },

        ]
    },
    pricingplan: [
        {
            icon: <IoMdPaperPlane />,
            title: 'Silver Community Plan',
            default: false,
            miles: 3,
            price: '595',
            currency: '$',
            mo: 'Per Month',
            features: [
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: '61,875 Guaranteed Ad Views',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Select community locations within 3-mile radius (up to 20)',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Targeted by age',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by education',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by home value',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by gender',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by family demographics',
                },
                {
                    icon: <MdClose />,
                    iconClr: 'text-danger',
                    title: 'and many more demographic measures...'
                },
                {
                    icon: <MdClose />,
                    iconClr: 'text-danger',
                    title: 'No competitors'
                },
                {
                    icon: <MdClose />,
                    iconClr: 'text-danger',
                    title: 'No custom market area'
                },
                {
                    icon: <MdClose />,
                    iconClr: 'text-danger',
                    title: 're-targeting of current customers'
                },

            ],
            buttonTxt: 'Continue',
            buttonUrl: '#',
            active: false
        },
        {
            icon: <RiPlaneLine />,
            title: 'Gold Combination Plan',
            default: true,
            miles: 5,
            price: '995',
            currency: '$',
            mo: 'Per Month',
            features: [
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: '105,625 Guaranteed Ad Views',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'AutoSelect competitor locations within 5 mile radius (up to 20)',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Select community locations within 5 mile radius (up to 20)',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Targeted by age',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by education',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by home value',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by gender',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by family demographics',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'and many more demographic measures...'
                },
                {
                    icon: <MdClose />,
                    iconClr: 'text-danger',
                    title: 'No custom market area'
                },

            ],
            buttonTxt: 'Continue',
            buttonUrl: '#',
            active: true
        },
        {
            icon: <IoIosRocket />,
            title: 'Platinum Competitive Advantage Plan',
            default: false,
            miles: 5,
            price: '1495',
            currency: '$',
            mo: 'Per Month',
            features: [
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: '155,625 Guaranteed Ad Views',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Custom drawn market area',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Select competitor locations (up to 60)',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Select community points of interest, including shopping centers, parks, and schools',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'Targeted by age',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by education',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by home value',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by gender',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'targeted by family demographics',
                },
                {
                    icon: <FiCheck />,
                    iconClr: 'text-success',
                    title: 'and many more demographic measures...'
                },

            ],
            buttonTxt: 'Continue',
            buttonUrl: '#',
            active: false
        }
    ],
    aboutdata: {
        imagebox: [
            {
                img: imgboximg,
                title: 'Careers',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo',
                cardLink: '#'
            },
            {
                img: imgboximg,
                title: 'Newsroom',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo',
                cardLink: '#'
            },
            {
                img: imgboximg,
                title: 'Investor Relations',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo',
                cardLink: '#'
            }
        ],
        aboutst2: {
            title: 'Who We Are',
            content1: 'We are a group of digital marketers and technology specialists focused solely on implementing geofencing technology for our clients. Our team members are Centro Certified and Simpli.fi trained in Addressable Audiences to keep us up to date on new technology as it becomes available. We pride ourselves in being ultra available and providing insight and recommendations that our clients demand. Our award-winning marketing professionals have 50+ years of combined experience in strategic marketing, creative, branding, media planning, account management, internet marketing and new business development.',
            //content2: 'Our award-winning marketing professionals have 50+ years of combined experience in strategic marketing, creative, branding, media planning, account management, internet marketing and new business development.',
            items: [
                {
                    img: about1img,
                    boxClass: 'mt-4'
                },
                {
                    img: about2img,
                    boxClass: ''
                },
                {
                    img: about3img,
                    boxClass: 'mt-4'
                },
                {
                    img: about4img,
                    boxClass: ''
                }
            ]
        },
        aboutst3: {
            title: ' How to Build a Campaign that Drives Growth',
            subtitle1: 'Start with A Customer Mindset',
            subtitle2: 'A Blend of Locations Drives More Customer Reach',
            subtitle3: 'Influence a Great Campaign - Go Where Your Customers Are',
            subtitle4: 'Add Nearby Places Help to Reach a Broad Audience',
            subtitle5: 'Leverage Your Current Customers - Stay Top of Mind',
            content1: 'One of the best ways to make your geofencing campaign a success is to think about the type of person that is your ideal customer.  What types of things so they like to do?  Where would they be most likely to visit?  What other companies, stores, or places would they be likely to shop? Think carefully about normal everyday behaviors and patterns of your ideal customer, to build a strong geofencing campaign.',

            content2: 'The most successful campaigns are usually a blend of a few types of local places, such as competitors, local parks, and local shopping areas.  Typically, a well-balanced campaign will include a wide variety of local competitors, which are geographically close to your primary location, or that serve a very similar customer base or product.',
            
            content3: 'For example, a local gym looking to grow their business would choose to target local parks as geofence zones, since parks are frequently visited by people who like to exercise.  They would target baseball, basketball, or soccer fields to market to members of sports teams.  They would also target other local gyms, to find existing gym users that may be unhappy or might not be aware of their gym.  Local health food grocery stores would be a great addition, to find people that are focusing on being healthy.  Local physical rehabilitation centers, vitamin stores, health-focused restaurants or cafes, or sports equipment stores are all good targets, to find people the most likely to consider joining a gym.',   
            
            content4: 'A local restaurant building a strong-performing geofence campaign has lots of great options as well.  For example, a restaurant serving Thai food, might choose to target a nearby restaurant selling Asian fusion cuisine, as well as other nearby restaurants serving food at a similar price point.  They could target a local Asian food supermarket as well as an International foods market nearby. An ideal campaign would also include targeting other stores in the same location or shopping center.  For example, a restaurant in a local strip shopping center could also advertise to people visiting the grocery store next door and the spa on the other side of their restaurant.  They could include the bank directly in front of their restaurant and the Starbucks Coffee shop two doors down. This would ensure that people already used to visiting the locations nearest to their restaurant are aware of it and will consider giving it a try!  The restaurant could also target other very close by landmarks, such as parks, which are geographically close to the restaurant.',
            
            content5: 'Many companies or people using geofencing also have current customers mailing list or a current interest list, and they can use this list in their geofencing campaign as a core component of their marketing strategy.  Using GeoPhenom for your geofencing campaign allows you to market to these people and stay top-of-mind.  Referrals and repeat customers can be the most effective type of marketing you can do, to grow your business. Using GeoPhenom, you can easily upload a list of your current interest list, former client list, or other email lists, and GeoPhenom will include those locations in your customized geofence campaign.  Your interest list or current customer list can include addresses from anywhere in the United States.',
            
            content6: 'Geofencing your client and prospect list ensures that you stay top-of-mind with your current clients or prospective clients, as well as their friends and family.  Anyone who visits their home, such as friends, family, or co-workers, will also begin to see your marketing.  It’s a fantastic way to use your existing client base to get new clients, since they are likely to ask for referrals or recommendations from their friends.  When a friend of a current client asks about a recommendation for a great real estate agent, for example, your name is more likely to be top-of-mind for both the person asking for the recommendation and for the person giving it! ',
            items: [
                {
                    img: great1,
                    boxClass: 'mt-4'
                },
                {
                    img: great2,
                    boxClass: ''
                },
                {
                    img: great3,
                    boxClass: 'mt-4'
                },
                {
                    img: great4,
                    boxClass: ''
                }
            ]
        },
        aboutst4: {
            titles:['Reach People Where They Go',
            'Deliver Location-Based Ads to Targeted Areas',
            'Here’s How Your Ads Reach Your Geo-Targets',
            'Geofencing Goes Beyond Mobile',
            'Measure Your Success, In Real-Time',
            'Optimize Campaigns with Comprehensive Reporting', 
            'Identify Your Target & Conversion Zones',
            'Build Your Geofences to Any Size - Large or Small',
            'Got a Few Minutes to Grow Your Business?'],
            contents:['Want to reach potential customers in specific and targeted locations? With geofencing, you can do just that, and GeoPhenom’s platform makes it simple and easy to get started, no matter your experience with digital marketing technology.',
            'A location-based geofencing campaign takes just minutes to create, thanks to GeoPhenom’s easy-to-use platform. Simply select the places where you would like to reach people, and GeoPhenom creates a virtual boundary (or geofence) around those locations. Your geofence can surround a park, church or nearly any place you choose, including competitive businesses. Anyone who then visits that area could then get targeted with your ads.',
            'Here’s how geofencing works: Location-based services on your smartphones, tablets and other devices identify where you are located. When someone enters your geofenced area, their device registers where they are and they are then targeted with your ads. They could be reading an article in Safari on their iPhone or playing a game in an app when your ad appears, increasing their awareness of your business or encouraging them to visit your location.',   
             'While your geofencing campaign will reach most clients on their mobile devices, there is potential to hit those same prospects on their tablets, laptops and more. If a person enters your geofence and their mobile phone is connected to their tablet or computer – any device that offers web browser access - via a shared account, your ads may then present to them on those devices, too.',
             ['An outdoor billboard is great, but you can’t quantify the ROI like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.',<br/>,<br/>,'It’s better reporting than you’ll get from any traditional advertising or marketing effort. Period.'],
             'Once your geofencing campaign is running, you’ll receive regular reports on its reach, including how many guests visited your store after going to one of your geofenced locations. You’ll see which geofences deliver the best results, so you can optimize your campaign over time.',
             'When you set up a campaign and draw geofences, those areas become your Target zones – the places where you are targeting new and existing customers. Your business (and its physical location or website) then becomes the Conversion zone – the place where you want those targets to visit.',
             'The beauty of geofencing is that you can target a single address, a group of addresses or something as large as shopping center or even a neighborhood. It’s your choice. From a single competitor to a local church or sports field,you can reach the right potential customers to grow your business with GeoPhenom.',
             'Let’s get started! In just a few minutes, your locationtargeted advertising campaign can be live and working to help grow your business. You’ll reach future guests at your competitors, local parks, nearby shopping areas and nearly anywhere else you think your prospects could be. You’re just a couple clicks away from building a well-balanced campaign to reach thousands of potential new clients.',
            ],
  
            items: [
                {
                    img: reach,
                },
                {
                    img: deliver,
                    boxClass: true
                },
                {
                    img: reachtarget
                },
                {
                    img: beyondmobile
 
                },
                {
                    img: measuresuccess
                },
                {
                    img: optimizecampaigns,
                    boxClass: true
                },
                {
                    img: idconversion
                },
                {
                    img: buildyour
                },
                {
                    img: gotafew
                }
            ]
        },
        services: {
            titles:[
            'Drive Business to Your Company, Quickly & Easily',
            'Attract New Customers in Minutes',
            'So…What Exactly Is Geofencing?',
            'Easily Reach Qualified Prospects',
            'Design Custom Mobile Ads With Just a Few Clicks',
            'Why Location-Based Marketing Works So Well for Home Service Providers',
            'Connect With DIY and/or Your Competitors\' Customers',
            'Retarget Your Current Clients to Stay Top of Mind',
            'Promote Special Offers With Just a Few Clicks.',
            'Measure Your Advertising Success',
            'In-Depth Reporting of Your ROI',
            'How to Build Your Location-Based Marketing Campaign',
            'Stay Top of Mind With Your Current Customers',
            'Got a Few Minutes? Get Started Today.' ],
            
            contents:['Want to reach potential customers at specific and targeted locations – like the DIYers who\'ll soon be in need of a professional or the first-time homeowners who need help with some plumbing, HVAC or electrical updates? Tired of not knowing if your advertising efforts are reaching the right people?  GeoPhenom can help. Using our geofencing platform, you can reach your prospective customers, as well as your current clients based on where they go and where they\'ve been. Cool, right?',
            'You don\'t need to be a marketing and tech genius to create successful geofencing campaigns with GeoPhenom. Using our platform, you can easily identify the areas you want to target (your virtual fences), as well as your conversion zones (your company website or physical location), and create your ads. In just a few minutes, your ads will be up and running, drawing traffic to your business.',
             'Geofencing allows you to advertise to people in a certain geographic area. You simply create a virtual fence (a “geofence”) around the place you want to reach, and anyone in that area is now part of your target audience, via their mobile devices. Virtually any area can be geofenced—from your local park to a competitor\'s business. Pick the boundaries and get started!',
             'Because you decide which locations to geofence (like an adjacent service outlet or home repair store), you have a much better chance to reach qualified prospects when compared to traditional advertising.  Especially important is that you can focus on those who are more likely to be in the market for your services.',
             'Ready to get started but don\'t have an ad designed yet? No problem. Using GeoPhenom\'s customized templates, it\'s simple and easy to build a custom ad and get your message out there. You can also upload pre-designed creative, if you\'d prefer. GeoPhenom\'s technology uniquely enables you to start a successful location-based mobile marketing campaign in minutes!',
              'You know your current customers – where they shop, go to school, live and play – and using location-based marketing like our geofencing solutions, you can reach people just like them (and even target some of your existing clients, too). GeoPhenom\'s platform leverages comprehensive location data, so you can reach those prospective clients, quickly, easily and effectively.',
              'Want to reach customers who are trying to DIY their HVAC, plumbing, electrical and more? Simply add local hardware or big-box repair stores to your list of geofence targets and reach those qualified prospects. Quickly add relevance and consideration for your home service business.',
              'Have a great offer to present current customers? Geofence your own business or upload a client list to GeoPhenom, and your ads will begin to target visitors at your own location or website – keeping your business top of mind and potentially increasing sales. The opportunities to grow your business with GeoPhenom and location-based advertising are virtually endless.',
              'Get the word out to your clients and competitors\' customers about your latest service offerings or specials, driving more traffic to your business and closing more deals. Your clients might not remember (or even know) to book seasonal maintenance or they might not think updating the look-and-feel of their kitchen is as easy as switching out fixtures, all of which you can do. Leverage a geofencing campaign to promote these types of services direct to your current customer base. Simply create ad that showcases your special offers, select your geofence/target areas and watch the business grow.',
              ['An outdoor billboard is great, but you can\'t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.',<br/>,<br/>,'It\'s better reporting than you\'ll get from any traditional advertising or marketing effort. Period.'],
              'At GeoPhenom, reporting on your campaign\'s success is just as easy as setting it up. Once your campaign is active, we\'ll deliver comprehensive reporting on its effectiveness. You\'ll gain valuable insights, such as how many people have seen your ads (also called impressions), visited your store or clicked on your site, among other useful metrics. You\'ll see which geofences deliver the best results, so you can optimize your campaign over time.',
            ['Options are limitless when building geofencing campaigns for your service business. For example, you could target nearby competitors, as well as retail stores where a homeowner might go to purchase supplies to repair items that your team of experts also handles. An ideal campaign could even target local real estate and settlement offices, where new homeowners are closing on their properties and may be in need of electrical, plumbing, HVAC and other maintenance services for their new homes.',<br/>,<br/>,'You could also target landmarks and attractions, such as neighborhood parks, that are in the areas where you do most of your business. Your ads will increase awareness of your business and its service offerings amongst those people who live where you work, so they may consider your business next time they\'re in need of home repair services.'],
              'Don\'t forget your current clients! Marketing to repeat customers is one the most effective ways to grow a business. You can make repeat and referral marketing part of your strategy with GeoPhenom, leveraging client lists, email lists and even interest lists to stay in touch with your existing customers.',
               'It\'s that easy to start your geofencing campaign. There are no ad agencies to work with or extensive contracts to sign. Through our easy-to-use online platform, you create and launch campaigns in minutes. You are just a few clicks away from increasing awareness and driving more customers to your business – let\'s get started.'] ,
  
            items: [
                {
                    img: driveb,
                },
                {
                    img: attractn,
                    boxClass: true
                },
                {
                    img: sow
                },
                {
                    img: easilyreach
 
                },
                {
                    img: designc
                },
                {
                    img: whyl,
                    boxClass: true
                },
                {
                    img: connectw
                },
                {
                    img: retargety
                },
                {
                    img: promotes
                },
                {
                    img: measurey,
                    boxClass: true
                },
                {
                    img: ind
                },
                {
                    img: howtobuild
                },
                {
                    img: staytop
                },
                {
                    img: gotafewm,
                    boxClass: true
                }
            ]
        },

        recruiting: {
            titles:[
            'Attract Potential Employees in Minutes',
            'How Geofencing Works',
            'How to Get Started – No Training Required',
            'Professional Ad Templates Ready to Use',
            'Qualified Job Candidates Just a Few Clicks Away',
            'How to Build Your Recruitment Geofencing Campaign',
            'Geofencing Works for Retention Efforts, Too',
            'Promote Open Positions With Just a Few Clicks',
            'Detailed Reports Prove Your Success',
            'Put Our Reports to Work for You',
            'Got a Few Minutes to Grow Your Candidate Base?' ],
            
            contents:['Not finding the right candidates on job board sites? Or getting overloaded with resumes without the necessary qualifications? Reach the right candidates for your open positions with a strategic geofencing campaign. Using GeoPhenom’s online service, you can reach potential job candidates based on where they go and where they have been.',
             'Geofencing enables you to advertise to people in a certain geographic area. You create a virtual fence, called a “geofence,” around the place you want to reach people and anyone who travels there becomes part of your audience, via their mobile devices. You can geofence virtually anywhere, from a local university campus to your competitor’s headquarters. ',
             'As a small business owner or HR professional, marketing might not be your forte, but with GeoPhenom, you’ll become a geofencing pro. First, identify the areas you want to target – like a convention center that’s hosting a job fair or your competitor’s offices. On our platform, you’ll create your geofences around these areas and then identify your conversion zones—that’s where you want individuals who see your ads to go (your physical location or a recruiting website). From there, you’ll create and launch your ads, almost instantly reaching new applicants.',
             'Ready to get started but don’t have an ad designed (or even a designer to create one)? No problem. Using GeoPhenom’s custom ad templates, it’s simple and easy to get your message out there. You can also upload predesigned creative, if you’d prefer. GeoPhenom’s technology uniquely enables you to start a successful location-based mobile recruitment marketing campaign in minutes! ',
             'Because you decide which locations to geofence (like a nearby office complex or local military base), you have a much better chance to reach qualified candidates for your company’s open positions when compared to traditional advertising. Especially important is that you can focus on those who are more likely to be interested in your opportunities, whether it’s someone who lives nearby and wants a short commute or a recent graduate looking for a first job.',
             ['Human Resources (HR) teams and corporate recruiters have unlimited options for building location-based recruitment campaigns. For example, let’s say your restaurant needs to hire new employees for the summer. You could target the local university to reach college students who might need work while classes are out of session. Likewise, you could geofence nearby restaurants – or even grocery stores – to reach employees who could be interested in new opportunities.',<br/>,<br/>,'Perhaps you’re looking to hire veterans or increase your organization’s diversity? You can geofence communities near local military bases, as an example, and reach service members in and around the area. Likewise, you can create geofences around event centers, local Chambers of Commerce and other business development organizations who host career fairs and other programs targeted at individuals like those you’re looking to hire.',<br/>,<br/>,'Don’t forget about businesses that are closing or relocating. Their employees may be looking for new opportunities and by seeing your geofenced ad, they’ll know of the positions you have available. Likewise, geofencing your competitors help you reach qualified candidates in your industry.'] ,
             'Don’t forget your current employees! Marketing to your current team members is not only an effective way to grow your team, but also keep them engaged with your company. For example, you can advertise a hiring referral bonus, where current employees get a bonus for bringing successful candidates on board. You can either leverage current employee lists or geofence your office locations to make retention and referral marketing part of your strategy.',
              'Get the word out about your latest job openings, driving more traffic to your career website and sourcing more qualified candidates. By leveraging geofencing as part of your recruitment marketing strategy, you can promote new positions, as well as those hard-to-fill spots, targeting candidates with the right skillsets (possibly at your competitors) or with the right education (attending your local college). ',
              ['Outdoor billboards are great, but you can’t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.',<br/>,<br/>,'It’s better reporting than you’ll get from any traditional advertising or marketing effort. Period.'],
              'Once your recruitment campaign is running, you’ll receive regular reports on your campaign’s reach, including how many people visited your career site or office after visiting one of your geofenced locations. You’ll see which geofences deliver the best results, so you can optimize your campaign over time.',
            'Let’s get started! In just a few minutes, your location-targeted advertising campaign can be live and working to attract qualified candidates to your team. You’ll reach future employees at your competitors, local schools and universities, area job fairs and nearly anywhere else you think your prospects could be. You’re just a couple clicks from building a well-balanced campaign to reach thousands of potential new clients.'] ,
  
            items: [
                {
                    img: attractn,
                },
                {
                    img: sow,
                    boxClass: true
                },
                {
                    img: notraining
                },
                {
                    img: professionalads
 
                },
                {
                    img: connectwith
                },
                {
                    img: geofencingbasics,
                    boxClass: true
                },
                {
                    img: smileygraphic
                },
                {
                    img: jobgraphic
                },
                {
                    img: putourreporting
                },
                {
                    img: indepthreporting,
                    boxClass: true
                },
                {
                    img: gotafewminutes
                }
            ]
        },

        realestaterecruiting: {
            titles:[
            'Attract Potential Employees in Minutes',
            'How Geofencing Works',
            'How to Get Started – No Training Required',
            'Ad Design Templates to Set Your Brokerage Apart',
            'Qualified Real Estate Agents Just a Few Clicks Away',
            'How to Build Your Real Estate Recruiting Geofencing Campaign',
            'Geofencing Works for Retention Efforts, Too',
            'Promote Your Brokerage With Just a Few Clicks',
            'Detailed Reports Prove Your Success ',
            'Put Our Reports to Work for You',
            'Got a Few Minutes?' ],
            
            contents:['Know who you want to join your real estate brokerage, but having trouble connecting with them through traditional advertising? Target the Realtors you want to join your firm with a strategic geofencing recruiting campaign. Using GeoPhenom\'s online marketing service, you can recruit new (and experienced) real estateagents based on where they go and where they have been.',
             'With geofencing, you can advertise to people in a certain geographic area. Simply create a virtual fence, called a "geofence," around the place you want to reach people (like a local real estate school) and anyone who travels there becomes part of your audience, via their mobile devices. You can geofence virtually anywhere, from a local real estate association to your competitions\' sales offices. ',
             ['As a managing broker or sales office leader, your daysare busy, and finding time for recruiting may be hard. But with GeoPhenom, you\'ll simplify the task and even if you\'re not a marketing or tech expert, you\'ll have professional, effective campaigns up and running in minutes. It\'s as easy as 1, 2, 3!',<br/>,<br/>,'1. Identify the areas you want to target - like a local real estate school, your competitions sales offices or even listings with open houses.',<br/>,<br/>,'2. Using GeoPhenom, create your geofences around these areas and then identify your conversion zonesthat\'s where you want individuals who see your ads to go (your physical location or a recruiting website).',<br/>,<br/>,'3. Create your ads (choose from pre-built templates or upload your own) and launch your campaign. '],
             'Ready to get started but don’t have an ad designed (or even a designer to create one)? No problem. Using GeoPhenom’s custom ad templates, it’s simple and easy to get your message out there. You can also upload predesigned creative, if you’d prefer. GeoPhenom’s technology uniquely enables you to start a successful location-based mobile recruitment marketing campaign quickly and easily! ',
             'Because you decide which locations to geofence, you have a much better chance to reach the real estate agents you want to attract when compared to traditional advertising. Especially important is that you can focus on those who are more likely to be interested in your opportunities, whether it’s someone who works for a competing brokerage, lives nearby and wants a short commute, or recently earned their real estate license.',
             'In real estate, there are unlimited options for building location-based recruitment campaigns. Not only can you target individuals who are attending real estate courses at a local school or university, but also you can reach existing agents at your competitors’ brokerages. Likewise, you can geofence open houses near your own real estate sales office, reaching both clients and agents who visit those properties. You can also geofence your local Realtor association and MLS offices, as well as business event spaces that Realtors often attend. Don’t forget about targeting other businesses with sales teams –like luxury retail stores or even high-end automotive dealerships. Their employees may be looking for new opportunities and by seeing your geofenced ad, they’ll know of the sales positions at your brokerage. It’s an easy way to reach qualified and experienced new entrants to the real estate business.' ,
             'Don’t forget your current agents! Marketing to your existing team keeps them better engaged and aware of what you have to offer –either in the form of benefits or bonuses. For example, you can advertise a referral bonus, where current agents get a bonus for bringing successful candidates on board. You can either leverage current agent lists or geofence your office locations to make retention and referral marketing part of your strategy. ',
              'Get the word out about your brokerage and all you have to offer real estate pros, driving more traffic to your career website and sourcing more qualified candidates. By leveraging geofencing as part of your recruitment marketing strategy, you’ll be a step ahead in reaching the best agents in the business. ',
              ['Outdoor billboards and print ads are great, but you can’t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it. ',<br/>,<br/>,'It’s better reporting than you’ll get from any traditional advertising or marketing effort. Period.'],
              'Once your recruitment campaign is running, you’ll receive regular reports on your campaign’s reach, including how many people visited your career site or office after visiting one of your geofenced locations. You’ll see which geofences deliver the best results, so you can optimize your campaign over time.',
            'Let’s Get Started. Your location-targeted advertising campaign can be live and working to attract qualified agents to your team today. You’ll reach experienced, new and prospective real estate agents nearly anywhere you think they could be. You’re just a couple clicks from building a well-balanced campaign to grow your team and your brokerage business.'] ,
  
            items: [
                {
                    img: attractn,
                },
                {
                    img: sow,
                    boxClass: true
                },
                {
                    img: notraining
                },
                {
                    img: professionalads
 
                },
                {
                    img: connectwith
                },
                {
                    img: geofencingbasics,
                    boxClass: true
                },
                {
                    img: smileygraphic
                },
                {
                    img: jobgraphic
                },
                {
                    img: putourreporting
                },
                {
                    img: indepthreporting,
                    boxClass: true
                },
                {
                    img: gotafewminutes
                }
            ]
        },





        retail: {
            titles:[
            'Attract New Customers in Minutes',
            'So…What Exactly Is Geofencing?',
            'No Training Needed – Get Started Today',
            'Professional Ads Designed for You',
            'Why Location-Based Marketing Works for Retail Storess',
            'Easily Reach Qualified Prospects',
            'How to Build Your Retail Store\'s Geofencing Campaign',
            'Connect With Your Competitors\' Customers',
            'Stay Top of Mind With Your Current Customers',
            'Don\'t Forget to “Target” Your Own Store',
            'Promote New Products & Special Offers With Just a Few Clicks',
            'Detailed Reports Prove Your Success',
            'Put Our Reporting to Work for You',
            'Got a Few Minutes to Grow Your Business?' ],
            
            contents:[


                'Want to reach potential clients at specific and targeted locations? Tired of not knowing if your advertising efforts are reaching the right people? GeoPhenom can help. Using our geofencing platform, you can reach prospective clients, as well as current customers, based on where they go and where they\'ve been. Cool, right?',
                
                
                'Geofencing allows you to advertise to people in a certain geographic area. You simply create a virtual fence (a “geofence”) around the place you want to reach, and anyone in that area is now part of your target audience, via their mobile devices. Virtually any area can be geofenced—from a specific address to your local park to a competitor\'s store. Pick the boundaries and get started!',
                
                
                'You don\'t need to be a marketing and tech genius to create successful geofencing campaigns with GeoPhenom. Using our platform, you can easily identify the areas you want to target (your virtual fences), as well as your conversion zones (your store\'s physical location or its website – even your Etsy shop), and create your ads. In just a few minutes, your marketing campaign will be up and running, drawing traffic to your store.',
                
                
                'Ready to get started but don\'t have an ad designed yet? No problem. Using GeoPhenom\'s customized templates, it\'s simple and easy to build a custom ad and get your message out there. You can also upload your own pre-designed creative, if you\'d prefer. GeoPhenom\'s technology uniquely enables you to start a successful location-based mobile marketing campaign in minutes!',
                
                
                'You know your current customers – where they dine, go to school, live, play, even other places they like to shop – and using location-based marketing like our geofencing solutions, you can reach people just like them (and even target some of your existing clients, too). GeoPhenom\'s platform leverages comprehensive location data, so you can reach those prospective clients, quickly, easily and effectively.',
                
                
                'Because you decide which locations to Geofence (like an adjacent retail store or competitive shop), you have a much better chance to reach qualified prospects when compared to traditional advertising. Especially important is that you can focus on those who are more likely to be shopping for the products you have to offer.',
                
                
                ['Local retailers have limitless options when building location-based marketing campaigns. For example, a boutique gift shop could target local department stores and big-box outlets that sell similar items. They also could target other retailers near their own store, especially if they\'re located in a complex with lots of traffic. If someone is shopping near your store, they might be inclined to stop by your shop if they see your ad.' ,<br/>,<br/>,'You could also target other close-by landmarks and attractions, such as neighborhood parks. Your ads will increase awareness of your retail store amongst those people who frequent areas near your business, so they may consider your shop next time they\'re looking for a unique gift or product.'],
                
                
                'Want to reach customers who are shopping at your competitors\' store? Simply add those locations to your list of geofence targets and reach those qualified prospects. Quickly add relevance and consideration for your retail store amongst individuals in market for your products and services.',
                 
                
                'Don\'t forget your current clients! Marketing to repeat customers is one the most effective ways to grow a business. You can make repeat and referral marketing part of your strategy with GeoPhenom, leveraging client lists, email lists and even interest lists to stay in touch with your existing customers.',
                
                
                'In addition to reaching your current customers via email lists, make sure to geofence your own location, so ads will target visitors there – keeping your retail outlet top of mind and potentially increasing visits. The opportunities to grow your business with GeoPhenom and location-based advertising are virtually endless.',
                
                
                'Get the word out to your clients and competitors\' customers about your newest inventory or latest specials, driving more traffic to your retail store and increasing sales. You can even leverage your geofencing campaign to drive traffic to your store\'s website. Simply create ad that showcases your special offers, select your target areas and watch the web traffic grow.',
                
                 
                ['An outdoor billboard is great, but you can\'t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.',<br/>,<br/>,'It\'s better reporting than you\'ll get from any traditional advertising or marketing effort. Period.'],
                
                
                'Once your campaign is active, we\'ll deliver comprehensive reporting on its effectiveness. You\'ll gain valuable insights, such as how many people have seen your ads (also called impressions), visited your retail store or clicked on your site, among other useful metrics. You\'ll know which geofenced locations deliver the best results, so you can optimize your campaigns over time.',
                
                
                'Let\'s get started! In just a few minutes, your location-targeted advertising campaign can be live and working to help grow your retail store. You\'ll reach future guests at your competitors, local parks, nearby shopping areas and nearly anywhere else your prospects could be. You\'re just a couple clicks away from increasing awareness and driving more customers to your store – let\'s get started.'] ,
  
            items: [
                {
                    img: attractnew,
                },
                {
                    img: sowhat,
                    boxClass: true
                },
                {
                    img: notraining
                },
                {
                    img: professionalads
 
                },
                {
                    img: whylocationb
                },
                {
                    img: easilyreachq,
                    boxClass: true
                },
                {
                    img: howtobuildy
                },
                {
                    img: connectwithy
                },
                {
                    img: staytopof
                },
                {
                    img: dontforgetto,
                    boxClass: true
                },
                {
                    img: promotenew
                },
                {
                    img: detailedreports
                },
                {
                    img: putourreporting
                },
                {
                    img: gotafewminutes,
                    boxClass: true
                }
            ]
        },
        restaurant: {
            titles:[
            'Drive Business to Your Location, Quickly & Easily',
            'So… What Exactly Is Geofencing?', 
            'Design Custom Mobile Ads With Just a Few Clicks',
            'Why Location-Based Marketing Works So Well for Restaurants', 
            'In-Depth Reporting to Measure Your Advertising Success',  
            'Geofencing Basics: Conversion & Target Zones',
            'How to Build Your Restaurant\’s Location-Based Marketing Campaign', 
            'Stay Top of Mind With Your Current Customers', 
            'Got a Few Minutes to Grow Your Business?'
         ],
            
            contents:[


                'Want to reach potential customers at specific and targeted locations? Tired of not knowing if your advertising efforts are reaching the right people?  GeoPhenom can help. Using our geofencing platform, you can reach your current guests (and future guests) based on where they go and where they\’ve been. Cool, right?',  
                'Geofencing allows you to advertise to people in a certain geographic area. You simply create a virtual fence (a “geofence”) around the place you want to reach, and anyone in that area is now part of your target audience, via their mobile devices. Virtually any area can be geofenced—from your local park to a competitor\’s restaurant. Pick the boundaries and get started!', 
                'Ready to get started but don\’t have an ad designed yet? No problem. Using GeoPhenom\’s customized templates, it\’s simple and easy to build a custom ad and get your message out there. You can also upload pre-designed creative, if you\’d prefer. GeoPhenom\’s technology uniquely enables you to start a successful location-based mobile marketing campaign in minutes!',   
                'You know your current customers – where they shop, go to school, live, play, even other places they like to eat – and using location-based marketing like our geofencing solutions, you can reach people just like them (and even target some of your existing clients, too). GeoPhenom\’s platform leverages comprehensive location data, so you can reach those prospective clients, quickly, easily and effectively.',  
                ['An outdoor billboard is great, but you can\’t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.',<br/>,<br/>,'It\’s better reporting than you\’ll get from any traditional advertising or marketing effort. Period.', <br/>,<br/>,<b>How it works</b>,<br/>,'Once your campaign is running, you\’ll receive regular reports on your campaign\’s reach, including how many guests visited your store after visiting one of your geofenced locations. You\’ll see which geofences deliver the best results, so you can optimize your campaign over time.'], 
                ['When you set up a campaign and draw geofences, those areas become your Target zones – the places where you are targeting new and existing customers. Your business (and its physical location) becomes the Conversion zone – the place where you want those targets to visit.', <br/>,<br/>,'Our reports leverage these identifiers to show you how many guests came from your Target zones and visited your store in person. And it does so with technology based on Google Maps, so it’s simple and easy to understand.'],  
                ['Local restaurants have limitless options when building geofencing campaigns. For example, a Thai restaurant could target a nearby restaurant selling Asian fusion cuisine, as well as cafes with similarly priced food. They also could target their local international or Asian food markets.', <br/>,<br/>,'An ideal campaign also could target other nearby stores. If someone is shopping near your restaurant, they might be inclined to dine with you if they see your ad while nearby – even if they are just visiting an adjacent grocery store, spa or other business.', <br/>,<br/>,'You could also target other close-by landmarks and attractions, such as neighborhood parks. Your ads will increase awareness of your restaurant amongst those people who frequent areas near your business, so they may consider your restaurant next time they\’re planning to dine out.'],  
                'Don\’t forget your current clients! Marketing to repeat customers is one the most effective ways to grow a business. You can make repeat and referral marketing part of your strategy with GeoPhenom, leveraging client lists, email lists and even interest lists to stay in touch with your existing customers.',  
                'Let\’s get started! In just a few minutes, your location-targeted advertising campaign can be live and working to help grow your business. You\’ll reach future guests at your competitors, local parks, nearby shopping areas and nearly anywhere else you think your prospects could be. You\’re just a couple clicks away from building a well-balanced campaign to reach thousands of potential new clients.'
            ] ,
  
            items: [
                {
                    img: drivebusiness,
                },
                {
                    img: sowhatexactly,
                    boxClass: true
                },
                {
                    img: designcustom
                },
                {
                    img: whylocationbasedm
 
                },
                {
                    img: indepthreporting
                },
                {
                    img: geofencingbasics,
                    boxClass: true
                },
                {
                    img: howtobuildyour
                },
                {
                    img: staytopof
                },
                {
                    img: gotafewminutes
                }
            ]
        },
        automotive: {
            titles:[
                'Attract New Customers in Minutes',
                'Easily Reach Qualified Prospects',
                'Connect With Your Competitors\’ Customers',
                'Retarget Your Current Clients to Stay Top of Mind',
                'Leverage Untapped Opportunities Nearby',
                'Promote New Incentives and Inventory With Just a Few Clicks',
                'Drive Traffic to Your Website',
                'Measure Your Results in Real-Time',
                'Got a Few Minutes? Get Started Today.'
         ],
            
            contents:[
                'You don\’t need to be a marketing and tech genius to create successful geofencing campaigns with GeoPhenom. Using our platform, you can easily identify the areas you want to target (your virtual fences), as well as your conversion zones (your dealership website or physical location) and create your ads. In just a few minutes, your ads will be up and running, drawing traffic to your car dealership.',
                'Because you decide which locations to Geofence (like an adjacent dealership, service outlet or independent dealer), you have a much better chance to reach qualified prospects when compared to traditional advertising. Especially important is that you can focus on those who are more likely to be in the market to buy or sell a vehicle or get their vehicle serviced.',
                'Want to reach customers who are shopping at a competitive dealership or service shop? Simply add those locations to your list of geofence targets and reach those qualified prospects. Quickly add relevance and consideration for your dealership.',
                'Have a great offer to present current customers? Geofence your own location or upload a client list to GeoPhenom, and your ads will begin to target visitors at your own location – keeping your dealership top of mind and potentially increasing visits. The opportunities to grow your business with GeoPhenom and location-based advertising are virtually endless.',
                'Retail stores, car washes, gas stations and independent repair shops all offer potential business opportunities for your dealership or service center. Make sure clients of these locations know about your dealership by adding them to your geofence targets. You can present special offers or the value of your dealership via your ads, and drive business and traffic to your store.',
                'Get the word out to your clients and competitors\’ customers about your latest manufacturer incentives and newest inventory, driving more traffic to your dealership and closing more deals. You can even remind prospects that your dealership often buys used cars in short inventory situations – a geofence around your nearest used car shop or CarMax is a great place to start.', 
                'Dealers often offer service and sales specials on their websites, and you can leverage your geofencing campaign to promote these opportunities and drive prospects to your site. Simply create an ad that showcases your special offers, select your geofence/target areas and watch the web traffic grow.',
                'At GeoPhenom, reporting on your campaign\’s success isjust as easy as setting it up. Once your campaign is active, we\’ll deliver comprehensive reporting on its effectiveness. You\’ll gain valuable insights, such as how many people have seen your ads (also called impressions), visited your store or clicked on your site, among other useful metrics.',
                'It\’s that easy to start your geofencing campaign. There are no ad agencies to work with or extensive contracts to sign. Through our easy-to-use online platform, you create and launch campaigns in minutes. You are just a few clicks away from increasing awareness and driving more customers to your dealership – let\’s get started.'
            ] ,
  
            items: [
                {
                    img: attractnew,
                },
                {
                    img: reach2,
                    boxClass: true
                },
                {
                    img: connectwithyour
                },
                {
                    img: retargety
 
                },
                {
                    img: leverageuntappedopp
                },
                {
                    img: promotenewincentives,
                    boxClass: true
                },
                {
                    img: drivetraffic2
                },
                {
                    img: measureyour
                },
                {
                    img: gotafewminutes
                }
            ]
        },
        realestate2: {
            titles:[
                'Attract New Buyers & Sellers in Minutes', 
                'So… What Exactly Is Geofencing?',
                'No Training Needed – Get Started Today',
                'Professional Ads Designed for You',
                'Why Location-Based Marketing Works for Realtors', 
                'Easily Reach Qualified Prospects', 
                'How to Build a Geofencing Campaign for Your Real Estate Business',
                'Connect With Your Competitors\' Customers',
                'Stay Top of Mind With Your Current Customers', 
                'Don’t Forget to “Target” Your Own Listings', 
                'Leverage Untapped Opportunities Nearby',
                'Promote New Listings With Just a Few Clicks',
                'Drive Traffic to Your Website',
                'Detailed Reports Prove Your Success',
                'Put Our Reports to Work for You',
                'Got a Few Minutes to Grow Your Business?'
         ],
            
            contents:[

                'Want to reach potential homebuyers and sellers – or even recruit real estate agents to your team – from specific and targeted locations? GeoPhenom can help. Using our geofencing platform, you can reach prospective clients, as well as current and past customers, based on where they go and where they\’ve been. Cool, right?', 
                'Geofencing allows you to advertise to people in a certain geographic area. You simply create a virtual fence (a “geofence”) around the place you want to reach, and anyone in that area is now part of your target audience, via their mobile devices. Virtually any area can be geofenced—from your local park to a competitor\’s listing or brokerage office. Pick the boundaries and get started!', 
                'You don\’t need to be a marketing and tech expert to create successful geofencing campaigns with GeoPhenom. Using our platform, you can easily identify the areas you want to target (your virtual fences), as well as your conversion zones (your real estate website, brokerage office or current listing), and create your ads. In just a few minutes, your ads will be up and running, drawing traffic to you.', 
                'Ready to get started but don\'t have any ads? No problem. Using GeoPhenom\’s customized templates, it\’s simple and easy to build a custom advertisement and get your message out there. You can also upload pre-designed creative, if you’d prefer. GeoPhenom\’s technology uniquely enables you to start a successful location-based mobile marketing campaign for your real estate business in minutes!', 
                'You know your current customers – where they shop, go to school, live, play, even other places they like to eat – and using location-based marketing like our geofencing solutions, you can reach people just like them (and even target some of your existing and past clients, too). GeoPhenom\’s platform leverages comprehensive location data, so you can reach those prospective clients quickly, easily and effectively.',  
                'Because you decide which locations to Geofence (like a specific neighborhood, homes that have just come on the market, or even a competitive brokerage office), you have a much better chance to reach qualified prospects when compared to traditional advertising.  Especially important is that you can focus on those who are more likely to be in the market to buy, sell or rent a home.',
                ['There are limitless opportunities for real estate agents and brokers to build location-based marketing campaigns. For example, a leading real estate team could target local real estate schools or competitive brokerages as part of an effort to attract new Realtors to their team. Likewise, they could geofence area colleges and universities, looking to introduce students to the world of real estate.', <br/>,<br/>,'An ideal campaign for a Realtor seeking new buyers and sellers could target specific communities where they often do business, as well as parks, restaurants and shops where their average clients frequent. You could also target mortgage lenders and banks, where homeowners and prospective buyers could be visiting. Your ads will increase awareness of your real estate services amongst those people in your geotargets.'],
                'Want to reach customers who are selling their homes with competitors or browsing your competitions\’ listings? Simply add those locations to your list of geofence targets and reach those qualified prospects. Quickly add relevance and consideration for your real estate business.',
                'Don\’t forget your current clients! Marketing to repeat customers is one the most effective ways to grow a business. You can make repeat and referral marketing part of your strategy with GeoPhenom, leveraging client lists, email lists and even interest lists to stay in touch with your existing buyers and sellers, as well as past clients who might be thinking of making a move soon.',
                'In addition to reaching your current customers via email lists, make sure to geofence your own real estate listings, so ads will target visitors there – keeping you top of mind and potentially increasing business opportunities and sales. The chances to grow your business with GeoPhenom and location-based advertising are virtually endless.',
                'Most homeowners are renters before they become buyers, so make sure to target popular apartment complexes and areas with high rental rates in your geofencing campaign. Make sure individuals in these locations know about your real estate services by adding them to your geofence targets. You can even present special offers like a complimentary market report or competitive market analysis (CMA) to drive business to you and your website.',  
                'Get the word out to your clients and competitors\’ customers about your new listings, driving more traffic to your agent website and increasing your business opportunities. You can even offer prospective clients access to downloadable content you have available for their use – such as a homebuyer\’s guide, moving checklist or seller\’s how-to manual.',  
                'Homebuyers and sellers start their searches online, and you want your real estate site to be the place where they begin. Using GeoPhenom, your geofencing campaign can promote your agent website as the place to start a new home search or learn about the home-selling prospect, and drive prospects there. Simply create an ad that showcases your site and its unique content, select your geofence/target areas and watch the web traffic grow.', 
                ['An outdoor billboard is great, but you can\’t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.', <br/>,<br/>,'It’s better reporting than you\’ll get from any traditional advertising or marketing effort. Period.'], 
                'Once your geofencing real estate campaign is running, you\’ll receive regular reports on its reach, including how many people visited your brokerage office or real estate website visiting one of your geofenced locations. You\’ll see which geofences deliver the best results, so you can optimize your campaign over time.', 
                'Let\’s get started! In just a few minutes, your location-targeted advertising campaign can be live and working to help grow your real estate business. You\’ll reach future buyers and sellers at your competitors, local parks, nearby shopping areas and nearly anywhere else you think your prospects could be. You\’re just a couple clicks away from building a well-balanced campaign to reach thousands of potential new clients.'
            ] ,
  
            items: [
                {
                    img: attractnew,
                },
                {
                    img: sowhatexactly,
                    boxClass: true
                },
                {
                    img: notrainingneeded
                },
                {
                    img: professionaladsdesign
 
                },
                {
                    img: whylocationbasedm
                },
                {
                    img: easilyreachqualified,
                    boxClass: true
                },
                {
                    img: howtobuildageofencing
                },
                {
                    img: connectwithyour
                },
                {
                    img: staytopof
                },
                {
                    img: dontforgetto,
                    boxClass: true
                },
                {
                    img: leverageuntapped
                },
                {
                    img: promotenewlistings
                },
                {
                    img: drivetraffic
                },
                {
                    img: detailedreportsprove
                },
                {
                    img: putourreports
                },
                {
                    img: gotafewminutes
                }
            ]
        },
        aboutus: {
            titles:['Who We Are','Why Choose Us?'],
            contents:[
                ['We are a group of digital marketers and technology specialists focused on implementing location-based marketing and geofencing technology for our clients. Our team members are Centro Certified and Simpli.fi trained in Addressable Audiences to keep us updated on new technology as it becomes available.', <br/>,<br/>,'We pride ourselves in being easily accessible and providing insight and recommendations that our clients demand, all with a focus on helping them grow their businesses.',<br/>,<br/>,'Our award-winning marketing professionals have 50+ years of combined experience in strategic marketing, creative design, branding, media planning, account management, digital marketing and new business development.'],
                ['We make it easy to connect to hyper-local audiences with pinpoint precision. Through the use of geofencing technology and demographic data, your next customer is just a few clicks away and campaigns can be mobilized in just minutes.']
            ],
            items:[                {
                img: whowearegraphic,
            },
            {
                img: whychooseus,
                boxClass: true
            }]
        },
        howtobuild: {
            titles:['Start with a Customer Mindset','Leverage a Blend of Locations','Go Where Your Customers Are','Make Sure to Include Nearby Attractions','Leverage Your Current Customers'],
            contents:[
                ['How do you make your geofencing campaign a success? Define the type of person who is your ideal customer. What do they like to do? Where are they most likely to visit? What other stores or places do they shop or dine, and what service providers – small businesses or national franchises - do they use? By thinking carefully about the typical behaviors and interests of your ideal customer, you’ll have a strong foundation on which to build your geofencing campaign.'],
                ['The most successful geofencing campaigns target a blend of locations, such as similar nearby businesses, local parks and area attractions, as well as neighborhood shopping centers. A well-balanced campaign also typically includes a wide variety of competitors – both those that are geographically close to your primary location and those that serve a similar customer base or sell comparable products.'],
                [`Let’s say you own a local gym and you’re looking to grow your membership. Your ideal clients are athletic and active, so you choose to target local parks and sports centers, which are frequented by people who like to exercise. You also include baseball, basketball and soccer fields in your geofenced, or targeted, areas to market to members of these sports teams. You also target other local gyms in hopes of finding potential gym members who are looking for someplace new.`,<br/>,<br/>,`Local health food or organic grocery stores are another great addition to your geofencing campaign, as their primary customers often are health-focused, just like your ideal client. Physical therapy and rehabilitation centers, vitamin stores, healthy restaurants and cafes, as well as sports equipment stores, also could make good targets for finding people most like to consider your gym.`],
                ['Options are limitless for businesses building successful geofencing campaigns. A Thai restaurant, for example, could target restaurants that sell the same cuisine or food at similar prices. They could also target nearby grocery stores or specialty markets.', <br/>,<br/>, 'Their ideal campaign also could target other nearby stores. If someone is shopping near the restaurant, they might be inclined to dine there when they see their ad— even if they are just visiting an adjacent grocery store, spa or other business.', <br/>,<br/>, 'The restaurant could also target close-by landmarks and attractions, such as neighborhood parks. Their ads help increase awareness of the restaurant amongst people who frequent areas nearby, so they may consider their restaurant next time they’re planning to dine out.'],
                ['Don’t forget your current clients! Marketing to repeat customers is one the most effective ways to grow a business. You can make repeat and referral marketing part of your strategy with GeoPhenom. Using GeoPhenom, you can target your current customers by uploading a list of:',<ul className='howlist'><li>Former and current clients, including their addresses (US only)</li><li>Email marketing prospects</li><li>Customer interests</li></ul>, 'Your GeoPhenom geofencing campaign can then include those locations in your customized campaign. It’s an easy way to connect with your current clients, as well as their friends and family, as anyone who visits their homes will also see your marketing efforts. Friends often share recommendations and referrals for everything from real estate agents to plumbers, so when a friend of a current client asks about you, you’re more likely to be top-of-mind for both the person asking for the recommendation and the person giving it!']
            ],
            items:[                {
                img: mindset,
            },
            {
                img: leverage,
                boxClass: true
            },
            {
                img: gowhere
            },
            {
                img: nearbyattract,
                boxClass: true

            },
            {
                img: leveragecurrent
            }]
        },
        financial: {
            titles:['Attract New Clients, Quickly & Easily','What Exactly Is Geofencing?','No Training Required – Get Started Today','Professional Ads Designed for You','Why Location-Based Marketing Works for Financial Services Professionals','Easily Reach Qualified Prospects','How to Build a Geofencing Campaign for Financial Services','Connect With Your Competitors’ Customers','Stay Top of Mind With Your Current Customers','Drive Traffic and Leads to Your Website','Comprehensive Reports Detail Your Success','Leverage our Reporting to Maximize ROI','Got a Few Minutes? Get Started Today.'],
            contents:[
                ['Looking to reach potential clients at specific and targeted locations? Tired of not knowing if your current advertising efforts are working? GeoPhenom can help you promote your insurance agency, investment company, accounting firm or mortgage lending business to the right audience. Using our geofencing platform, you can reach prospective and current clients, based on where they go and where they have been.'],
                ['Geofencing is a digital marketing technology that allows you to advertise to people in a certain geographic area. You create a virtual fence (a “geofence”) around the place you want to reach, and anyone in that area is now part of your target audience, via their mobile devices. Virtually any area can be geofenced—from a specific address to your local Chamber of Commerce to a competitor’s financial services office.'],
                ['You don’t need to be a marketing and technology expert to create successful geofencing campaigns with GeoPhenom. Using our platform, you can easily identify the areas you want to target (your virtual fences), as well as your conversion zones (your business website or its physical office), and create your ads. In just a few minutes, your ads will be up and running, drawing traffic to you.'],
                ['Using GeoPhenom’s customized templates, it’s easy to build a custom ad and get your message out there. You can also upload your own pre-designed creative, if you’d prefer. GeoPhenom’s technology uniquely enables you to start a successful location-based mobile marketing campaign in minutes!'],
                ['You know your current customers – where they work, shop, go to school, live, play, even where they vacation or spend their free time – and using location-based marketing like our geofencing solutions, you can reach people just like them (and even target some of your existing clients, too). GeoPhenom’s platform leverages comprehensive location data, so you can reach those prospective clients.'],
                ['Because you decide which locations to geofence (like a nearby Chamber of Commerce or competitive firm), you have a much better chance to reach qualified prospects when compared to traditional advertising. Especially important is that you can focus on those who are more likely to be in the market for your services.'],
                ['Financial services professionals have limitless options when building location-based marketing campaigns. For example, a wealth advisor or accountant could target nearby office complexes, as well as their community’s top businesses – areas where their potential clients work.',<br/>,<br/>,'An ideal campaign also could target local Chambers of Commerce, business associations and other establishments where C-suite executives and other prospective clients frequent. Your ads will increase awareness of your services amongst those individuals, so they may consider your firm for future business opportunities.'],
                ['Looking to reach clients who do business with your competitors or even those financial services firms rated as the area’s best in your local business journals? Simply add those locations to your list of geofence targets and reach those qualified prospects. Quickly add relevance and consideration for your insurance agency, mortgage bank, accounting firm, investment firm and financial services firm.'],
                ['Marketing to repeat customers is one the most effective ways to grow a business. You can make repeat and referral marketing part of your strategy with GeoPhenom, leveraging client lists, email lists and even interest lists to stay in touch with your existing customers. As an example, consider which services you offer that your current clients aren’t leveraging and target them with advertising to promote that offering.'],
                ['Prefer to direct clients and prospects to your website to learn more? Using GeoPhenom’s geofencing platform, you can drive traffic to your website and get the word out about your services. You can even promote content marketing pieces – like checklists and how-to guides - you have available for download, capturing leads for new business opportunities. Simply create an ad that showcases your special offer, select your geofence/target areas and watch the web traffic and leads grow.'],
                ['An outdoor billboard is great, but you can’t quantify the results like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it. It’s better reporting than you’ll get from any traditional advertising or marketing effort.'],
                ['Once your campaign is active, we’ll deliver comprehensive reporting on its effectiveness. You’ll gain valuable insights, such as how many people have seen your ads (also called impressions), visited your office or website, among other useful metrics. You’ll know which geofenced locations deliver the best results, so you can optimize your campaigns over time.'],
                ['It’s as easy as 1, 2, 3 to start your geofencing campaign. There are no advertising agencies to work with or complicated contracts to sign. Through our easy-to-use online platform, you create and launch campaigns in minutes. You are just a few clicks away from increasing awareness and business opportunities – let’s get started.']
            ],
            items:[                {
                img: attractc,
            },
            {
                img: reacht,
                boxClass: true
            },
            {
                img: notraining
            },
            {
                img: professionala

            },
            {
                img: whylocationb
            },
            {
                img: easilyreach,
                boxClass: true
            },
            {
                img: howtobuildag
            },
            {
                img: connectwith
            },
            {
                img: staytop
            },
            {
                img: drivet,
                boxClass: true
            },
            {
                img: comprehensiver
            },
            {
                img: levero
            },
            {
                img: gotafewm,
                boxClass: true
            }
        ]
        },
        aboutst5: {
            titles:['Attract New Customers in Minutes',
            'Reach a Targeted Audience',
            'Connect With Your Competitors\' Customers',
            'Retarget Your Current Clients to Stay Top of Mind',
            'Build a Fully Customized Campaign Unique to Your Business',
            'Get the Maximum Return on Your Investment', 
            'Measure Your Results in Real-Times',
            'Got a Few Minutes? Get Started Today.'],
            contents:['You don’t need to be a marketing and tech genius to create successful geofencing campaigns with GeoPhenom. Using our platform, you can easily identify the areas you want to target (your virtual fences), as well as your conversion zone (your website or physical location), and create customized ads. In just a few minutes, your ads will be up and running, drawing traffic to your business.',
            'A location-based geofencing campaign takes just minutes to create, thanks to GeoPhenom’s easy-to-use platform. Simply select the places where you would like to reach people, and GeoPhenom creates a virtual boundary (or geofence) around those locations. Your geofence can surround a park, church or nearly any place you choose, including competitive businesses. Anyone who then visits that area could then get targeted with your ads.',
            'Here’s how geofencing works: Location-based services on your smartphones, tablets and other devices identify where you are located. When someone enters your geofenced area, their device registers where they are and they are then targeted with your ads. They could be reading an article in Safari on their iPhone or playing a game in an app when your ad appears, increasing their awareness of your business or encouraging them to visit your location.',
            'While your geofencing campaign will reach most clients on their mobile devices, there is potential to hit those same prospects on their tablets, laptops and more. If a person enters your geofence and their mobile phone is connected to their tablet or computer – any device that offers web browser access - via a shared account, your ads may then present to them on those devices, too.',
            ['An outdoor billboard is great, but you can’t quantify the ROI like you can with geofencing and digital advertising. Not only does GeoPhenom guarantee how many people will see your advertisements (also called impressions), but also we provide comprehensive reporting to prove it.',<br/>,<br/>,'It’s better reporting than you’ll get from any traditional advertising or marketing effort. Period.'],
            'Once your geofencing campaign is running, you’ll receive regular reports on its reach, including how many guests visited your store after going to one of your geofenced locations. You’ll see which geofences deliver the best results, so you can optimize your campaign over time.',
            'When you set up a campaign and draw geofences, those areas become your Target zones – the places where you are targeting new and existing customers. Your business (and its physical location or website) then becomes the Conversion zone – the place where you want those targets to visit.',
            'The beauty of geofencing is that you can target a single address, a group of addresses or something as large as shopping center or even a neighborhood. It’s your choice. From a single competitor to a local church or sports field,you can reach the right potential customers to grow your business with GeoPhenom.'
            ],
  
            items: [
                {
                    img: attract,
                },
                {
                    img: reach2,
                    boxClass: true
                },
                {
                    img: connect
                },
                {
                    img: retarget
 
                },
                {
                    img: build2
                },
                {
                    img: maximum,
                    boxClass: true
                },
                {
                    img: measure
                },
                {
                    img: time,
                    boxClass: true
                }
            ]
        },
    },
    mostvisitedplaces: {
        sectitle: 'Most Visited Places',
        seccontent: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
        places: [
            {
                bedge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img1,
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                websiteUrl: 'https://example.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.7'
            },
            {
                bedge: 'New Open',
                title: 'Favorite Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                bedge: 'Closed',
                title: 'beach blue boardwalk',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Travel',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'sticky band party',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Event',
                cardTypeIcon: <IoMdMusicalNotes />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.4'
            },
            {
                bedge: 'Closed',
                title: 'Sena Clothing Shopping Mall',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Shop',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.7'
            },
            {
                bedge: 'New Open',
                title: 'Favorite Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: img2,
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            }
        ]
    },
    recommendedplaces: {
        sectitle: 'Places We Recommend You Visit',
        seccontent: 'Handpicked places by our team,Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero,',
        items: [
            {
                bedge: 'New Open',
                title: 'Favorite Place Food Bank',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: destinationimg6,
                cardType: 'Restaurant',
                cardTypeIcon: <GiChickenOven />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '204',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.5'
            },
            {
                bedge: 'Closed',
                title: 'beach blue boardwalk',
                titleIcon: '',
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: destinationimg6,
                cardType: 'Travel',
                cardTypeIcon: <RiPlaneLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
            {
                bedge: 'New Open',
                title: 'Hotel Govendor',
                titleIcon: <IoIosCheckmarkCircle />,
                titleUrl: '/listing-details',
                stitle: 'Bishop Avenue, New York',
                image: destinationimg6,
                cardType: 'Hotel',
                cardTypeIcon: <RiHotelBedLine />,
                author: authorimg,
                authorUrl: '#',
                number: '(492) 492-4828',
                website: 'www.mysitelink.com',
                date: 'Posted 1 month ago',
                view: '248',
                ratings: [
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStar />,
                    <IoMdStarHalf />,
                    <IoMdStar className="last-star" />,
                ],
                ratingNum: '4.6'
            },
        ]
    },
    dreamplaces: {
        sectitle: 'Explore Your Dream Places',
        seccontent: 'Explore most popular destination and places, Morbi convallis bibendum urna ut viverra. Maecenas,',
        places: [
            {
                img: dreamimg,
                country: 'united states',
                badge: 'Explore',
                placeNum: 5,
                listingNum: 250,
                cardColumn: 6,
                cardLink: '#'
            },
            {
                img: dreamimg,
                country: 'united kingdom',
                badge: 'Explore',
                placeNum: 7,
                listingNum: 220,
                cardColumn: 6,
                cardLink: '#'
            },
            {
                img: dreamimg,
                country: 'Australia',
                badge: 'Explore',
                placeNum: 8,
                listingNum: 80,
                cardColumn: 4,
                cardLink: '#'
            },
            {
                img: dreamimg,
                country: 'New zealand',
                badge: 'Explore',
                placeNum: 9,
                listingNum: 190,
                cardColumn: 4,
                cardLink: '#'
            },
            {
                img: dreamimg,
                country: 'Russia',
                badge: 'Explore',
                placeNum: 11,
                listingNum: 110,
                cardColumn: 4,
                cardLink: '#'
            }
        ],
        loadmoretext: 'Load More'
    },
    funfacts: {
        funfact1: {
            sectitle: 'Numbers Say Everything',
            seccontent: 'In just five minutes, you can precisely target your perfect customer.',
            counteritems: [
                {
                    id: '1',
                    start: 0,
                    end: 8757,
                    duration: '8',
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '+',
                    title: 'Competitors Targeted'
                },
                {
                    id: '2',
                    start: 0,
                    end: 5,
                    duration: '8',
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '',
                    title: 'Minutes to Start Your Campaign'
                },
                {
                    id: '3',
                    start: 0,
                    end: 5000000,
                    duration: '8',
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '+',
                    title: 'Ad Views'
                },
                {
                    id: '4',
                    start: 0,
                    end: 350,
                    duration: '8',
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '+',
                    title: 'Custom Options'
                }
            ]
        },
        funfact2: {
            sectitle: 'Numbers Say Everything',
            seccontent: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
            counteritems: [
                {
                    id: '1',
                    title: 'Projects Completed',
                    buttonTxt: 'join now',
                    buttonUrl: '#',
                    start: 0,
                    end: 1150,
                    duration: 8,
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '+'
                },
                {
                    id: '2',
                    title: 'Happy Clients',
                    buttonTxt: 'join now',
                    buttonUrl: '#',
                    start: 0,
                    end: 3040,
                    duration: 8,
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: ''
                },
                {
                    id: '3',
                    title: 'Cup of Coffee',
                    buttonTxt: 'join now',
                    buttonUrl: '#',
                    start: 0,
                    end: 2020,
                    duration: 8,
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '+'
                },
                {
                    id: '4',
                    title: 'Awards Winning',
                    buttonTxt: 'join now',
                    buttonUrl: '#',
                    start: 0,
                    end: 350,
                    duration: 8,
                    separator: '',
                    decimal: ',',
                    prefix: ' ',
                    suffix: '+'
                }
            ]
        },
        funfact3: [
            {
                id: 1,
                title: 'New Visitors Every Week',
                start: 0,
                end: 250,
                duration: 8,
                separator: '',
                decimal: ',',
                prefix: ' ',
                suffix: '+'
            },
            {
                id: 2,
                title: 'Happy customers every year',
                start: 0,
                end: 3040,
                duration: 8,
                separator: '',
                decimal: ',',
                prefix: ' ',
                suffix: '+'
            },
            {
                id: 3,
                title: 'Awards Won',
                start: 0,
                end: 172,
                duration: 8,
                separator: '',
                decimal: ',',
                prefix: ' ',
                suffix: ''
            },
            {
                id: 4,
                title: 'New Listing Every Week',
                start: 0,
                end: 150,
                duration: 8,
                separator: '',
                decimal: ',',
                prefix: ' ',
                suffix: '+'
            }
        ]
    },
    accordions: {
        sectitle: 'FAQs',
        seccontent: 'Get answers to your most commonly asked questions.',
        items: [
            {
                title: 'What is a geofence campaign?',
                desc: 'Geofence marketing is location-based marketing.  A geographic boundary zone is placed around a wide variety of places, like stores, restaurants, homes, or parks.  When a mobile device then enters into one of these zones, the geofence is triggered to present that device with your advertisement.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'Can I create a custom campaign?',
                desc: 'Yes, our platform makes it very easy to precisely reach your potential customers and clients, with a wide variety of options for selecting your ideal future customer.  You can select based on geographic areas, as well as demographic characteristics, such as income, size of household, and age.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How much time does it take to get my campaign online?',
                desc: 'Typically, we can launch your campaign in less than five business days.  (Need to verify).',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'Do I have to commit to a longer subscription period?',
                desc: 'Yes, because each campaign is completely customized, a commitment of six months is required.  This gives you enough time to ensure that you see results and get new leads.  You are able to edit your campaigns during the subscription period, if desired, to help ensure you get the results you want.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How does this work with privacy laws?',
                desc: 'As the most advanced location-based mobile advertising based on people’s physical activities and places they go, our geofencing technology allows you to reach your potential clients wherever they go, while maintaining privacy.  Using our precise polygon tracking, we can target actual physical addresses (not just entire blocks) where your ideal audience frequents.This service only requires location services on mobile device turned on. Approximately 90% of individuals typically have location services turned on.  We can then serve ads across thousands of apps and websites we have access to and can also retarget customers who visit or commute through any our geofenced locations.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'Is my campaign private and secure?',
                desc: 'Yes, your campaign is private and protected.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'What is a target zone?',
                desc: 'A virtual geofence is traced around an area where you want to target people based on their physical location such as physical addresses, points of interest or employers. When the person enters the geofence target location, they will then be served your ads on their mobile device.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'Where do mobile ads appear?',
                desc: 'We have coverage across 90% of all smartphone users in the U.S. Mobile ads will appear on any mobile apps or mobile websites that accept advertising, such as general sites like CNN, ESPN, TMZ or local blogs. Mobile ads can be various sizes and can rotate to deliver key messaging and branding.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How can I modify my campaign once I have created it?',
                desc: 'Using the edit functionality in your user dashboard, you can edit your campaign and modify it.  Additionally, you can always contact our team for extra support for your campaign.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How do I know it is working?',
                desc: 'You will be provided with usage reports during the course of your subscription.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How does this technology work?',
                desc: 'GeoPhenom runs on platforms such as Basis, Facebook, Google Ads, and Simpli.fi. Your GeoPhenom marketing team customizes the suite of technology to best suit your media plan and budget for digital ad buying. We find that the programmatic platform is purpose-built for localized advertising and have efficiently perfected the way we build, run, and track campaigns. We work as partners with major platforms because the most sophisticated geofencing technology on the market today is critical for this competitive real estate market. We offer an unparalleled scale and precision in mobile geofencing and attribution. Using our precise polygon tracking and bulk radius uploads, we can target actual physical addresses allowing for granular advertising never seen before.',
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            },
            {
                title: 'How is this type of marketing compliant with Fair Housing regulations?',
                desc: [<a href='https://www.hud.gov/program_offices/fair_housing_equal_opp/fair_housing_act_overview'>The Fair Housing Act</a>,' protects people from discrimination when they are renting or buying a home, getting a mortgage, seeking housing assistance, or engaging in other housing-related activities.  ',<a href='https://www.hud.gov/program_offices/fair_housing_equal_opp/non_discrimination_housing_and_community_development_0'>Additional protections</a>,' apply to federally-assisted housing.  In addition, your local jurisdiction might have additional laws and/or protected classes.  Ad compliance is the sole responsibility of the owner of the campaign.  We recommend contacting your broker or a real estate attorney to ensure your compliance with Fair Housing regulations for any campaign.'],
                plus: <FaPlus />,
                minus: <FaMinus />,
                cardClass: 'mb-3'
            }
        ]
    },
    iconboxes: {
        iconlist1: [
            {
                id: 1,
                icon: <FiUsers />,
                title: 'Ask Community',
                subtitle: 'Get help from 1M+ Revolut users'
            },
            {
                id: 2,
                icon: <FiBookOpen />,
                title: 'Read our blog',
                subtitle: 'Follow the latest news and stories'
            },
            {
                id: 3,
                icon: <AiOutlineQuestionCircle />,
                title: 'Get help in the app',
                subtitle: 'Just head to Help in the app'
            },
        ]
    },
    calltoactions: {
        cta1: {
            title: 'Geophenom is the best way to find & discover great new customers',
            content: 'Precisely target your ideal customer. We make it easy.',
            btntext: 'Let\'s Get Started',
            btnurl: '/list-map-view?place=-77.0364,38.8951&type=',
            shapes: [
                {
                    img: symble1
                },
                {
                    img: symble2
                },
                {
                    img: symble3
                },
                {
                    img: symble4
                }
            ]
        },
        cta2: {
            title: 'Dirto Android and IOS App is Available!',
            content: 'Omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet Lorem ipsum dolor sit amet.',
            img: cta2mobileimg,
            featurelists: [
                {
                    icon: <GoFile />,
                    text: 'Real Time Listing'
                },
                {
                    icon: <FiFilter />,
                    text: 'Budget Filter For Budget'
                },
                {
                    icon: <AiOutlineQuestionCircle />,
                    text: 'Notification Price Reduction'
                }
            ],
            shapes: [
                {
                    img: symble1
                },
                {
                    img: symble2
                },
                {
                    img: symble3
                },
                {
                    img: symble4
                }
            ]
        },
        cta3: {
            title: 'Dirto is the best way to find & discover great local businesses',
            content: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero',
            btntext: 'Create Account',
            btnurl: '/sign-up'
        },
        cta4: {
            title: 'Want to Join with us?',
            content: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
            btntext: 'Join Now',
            btnurl: '#'
        },
        newsletters: {
            title: 'Subscribe to Newsletter!',
            content: 'Subscribe to get latest updates and information.',
            btntext: 'Subscribe',
            inputplaceholder: 'Enter your email'
        }
    },
    contactdata: {
        title: 'Our Office',
        img: contactus,
        desc: 'We’re located in the Washington DC Region… but our virtual employees work here, there and everywhere.',
        address: '523 Benfield Rd. #203 Severna Park, MD 21146',
        phoneNum: '410-562-1983',
        email: 'customerservice@geophenomllc.com',
        opendays: 'Monday To Friday',
        opendaytime: '9am - 7pm',
        closeday: 'Saturday To Sunday',
        mapoverlay: {
            address: '523 Benfield Rd. #203',
            city: 'Severna Park, MD 21146',
            number: '410-562-1983',
            email1: 'customerservice@geophenomllc.com'
        }
    },
    creativedata: {
        sectitle: 'What Our Customers Have To Say',
        seccontent: 'GeoPhenom customers have experienced great success using our location-based digital campaigns.',
        sliders: [
            {
                id: 1,
                img: team1,
                title: 'Richard Doe',
                designation: '~ The Goodhart Team of Compass Realty',
                content: '“We started geofencing with Geophenom over two years ago and love their service!  We are able to stay top-of-mind with our current sphere of influence by targeting their personal home addresses, but also capture new leads and clients by promoting our services to specific neighborhoods and high traffic areas.  It has been a great marketing vehicle to promote recently sold homes and branding ads for our company.  The staff at Geophenom has been very responsive and offer great suggestions on how to maximize our marketing efforts each month.  I would highly recommend Geophenom to any business looking to brand and grow their client base.'
            },
            {
                id: 2,
                img: team1,
                title: 'Denwen Evil',
                designation: '~ Automotive client',
                content: 'Geofencing is a big part of our digital marketing mix.  We can identify our competitors in the marketplace and serve relevant display ads to them offering specials or new inventory to keep us in their consideration when purchasing a new vehicle.  We can even tell when the shopper has visited our dealership by the number of conversions we receive each month.   We have seen a huge lift in our service business too.  By identifying our customer base with special service-related packages, we are able to keep our message fresh and relevant to our customer base when they are in need of service.'            
            },
            {
                id: 3,
                img: team1,
                title: 'Collis Pong',
                designation: '~ Local Realtor',
                content: 'We started geofencing with Geophenom over two years ago and love their service!  We are able to stay top-of-mind with our current sphere of influence by targeting their personal addresses, but also capture new leads and clients by promoting our services to specific neighborhoods and high traffic areas.  It has been a great marketing vehicle to promote recent sold homes and branding ads for our company.  The staff at Geophenom has been very responsive and offer great suggestions on how to maximize our marketing efforts each month.  I would highly recommend Geophenom to any business looking to brand and grow their client base.'            
            },
            {
                id: 4,
                img: team1,
                title: 'Collis Pong',
                designation: '~ Real Estate Brokerage',
                content: 'The life blood of the real estate brokerage business is the ability to recruit and retain our real estate agents.  With the help of Geophenom, we have been able to do just that.  We target our competitor brokerages and agents in the market, display ads with our distinct value proposition, keeping our brand in front of our agents and new agents to grow our company.  Geofencing has been a great addition to our recruitment strategy by efficiently and effectively targeting our target market.'            
            },
            {
                id: 5,
                img: team1,
                title: 'Collis Pong',
                designation: '~ Local Realtor',
                content: 'Our bank was acquiring a number of local branches of another financial institution.  For us to communicate an effective marketing transition from one bank name to another, we worked with Geophenom to help with a digital marketing strategy that targeted all the bank branches we were acquiring with branded messages.  In addition, we were able to target other competitors in the marketplace and promote our personal/business checking and mortgage services.  We were very pleased with the campaign and would highly recommend Geophenom and their services.'            
            }
        ],
        tmimage: [
            {
                tmimg: team1
            },
            {
                tmimg: team2
            },
            {
                tmimg: team3
            },
            {
                tmimg: team4
            },
            {
                tmimg: team1
            },
            {
                tmimg: team6
            }
        ]
    },
    testimonialdata: {
        sectitle: 'Creative Ad Examples',
        seccontent: 'GeoPhenom customers have experienced great success using our location-based digital campaigns.'
    },
    blogsidebar: {
        wAuthor: {
            img: allensmall,
            name: 'Allen Warren',
            designation: 'Founder',
            content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolorem illo laborum magni, maxime omnis quam quod totam voluptatem voluptatibus?',
            sociallinks: [
                {
                    icon: <FaFacebookF />,
                    url: 'https://facebook.com'
                },
                {
                    icon: <FaTwitter />,
                    url: 'https://twitter.com'
                },
                {
                    icon: <FaLinkedinIn />,
                    url: 'https://linkedin.com'
                }
            ],
        },
        wPopularPosts: {
            title: 'Popular Posts',
            items: [
                {
                    img: mainimage,
                    title: 'The Thing About Growth',
                    titleLink: '/blog-single',
                    date: 'May 25, 2021',
                    author: 'Allen Warren',
                    authorUrl: 'https://techydevs.com',
                    cardClass: 'mb-3',
                },
                {
                    img: mainimage2,
                    title: 'Global Positioning and Geofencing',
                    titleLink: '/blog-single2',
                    date: 'June 1, 2021',
                    author: 'Allen Warren',
                    authorUrl: 'https://techydevs.com',
                    cardClass: 'mb-3',
                }
            ]
        }
    },
    blogfullwidth: {
        items: [
            {
                img: mainimage,
                title: 'The Thing About Growth',
                titleLink: '/blog-single',
                desc: 'One of the things I love the most about working with our clients, is that we’re all in the same boat. In many cases, our customers, just like our experience here at Geophenom, is one of a constant tug-of-war about how to balance steady growth, in a healthy way.  There’s no business owner I know that doesn’t go through crazy periods of stress and it’s hard to find solutions that save you time.  Time is always the most precious resource of our clients. ',
                date: 'May 25, 2021',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'Allen Warren',
                authorImg: allensmall,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: mainimage2,
                title: 'Global Positioning and Geofencing',
                titleLink: '/blog-single2',
                desc: 'One of the most interesting things about geofencing is how quickly the technology we use has changed in just the past few years.  It’s really quite incredible, when you think about the level of knowledge and ability we have now, to better understand our potential clients and customers.',
                date: 'June 1, 2021',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'Allen Warren',
                authorImg: allensmall,
                readmore: 'Read More',
                readmoreLink: '/blog-single2',
                likes: '340'
            },

        ],
        blgriditems: [
            {
                img: img2,
                title: '50 Greatest Event Places in United Kingdom',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 10 Best Clothing Shops In Sydney',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 15 Greatest Hotels In United States',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: '50 Greatest Event Places in United Kingdom',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 10 Best Clothing Shops In Sydney',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 15 Greatest Hotels In United States',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
        ],
        bl2colitems: [
            {
                img: img2,
                title: '50 Greatest Event Places in United Kingdom',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 10 Best Clothing Shops In Sydney',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 15 Greatest Hotels In United States',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: '50 Greatest Event Places In United Kingdom',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 10 Best Clothing Shops In Sydney',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 15 Greatest Hotels In United States',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 10 Best Clothing Shops In Sydney',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
            {
                img: img2,
                title: 'Top 15 Greatest Hotels In United States',
                titleLink: '/blog-single',
                desc: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                date: '12 Jan, 2020',
                meta: 'Tips & Tricks',
                metaLink: '#',
                author: 'David Wise',
                authorImg: authorimg,
                readmore: 'Read More',
                readmoreLink: '/blog-single',
                likes: '340'
            },
        ]
    },
    blogDetails: {
        img: mainimage,
        author: 'Allen Warren',
        authorImg: allensmall,
        date: 'May 25, 2021',
        meta: 'Tips & Tricks',
        metaLink: '#',
        likes: '480',
        title: 'The Thing About Growth',
        desc1: 'One of the things I love the most about working with our clients, is that we’re all in the same boat. ', 
        desc2: 'In many cases, our customers, just like our experience here at Geophenom, is one of a constant tug-of-war about how to balance steady growth, in a healthy way.  There’s no business owner I know that doesn’t go through crazy periods of stress and it’s hard to find solutions that save you time.  Time is always the most precious resource of our clients. ',
        desc3: 'When you are in a business that’s growing…. there’s always more to do.  Accounting is always there.  So is staffing, legal questions, suppliers, contracts, client calls… it’s never ending… and it can be overwhelming to try and do everything you’d like to do.  That’s why it’s easy for us to identify with our clients.  We get it.  Completely. ',
        desc4: 'One of the best things about our campaigns, is that we get to see our clients feel RELIEF.  It’s a genuine pleasure to see someone that’s been struggling to manage a lot of different marketing tools or programs, many of which are really, really time consuming, start to see the light at the end of the tunnel with our geofencing solutions. Some businesses have been able to stop using a number of other marketing channels, just by switching to our more effective and efficient approach, and they accelerated their growth rate. ',
        desc5:  'A particular client, who is a fantastic small-business owner, must have had a dozen of different types of marketing campaigns for his local restaurant. Flyers, mailers, coupons, social media, events, local sponsorships, etc.   When he realized he could just stop most of it and focus in on just the few places that mattered to his restaurant, in the immediate area, he decided to transition most of his marketing budget to focus on geofencing and automate his marketing. ',
        desc6:  'It was a massive sense of relief.  The burden of having to manage so many different marketing types was a never-ending list of to-do items. And since all of these marketing solutions didn’t really provide any data back for him, he had no idea if any of this work was actually effective.  In short, it was a ton of work and he always felt it wasn’t being done as well as it could have been, since he didn’t happen to have any staff specifically dedicated to marketing and frequently couldn’t really keep track of it all.  It was a massive time save for him personally.',
        desc7:  'Now, he has frequent data review meetings with our team to review and optimize his campaign.  Each time, our team can recommend a change or two that will help the campaign produce even more results.  Since we can tag people when they enter his restaurant, we know that its working.  That alone helps him feel more relaxed, knowing that the job is getting done.  ',
        desc8:  'I just love stories like this.  It is a wonderful thing to work with hard-working people that want to grow their businesses and helping them be more effective and efficient is my absolute favorite part.  It’s the best--- Just freeing them up to focus more on their business and their success.  ',

        /* Blockquote */
        bgimg: mainimage,
        quoteIcon: <FaQuoteRight />,
        quoteDesc: 'Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn\'t really do it, they just saw something. It seemed obvious to them after a while. That\'s because they were able to connect experiences they\'ve had and synthesize new things.',
        name: '- Steve Jobs',
        designation: 'Founder of Apple Inc.',

        /* desc Images */
        images: [
            {
                img: img2
            },
            {
                img: img2
            },
            {
                img: img2
            }
        ],
        bltags: {
            title: 'Tags: ',
            lists: [
                {
                    path: '#',
                    title: 'Travel'
                },
                {
                    path: '#',
                    title: 'Food'
                },
                {
                    path: '#',
                    title: 'Gym'
                },
            ]
        }
    },
    blogDetails2: {
        img: mainimage2,
        author: 'Allen Warren',
        authorImg: allensmall,
        date: 'June 1, 2021',
        meta: 'Tips & Tricks',
        metaLink: '#',
        likes: '340',
        title: 'Global Positioning and Geofencing',
        desc1: 'I’m not a scientist. But I know how to google. And it’s just fascinating.', 
        desc2: 'The foundation of geofencing is built on our technical ability to know where people are, anywhere in the world.  According to Wikipedia and Physicscentral.com, the key to this data is built with the Global Positioning System or GPS.   GPS was originally based on technology developed during World War II to help with navigation of ships and planes at long range.  In 2000, higher quality GPS signals became commercially available. Prior to this, they were only available to the military.  Mobile devices rely on GPS for many uses and use of GPS has risen dramatically in in the past decade.',
        desc3: 'That means creating a geofence campaign literally begins in outer space.  Really.  Global Navigation Satellite Systems (GNSS) are the most widely used for supporting the GPS capabilities of mobile phones or devices.  Signals are emitted from 32 GPS satellites which are circling the globe, although the number of satellites can range up or down a few satellites at any given time, depending on which ones are operational.  These satellites need to have a minimum of 24 working at any given time, to ensure an accurate position is identified for any user on the earth.   GPS satellites move around the earth at approximately 12,550 miles per hour.  It takes about four or five seconds for the signal to reach a device here on earth. ',
        desc4: 'One of the most amazing things about these satellites is that due to their location, speed, and path orbiting the Earth, these satellites require a time adjustment; time actually moves more quickly for these satellites, since both gravity and speed will change the speed of time. Gravity is four times weaker on these satellites.  And at 12,550 miles per hour, they move very fast, fast enough to impact their clocks. ',
        desc5: 'There are two competing forces: the speed of the satellite and the gravitational force exerted on the satellite.  The weaker gravity results in time moving faster, about 45 microseconds per day (about 0.016 seconds per year).  But the speed has the opposite effect and time moves more slowly, by about seven microseconds daily. ',
        desc6:  'These differences, while they seem small, require complex time adjustments to the clocks on each satellite.  Each one has a precise adjustment to the clock time on each satellite to make up these for these differences in the speed of time.  The satellites would stop working within about two minutes if they didn’t adjust for the impact of time and Einstein’s Theories of General and Special Relativity.  Without Einstein’s work, our company wouldn’t exist.  How cool is that? ',
        desc7:  'To me, it’s simply amazing that we live in a time and age where this is possible. Each and every person that’s ever used a map on their phone, just sees it work…. And they find their way to the party, or the restaurant or the coffee shop…. ',
        desc8:  'But it’s actually an amazing thing that this occurs at all!  And it’s just hiding in plain sight.    Knowing just how much effort and thought went into this technology makes it so much more interesting and astonishing to me.  ',

        /* Blockquote */
        bgimg: mainimage,
        quoteIcon: <FaQuoteRight />,
        quoteDesc: 'Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn\'t really do it, they just saw something. It seemed obvious to them after a while. That\'s because they were able to connect experiences they\'ve had and synthesize new things.',
        name: '- Steve Jobs',
        designation: 'Founder of Apple Inc.',

        /* desc Images */
        images: [
            {
                img: img2
            },
            {
                img: img2
            },
            {
                img: img2
            }
        ],
        bltags: {
            title: 'Tags: ',
            lists: [
                {
                    path: '#',
                    title: 'Travel'
                },
                {
                    path: '#',
                    title: 'Food'
                },
                {
                    path: '#',
                    title: 'Gym'
                },
            ]
        }
    },
    latestarticles: {
        sectitle: 'Latest Tips & Articles',
        seccontent: 'Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortors.',
        btntext: 'View All Post',
        btnurl: '/blog-grid',
        items: [
            {
                img: img2,
                title: '50 Greatest Event Places in United Kingdom',
                date: '25 Dec,  2020',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                author: 'David Wise',
                authorImg: authorimg,
                likeNum: '244',
                cardUrl: '/blog-single'
            },
            {
                img: img2,
                title: 'Top 10 Best Clothing Shops In Sydney',
                date: '26 Dec,  2020',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                author: 'Mark Wins',
                authorImg: authorimg,
                likeNum: '535',
                cardUrl: '/blog-single'
            },
            {
                img: img2,
                title: 'Top 15 Greatest Hotels In United States',
                date: '27 Dec,  2020',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore incididunt ut labore et dolore magna',
                author: 'Matt Derry',
                authorImg: authorimg,
                likeNum: '294',
                cardUrl: '/blog-single'
            }
        ]
    },
    authors: {
        sectitle: 'Meet Top Authors',
        seccontent: 'Top 700+ famous authors on the Dirto. consectetur adipisicing elit Adipisci culpa possimus quis voluptatibus!',
        sliders: [
            {
                authorImg: team2,
                authorName: 'Steven Harvey',
                city: 'Australia',
                authorStatus: 'Power Author',
                powerauthor: true,
                listingNum: '120',
                followers: '49',
                following: '49',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            },
            {
                authorImg: team2,
                authorName: 'Alex Smith',
                city: 'United States',
                authorStatus: 'Elite Author',
                powerauthor: false,
                listingNum: '130',
                followers: '55',
                following: '24',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            },
            {
                authorImg: team2,
                authorName: 'Sirazz Khan',
                city: 'United Kingdom',
                authorStatus: 'Elite Author',
                powerauthor: false,
                listingNum: '130',
                followers: '55',
                following: '24',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            },
            {
                authorImg: team2,
                authorName: 'Daniel Wave',
                city: 'Italy',
                authorStatus: 'Power Author',
                powerauthor: true,
                listingNum: '130',
                followers: '55',
                following: '24',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            },
            {
                authorImg: team2,
                authorName: 'Juhen Villa',
                city: 'Bangladesh',
                authorStatus: 'Elite Author',
                powerauthor: false,
                listingNum: '130',
                followers: '55',
                following: '24',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            },
            {
                authorImg: team2,
                authorName: 'Daniel Villa',
                city: 'Costa Rica',
                authorStatus: 'Power Author',
                powerauthor: true,
                listingNum: '130',
                followers: '55',
                following: '24',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            },
            {
                authorImg: team2,
                authorName: 'Jhon Doe',
                city: 'France',
                authorStatus: 'Power Author',
                powerauthor: true,
                listingNum: '130',
                followers: '55',
                following: '24',
                buttonTxt: 'Follow',
                buttonIcon: <FiPlus />
            }
        ],
    },
    clientlogos: [
        creative1,
        creative2,
        creative3,
        creative4,
        creative5,
        creative6,
        creative8,
        creative9,
        creative10,
        creative11,
        creative12,
        creative13,
        creative14,
        creative15,
        creative16,
        creative17,
        creative18,
        creative19,
        creative20,
        creative21,
        creative22,
        creative23,
        creative24,
        creative25,
        creative26,
        creative27,
        creative28,
        creative29,
        creative30,
        creative31,
        creative32,
        creative33,
        creative34,
        creative35,
        creative36,
        creative37,
        creative38,
        creative39,
    ],
    footerdata: {
        footerlogo: darklogo,
        footerdesc: 'We make it easy.',
        sociallinks: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/GeoPhenom-LLC-106148535072570'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/geophenom'
            },
            {
                icon: <FaInstagram />,
                url: 'https://www.instagram.com/geophenomllc/'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/company/geophenom/'
            }
        ],
        footerquicklinks: {
            title: 'Quick Links',
            links: [
                {
                    path: '/about',
                    text: 'about us'
                },
                {
                    path: '/why',
                    text: 'why geofencing'
                },
                {
                    path: '/gf101',
                    text: 'geofencing 101'
                },
                {
                    path: '/pricing',
                    text: 'pricing'
                },
                {
                    path: '/faq',
                    text: 'FAQs'
                },
                {
                    path: '/campaigndesign',
                    text: 'design your ad'
                },
                {
                    path: '/contact',
                    text: 'get ad design help'
                },
                {
                    path: '/sign-up',
                    text: 'sign up'
                },
                {
                    path: '/login',
                    text: 'log in'
                },
                {
                    path: '/contact',
                    text: 'contact us'
                }
                
            ]
        },
        footercategories: {
            title: 'Categories',
            links: [
                {
                    path: '/realestate',
                    text: 'Real Estate'
                },
                {
                    path: '/automotive',
                    text: 'Automotive'
                },
                {
                    path: '/restaurant',
                    text: 'Restaurants'
                },
                {
                    path: '/retail',
                    text: 'Retail'
                },
                {
                    path: '/services',
                    text: 'Services'
                },
                {
                    path: '/financial',
                    text: 'Financial Services'
                },
                {
                    path: '/recruiting',
                    text: 'Recruiting'
                },
                {
                    path: '/realestaterecruiting',
                    text: 'Real Estate Recruiting'
                },
                {
                    path: '/contact',
                    text: 'Ask About Custom Categories'
                }
            ]
        },
        footercontact: {
            title: 'Connect With Us',
            lists: [
                {
                    icon: <AiOutlineHome />,
                    text: '523 Benfield Rd. #203 Severna Park, MD 21146'
                },
                {
                    icon: <FiHeadphones />,
                    text: '410-562-1983'
                },
                {
                    icon: <FaRegEnvelope />,
                    text: 'customerservice@geophenomllc.com'
                }
            ]
        },
        copyright: {
            menus: [
                {
                    path: '/terms',
                    title: 'Terms & Conditions'
                },
                {
                    path: '/privacy',
                    title: 'Privacy Policy'
                },
                {
                    path: '/faq',
                    title: 'Help Center'
                },
                {
                    path: '/accessibility',
                    title: 'Accessibility'
                }
            ]
        }
    }

}
export default sectiondata
