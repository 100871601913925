import React, { useState, useEffect } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import MapViewCluster from "../../components/contact/MapViewCluster";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { IDENTITY_USER_TOKEN_KEY } from '../../components/data/constants';
import Modal from "../../components/contact/Modal";
import {refresh,isLoggedIn} from '../../components/common/TokenRefresh';
import wizard3 from "../../assets/images/1-Claim-Zones.svg"
import saveandcontinue from "../../assets/images/Continue-Button-Arrow-Green.svg";
import saveedits from "../../assets/images/Save-Edits-arrow-button.svg";
import save from "../../assets/images/Save-Campaign-button-small.svg";
import wizard1mobile from "../../assets/images/1-Claim-Zones-mobile.svg";
import useIsMobile from '../../components/customHooks/useIsMobile';
import { isUserLoggedIn } from '../../utils/APIRequests';


function ListMapView(props) {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const [count, setCount] = useState(JSON.parse(localStorage.getItem("storedData")||'[]').length);
    const [plan, setPlan] = useState(25);
    const [latLngPair, setLatLngPair] = useState([0, 0]);
    const [fences, setFences] = useState(JSON.parse(localStorage.getItem("storedData"))||[]);
    const [priceLevel, setPriceLevel] = useState(-1);
    const [modal, setModal] = useState(false);
    const [placeName, setPlaceName] = useState("");
    const [centroidtype, setCentroidtype] = useState("");
    const [centroidAddress, setCentroidAddress] = useState("");
    const [boxLabel, setBoxLabel] = useState('competitors');
    const [campaignId,setCampaignId] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.type) {
            setCentroidtype(location.type);
        }
        if (location.campaign){
            setCampaignId(location.campaign);
        }
    }, []);


    useEffect(()=>{
        if(centroidtype!=''){
            localStorage.setItem("type",centroidtype);
        }
    },[centroidtype]);



    let location = useLocation();
    //const [center, setCenter]=useState("-77.0363890,38.895");
    let center = "-77.0363890,38.895";
    let campaign = "";

    location = queryString.parse(location.search);
    if (location.place&&location.place!='-77.0364,38.8951') {
        center = location.place;
        localStorage.setItem("place",center);
    }else{
        center = localStorage.getItem("place")||'-77.0364,38.8951';
    }

    if (location.plan_type) {
        setPlan(planIdToPlanLimit(parseInt(location.plan_type)));
    }
    if (location.campaign) {
        campaign = location.campaign;
    }

    useEffect(() => {
        
        if (location.priceLevel) {
            setPriceLevel(location.priceLevel);
        };
        if (location.name) {
            setPlaceName(location.name);
        };
    }, []);



    const [distanceInMiles, setDistanceInMiles] = useState(5);


    function onClickRadius(distance) {
        setDistanceInMiles(distance)

    }
    const planLimitToPlanId = (planLimit) => {
        switch (planLimit) {
            case 10:
                return 1;
            case 25:
                return 2;
            case 50:
                return 3;
        }
    };

    const planIdToPlanLimit = (planId) => {
        switch (planId) {
            case 1:
                return 10;
            case 2:
                return 25;
            case 3:
                return 50;
        }
    }
    const addCampaignToFences = (campaignId, fenceArray) => {
        var newArr = [];
        fenceArray.forEach(function (entry) {
            if(entry.location&&entry.location.lat){
                var newObj = {};
                newObj['campaign'] = campaignId;
                newObj['locationid'] = entry.place_id;
                newObj['businessname'] = entry.name;
                newObj['address'] = entry.formatted_address;
                if(typeof entry.location.lat === "function"){
                    newObj['latitude'] = entry.location.lat();
                    newObj['longitude'] = entry.location.lng();
                }else{
                    newObj['latitude'] = entry.location.lat;
                    newObj['longitude'] = entry.location.lng;
                }

                newObj['type'] = (entry.type) ? entry.type : '';
                newArr.push(newObj);
            }
        });
        return newArr;
    };
    const saveAndContinue = (e) => {
        if(e){
            e.preventDefault();
        }
        // if(!isLoggedIn()){
        //     continueNoSave();
        //   }
        if (count == 0) {
            alert('You must select at least one geofence location to continue!');
            return;
        }
        if (campaign.length > 0 ) {
            handleSubmitEdit();
        } else {
            setModal(true);
        }

        // if(plan<count){
        //     alert('You have exceeded your plan limit, please select a new plan or unselect geofence locations to continue!');
        // }else{


        //}
    };


    const modalClose = () => {
        setModal(false);
    };

    const showLoading=()=>{
        document.getElementById('loading').style.display='block';
    }
    const continueNoSave = () => {
        showLoading();
        window.open("/campaigndesign?type="+centroidtype, "_self");
    }
    const handleChange = (e) => {
        setPlaceName(e.target.value);
    }

    const handleSubmit = () => {
        // console.log("centroidtype ==>",centroidtype)
        // console.log("priceLevel ==>",priceLevel)
        // console.log("centroidAddress ==>",centroidAddress)
        // console.log("latLngPair[1]latitude ==>",latLngPair[1])
        // console.log("longitude latLngPair[0] ==>",latLngPair[0])
        (async () => {
            const userLoggedIn = await isUserLoggedIn();
            const campaignPostBody = JSON.stringify({
                name: placeName, email: localStorage.getItem('email'),
                plantype: planLimitToPlanId(plan), type: centroidtype, pricelevel: priceLevel, address: centroidAddress,
                latitude: latLngPair[1], longitude: latLngPair[0]
            });
            if (!userLoggedIn) {
                localStorage.setItem("pendingCampaignBody", campaignPostBody);
                localStorage.setItem("pendingFences", JSON.stringify(fences));
                continueNoSave();
            }
            const rawResponse = await fetch(SERVER_URL+'/campaign', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                },
                body: campaignPostBody
            });
            const content = await rawResponse.json();
            localStorage.setItem('campaign',content.message);

            //console.log(content);
            //  console.log(fences);
            console.log("fences ==>",fences)
            var formattedFences = addCampaignToFences(content.message, fences)
            console.log(formattedFences);
            const formattedFencesStr = JSON.stringify(formattedFences);
            const rawResponseFences = await fetch(SERVER_URL+'/fence', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                },
                body: formattedFencesStr
            });

            showLoading();
            window.open("/campaigndesign?campaign=" + content.message + "&type="+centroidtype, "_self");
        })();
    };

    const isMobile = useIsMobile();

    const handleSubmitEdit = (goBack) => {
        (async () => {
            refresh();
            var formattedFences = addCampaignToFences(campaign, fences)
            console.log(formattedFences);
            const rawResponseFences = await fetch(SERVER_URL+'/fence?campaign=' + campaign, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                },
                body: JSON.stringify(formattedFences)
            });
            let sendURL = SERVER_URL + '/sendchangereport?campaign=' + campaign + '&ad=0&email=' + localStorage.getItem('email');

            (async () => {
                refresh();
                const rawResponseFences = await fetch(sendURL, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)

                    }
                });
                const content = await rawResponseFences.json();
                console.log(content);
                showLoading();
                if(goBack){
                    window.history.back();
                }else{
                    window.open("/campaigndesign?campaign=" + campaign, "_self");
                }
            })();


            
        })();
    };






    return (
        <main className="List-map-view">
            {/* Header */}
            <GeneralHeader />
            <Modal show={modal} handleClose={e => modalClose(e)}>
                <h2 style={{color:'#333F57',fontWeight:'bold'}}>Save Campaign</h2>
                <div className="myModal">
                    <label>Name Your Campaign:</label>
                    <input
                        type="text"
                        value={placeName}
                        onChange={handleChange}
                        name="modalInputName"
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <img style={{marginTop:'10px', cursor:'pointer'}} onClick={e => handleSubmit(e)} src={save} width='75px'/>
                </div>
            </Modal>
            <div id='loading' style={{display:'none'}} class="loading">Loading&#8230;</div>
            <div className="row createAd-layout" style={{ 'marginTop': '75px', 'marginLeft': '20px','marginRight':'0' }}>


                {/* Mapview */}
                <MapViewCluster setAddress={setCentroidAddress} setCentroidType={setCentroidtype} setPlan={setPlan} setFences={setFences} setPriceLevel={setPriceLevel} setLatLngPair={setLatLngPair} setCount={setCount} place={center} distanceInMiles={distanceInMiles} type={location.type} campaign={campaign} priceLevel={priceLevel} boxLabel={boxLabel} campaignId={campaignId} saveAndContinue={saveAndContinue} handleSubmitEdit={handleSubmitEdit} saveandcontinueImg={saveandcontinue}/>

            </div>

            <div style={{ 'marginTop': '25px', 'marginBottom': '25px', 'height': '100%','marginRight':'0' }} className="row progDesk">

                
                    {!campaignId&&<div className='row align-items-center claim1-wizard' style={{ position:'fixed',bottom:'0px',overflow:'hidden',margin: '0px', backgroundColor: '#333F57', width: '100%',height:'75px','marginRight':'0' }}>
                        <div className="mx-auto" style={{  }}> <a onClick={(e) => saveAndContinue(e)} href='javascript:void(0)'><img className='wizard-Create' src={wizard3} style={{ height: '50px' }} /></a>
                            <button className='claim1-continue' style={{ border: '0', backgroundColor: '#333F57' }} onClick={(e) => saveAndContinue()}><img style={{ marginLeft: '30px', height: '50px' }} src={saveandcontinue} /></button>  </div>
                    </div>}

                    {campaignId&&<div className='row align-items-center' style={{ position:'fixed',bottom:'0px',overflow:'hidden',margin: '0px', backgroundColor: '#333F57', width: '100%',height:'100px','marginRight':'0' }}>
                        <div className="mx-auto" style={{  }}> 
                            <button style={{ border: '0', backgroundColor: '#333F57' }} onClick={(e) => handleSubmitEdit(true)}><img style={{ marginLeft: '30px', height: '60px' }} src={saveedits} /></button>  </div>
                    </div>}
               

            </div>

            {/* Footer */}


            <ScrollTopBtn />
        </main>
    );
}

export default ListMapView;