import { Auth, toast } from "aws-amplify";
import { useState, React, useEffect } from "react";
import { fetchUserIsAdmin } from "../utils/APIRequests";
import GeneralHeader from "../components/common/GeneralHeader";
import Footer from "../components/common/footer/Footer";
import Breadcrumb from "../components/common/Breadcrumb";
import breadcrumbimg from "../assets/images/bread-bg.jpg";
import { useHistory, useLocation } from "react-router";
import queryString from 'query-string';
import Select from "react-select";
import { IDENTITY_USER_TOKEN_KEY } from "../components/data/constants";
import Loader from "../components/loading/Loader";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link } from "react-router-dom";

const state = {
  breadcrumbimg: breadcrumbimg,
};

function UpgradePlan() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  let history = useHistory();
  let location = useLocation();
  location = queryString.parse(location.search);
  const [campaign, setCampaign] = useState([]);
  const [error, setError] = useState("");
  const [planType, setPlanType] = useState({value: 0, label: "Starter"});
  const [planTime, setPlanTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const planOptions = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: "Starter",
    },
    {
      value: 2,
      label: "Growth",
    },
    {
      value: 3,
      label: "Rockstar",
    },
  ];

  // Check if user is logged in, otherwise redirect to login page
  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        if ((await fetchUserIsAdmin()) === false) {
          throw new Error("User is not an admin");
        } else {
          let email = localStorage.getItem("email");
          const url = location.email ? '/campaign/info?email=' + location.email + '&campaignid=' + location.campaignid : '/campaign/info?email=' + email + '&campaignid=' + location.campaignid;
          const fetchData = async () => {
            const rawResponse = await fetch(SERVER_URL + url, {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
              },
            });
            const content = await rawResponse.json();
            if (content && Array.isArray(content.message)) {
              setCampaign(content.message);
              if(content.message && content.message.length > 0 && content.message[0].plantype.Int32 >= 0){
                setPlanType({
                  value: content.message[0].plantype.Int32,
                  label: `${planOptions[content.message[0].plantype.Int32].label}`,
                });
              }
              if(content.message && content.message.length > 0 && content.message[0].remainingterm >= 0){
                setPlanTime(content.message[0].remainingterm);
              }
              setLoading(false);
            }
          };
          fetchData();
        }
      } catch (e) {
        history.push("/login");
      }
    }
    checkAuth();    
  }, []);

  const resetData = () => {
    if(campaign && campaign.length > 0){
      if(campaign[0].plantype.Int32 > 0){
        setPlanType({
          value: campaign[0].plantype.Int32,
          label: `${planOptions[campaign[0].plantype.Int32].label}`,
        });
      }else{
        setPlanType({value: 0, label: "Starter"});
      }
      if(campaign[0].remainingterm > 0){
        setPlanTime(campaign[0].remainingterm);
      }else{
        setPlanTime(0);
      }
    }
  };

  const onSubmitData = async () => {
    const updatePlanDataBody = JSON.stringify({ monthlypayment: parseFloat(planType.value + ".00"), numberofmonths: parseInt(planTime), isupdatepayterm: true });
    const rawResponse = await fetch(SERVER_URL+`/subscription?campaign=${location.campaignid}`, {
      method: 'PUT',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem(IDENTITY_USER_TOKEN_KEY)
      },
      body: updatePlanDataBody
    });
    const content = await rawResponse.json();
    if (content && content.message && content.resultCode !== "Error") {
      history.goBack();
    }else{
      alert(content.message);
    }
  };

  return (
    <main className="home-1">
      {/* Header */}
      <GeneralHeader />
      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle={`Upgrade plan for ${campaign && campaign.length > 0 ? campaign[0].name.String : "-"}`}
        MenuPgTitle="pages"
        img={state.breadcrumbimg}
      />
      <div className="container">
        <Link to={`/single-campaign?email=${location.email ? location.email : localStorage.getItem("email")}&campaign=${location.campaignid}&edit=true&type=`}>{"<< Back to Edit Campaign"}</Link>
        {/* <div className="row">
          <p onClick={() => history.goBack()}>{"<< Back to Campaign"}</p>
        </div>
        <br/> */}
        {loading ? (
          <Loader
            style={{ marginTop: "30px" }}
            message="Loading user's data, please wait..."
          />
        ) : (
          <div className="row">
            <div className="col-4 m-auto">
              <p className="mb-1">
                <b>Plan Type</b>
              </p>
              <Select
                placeholder="Select Your Plan"
                options={planOptions}
                value={planType}
                onChange={(e) => setPlanType(e)}
              />
              <p className="mt-4 mb-1">
                <b>Remaining Months</b>
              </p>
              {/* <input
                onChange={event => setPlanTime(event.target.value)}
                placeholder="Enter plan time"
                value={planTime}
              /> */}
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Remaining Months"
                  value={planTime}
                  type="number"
                  onChange={(e) => setPlanTime(e.target.value)}
                />
              </InputGroup>
              {/* <Select
                placeholder="Select Remaining Months"
                className="mb-4"
                options={planDuration}
                value={planTime}
                onChange={(e) => setPlanTime(e)}
              /> */}
              <div
                className="admin-button-set mb-5"
                style={{ justifyContent: "center" }}
              >
                <input type="submit" value="Update" onClick={onSubmitData} />
                <input type="reset" value="Reset" onClick={resetData} />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Footer */}
      <Footer />
    </main>
  );
}

export default UpgradePlan;
