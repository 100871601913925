import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import GeneralHeader from '../components/common/GeneralHeader';
import ScrollTopBtn from '../components/common/ScrollTopBtn';
import Banner7 from '../components/banner/banner7/Banner7';
import { FaBrush } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { BsCloudUpload } from 'react-icons/bs';
import { Cropper } from 'react-cropper';
import { compressImage, imageFromFile } from '../utils/FieldValidations';
import { IDENTITY_USER_TOKEN_KEY } from '../components/data/constants';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Loader from '../components/loader/Loader';
import { refresh } from '../components/common/TokenRefresh';
import { isUserLoggedIn } from '../utils/APIRequests';

const PhotoCropModal = ({
  showCropperModal,
  handleClosePhotoCropperModal,
  imageToCrop,
  cropperRef,
  applyCrop,
}) => {
  return (
    <Modal
      show={showCropperModal}
      onHide={() => {
        handleClosePhotoCropperModal();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Crop your image</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '1000px' }}>
        <Cropper
          style={{ maxHeight: '600px' }}
          src={imageToCrop}
          // style={{ height: 400, width: "100%" }}
          // Cropper.js options
          // initialAspectRatio={29 / 30}
          minCropBoxWidth={100}
          viewMode={1}
          aspectRatio={29 / 30}
          guides={false}
          ref={cropperRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClosePhotoCropperModal(true);
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={applyCrop}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function UploadCustomAd(props) {
  let location = useLocation();
  let campaign = '0';
  let isEditing = false;
  let ad = undefined;
  location = queryString.parse(location.search);
  if (location.campaign) {
    campaign = location.campaign;
  }
  if (location.edit) {
    isEditing = location.edit === 'true';
  }
  if (location.ad) {
    ad = location.ad;
  }

  useEffect(() => {
    async function fetchData() {
      const rawResponse = await fetch(SERVER_URL + '/out?ad=' + ad, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
        },
      });
      const content = await rawResponse.json();
      // console.log({ content });
      console.log("content ------->",content)
      setCroppedImage(`data:image/jpeg;base64,${content.message[0].out}`);
      if(isEditing) {
          setOldCroppedImage(`data:image/jpeg;base64,${content.message[0].out}`);
      }
      setLoading(false);
    }
    if (isEditing) {
      refresh();
      fetchData();
    }
  }, []);

  const [loading, setLoading] = useState(isEditing);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [oldCroppedImage, setOldCroppedImage] = useState(null);
  const cropperRef = useRef();

  const handleClosePhotoCropperModal = (cleanup = false) => {
    if (cleanup) {
      setImageToCrop('');
      cropperRef.current.value = '';
    }
    setShowCropperModal(false);
  };
  const handleShowPhotoCropperModal = () => {
    if (showCropperModal === false) setShowCropperModal(true);
  };

  const startCropper = async (file) => {
    const image = await imageFromFile(file);
    if (image) {
      setImageToCrop(image);
    }
    console.log('Entered');
    handleShowPhotoCropperModal();
    // setShowCropperModal(true);
  };

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const stripBase64Prefix = (base64text) => {
    if (typeof base64text === 'string') {
      return base64text.split(',')[1];
    }
    return base64text;
  };

  const continueNoSave = () => {
    window.open("/review", "_self");
  };

  const sendAdRequest = async () => {
    const method = isEditing ? 'PUT' : 'POST';
    let body = JSON.stringify({
      campaign: parseInt(campaign),
      creative: true,
      out: stripBase64Prefix(croppedImage),
      uploadCreative: true,
    });
    console.log("Body ==> ",body);
    const userLoggedIn = await isUserLoggedIn();
    if (!userLoggedIn) {
      localStorage.setItem("pendingAd", body);
      continueNoSave();
    }
    if (isEditing) {
      body = JSON.stringify([
        {
          campaign: parseInt(campaign),
          creative: true,
          out: stripBase64Prefix(oldCroppedImage),
          uploadCreative: true,
        },
        {
          campaign: parseInt(campaign),
          creative: true,
          out: stripBase64Prefix(croppedImage),
          uploadCreative: true,
        },
      ]);
    }
    const rawResponse = await fetch(SERVER_URL + '/ad?ad=' + ad, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
      },
      body: body,
    });
    const content = await rawResponse.json();
    console.log(content);
    if (location.edit) {
      window.history.back();
    } else {
      window.open(
        '/review?ad=' + content.message + '&campaign=' + campaign,
        '_self'
      );
    }
  };

  const applyCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    const croppedURL = cropper.getCroppedCanvas().toDataURL();
    compressImage(croppedURL)
      .then((data) => {
        console.log({ data });
        setCroppedImage((_) => data);
        handleClosePhotoCropperModal();
      })
      .catch((err) => {
        console.log(err);
        handleClosePhotoCropperModal();
      });
  };

  const { open, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      startCropper(acceptedFiles[0]);
      console.log({ acceptedFiles });
    },
  });

  const browseButtonRef = useRef();

  const clickBrowseButton = () => {
    browseButtonRef.current.click();
  };

  return (
    <main className="booking-page booking-area-mobile">
      {/* Header */}
      <GeneralHeader />

      {/* Banner */}
      <Banner7 />

      {/* Personal Information */}
      <section style={{}} className="booking-area padding-bottom-80px">
        <div style={{}} className="container">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                class="btn btn-link"
                onClick={() => {
                  // go back
                  props.history.goBack();
                }}
              >
                {props.history.location.state &&
                props.history.location.state.from.includes('review')
                  ? '<< Back to Review'
                  : '<< Back to Ad Builder'}{' '}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <FaBrush size={36} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                <h2 className="sec__title ad">
                  Upload your own ad <br />
                  {/* It's Fast & Simple */}
                </h2>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {!croppedImage && (
                <div className="row">
                  <div className="col-12">
                    <div className="drop-zone">
                      <div className="drag-and-drop-file">
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <span className="drag-drop-icon">
                            <BsCloudUpload />
                          </span>
                          <h3>Drag & Drop File Here to Upload</h3>
                          <button className="mt-2 btn btn-primary">
                            Browse files
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {croppedImage && (
                <div className="row ">
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={{
                      maxHeight: '0px',
                      overflow: 'hidden',
                      margin: '0px',
                      opacity: '0',
                      padding: '0px',
                    }}
                  >
                    <input {...getInputProps()} />
                    <span className="drag-drop-icon">
                      <BsCloudUpload />
                    </span>
                    <h3>Drag & Drop File Here to Upload</h3>
                    <button
                      className="mt-2 btn btn-primary"
                      ref={browseButtonRef}
                    >
                      Browse files
                    </button>
                  </div>
                  <div className="col-12">
                    <div className="img-preview text-center">
                      <img
                        src={croppedImage}
                        alt="Preview of your uploaded ad"
                        style={{
                          maxHeight: '300px',
                          maxWidth: '300px',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                    <div className="text-center mt-4">
                      <button
                        type="danger"
                        className="btn btn-outline-danger btn-lg mr-3"
                        onClick={() => {
                          open();
                          setCroppedImage(null);
                          setImageToCrop(null);
                        }}
                      >
                        Remove image
                      </button>
                      <button
                        type="primary"
                        className="btn btn-outline-primary btn-lg mr-3"
                        onClick={() => {
                          if (!imageToCrop) {
                            setImageToCrop(null);
                            clickBrowseButton();
                          } else {
                            setShowCropperModal(true);
                          }
                        }}
                      >
                        Edit ad image
                      </button>
                      <button
                        type="primary"
                        className="btn btn-primary btn-lg"
                        style={{
                          backgroundColor: '#40cc6f',
                        }}
                        onClick={() => {
                          sendAdRequest();
                        }}
                      >
                        {isEditing ? 'Save Changes' : 'Next'}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>

      {/* Footer */}

      <ScrollTopBtn />
      <PhotoCropModal
        showCropperModal={showCropperModal}
        handleClosePhotoCropperModal={handleClosePhotoCropperModal}
        imageToCrop={imageToCrop}
        cropperRef={cropperRef}
        applyCrop={applyCrop}
      />
    </main>
  );
  // return (
  //     <main className="booking-page booking-area-mobile">
  //         {/* Header */}
  //         <GeneralHeader />

  //         <Banner7 />

  //         {/* Breadcrumb */}
  //         <Breadcrumb CurrentPgTitle="Ad Design" MenuPgTitle="pages" img={breadcrumbimg} />

  //         {/* Ad Design */}
  //         <section className="booking-area padding-top-70px padding-bottom-90px">
  //             <div className="container">
  //                 <h1>Hello</h1>
  //                 {/* <img src={sectiondata.addesign.videoImg} alt="video-img" /> */}
  //             </div>
  //         </section>

  //         {/* Footer */}
  //         <Footer />

  //         <ScrollTopBtn />

  //     </main>
  // );
}

export default UploadCustomAd;
