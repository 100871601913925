import React, { useEffect, useMemo } from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import breadcrumbimg from "../assets/images/bread-bg.jpg";
import plan2 from "../assets/images/Growth-Package-1.svg";
import plan1 from "../assets/images/Starter-Package-1.svg";
import plan3 from "../assets/images/Rockstar-Package-1.svg";
import plan4 from "../assets/images/Customized-Elite-Package-1.svg";
import mainpricing from "../assets/images/Main-Pricing-Page-Graphics.svg";
import { Link } from "react-router-dom";
import { ImageMap } from "@qiuz/react-image-map";
import { IDENTITY_USER_TOKEN_KEY } from '../components/data/constants';
import {refresh} from '../components/common/TokenRefresh';
import { fetchPrices, RequestStatus } from "../utils/APIRequests";

const state = {
  breadcrumbimg: breadcrumbimg,
};
function PricingPlan() {

  const [pricing, setPricing] = React.useState([]);
  const [pricingState, setPricingState] = React.useState(RequestStatus.IDLE);

  // fetch prices on load
  useEffect(() => {
    setPricingState(RequestStatus.LOADING);
    fetchPrices().then((pricing) => {
      setPricing(pricing);
      setPricingState(RequestStatus.SUCCESS);
    }).catch((error) => {
      console.error(error);
      window.alert(error);
      setPricingState(RequestStatus.ERROR);
    });
  }, []);

  const onMapClick = (area, index) => {
    if (index == 0) {
      window.open("/contact", "_self");
    } else if (index == 1) {
      window.open("/list-map-view?place=-77.0364,38.8951&type=", "_self");
    }
  };
  const mapArea = [
    {
      width: "17.68421464330413%",
      height: "99.77827050997783%",
      left: "82.31578535669587%",
      top: "0%",
    },
    {
      width: "82.22778473091364%",
      height: "99.77827050997783%",
      left: "0.08800062578222778%",
      top: "1.2603862275123729e-14%",
    },
  ];
  const ImageMapComponentGrowth = useMemo(
    () => (
      <ImageMap
        className="usage-map"
        src={mainpricing}
        map={mapArea}
        onMapClick={onMapClick}
      />
    ),
    [mapArea, mainpricing]
  );
  return (
    <main className="pricing-plan-page">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Pricing Plans"
        MenuPgTitle="pages"
        img={state.breadcrumbimg}
      />

      {/* Pricing Plan */}
      {
        pricingState === RequestStatus.ERROR && (
          <div className="alert alert-danger" role="alert">
            Error fetching pricing plans.
          </div>
        )
      }
      {
        (pricingState === RequestStatus.LOADING || pricingState === RequestStatus.IDLE) && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="loading-spinner">
              <div className="spinner-border text-primary" role="status">

              </div>
            </div>
            <span>Loading prices...</span>
          </div>
        )
      }
      {
        pricingState === RequestStatus.SUCCESS &&
        <section className="pricing-area padding-top-0px padding-bottom-90px">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {/* {ImageMapComponentGrowth} */}
              <table
                    id="pricing-table"
                    className="table table-striped table-bordered table-hover"
                >
                    <thead>
                    <tr>
                        <th scope="col-4">
                        <div>
                            <h3 className="text-left font-weight-bold custom-color">
                            Select
                            </h3>
                            <h3 className="text-left font-weight-bold custom-color">
                            Your Plan
                            </h3>
                            <p className="text-left font-weight-light">
                            Please Select Your Desired Pricing Plan.
                            </p>
                        </div>
                        </th>
                        <th className="row-custom-bg-color" scope="col">
                        <div>
                            <h3 className="text-center pricing-package-name">
                            Starter
                            </h3>
                            <h3 className="text-center pricing-price">${pricing[0].cost.Float64}</h3>
                            <p className="text-center font-weight-light">per month</p>
                        </div>
                        </th>
                        <th className="row-custom-bg-color" scope="col">
                        <div>
                            <h3 className="text-center pricing-package-name">Growth</h3>
                            <h3 className="text-center pricing-price">${pricing[1].cost.Float64}</h3>
                            <p className="text-center font-weight-light">per month</p>
                        </div>
                        </th>
                        <th className="row-custom-bg-color" scope="col">
                        <div>
                            <h3 className="text-center pricing-package-name">
                            Rockstar
                            </h3>
                            <h3 className="text-center pricing-price">${pricing[2].cost.Float64}</h3>
                            <p className="text-center font-weight-light">per month</p>
                        </div>
                        </th>
                        <th className="row-custom-bg-color" scope="col">
                        <div>
                            <h3 className="text-center pricing-package-name pb-2">
                            Custom
                            </h3>
                            <p className="text-center font-weight-light">Contact us</p>
                            <p className="text-center font-weight-light">for pricing</p>
                        </div>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr className="row-bg-color">
                        <th className="text-white font-weight-bold pl-2" scope="row">
                        Guaranteed Ad Views
                        </th>
                        <td className="text-center text-white font-weight-bold">
                        35,000+
                        </td>
                        <td className="text-center text-white font-weight-bold">
                        60,000+
                        </td>
                        <td className="text-center text-white font-weight-bold">
                        110,000+
                        </td>
                        <td className="text-center text-white font-weight-bold">
                        200,000+
                        </td>
                    </tr>
                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Competitor Targeting
                        </th>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Local Area Targeting
                        </th>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Behaviour Targeting
                        </th>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Targeted by Demographics
                        </th>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Re-Targeting Existing Clients
                        </th>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Automated Reporting
                        </th>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr>
                        <th className="pl-2 pricing-addons" scope="row">
                        Dedicated Acoount Manager
                        </th>
                        <td className="text-center">&#x1F5F4;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                        <td className="text-center">&#x2714;</td>
                    </tr>

                    <tr className="row-bg-color">
                        <th className="text-white font-weight-bold pl-2" scope="row">
                        Upload Contact & Interest List
                        </th>
                        <td className="text-center text-white font-weight-bold">
                        up to 250
                        </td>
                        <td className="text-center text-white font-weight-bold">
                        up to 1,000
                        </td>
                        <td className="text-center text-white font-weight-bold">
                        up to 10,000
                        </td>
                        <td className="text-center text-white font-weight-bold">
                        Unlimited
                        </td>
                    </tr>

                    <tr className="bg-white">
                        <th scope="row"></th>
                        <td className="text-center">
                          <Link to='/review'>
                            <button
                              type="button"
                              className="btn get-started-btn"
                            >
                              Get Started
                            </button>
                          </Link>
                        </td>
                        <td className="text-center">
                          <Link to='/review'>
                            <button
                              type="button"
                              className="btn get-started-btn"
                            >
                              Get Started
                            </button>
                          </Link>
                        </td>
                        <td className="text-center">
                          <Link to='/review'>
                              <button
                                type="button"
                                className="btn get-started-btn"
                              >
                                Get Started
                              </button>
                            </Link>
                        </td>
                        <td className="text-center">
                          <Link to='/review'>
                            <button
                              type="button"
                              className="btn get-started-btn"
                            >
                              Get Started
                            </button>
                          </Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </section>
      }

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default PricingPlan;
