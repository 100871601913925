import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa'
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const responsive = [
    {
        breakpoint: 2100,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
]


function CreativeAd({slideitems}) {
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
    return (
        <>
            <Slider className="places-carousel testimonial-carousel" dots={false} infinite={true}  centerMode={true} centerPadding="0px" arrows={true} responsive={responsive}>
            {slideitems.filter(onlyUnique).map((slider, index) => {
                                return (
                                    <div style={{opacity:1}} className="client-logo-item" key={index}>
                                        <img src={slider} alt="Client Logo" />
                                    </div>
                                )
                            })}
            </Slider>
        </>
    );
}

export default CreativeAd;
