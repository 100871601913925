import React from 'react';
import { FiSearch } from 'react-icons/fi'

function Banner6({title}) {
    return (
        <>
            <section className="breadcrumb-area faq-breadcrumb">
                <div className="breadcrumb-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <h2  className="breadcrumb__title">
                                        {title}
                                    </h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner6;