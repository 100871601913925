import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchUserIsAdmin } from '../utils/APIRequests';

function DashboardItem(props) {
    // const plantype = ["Starter", "Growth", "Rockstar", "Customized Elite"];
    const [isUserPriceAdmin, setIsUserPriceAdmin] = useState(false);
    useEffect(() => {
        fetchUserIsAdmin().then(res => {
            if (res["Bool"] === true) {
                setIsUserPriceAdmin(true);
            }
        }).catch(err => {
            console.error(err);
        });
    }, []);
    return (
        <div className="row" style={{ minHeight: "280px", fontSize: "14px", marginTop: "8%", marginRight: "1%", color: "#333F57", borderStyle: "solid", borderColor: "#808895" }}>
            <div className="col-12">
                <div className="row">
                    <div style={{ fontSize: "18px", fontWeight: "600", padding: "1.1%", backgroundColor: "#808895", color: "white", textTransform: "capitalize", minHeight: "39px" }} className="col-12 text-center d-flex align-items-center justify-content-around">
                        {props.campaign.name.String}
                        <Link
                                    to={props.email == '' ?
                                        "/single-campaign?campaign=" +
                                        props.campaign.id +
                                        "&edit=true&type=" +
                                        props.campaign.type.String
                                        :
                                        "/single-campaign?email=" +  props.email
                                        + "&campaign=" +
                                        props.campaign.id +
                                        "&edit=true&type=" +
                                        props.campaign.type.String
                                    }
                                >
                                    <button
                                        type="button"
                                        className="dashboardCampaign-edit-btn"
                                    >
                                        Edit
                                    </button>
                        </Link>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        {/* Left-Wrapper */}
                        <div className="col-lg-6">
                            <div style={{ padding: "0px", marginTop: "5%", border: "1px solid #000", marginRight: "0px" }} className="col-11 campaign-image-dashboard">
                                <img width="100%" height="auto" src={"data:image/png;base64," + props.campaign.adimage.String} className="card__img" alt="Card"/>
                            </div>
                        </div>
                        {/* Right-Wrapper */}
                        <div className="col-lg-6">
                            <div className="row" style={{ marginTop: "2%" }}>
                            <div style={{ minHeight: "160px" }} className="col-12">
                                <div style={{ marginTop: "5%" }} className="row">
                                    <div className="col-12">
                                        <h6 className="sec__title">Conversion Zone:</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">{props.campaign.name.String}</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div>{props.campaign.address.String}</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "5%" }} className="row">
                                    <div className="col-12">
                                        <h6 className="sec__title">Campaign Start Date:</h6>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "5%" }} className="row">
                                    <div className="col-12">
                                        {new Date(
                                            props.campaign.creationdate.Time
                                        ).toLocaleDateString()}
                                    </div>
                                </div>
                                {isUserPriceAdmin && 
                                    <>
                                        <div style={{ marginTop: "5%" }} className="row">
                                            <div className="col-12">
                                                <h6 className="sec__title">Campaign Id:</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">{props.campaign.id}</div>
                                        </div>
                                    </>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    );
}

export default DashboardItem;
