import React from 'react';
import {Link} from "react-router-dom";

function FaqCategories() {
    return (
        <>
            <div className="row">
                <div className="col-lg-3 column-td-6">
                    <div className="category-item faq-cat-item p-0">
                        <Link to="/list-map-view?place=-77.0364,38.8951&type=" className="d-block padding-top-30px padding-bottom-30px">
                            <svg className="cat-svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" >
                                <g>
                                    <path d="M256,165.004c-50.176,0-90.996,40.82-90.996,90.996c0,17.666,5.066,34.168,13.813,48.142
                                        c0.266,0.516,0.586,1.011,0.948,1.484c16.256,24.887,44.355,41.37,76.235,41.37c50.176,0,90.996-40.821,90.996-90.996
                                        S306.176,165.004,256,165.004z M190.288,282.841c-3.397-8.286-5.285-17.345-5.285-26.841
                                        c0.001-39.148,31.849-70.996,70.997-70.996c23.955,0,45.16,11.937,58.024,30.163l-54.921,54.921l-20.96-20.959
                                        c-3.906-3.905-10.236-3.904-14.143,0L190.288,282.841z M256,326.996c-22.264,0-42.158-10.31-55.185-26.397l30.257-30.257
                                        l20.96,20.959c3.906,3.905,10.236,3.904,14.143,0l57.312-57.312c2.268,6.934,3.51,14.328,3.51,22.01
                                        C326.996,295.147,295.147,326.996,256,326.996z"/>
                                </g>
                                <g>
                                    <path d="M409.845,249.101c-1.571-48.868-15.246-96.792-39.792-139.167C343.92,64.82,306.201,27.263,260.974,1.325
                                        c-3.08-1.767-6.869-1.767-9.949,0C205.799,27.263,168.08,64.82,141.946,109.933c-24.546,42.375-38.221,90.299-39.792,139.167
                                        C67.098,264.144,44,298.76,44,337.351V408c0,5.523,4.478,10,10,10h146.941c2.155,30.079,15.324,58.889,36.883,80.131
                                        l11.157,10.993c1.946,1.917,4.482,2.876,7.019,2.876s5.072-0.959,7.019-2.876l11.157-10.993
                                        c21.559-21.242,34.728-50.051,36.883-80.13H342v51.612c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10V418h96
                                        c5.522,0,10-4.477,10-10v-70.648C468,298.76,444.901,264.144,409.845,249.101z M256,21.597
                                        C287.442,40.516,314.32,65.27,335.574,94H176.426C197.68,65.27,224.558,40.516,256,21.597z M64,398v-60.649
                                        c0-27.495,14.81-52.442,38-65.844V388c0,3.506,0.61,6.87,1.72,10H64z M260.139,483.884L256,487.962l-4.139-4.078
                                        c-17.769-17.507-28.747-41.135-30.858-65.884h69.994C288.885,442.749,277.907,466.377,260.139,483.884z M390,388
                                        c0,5.514-4.486,10-10,10H132c-5.514,0-10-4.486-10-10V258.588c0-36.21,7.152-71.494,20.428-104.088h45.279c5.522,0,10-4.477,10-10
                                        s-4.478-10-10-10h-36.194c3.515-6.992,7.326-13.831,11.417-20.5h186.14C375.355,156.854,390,206.772,390,258.588V388z M448,398
                                        h-39.72c1.11-3.13,1.72-6.494,1.72-10V271.508c23.19,13.401,38,38.348,38,65.844V398z"/>
                                </g>
                                <g>
                                    <path d="M231.069,137.43c-1.859-1.86-4.439-2.93-7.069-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07
                                        c1.861,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07S232.93,139.29,231.069,137.43z"/>
                                </g>
                                <g>
                                    <path d="M160,444.751c-5.522,0-10,4.477-10,10v30.806c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-30.806
                                        C170,449.228,165.522,444.751,160,444.751z"/>
                                </g>
                                <g>
                                    <path d="M359.069,494.93C357.21,493.07,354.63,492,352,492s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
                                        s1.069,5.21,2.93,7.07c1.861,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07
                                        S360.93,496.79,359.069,494.93z"/>
                                </g>
                            </svg>
                            <span className="d-block font-size-18 font-weight-semi-bold mt-3">getting started</span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 column-td-6">
                    <div className="category-item faq-cat-item p-0">
                        <Link to="/pricing" className="d-block padding-top-30px padding-bottom-30px">
                            <svg className="cat-svg" version="1.1" id="Capa_2" x="0px" y="0px" viewBox="0 0 512 512" >
                        <g>
                            <g>
                                <path d="M161.95,125.6c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.859,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
                                    c1.86,1.861,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07S163.81,127.46,161.95,125.6z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M509.075,41.85L496.71,29.471l12.361-12.361c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
                                    L482.574,15.32L470.391,3.122c-1.875-1.876-4.417-2.932-7.069-2.933L294.068,0.095c-0.01,0-0.019,0-0.028,0
                                    c-13.365,0-25.93,5.204-35.381,14.655l-84.46,84.46c-3.905,3.905-3.905,10.237,0,14.143c3.906,3.905,10.236,3.905,14.143,0
                                    l84.46-84.46c5.674-5.674,13.215-8.797,21.238-8.797c0.006,0,0.012,0,0.017,0l165.111,0.092l9.266,9.276L431.33,66.568
                                    c-15.734-9.424-36.477-7.369-50.021,6.173c-7.737,7.738-11.998,18.025-11.998,28.967s4.261,21.229,11.998,28.967
                                    c7.986,7.986,18.477,11.979,28.968,11.979c10.49,0,20.98-3.993,28.967-11.979c13.522-13.523,15.591-34.225,6.216-49.951
                                    l37.109-37.109l9.43,9.441l-0.092,164.887c-0.005,8.016-3.129,15.553-8.798,21.222L239.086,483.186
                                    c-5.673,5.673-13.216,8.797-21.238,8.797c-8.022,0-15.565-3.124-21.238-8.797L28.798,315.373C23.124,309.7,20,302.157,20,294.135
                                    c0-8.022,3.124-15.565,8.798-21.238l107.794-107.794c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
                                    L14.655,258.754C5.205,268.204,0,280.77,0,294.135s5.205,25.931,14.655,35.381l167.812,167.813
                                    c9.755,9.754,22.567,14.632,35.381,14.632c12.814,0,25.626-4.878,35.381-14.632l244.022-244.023
                                    c9.443-9.443,14.647-21.999,14.655-35.354L512,48.922C512.002,46.27,510.949,43.726,509.075,41.85z M425.101,116.531
                                    c-3.96,3.96-9.225,6.141-14.824,6.141c-5.601,0-10.865-2.181-14.825-6.141c-8.175-8.174-8.175-21.475,0-29.649
                                    c4.088-4.088,9.455-6.131,14.825-6.131c2.022,0,4.043,0.291,5.997,0.871l-11.542,11.542c-3.905,3.905-3.905,10.237,0,14.143
                                    c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929l11.51-11.51C432.463,102.916,430.704,110.928,425.101,116.531z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M427.159,201.363L310.62,84.823c-15.438-15.438-40.559-15.436-55.995,0L70.204,269.245
                                    c-7.479,7.478-11.598,17.421-11.598,27.997s4.118,20.52,11.597,27.998l116.54,116.538c7.479,7.479,17.421,11.598,27.997,11.598
                                    s20.52-4.118,27.997-11.597l184.422-184.422c7.479-7.479,11.597-17.421,11.597-27.997S434.638,208.841,427.159,201.363z
                                     M413.016,243.214L228.595,427.638c-3.7,3.7-8.621,5.738-13.854,5.738c-5.233,0-10.154-2.038-13.854-5.739L84.346,311.098
                                    c-3.701-3.701-5.739-8.621-5.739-13.855c-0.001-5.234,2.038-10.155,5.739-13.855L268.768,98.966c3.82-3.82,8.837-5.73,13.855-5.73
                                    c5.017,0,10.035,1.911,13.854,5.73l116.539,116.539c3.701,3.701,5.739,8.621,5.739,13.855
                                    C418.755,234.593,416.717,239.514,413.016,243.214z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M326.078,200.082c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0l-5.758,5.758
                                    c-15.375-9.497-35.103-8.222-47.574,4.247c-7.271,7.271-10.991,17.145-10.475,27.802c0.499,10.292,4.931,20.117,12.478,27.664
                                    c0.001,0.001,0.002,0.001,0.002,0.002c8.043,8.047,8.941,20.239,2.001,27.179c-6.943,6.941-19.137,6.042-27.183-2.002
                                    c-8.046-8.047-8.944-20.241-2.003-27.183c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.905-10.236-3.905-14.143,0
                                    c-12.47,12.47-13.744,32.198-4.247,47.574l-6.832,6.831c-3.905,3.905-3.905,10.237,0,14.143c1.958,1.955,4.517,2.931,7.076,2.931
                                    c2.56,0,5.118-0.977,7.071-2.929l6.836-6.836c6.718,4.149,14.264,6.251,21.676,6.251c9.549-0.001,18.872-3.474,25.893-10.494
                                    c14.736-14.737,13.84-39.612-1.995-55.457c-0.003-0.003-0.005-0.006-0.008-0.009c-4.026-4.026-6.386-9.171-6.644-14.49
                                    c-0.239-4.952,1.408-9.459,4.641-12.691c6.94-6.941,19.132-6.043,27.179,2c0.001,0.001,0.001,0.002,0.002,0.003
                                    c0.001,0.001,0.002,0.001,0.002,0.001c8.044,8.047,8.941,20.239,2,27.18c-3.905,3.905-3.905,10.237,0,14.143
                                    c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929c12.47-12.471,13.745-32.199,4.248-47.574L326.078,200.082z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M224.867,365.867l-51.361-51.362c-3.905-3.902-10.235-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l51.362,51.362
                                    c1.953,1.952,4.512,2.929,7.071,2.929s5.118-0.977,7.071-2.929C228.772,376.105,228.772,369.773,224.867,365.867z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M146.109,287.11c-1.859-1.859-4.43-2.93-7.069-2.93c-2.63,0-5.2,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.08
                                    c0,2.63,1.069,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.069-2.93c1.87-1.87,2.94-4.44,2.94-7.07
                                    C149.049,291.55,147.979,288.98,146.109,287.11z"/>
                            </g>
                        </g>
                    </svg>
                            <span className="d-block font-size-18 font-weight-semi-bold mt-3">pricing plans</span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 column-td-6">
                    <div className="category-item faq-cat-item p-0">
                        <Link to="/contact" className="d-block padding-top-30px padding-bottom-30px">
                            <svg className="cat-svg" version="1.1" id="Capa_3" x="0px" y="0px" viewBox="0 0 512.001 512.001">
                        <g>
                            <g>
                                <path d="M444.033,406.871c-1.859-1.86-4.439-2.929-7.068-2.929c-2.629,0-5.209,1.069-7.068,2.929
                                    c-1.859,1.859-2.929,4.439-2.929,7.068s1.07,5.209,2.929,7.067c1.859,1.859,4.439,2.93,7.068,2.93c2.629,0,5.209-1.07,7.068-2.93
                                    c1.87-1.859,2.929-4.438,2.929-7.067S445.903,408.73,444.033,406.871z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M193.511,435.933c-20.947,0-37.99,17.042-37.99,37.99c0,20.947,17.042,37.99,37.99,37.99
                                    c20.947,0,37.99-17.042,37.99-37.99C231.501,452.975,214.458,435.933,193.511,435.933z M193.511,491.918
                                    c-9.922,0-17.995-8.073-17.995-17.995s8.073-17.995,17.995-17.995c9.922,0,17.995,8.073,17.995,17.995
                                    S203.433,491.918,193.511,491.918z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M376.883,435.933c-20.947,0-37.99,17.042-37.99,37.99c0,20.947,17.042,37.99,37.99,37.99
                                    c20.947,0,37.99-17.042,37.99-37.99C414.873,452.975,397.831,435.933,376.883,435.933z M376.883,491.918
                                    c-9.922,0-17.995-8.073-17.995-17.995s8.073-17.995,17.995-17.995c9.922,0,17.995,8.073,17.995,17.995
                                    S386.807,491.918,376.883,491.918z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M467.423,264.491c-5.418-1.045-10.664,2.503-11.708,7.926l-14.53,75.431c-0.905,4.697-5.034,8.106-9.817,8.106H142.872
                                    l-39.154-170.287h102.806c5.522,0,9.997-4.476,9.997-9.997c0-5.521-4.476-9.997-9.997-9.997H99.12l-10.663-46.376
                                    c-3.149-13.702-15.169-23.272-29.229-23.272H9.997C4.476,96.024,0,100.5,0,106.022c0,5.522,4.476,9.997,9.997,9.997h49.232
                                    c4.687,0,8.693,3.189,9.743,7.757l67.232,292.403c1.044,4.54,5.085,7.757,9.743,7.757h254.998c5.522,0,9.997-4.477,9.997-9.997
                                    c0-5.521-4.476-9.997-9.997-9.997H153.906l-6.436-27.992h283.898c14.35,0,26.737-10.227,29.45-24.318l14.53-75.431
                                    C476.393,270.777,472.845,265.536,467.423,264.491z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M508.134,135.11c6.35-10.318,4.757-23.464-3.875-31.965l-9.984-9.835c-1.223-1.205-1.854-2.877-1.733-4.59l1-14.048
                                    c0.865-12.142-6.67-23.075-18.325-26.588l-13.221-3.986c-1.654-0.499-2.996-1.694-3.684-3.278l-5.534-12.767
                                    c-4.796-11.064-16.866-17.407-28.698-15.087l-13.415,2.633c-1.706,0.333-3.456-0.1-4.807-1.191l-10.679-8.622
                                    c-9.409-7.596-23.069-7.598-32.479,0l-10.679,8.622c-1.352,1.091-3.102,1.526-4.808,1.19l-13.416-2.633
                                    c-11.833-2.323-23.902,4.022-28.697,15.084l-5.536,12.77c-0.687,1.585-2.029,2.779-3.683,3.278l-13.219,3.985
                                    c-11.654,3.514-19.189,14.447-18.324,26.588l1,14.049c0.122,1.712-0.51,3.384-1.732,4.589l-9.984,9.835
                                    c-8.632,8.502-10.225,21.647-3.875,31.965l7.382,11.997c0.898,1.46,1.112,3.232,0.588,4.864l-4.32,13.433
                                    c-3.715,11.553,0.979,23.949,11.413,30.143l11.967,7.105c1.48,0.878,2.494,2.355,2.783,4.052l2.354,13.814
                                    c2.052,12.039,12.005,20.869,24.201,21.475l13.704,0.681c1.731,0.086,3.325,0.927,4.373,2.305l8.357,10.996
                                    c4.995,6.57,12.76,10.179,20.67,10.179c3.673,0,7.378-0.778,10.85-2.399l12.39-5.785c1.575-0.736,3.381-0.736,4.957,0
                                    l12.388,5.785c10.952,5.113,24.208,1.841,31.521-7.783l8.353-10.992c1.049-1.38,2.643-2.22,4.374-2.306l13.707-0.682
                                    c12.197-0.606,22.149-9.436,24.201-21.475l2.354-13.814c0.289-1.697,1.304-3.173,2.783-4.052l11.967-7.104
                                    c10.434-6.194,15.128-18.59,11.414-30.143l-4.32-13.433c-0.525-1.632-0.311-3.404,0.587-4.863L508.134,135.11z M491.105,124.631
                                    l-7.383,11.998c-3.962,6.44-4.908,14.263-2.593,21.461l4.32,13.433c0.842,2.618-0.222,5.427-2.585,6.83l-11.966,7.104
                                    c-6.532,3.878-11.01,10.396-12.288,17.885l-2.354,13.813c-0.465,2.727-2.719,4.728-5.484,4.866l-13.708,0.682
                                    c-7.638,0.38-14.672,4.089-19.3,10.178l-8.353,10.992c-1.658,2.179-4.661,2.921-7.143,1.763l-12.388-5.785
                                    c-6.952-3.247-14.927-3.246-21.878,0l-12.389,5.785c-2.481,1.16-5.485,0.417-7.142-1.763l-8.357-10.996
                                    c-4.627-6.087-11.661-9.796-19.299-10.176l-13.704-0.681c-2.763-0.137-5.019-2.138-5.484-4.866l-2.354-13.814
                                    c-1.277-7.488-5.755-14.007-12.287-17.884l-11.967-7.104c-2.364-1.404-3.427-4.212-2.585-6.83l4.32-13.433
                                    c2.314-7.199,1.37-15.021-2.593-21.462l-7.383-11.998c-1.439-2.337-1.078-5.316,0.878-7.242l9.985-9.836
                                    c5.396-5.316,8.182-12.698,7.644-20.253l-1-14.049c-0.196-2.75,1.512-5.228,4.151-6.024l13.219-3.985
                                    c7.298-2.2,13.223-7.474,16.256-14.467l5.536-12.77c0.937-2.159,3.096-3.526,5.392-3.526c0.369,0,0.74,0.036,1.111,0.108
                                    l13.415,2.632c7.521,1.479,15.254-0.438,21.218-5.253l10.679-8.622c2.133-1.722,5.228-1.721,7.359,0l10.679,8.622
                                    c5.963,4.814,13.694,6.728,21.218,5.254l13.414-2.632c2.683-0.53,5.416,0.912,6.501,3.418l5.534,12.767
                                    c3.032,6.996,8.958,12.27,16.258,14.47l13.221,3.986c2.64,0.796,4.348,3.273,4.152,6.024l-1,14.048
                                    c-0.538,7.555,2.248,14.937,7.644,20.254l9.984,9.835C492.182,119.314,492.543,122.292,491.105,124.631z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M402.067,87.828c-4.809-2.715-10.906-1.018-13.62,3.791l-46.365,82.13c-2.714,4.809-1.018,10.906,3.791,13.62
                                    c1.554,0.877,3.24,1.294,4.906,1.294c3.489,0,6.877-1.83,8.715-5.085l46.365-82.13C408.572,96.639,406.874,90.542,402.067,87.828z
                                    "/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M409.072,140.346c-14.337,0-26.001,11.664-26.001,26.001s11.664,26.001,26.001,26.001
                                    c14.337,0,26.001-11.664,26.001-26.001S423.408,140.346,409.072,140.346z M409.072,172.354c-3.312,0-6.006-2.694-6.006-6.006
                                    c0-3.312,2.694-6.006,6.006-6.006c3.312,0,6.006,2.694,6.006,6.006C415.078,169.66,412.384,172.354,409.072,172.354z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M348.811,73.885c-14.337,0-26.001,11.664-26.001,26.001c0,14.337,11.664,26.001,26.001,26.001
                                    s26.001-11.664,26.001-26.001C374.812,85.549,363.148,73.885,348.811,73.885z M348.81,105.893c-3.312,0-6.006-2.694-6.006-6.006
                                    c0-3.312,2.694-6.006,6.006-6.006c3.312,0,6.006,2.694,6.006,6.006C354.816,103.198,352.122,105.893,348.81,105.893z"/>
                            </g>
                        </g>
                    </svg>
                            <span className="d-block font-size-18 font-weight-semi-bold mt-3">sales question</span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-3 column-td-6">
                <div className="category-item faq-cat-item p-0">
                        <Link to="/dashboard" className="d-block padding-top-30px padding-bottom-30px">
                            <svg className="cat-svg" version="1.1" id="Capa_8" x="0px" y="0px" viewBox="0 0 511.997 511.997" >
                        <g>
                            <g>
                                <path d="M415.679,24.4H31.511C14.136,24.4,0,38.536,0,55.911v320.14c0,17.375,14.136,31.51,31.511,31.51h251.61
                                    c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5h-28.016v-48.524c0-12.762-7.611-24.179-19.391-29.086l-39.402-16.418
                                    c-3.18-1.325-5.235-4.407-5.235-7.852v-11.71c10.097-10.269,16.007-24.252,16.007-38.979v-32.014
                                    c0-26.201-24.907-47.518-55.521-47.518s-55.521,21.316-55.521,47.518v32.014c0,14.725,5.91,28.709,16.007,38.979v11.71
                                    c0,3.445-2.055,6.527-5.235,7.853l-39.401,16.417c-11.78,4.908-19.392,16.325-19.392,29.087v48.524h-16.51
                                    c-9.104,0-16.511-7.407-16.511-16.51V119.435h417.189v124.558c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V55.911
                                    C447.189,38.536,433.054,24.4,415.679,24.4z M63.021,344.037c0-6.687,3.988-12.669,10.161-15.241l39.402-16.417
                                    c8.787-3.661,14.465-12.178,14.465-21.698v-1.625c2.753,0.176,5.5-1.17,6.977-3.719c2.075-3.585,0.852-8.173-2.732-10.249
                                    c-12.491-7.232-20.251-20.68-20.251-35.095v-32.014c0-17.93,18.178-32.518,40.521-32.518s40.521,14.587,40.521,32.518v32.014
                                    c0,14.421-7.765,27.872-20.264,35.104c-3.585,2.074-4.81,6.662-2.736,10.248c1.39,2.403,3.909,3.746,6.499,3.746
                                    c0.165,0,0.33-0.021,0.495-0.032v1.624c0,9.52,5.678,18.037,14.466,21.698l39.402,16.418c6.172,2.571,10.16,8.554,10.16,15.24
                                    v48.524H63.021V344.037z M432.189,104.435H15V55.911C15,46.807,22.407,39.4,31.511,39.4h384.168c9.104,0,16.51,7.407,16.51,16.511
                                    V104.435z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M39.594,64.418h-0.08c-4.142,0-7.46,3.358-7.46,7.5c0,4.142,3.398,7.5,7.54,7.5c4.142,0,7.5-3.358,7.5-7.5
                                    C47.094,67.776,43.736,64.418,39.594,64.418z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M87.535,64.418h-0.08c-4.142,0-7.46,3.358-7.46,7.5c0,4.142,3.398,7.5,7.54,7.5c4.142,0,7.5-3.358,7.5-7.5
                                    C95.035,67.776,91.677,64.418,87.535,64.418z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M63.524,64.418h-0.08c-4.142,0-7.46,3.358-7.46,7.5c0,4.142,3.398,7.5,7.54,7.5c4.142,0,7.5-3.358,7.5-7.5
                                    C71.024,67.776,67.666,64.418,63.524,64.418z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M407.675,64.418H119.549c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h288.126c4.142,0,7.5-3.358,7.5-7.5
                                    C415.175,67.776,411.817,64.418,407.675,64.418z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M506.964,294.5l-108.047-37.582c-1.596-0.556-3.332-0.556-4.928,0l-77.699,27.025c-3.912,1.361-5.98,5.636-4.62,9.548
                                    c1.361,3.912,5.636,5.979,9.547,4.62l75.235-26.168l100.188,34.848c-0.873,9.577-3.811,31.034-13.553,56.728
                                    c-11.876,31.323-36.502,75.061-86.635,107.688c-49.917-32.489-74.526-75.991-86.426-107.14
                                    c-13.325-34.879-14.117-62.377-14.124-62.651c-0.093-4.137-3.47-7.407-7.657-7.331c-4.141,0.088-7.427,3.516-7.34,7.657
                                    c0.026,1.204,0.786,29.906,14.885,67.094c13.004,34.299,40.336,82.548,96.697,117.628c1.213,0.755,2.588,1.133,3.963,1.133
                                    s2.75-0.377,3.963-1.133c56.361-35.08,83.693-83.329,96.697-117.628c14.1-37.188,14.86-65.89,14.885-67.094
                                    C512.067,298.492,510.034,295.567,506.964,294.5z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M445.21,336.798c-2.929-2.929-7.678-2.929-10.606,0l-46.372,46.372l-27.581-27.581c-2.929-2.929-7.678-2.929-10.606,0
                                    c-2.929,2.929-2.929,7.678,0,10.606l32.884,32.884c1.464,1.465,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197l51.675-51.675
                                    C448.139,344.475,448.139,339.726,445.21,336.798z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M383.665,152.456H255.608c-8.549,0-15.503,6.955-15.503,15.503v24.011c0,8.549,6.955,15.503,15.503,15.503h128.056
                                    c8.549,0.001,15.504-6.954,15.504-15.503v-24.011C399.168,159.41,392.213,152.456,383.665,152.456z M384.167,191.97
                                    c0,0.278-0.226,0.503-0.503,0.503H255.608c-0.277,0.001-0.503-0.225-0.503-0.503v-24.011c0-0.278,0.226-0.503,0.503-0.503h128.056
                                    c0.278,0,0.503,0.226,0.503,0.503V191.97z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M351.65,224.487h-96.042c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h96.042c4.142,0,7.5-3.358,7.5-7.5
                                    C359.15,227.845,355.792,224.487,351.65,224.487z"/>
                            </g>
                        </g>
                                <g>
                            <g>
                                <path d="M311.633,256.502h-56.025c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h56.025c4.142,0,7.5-3.358,7.5-7.5
                                    C319.133,259.86,315.775,256.502,311.633,256.502z"/>
                            </g>
                        </g>
                     </svg>
                            <span className="d-block font-size-18 font-weight-semi-bold mt-3">account</span>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    );
}

export default FaqCategories;
