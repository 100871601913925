import React, { useCallback, useState} from 'react';
import { useHistory } from "react-router-dom";
import {Link} from "react-router-dom";
import 'crypto-js/lib-typedarrays'; 
import { Auth } from 'aws-amplify';
import { AiOutlineUser } from 'react-icons/ai'
import { FaRegEnvelope,FaPhoneAlt } from 'react-icons/fa'
import { FiLock } from 'react-icons/fi'
import { Form, Input, Button, notification, Popover, Spin, Col, Row,Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {refresh} from '../../common/TokenRefresh';
import { IDENTITY_USER_TOKEN_KEY } from '../../data/constants';

function SignUpBox({title, subtitle}) {
    let history = useHistory();
    const passwordValidator = require('password-validator');
    const [form] = Form.useForm();
    const [confirmDirty, setConfirmDirty] = useState(false);
    const[loading,setLoading] = useState(false);
    const[email,setEmail] = useState('');
    const[phone,setPhone] = useState('');
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    // create a password schema
    const schema = new passwordValidator();
    schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();  
    const titlePassword = 'Password Policy';
    const passwordPolicyContent = (
      <React.Fragment>
        <h4>Your password should contain: </h4>
        <ul>
          <li>Minimum length of 8 characters</li>
          <li>Numerical characters (0-9)</li>
          <li>Special characters</li>
          <li>Uppercase letter</li>
          <li>Lowercase letter</li>
        </ul>
      </React.Fragment>
    );
    const formatPasswordValidateError = (errors) => {
        for (let i = 0; i < errors.length; i++) {
          if (errors[i] === 'min') {
            return 'Password length should be a at least 8 characters';
          } else if (errors[i] === 'lowercase') {
            return 'Password should contain lowercase letters';
          } else if (errors[i] === 'uppercase') {
            return 'Password should contain uppercase letters';
          } else if (errors[i] === 'digits') {
            return 'Password should contain digits';
          } else if (errors[i] === 'symbols') {
            return 'Password should contain symbols';
          }
        }
      };
    const validateToNextPassword = (rule, value, callback) => {
        const validationRulesErrors = schema.validate(value, { list: true });
    
       // if (value && confirmDirty) {
      //    form.validateFields(['password2'], { force: true });
      //  }
        if (validationRulesErrors.length > 0) {
          callback(formatPasswordValidateError(validationRulesErrors));
        }
        callback();
      };
      const compareToFirstPassword = (rule, value, callback) => {
        console.log(form.getFieldValue('password1'));
        if (value && value !== form.getFieldValue('password1')) {
          callback('Two passwords that you entered are inconsistent!');
        } else {
          callback();
        }
      };
    const handleConfirmBlur = (event) => {
        const { value } = event.currentTarget;
    
        setConfirmDirty(confirmDirty || !!value );
      };

    const onCheckboxChange = async (e) => {
        form.validateFields(['checkbox1']);

        setChecked(!checked);

    };
    const onCheckboxChange2 = async (e) => {
        form.validateFields(['checkbox1']);
        setChecked2(!checked2);

    };
    const validation = (rule, value, callback) => {
        if(checked) {
            return callback()
        }
        return callback("Please accept the Privacy Policy")
    };
    const validation2 = (rule, value, callback) => {
        if(checked2) {
            return callback()
        }
        return callback("Please accept the Terms of Services")
    };

    const onFinish = (values) => {
      let userBody = {
        email: values.email,
        firstname:values.firstName,
        lastname: values.lastName,
        phone:values.phone
    }
      let userUrl = SERVER_URL+'/user';
        console.log('Success:', values);
        setLoading(true);
        const email = values.email;
        const phone = values.phone;
        const password = values.password1;
        const {firstName,lastName}=values;
        Auth.signUp({
            username: email,
            password,
            attributes: {
              email,
              name: firstName+' '+lastName
            }
          })
            .then(() => {

              const navigate = () => {
                window.open('/verify-code?email='+email,'_self');
             };
         
             // call the async function 
             navigate()
              
            })
            .catch(err => {

  
              setLoading(false);
              console.log(err);
              alert(err.message);
            });
        
      }; 
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 16,
        },
      };
      const onChange = (e) => {
        console.log(e);
      }
    return (
        <>
            <div className=" ">
                <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
                    <h3 className="widget-title font-size-28 pb-0">
                        {title}
                    </h3>
                    <p className="font-size-16 font-weight-medium">
                        {subtitle}
                    </p>
                </div>
                <div className="">
                    <div className="contact-form-action">
                        <Form  form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>

                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                    <span className="form-icon">
                                                    <AiOutlineUser />
                                                </span>
                                            <Form.Item  label="First Name" name="firstName" rules={[{required:true,message:'Please input your first name!'}]}>

                                             <Input  onChange={onChange} className="form-control" placeholder='First Name' type="text" />
                                            </Form.Item>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                    <span className="form-icon">
                                                    <AiOutlineUser />
                                                </span>
                                            <Form.Item  label="Last Name" name="lastName" rules={[{required:true,message:'Please input your last name!'}]}>

                                             <Input  className="form-control" placeholder='Last Name'/>
                                            </Form.Item>
                                            </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                    <span className="form-icon">
                                                    <FaRegEnvelope />
                                                </span>
                                            <Form.Item  label="Email" name="email" rules={[{required:true,message:'Please input your email!'}]}>

                                             <Input  className="form-control" placeholder='Email'/>
                                            </Form.Item>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                    <span className="form-icon">
                                                    <FaPhoneAlt />
                                                </span>
                                            <Form.Item  label="Phone" name="phone" rules={[{required:true,message:'Please input your phone number!'}]}>

                                             <Input  className="form-control" placeholder='Phone'/>
                                            </Form.Item>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                    <span className="form-icon">
                                                    <FiLock />
                                                </span>
                                                
                                            <Form.Item  label="Password" name="password1" rules= {[{ required: true, message: 'Please input your Password!' },{validator: validateToNextPassword}] }>
                                            
                                               
                                                <Input  className="form-control" placeholder='Enter password' type="Password"/>
 
                                                
                                            </Form.Item>
                                            
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <div className="form-group">
                                    <span className="form-icon">
                                                    <FiLock />
                                                </span>
                                            <Form.Item  label="Confirm Password" name="password2" rules={[{required:true,message:'Please confirm your password!'},{validator:compareToFirstPassword}]}>

                                             <Input  className="form-control" placeholder='Confirm password' type="password" onBlur={handleConfirmBlur}/>
                                            </Form.Item>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                

                                    <div className="form-group">
                                        <div >
                                        <Form.Item name="checkbox1" rules={[{validator:validation}]} >
                                            <Checkbox id="chb13" value="A" onChange={onCheckboxChange}/>
                                            <label style={{margin:'5px'}} htmlFor="chb13">I Agree to Geophenom's <Link to="#" className="color-text">Privacy Policy</Link></label>
                                            </Form.Item>
                                           
                                        </div>
                                        <div >
                                        <Form.Item name="checkbox2" rules={[{validator:validation2}]} >
                                            <Checkbox id="chb14" value="B" onChange={onCheckboxChange2}/>
                                            <label style={{margin:'5px'}} htmlFor="chb14">I Agree to Geophenom's <Link to="#" className="color-text">Terms of Services</Link></label>
                                        </Form.Item>
                                            
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12">

                                    <div className="btn-box margin-top-20px margin-bottom-20px">
                                        <Button className="theme-btn border-0" htmlType="submit" disabled={loading}>
                                        {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Register Account'}
                                        </Button>
                                    </div>

                                </div>
                                <div className="col-lg-12">
                                    <p className="font-weight-medium">
                                        Already have an account? <Link to="/login" className="color-text">Login</Link>
                                    </p>
                                </div>
                            
                        </Form>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default SignUpBox;