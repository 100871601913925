import React, { useEffect } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import BlogSidebar from "../../components/sidebars/BlogSidebar";
import BlogDetailContent from "../../components/blogs/BlogDetailContent";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from "../../assets/images/bread-bg.jpg";
// import BlogItems from './BlogItems';
import { TiArrowMaximiseOutline } from "react-icons/ti";
// import Blogs from './Blogs';
import axios from "axios";

const state = {
  breadcrumbimg: breadcrumbimg,
};

const BlogPostRequestStatus = Object.freeze({
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
});

function BlogDetail() {
  const [blogPosts, setBlogPosts] = React.useState([]);
  const [blogImages, setBlogImages] = React.useState({});
  const [blogSlugs, setBlogSlugs] = React.useState({});
  const [blogPostStatus, setBlogPostStatus] = React.useState(
    BlogPostRequestStatus.LOADING
  );
  const BLOG_URL = process.env.REACT_APP_BLOG_URL;

  const fetchBlogPosts = async () => {

    const res = await axios.get(BLOG_URL +'/blogs');
    return res.data;
  };

  const fetchBlogImage = async (featuredImageId, blogId) => {
    try {
      const res = await axios.get(BLOG_URL +`/media/${featuredImageId}`);
      setBlogImages((prevState) => ({
        ...prevState,
        [blogId]: res.data.source_url,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchBlogSlug = async (blogId) => {
    try {
      const res = await axios.get(BLOG_URL + `/blogs/${blogId}`);
      setBlogSlugs((prevState) => ({
        ...prevState,
        [blogId]: res.data.slug,
      }));
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    async function fetchData() {
      setBlogPostStatus(BlogPostRequestStatus.LOADING);
      const posts = await fetchBlogPosts();
      setBlogPosts(posts);
      for (const blog of posts) {
        await fetchBlogImage(blog.featured_media, blog.id);
        await fetchBlogSlug(blog.id);
      }
      setBlogPostStatus(BlogPostRequestStatus.SUCCESS);
    }
    fetchData();
  }, []);

  const formatDate = (userdate) => {
    let blogDate = new Date(userdate);
    let date = blogDate.toLocaleDateString("en-us", { day: "numeric" });
    let month = blogDate.toLocaleDateString("en-us", { month: "long" });
    let year = blogDate.toLocaleDateString("en-us", { year: "numeric" });
    return `${month} ${date}, ${year}`;
  };

  return (
    <div>
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Phenomenoms"
        MenuPgTitle="Blog"
        img={state.breadcrumbimg}
      />

      <section className="blog-single-area padding-top-40px padding-bottom-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {/* <BlogDetailContent /> */}

              {blogPostStatus === BlogPostRequestStatus.LOADING && (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {blogPostStatus === BlogPostRequestStatus.SUCCESS &&
                blogPosts.map((blog) => (
                  // <BlogPost key={blog.id} blog={blog} />
                  <a
                    href={`/blog/${blogSlugs[blog.id]}`}
                    target="_self"
                    rel="noreferrer"
                  >
                    <div
                      className="blog-card card"
                      key={blog.id}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        className="card-horizontal"
                        style={{ display: "flex", flex: "1 1 auto" }}
                      >
                        <div className="img-square-wrapper">
                          <img
                            style={{
                              maxWidth: "150px",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={blogImages[blog.id]}
                            alt="blog-img"
                          />
                        </div>
                        <div className="card-body">
                          <h4 class="card-title" style={{ marginTop: "0px" }}>
                            {blog.title.rendered}
                          </h4>
                          <p class="card-text">{formatDate(blog.date)}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
           
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />
    </div>
  );
}

export default BlogDetail;
