import React, { useState,useEffect } from 'react'
import {
  FiPlus,
  FiPlusCircle,
  FiBookmark,
  FiSearch,
  FiEdit,
  FiUsers
} from "react-icons/fi";


import {
  RiCoupon2Line
} from "react-icons/ri";

import { BsListCheck, BsQuestion, BsGear, BsPower } from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'
import {Link} from "react-router-dom";
import userimg from '../../../assets/images/team1.jpg'
import { Auth } from 'aws-amplify';
import { IDENTITY_USER_TOKEN_KEY } from '../../data/constants';
import { Dropdown } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { fetchUserIsAdmin } from '../../../utils/APIRequests';
import { refresh } from '../../common/TokenRefresh';

export default function HeaderAuthorAccess() {
    const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false);
    const[userName,setUserName] = useState('Guest');
    const[name,setName] = useState('Guest');
    const[campaigns,setCampaigns]=useState([]);
    const [isUserPriceAdmin, setIsUserPriceAdmin] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);


  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      if (user) {
        setIsUserLoggedIn(true);
        setUserName(user.attributes.email);
        setName(user.attributes.name);
        console.log({ user })
      }
    }).catch(err => {
      console.log(err);
    });
  }, []);

    useEffect(() => {
        fetchUserIsAdmin().then(res => {
            if (res["Bool"] === true) {
                setIsUserPriceAdmin(true);
            }
        }).catch(err => {
            console.error(err);
        });
    }, []);

    useEffect(()=>{
      if (userName === 'Guest') {
            Auth.currentAuthenticatedUser().then((userInfo) => {
                if(userInfo&&userInfo!=null&&localStorage.getItem(IDENTITY_USER_TOKEN_KEY)){
                    //console.log(userInfo);
                    var decoded = jwt_decode(localStorage.getItem(IDENTITY_USER_TOKEN_KEY));
                    const start = Date.now()/1000;
                    if(start>decoded.exp){
                        localStorage.removeItem(IDENTITY_USER_TOKEN_KEY)
                        setUserName('Guest');
                        setName('Guest');
                    }else{
                        const { attributes = {} } = userInfo;
                        const{username}=userInfo;
                        //console.log(userInfo);
                        setUserName(attributes['email']);
                        setName(attributes['name']);
                    }
 
                }
            }).catch((err)=>{
                console.log(err);
                localStorage.removeItem(IDENTITY_USER_TOKEN_KEY)
                setUserName('Guest');
                setName('Guest');
            });
        }

    },[]);

    const signOut=()=>{
        Auth.signOut().then(() => {
            localStorage.removeItem(IDENTITY_USER_TOKEN_KEY)
           setUserName('Guest');
           setName('Guest');
           window.location.href='/';
        })
        .catch(err => {
         console.log(err);
        });

    };

    return (
      <>
        <div className="logo-right-content">
          <ul className="author-access-list">
            {(userName !== "Guest" && isUserLoggedIn) && <li>{userName}</li>}
            {(userName === "Guest" || !isUserLoggedIn) && (
              <li>
                <Link to="/login">Login</Link>
                <span className="or-text">or</span>
                <Link to="/sign-up">Sign up</Link>
              </li>
            )}
          </ul>
          <div
            className="side-user-menu-open"
            onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
          >
            <AiOutlineUser />
          </div>
        </div>

        {/* Side User panel */}
        <div
          className={
            AuthorAccessOpen ? "side-user-panel active" : "side-user-panel"
          }
        >
          <div className="humburger-menu">
            <div
              className="humburger-menu-lines side-menu-close"
              onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
            ></div>
          </div>
          <div className="side-menu-wrap side-user-menu-wrap">
            <div className="side-user-img">
              <h4 className="su__name">{name}</h4>
            </div>

            <ul className="side-menu-ul">
              <li>
                <Link to="/user-profile">
                  <AiOutlineUser className="user-icon" /> My Profile
                </Link>
              </li>
              <li>
                <Link to="/dashboard">
                  <BsListCheck className="user-icon" /> My Dashboard
                </Link>
              </li>
              <li>
                <Link to="/list-map-view?type=gas_station">
                  <FiPlusCircle className="user-icon" /> add campaign
                </Link>
              </li>
              {campaigns && campaigns.length > 0 && (
                <li>
                  <Dropdown className="dropdown share-dropmenu">
                    <Dropdown.Toggle
                      className="theme-btn dropdown-toggle border-0 after-none"
                      id="dropdown-basic"
                    >
                      <i className="d-inline-block"></i> campaigns
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu">
                      {campaigns.map((item, index) => {
                        return (
                          <Dropdown.Item
                            href={
                              "/list-map-view?type=" +
                              item.type.String +
                              "&place=" +
                              item.longitude.Float64 +
                              "," +
                              item.latitude.Float64 +
                              "&campaign=" +
                              item.id +
                              "&priceLevel=" +
                              item.pricelevel.Int32 +
                              "&name=" +
                              item.name.String
                            }
                            className={"dropdown-item "}
                            key={index}
                          >
                            {item.name.String}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
              <li>
                <Link to="/list-map-view?type=gas_station">
                  <FiPlusCircle className="user-icon" /> modify existing
                  campaign
                </Link>
              </li>
              {isUserPriceAdmin && (
                <li>
                  <Link to="/edit-pricing">
                    <FiEdit className="user-icon" /> edit pricing
                  </Link>
                </li>
              )}
              {isUserPriceAdmin && (
                <li>
                  <Link to="/user-list">
                    <FiUsers className="user-icon" /> User List
                  </Link>
                </li>
              )}
              {isUserPriceAdmin && (
              <li>
                <Link to="/discount">
                  <RiCoupon2Line className="user-icon" /> Edit Discounts 
                </Link>
              </li>
              )}
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link to="/faq">
                  <BsQuestion className="user-icon" /> help
                </Link>
              </li>
              
              {
                isUserLoggedIn && (
                  <li>
                <Link to="#" onClick={signOut}>
                  <BsPower className="user-icon" /> Sign Out
                </Link>
              </li>
                )
              }
            </ul>
          </div>
        </div>
      </>
    );
}
