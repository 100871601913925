import React from 'react';
import {IoIosLink} from "react-icons/io";
import {FiHeart, FiPhone} from "react-icons/fi";
import {FaRegCalendarCheck} from "react-icons/fa";
import {AiOutlineEye} from "react-icons/ai";
import {Link} from "react-router-dom";
import sectiondata from "../../store/store";

function PlaceGrid({griditems}) {

    return (
        <>
            {griditems.map((item, index) => {
                return (
                    <div className="col-lg-4 column-td-6" key={index}>
                        <div className="card-item">
                            <div className="card-content-wrap">
                                <div className="card-content">
                                <h4 className="card-title">
                                <span className="la d-inline-block"><IoIosLink /></span>
                                   <a href={"https://www.google.com/maps/place/?q=place_id:"+item.place_id} target="_blank">{item.name}</a> 
                                </h4>
                                <p className="card-sub">
                                            {item.formatted_address}
                                </p>
                                <img src={typeof item.photos!='undefined' ? item.photos[0].getUrl({'maxWidth': 100, 'maxHeight': 100}):sectiondata.aboutdata.aboutst2.items[0].img} alt="author-img" />
                                <img src={"https://maps.googleapis.com/maps/api/streetview?size=100x100&location="+item.geometry.location.lat()+","+item.geometry.location.lng()+"&heading=151.78&pitch=-0.76&key=AIzaSyAkYU26J-26PQpMWBXAuwWiNPmHZE60PMQ"} alt="author-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}

export default PlaceGrid;
