import React, {useEffect, useState} from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import sectiondata from "../../store/store";

function Metrics() {
return (
    <main className="dashboard-page">
        {/* Header */}
        <GeneralHeader />

        {/* Breadcrumb */}
        <Breadcrumb CurrentPgTitle="Metrics (Coming Soon)" MenuPgTitle="pages" img={sectiondata.dashboard.breadcrumbimg} />
        </main>
        )
}
export default Metrics;