import React,{Component} from 'react'
import  { FiSearch } from 'react-icons/fi'
import Select from "react-select";
import GooglePlacesAutocomplete from '../../GooglePlacesAutocomplete.tsx';


class BannerOneSearchInput extends Component{
    constructor()
    {
        super()
        this.onClick = this.onClick.bind(this);
        this.state = {
            googleMapsReady: false,
            isNotAllowed: false,
            confirm: [],
            value:'',
            choice:'',
            structuredName:'',
            selectedChoice: {},
            selectedCatOp: null,
            categories: [
                {
                    value: 0,
                    label: 'Select a category'
                },
                {
                    value: 'All Categories',
                    label: 'All Categories'
                },
                {
                    value: 'shopping_mall',
                    label: 'Shops'
                },
                {
                    value: 'lodging',
                    label: 'Hotels'
                },
                {
                    value: 'convenience_store',
                    label: 'Foods & Drink'
                },
                {
                    value: 'gym',
                    label: 'Fitness'
                },
                {
                    value: 'gas_station',
                    label: 'Automotive'
                },
                {
                    value: 'real_estate_agency',
                    label: 'Real Estate'
                },
                {
                    value: 'restaurant',
                    label: 'Restaurant'
                },
                {
                    value: 'accounting',
                    label: 'Financial Services'
                },
                {
                    value: 'lawyer',
                    label: 'Services'
                }
            ]
        }

    }
   service={};
    componentDidMount() {
            if(!window.google){
                this.loadGoogleMaps(() => {
                // Work to do after the library loads.
                this.setState({ googleMapsReady: true });
                this.service = new window.google.maps.places.PlacesService(document.createElement('div'));
            });
        }else{
            this.service = new window.google.maps.places.PlacesService(document.createElement('div'));
        }
    }

    loadGoogleMaps = callback => {
        const existingScript = document.getElementById("googlePlacesScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src =
                "https://maps.googleapis.com/maps/api/js?libraries=places&key="+process.env.REACT_APP_MAPS_KEY;
            script.id = "googleMaps";
            document.body.appendChild(script);
            //action to do after a script is loaded in our case setState
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    };
   typesNotAllowed=['administrative_area_level_1','administrative_area_level_2','administrative_area_level_3','administrative_area_level_4','administrative_area_level_5','archipelago','colloquial_area','continent','country','establishment','geocode','intersection','landmark','locality','natural_feature','neighborhood','plus_code','point_of_interest','political','post_box','postal_code','postal_code_prefix','postal_code_suffix','postal_town','premise','room','route','street_address','street_number','sublocality','sublocality_level_1','sublocality_level_2','sublocality_level_3','sublocality_level_4','sublocality_level_5','subpremise','town_square'];
   getNearby=(placeId)=>{
    var request = {
        placeId: placeId,
        fields: ['name', 'geometry']
      };
      
      this.service.getDetails(request, (place,status)=>this.placeDetailsCallback(place,status,placeId));
   };
   nearbyCallback=(name,placeid,results)=>{
       console.log(results);
       var outarray=[];
       let firstVal={};
       firstVal['value']=[];
       firstVal['value']['place_id']=placeid;
       firstVal['value']['types']=[];
       firstVal['value']['types'][0]='';
       let dropDownEle = { label: name, value: firstVal };
       outarray.push(dropDownEle);
        for (var i = 0; i < results.length; i++) {
            let val={};
            val['value']=results[i];
            let dropDownEle = { label: results[i]['name']+' ('+results[i]['types'][0]+')', value: val };
            outarray.push(dropDownEle);
        }
        this.setState({confirm: outarray});
   };
   placeDetailsCallback=(place,status,placeId)=>{
        //var lat = place.geometry.location.lat();
        //var lng = place.geometry.location.lng();
        //var pyrmont = new window.google.maps.LatLng(lat,lng);
        var request = {
            location: place.geometry.location,
            radius: '100',
            //keyword:'cafe',
            //rankBy: window.google.maps.places.RankBy.DISTANCE
          };
        this.service.nearbySearch(request, (results)=>this.nearbyCallback(place.name,placeId,results));
   };
   matchToExistingType=(typeArray,place_id)=>{
       if(typeArray.length>0){
           if(this.typesNotAllowed.includes(typeArray[0])){
               this.getNearby(place_id);
               this.setState({isNotAllowed:true});
           }else{
            this.setState({isNotAllowed:false});
           }
       }else{
        this.setState({isNotAllowed:false});
       }

        // let out = null;
        // let cats = ['shopping_mall',
        //       'lodging',
        //         'convenience_store',
        //         'gym',
        //         'gas_station',
        //         'real_estate_agency',
        //         'restaurant',
        //         'accounting',
        //         'lawyer']
        // for(var type in typeArray){
        //     if(cats.includes(typeArray[type])){
        //         return typeArray[type];
        //     }
        // }
        // return out;
    };

    getLabel=(type)=>{
        let labelObjs = this.state.categories;
        for(var index in labelObjs){
            if(labelObjs[index].value===type){
                return labelObjs[index].label;
            }
        }
        return null;
    };
    setValue(value){
        console.log(value);
        this.matchToExistingType(value.value.types,value.value.place_id);
        let outVal={place_id:value.value.place_id};
        this.setState({value:outVal})
        this.setState({choice:value.value.types[0]})  
        this.setState({structuredName: value.value.structured_formatting.main_text})
    };
    setValue2(value){
        if(value.value&&value.value.value){
            let outVal={place_id:value.value.value.place_id};
            this.setState({value:outVal});
            this.setState({choice:value.value.value.types[0]});
            console.log(value.value.value);
            this.setState({structuredName: value.label})

        }else{
            let outVal={place_id:value.place_id};
            this.setState({value:outVal});
            this.setState({choice:''})
            this.setState({structuredName: value.label})

        }
        this.setState({isNotAllowed:false});
    };
    goToMapSearch(location,choice,e){
        localStorage.removeItem("storedData");
        localStorage.removeItem("place");
        localStorage.removeItem("type");
        if ("geolocation" in navigator){
            if(this.state.choice.length>0){
                e.preventDefault();
                window.location.href='/list-map-view?place='+location+'&type='+this.state.choice+'&name='+this.state.structuredName;
            }else{
                e.preventDefault();
                window.location.href='/list-map-view?place='+location+'&type='+'&name='+this.state.structuredName;
            }
        }else{
            alert("Unable to determine your location or the location you wish to search for.")
        }
    }
    onClick(id,choice,isNotAllowed,e) {
        let location="";
        if(!id.place_id){
                var self=this;
                navigator.geolocation.getCurrentPosition(function(position){
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);
                    location=position.coords.longitude+","+position.coords.latitude;
                    if(!isNotAllowed){
                        self.goToMapSearch(location,choice,e);
                    }else{
                        alert("Please confirm you location choice");
                    }
                },function(error) {
                    if (error.code === error.PERMISSION_DENIED)
                      console.log("you denied me :-(");
                      alert("Oops. Can't find your location. Please enter business name (or address)")
                  });
        }else{
            if(!isNotAllowed){
                let placeId = id.place_id;
                this.goToMapSearch(placeId,choice,e);
            }else{
                alert("Please confirm you location choice");
            }
        }
    };

      styles={
          option: (base, state) =>({
              ...base,
              backgroundColor: state.isFocused ? "#40CC6F" : null,
              color: "black"
          })
      };

    render() {
        var handleChange = selectedChoice => {
            this.setState({ selectedChoice });
        };

        return (
            <>
                <div className="main-search-input">

                    <div className="main-search-input-item">
                        <div className="contact-form-action">
                            <form action="#">
                                <div className="form-group mb-0">
                                <span className="form-icon">
                                    <FiSearch/>
                                </span>
        <GooglePlacesAutocomplete
        selectProps={{
            onChange: this.setValue.bind(this),
            styles: this.styles,
            placeholder:"Enter business name (or address) or click to search near your location."

            
          } } 

        />

        
                                </div>
                            </form>
                        </div>
                    </div>

                    

                    
                    {this.state.isNotAllowed && <div className="main-search-input-item category">
                        <Select
                            placeholder="Confirm Your Selected Place"
                            options={this.state.confirm}
                            onChange={this.setValue2.bind(this)}
                        />
                    </div>
                    }
                    <div className="main-search-input-btn">
                    <button
                    onClick={(e) =>this.onClick(this.state.value,this.state.selectedChoice,this.state.isNotAllowed, e) }
                    className="button theme-btn" type="submit"
                    >
                        Search
                    </button>
                    </div>

                </div>
            </>
        )
    }
}
export default BannerOneSearchInput;
