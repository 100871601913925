import React, {Component} from 'react';
import ModalVideo from "react-modal-video";

class Banner9 extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal()
    {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <>
                <section style={{marginBottom:'-10%'}} className="breadcrumb-area about-breadcrumb">
                    <div className="breadcrumb-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="breadcrumb-content">
                                        <h2 className="breadcrumb__title mb-0 breadcrumb-title-style">{this.props.title}</h2>
                                    </div>
                                    <div className="breadcrumb-content">
                                        <h3 className="breadcrumb__title mb-0 breadcrumb-subtitle-style" style={{fontWeight:500,fontSize:'25px'}}>{this.props.subtitle}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bread-svg">
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none">
                            <path
                                d="M-4.22,89.30 C280.19,26.14 324.21,125.81 511.00,41.94 L500.00,150.00 L0.00,150.00 Z"/>
                        </svg>
                    </div>
                </section>
            </>
        );
    }
}

export default Banner9;
