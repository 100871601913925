import React, { useEffect, useState } from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import Breadcrumb from "../components/common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import { BsListCheck, BsBookmark, BsPencil } from "react-icons/bs";
import {
  FaGlobeAmericas,
  FaRegEdit,
  FaRegEnvelope,
  FaRegTrashAlt,
} from "react-icons/fa";
import { GiPositionMarker } from "react-icons/gi";
import { FiPhone, FiEdit } from "react-icons/fi";
import {
  AiOutlineUser,
  AiOutlinePlusCircle,
  AiOutlinePoweroff,
  AiOutlineYoutube,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import Button from "../components/common/Button";
import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import sectiondata from "../store/store";
import { Auth } from "aws-amplify";
import { IDENTITY_USER_TOKEN_KEY } from "../components/data/constants";
import { refresh } from "../components/common/TokenRefresh";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// import campaignsb from "../../assets/images/Campaigns-button-navy.svg";
// import profile from "../../assets/images/Profile-button-navy.svg";
// import metrics from "../../assets/images/Metrics-Reporting-button-navy.svg";
// import signout from "../../assets/images/Sign-Out-button-navy.svg";
// import create from "../../assets/images/Create-Campaign-button-green.svg";
import SingleCampaignItems from "./SingleComponentItems";
import Loader from "./loading/Loader";


function SingleCampaign() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  let location = useLocation();
  let currentCampaignID = "0";
  location = queryString.parse(location.search);
  if (location.campaign) {
    currentCampaignID = location.campaign;
  }

  const signOut = () => {
    Auth.signOut()
      .then(() => {
        localStorage.removeItem(IDENTITY_USER_TOKEN_KEY);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const yyyymmdd = (indate) => {
    let outdate = new Date(indate);
    if (outdate instanceof Date) {
      var mm = outdate.getMonth() + 1; // getMonth() is zero-based
      var dd = outdate.getDate();

      return [
        outdate.getFullYear(),
        "-" + (mm > 9 ? "" : "0") + mm + "-",
        (dd > 9 ? "" : "0") + dd,
      ].join("");
    } else {
      return "0";
    }
  };

  const changeAccount = (campaignid) => {
    let email = localStorage.getItem("email");
    const fetchData = async () => {
      refresh();
      const rawResponse = await fetch(
        SERVER_URL + "/account?email=" + email + "&campaign=" + campaignid,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
          },
        }
      );
    };
    fetchData();
    alert(
      "Request to change plan received.  You will receive an email followup from our account team shortly."
    );
  };

  useEffect(() => {
    const body = document.querySelector("body");

    function showDeleteAcntModal(e) {
      body.classList.add("modal-open");
      body.style.paddingRight = "17px";
      e.preventDefault();
    }
    document.addEventListener(
      "click",
      function (e) {
        for (
          let target = e.target;
          target && target !== this;
          target = target.parentNode
        ) {
          if (
            target.matches(
              ".delete-account-info .delete-account, .card-item .card-content-wrap .delete-btn"
            )
          ) {
            showDeleteAcntModal.call(target, e);
            break;
          }
        }
      },
      false
    );
    function hideDeleteAcntModal(e) {
      body.classList.remove("modal-open");
      body.style.paddingRight = "0";
      e.preventDefault();
    }
    document.addEventListener(
      "click",
      function (e) {
        for (
          let target = e.target;
          target && target !== this;
          target = target.parentNode
        ) {
          if (
            target.matches(
              ".account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn"
            )
          ) {
            hideDeleteAcntModal.call(target, e);
            break;
          }
        }
      },
      false
    );

    let email = localStorage.getItem("email");
    const url = location.email ? '/campaign/info?email=' + location.email + '&campaignid=' + currentCampaignID : '/campaign/info?email=' + email + '&campaignid=' + currentCampaignID;
    const fetchData = async () => {
      refresh();
      const rawResponse = await fetch(SERVER_URL + url, {
      // const rawResponse = await fetch(SERVER_URL + "/campaign?email=" + email, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem(IDENTITY_USER_TOKEN_KEY),
        },
      });
      const content = await rawResponse.json();
      console.log(content);
      if (content && Array.isArray(content.message)) {
        // setCampaigns(content.message.filter(item => String(item.id) === String(currentCampaignID)));
        setCampaigns(content.message);
        console.log(content.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <main className="dashboard-page">
      {/* Header */}
      <GeneralHeader />

      {/* Breadcrumb */}
      <Breadcrumb
        CurrentPgTitle="Edit Campaign"
        MenuPgTitle="pages"
        img={sectiondata.dashboard.breadcrumbimg}
      />


      {/*<TestVanillaJs />*/}
      

      <section className="dashboard-area padding-bottom-70px">
        <div className="container">
          <div className="row">
            <Link to="/dashboard/campaigns">{"<< Back to My Campaigns"}</Link>
            <div className="col-lg-12">
              <div className="tab-content" id="nav-tabContent">
                <div className="row res-margin">
                  {campaigns.map((item, i) => {
                    return (
                      <div key={i} className="col-lg-12 col-md-12 col-sm-12 mr-4 ml-4">
                        <SingleCampaignItems
                          campaign={item}
                          changeAccount={changeAccount}
                        />
                      </div>
                    );
                  })}
                  {/* {campaigns && campaigns.length > 0 &&
                    <div key="0" className="col-lg-12 col-md-12 col-sm-12 mr-4 ml-4">
                      <SingleCampaignItems
                        campaign={campaigns[0]}
                        changeAccount={changeAccount}
                      />
                    </div>
                  } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {
        loading && (
          <Loader style={{  marginBottom: '30px' }} message="Loading campaign details, please wait..." />
        )
      }

      {/* Footer */}
      <Footer />

      <ScrollTopBtn />

      {/* Modal */}
      <div className="modal-form text-center">
        <div
          className="modal fade account-delete-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
        >
          <div className="modal-bg"></div>
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content p-4">
              <div className="modal-top border-0 mb-4 p-0">
                <div className="alert-content">
                  <span className="la warning-icon">
                    <AiOutlineExclamationCircle />
                  </span>
                  <h4 className="modal-title mt-2 mb-1">
                    Your account will be deleted permanently!
                  </h4>
                  <p className="modal-sub">Are you sure to proceed.</p>
                </div>
              </div>
              <div className="btn-box">
                <button
                  type="button"
                  className="theme-btn border-0 button-success mr-1"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="theme-btn border-0 button-danger"
                >
                  delete!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SingleCampaign;
